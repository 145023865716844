import React, { useState, useCallback, useRef, useEffect } from 'react';
import AddSelectBox from 'components/form/AddSelectBox';
import useOutsideClick from "components/hook/useOutsideClick";
import i18next from 'i18next';
import { storePlaylistLayer, fetchPlaylistLayer } from "actions/playlist.action";
import { useDispatch } from "react-redux";

const LAYER = {
    LIST : "LIST",
    ADD : "ADD",
}

export default function AddLayer({ref, layers, title_no, onLayer, onTitle, top, left}) {
    const currentRef = useRef(ref);
    const [title, setTitle] = useState("");
    const [display, setDisplay] = useState("Y"); // 공개 / 비공개 세팅
    const [share, setShare] = useState("Y"); // 재생목록 퍼가기 허용/비허용
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    useOutsideClick(currentRef, () => {
        onLayer('');
        onTitle(0);
    });

    useEffect(() => {
        if(layers === "ADD") {
            setVisible(true);
        } 
    }, []);

    // 제목 변경
	const handleChangeTitle = useCallback(
		(event) => {
			const value = event.target.value.trimStart();
			if (value.length > 30) {
				return false;
			}

			setTitle(value);
		},
		[],
	);

    // 공개 / 비공개 세팅
	const handleChangeDisplay = useCallback((value) => {
		setDisplay(value);
	}, []);

    // 재생목록 퍼가리 허용/비허용
	const handleChangeShare = useCallback((value) => {
		setShare(value);
	}, []);

	// 재생목록 추가
	const handleChangeLayer = useCallback((layer, titleNo) => (event) => {
        // // 재생 목록 추가
        dispatch(storePlaylistLayer(title, display, share));

        // 재생목록 리스트 레이어로 이동
        if(title && display && share) {
            setTimeout(() => {
                dispatch(fetchPlaylistLayer(titleNo));
                onLayer(layer);
                onTitle(titleNo);
            }, 500);
        }
        event.preventDefault();
    }, [ dispatch, title, display, share, onLayer, onTitle ]);

    const handleClickLayer = useCallback(() => (event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }, []);

    
    return (
        <>
            <div ref={currentRef} className="ui-pop PlayListAdd AddList" style={{ display: visible ? "block" : "none", position: "absolute", top: top, left: left}} onClick={handleClickLayer}>
				<div className="pop-title">
                    <h3>{i18next.t(`새 재생목록 만들기`)}</h3>
                </div>
                <div className="pop-body">
                    <div className="ListForm">
                        <div className="title">
                            <input type="text" placeholder={i18next.t(`그룹명을 입력해주세요.`)} value={title} onChange={handleChangeTitle} />
                            <span className="bytes"><em>{title.length}/</em>30</span>
                        </div>
                        <div className="select">
                            <AddSelectBox 
                                className="select_wrap"
                                options={[{ value: "Y", name: i18next.t(`목록 공개`) }, { value: "N", name: i18next.t(`목록 비공개`) }]}
                                value={display}
                                onChange={handleChangeDisplay}
                                stopPropagation={true}
                            />
                            {/* 재생목록 저장 허용 / 비허용 */}
                            <AddSelectBox
                                className="select_wrap"
                                options={[{ value: "Y", name: i18next.t(`재생목록 저장 허용`) }, { value: "N", name: i18next.t(`재생목록 저장 비허용`) }]}
                                value={share}
                                onChange={handleChangeShare}
                                stopPropagation={true}
                            />
						</div>
                    </div>
                </div>
                <div className="pop-btn">
					<a href="#" className="btn blue" onClick={handleChangeLayer('LIST', title_no)}>{i18next.t(`추가`)}</a>
				</div>
                <a href="#" className="pop-close" onClick={(e) => { e.preventDefault(); onLayer(''); onTitle(0)}}><span>{i18next.t(`닫기`)}</span></a>
			</div>
        </>
    );
}