import React, { useCallback, useMemo, useState,useContext } from "react";
import { useAuth } from "react-navigationbar";
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router";
import { fetchUpDeleteVod, fetchRecentVodDelete } from 'actions/vods.action';
import ContextMenu from "components/ict/ContextMenu";
import VodThumbnail from "components/thumbnail/VodThumbnail";
import ThreeDotHistoryButton from "components/button/ThreeDotHistoryButton";
import ShareButton from "components/button/ShareButton";
import { LaterViewAddButton, fetchLaterDelete, fetchLaterVodPlay } from  "components/vods/LaterViewButton";
import {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";
import ProfileImage from "components/image/ProfileImage";
import ItemCommon from "components/item/ItemCommon";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, SUBSCRIBE_ROUTE, HISTORY_RECENT_VOD_ROUTE, 
    HISTORY_LATER_ROUTE, HISTORY_UP_VOD_ROUTE, HISTORY_RECENT_ROUTE, HISTORY_PLAYLIST_ROUTE } from "constant/routeUrl";
import i18next from "i18next";
import { vodInflowLog } from "libs/log";
import { makeVodUrl, numberCommaString, sendLogApi, regDateConvert } from "helpers";
import { makeProfileUrl, makeStationUrl } from "helpers";

import useAutoHashTag from "components/hook/useAutoHashTag";

export default function VodItem({ vod, path3, onClose=null, page=1, sortColumn="", onChangeLayer, onPosition, onCallBackChangeLayer }) {
    const { pathname } = useLocation();
    const { isLogin } = useAuth();
    const { getTagStyle } = useAutoHashTag();
    const [layer, setLayer] = useState('');
    const [titleNo, setTitleNo] = useState(0);
    const dispatch = useDispatch();
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);

    const { hash_tags = [] , category_tags = [], auto_hashtags = [] } = vod; // 해시태그, 고정태그
    const [top, setTop] = useState(0);
    const { hashtagUrl } = ItemCommon();

    /**
     * 영상 진입 로그 호출
     */
    const onClickVodLink = useCallback(
        (data, title_no) => (e) => {

            // 나중에 보기 update
            if(pathname === "/history/later") {
                setTimeout(() => {
                    dispatch(fetchLaterVodPlay(title_no));

                }, 1000);
            }
            // watch_type=vod&bj_id=youja2016&title_no=1000134416&vtype=NORMAL&location=subsbj
            let sendData = {
                watch_type: "vod",
                bj_id: data.user_id,
                title_no: data.title_no,
                vtype: data.file_type
            };
            let codeType = "";
            let pathType = "";
            let buttonType = "";
            let etcData = {
                //'tag': hash_tags.join("|"),
                //'category_tag': category_tags.join("|"),
            }
            switch (pathname) {
                case FAVORITE_ROUTE:
                    codeType = "main_00000006";
                    pathType = "fav";
                    etcData = {
                        ...etcData,
                        'group_type': groupIdx ===0 ? 'all' : 'custom',
                        'group_name': groupIdx > 0 ? groupTitle : '',
                    }
                    break;

                case SUBSCRIBE_ROUTE:
                    codeType = "main_00000007";
                    sendData["location"] = "subsbj";
                    pathType = "subbj";
                    break;

                case FANCLUB_ROUTE:
                    codeType = "main_00000007";
                    sendData["location"] = "fanbj";
                    pathType = "fanbj";
                    break;

                case HISTORY_RECENT_VOD_ROUTE:
                    codeType = "my_history_recent";
                    pathType = "history";
                    buttonType = "to_vod";
                    break;

                case HISTORY_LATER_ROUTE:
                    codeType = "my_history_afterward";
                    pathType = "history";
                    buttonType = "to_vod";
                    break;

                case HISTORY_UP_VOD_ROUTE:
                    codeType = "my_history_up";
                    pathType = "history";
                    buttonType = "to_vod";
                    break;

                default:
                    return false;
            }
            sendLogApi(codeType, sendData, "web", buttonType,);
            vodInflowLog(data.user_id, data.title_no, ["my", pathType, path3 || "vod"], etcData);
        },
        [pathname, category_tags, hash_tags, path3, dispatch, groupIdx, groupTitle],
    );

    const handleLaterDeleteClick = useCallback((isLogin, titleNo, type) => (event) => {
        dispatch(fetchLaterDelete(isLogin, titleNo, type));
    }, [dispatch]);

    const handleUpDeleteClick = useCallback((bjId, titleNo) => (event) => {
        dispatch(fetchUpDeleteVod(bjId, titleNo));
    }, [dispatch]);

    const handleRecentVodDeleteClick = useCallback((titleNo) => (event) => {
        dispatch(fetchRecentVodDelete(titleNo));
    }, [dispatch]);

    
    const handlePosition = useCallback((top, left) => {
        onPosition(top, left);
        setTop(top);
    }, [onPosition, setTop]);
    
    const handleClickLayer = useCallback((layer, titleNo) => (event) => {
        setLayer(layer);
        setTitleNo(titleNo);
    }, [ setLayer, setTitleNo]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        onChangeLayer(layer, titleNo);
        setLayer(layer);
        setTitleNo(titleNo);
    }, [onChangeLayer, setLayer, setTitleNo]);

    const handleChangeStyle = useCallback((hoverCheck, style, hover) => (event) => {
        if(hoverCheck) {
            if(hover) {
                event.target.style.background = hover.hover.background;
            }
        } else {
            if(style) {
                event.target.style.background = style.background;
            }
        }
    }, []);
    /**
     * 동영상 플레이어페이지 URL
     */
    const url = useMemo(() => makeVodUrl(vod.title_no, pathname, sortColumn, page), [vod.title_no, pathname, sortColumn]);

    // PATH
    const _FAVORITE = useMemo(()=> pathname === FAVORITE_ROUTE, [pathname]); // 즐겨찾기한 BJ
    const _SUBSCRIBE = useMemo(() => pathname === SUBSCRIBE_ROUTE, [pathname]); // 구독한 BJ
    const _FANCLUB = useMemo(() => pathname === FANCLUB_ROUTE, [pathname]); // 팬가입한 BJ

    //기록
    const _RECENT = useMemo(() => pathname === HISTORY_RECENT_ROUTE, [pathname]); // 최근 본 BJ의 LIVE
    const _HISTORY_RECENT_VOD = useMemo(() => pathname === HISTORY_RECENT_VOD_ROUTE, [pathname]); // 최근 본 BJ의 LIVE
    const _HISTORY_LATER = useMemo(() => pathname === HISTORY_LATER_ROUTE, [pathname]); // 나중에 보기
    const _HISTORY_UP_VOD = useMemo(() => pathname === HISTORY_UP_VOD_ROUTE, [pathname]); // UP한 VOD
    const _HISTORY_PLAYLIST= useMemo(() => pathname === HISTORY_PLAYLIST_ROUTE, [pathname]); // 재생목록

    ///history/recent/vod  HISTORY_RECENT_VOD_ROUTE
    return (
        <>
            <VodThumbnail href={url} vod={vod} onClick={onClickVodLink(vod, vod.title_no, url)} pathname={pathname} />
            <div className="cBox-info">
                <ProfileImage 
                    tag="a" 
                    className="thumb" 
                    url={makeStationUrl(vod.user_id)}
                    src={makeProfileUrl(vod.user_id)}
                    user_id={vod.user_id}
                    childrenTag="img"
                    childrenTagProps = {{
                        src:makeProfileUrl(vod.user_id),
                        loading: "lazy"
                    }}
                />

                <div className="details">
                    <div className="nick_wrap">
                        <ContextMenu user_id={vod.user_id} user_nick={vod.user_nick}>
                            <div className="nicknames">
                                <a href="#">
                                    <span>{vod.user_nick}</span>
                                </a>
                                {vod.is_subscribe ? <span className="ico_subscribe">{i18next.t(`구독`)}</span> : ``}
                                {vod.is_fanclub ? <span className="ico_fan">{i18next.t(`팬`)}</span> : ``}
                            </div>
                        </ContextMenu>
                        {vod.original_user_id && (
                            <div className="clip_nick">
                                <ContextMenu user_id={vod.original_user_id} user_nick={vod.original_user_nick}>
                                    <a href="#">
                                        <span>{vod.original_user_nick}</span>
                                    </a>
                                </ContextMenu>
                            </div>
                        )}
                        {vod.copyright && (
                            <div className="clip_nick">
                                <ContextMenu user_id={vod.copyright.user_id} user_nick={vod.copyright.user_nick}>
                                    <a href="#">
                                        <span>{vod.copyright.user_nick}</span>
                                    </a>
                                </ContextMenu>
                            </div>
                        )}
                    </div>
                </div>

                <h3>
                    <a href={url} onClick={onClickVodLink(vod, vod.title_no, url)} target="_blank" className="title" title={vod.title_name}>
                        {vod.title_name}
                    </a>

                    { 
                        <ThreeDotHistoryButton

                            trigger={
                                <button type="button" className="more_dot">
                                    <span>더보기 메뉴</span>
                                </button>
                            }
                            onPosition={handlePosition}
                            onChangeLayer={handleChangeLayer}
                        >

                        {/* 나중에 보기 등록 (나중에 보기, 재생목록 제외)*/}
                        {( _HISTORY_PLAYLIST === false && _HISTORY_LATER === false ) && vod.file_type !=="CATCH" && 
                            <LaterViewAddButton no={vod.title_no} type="VOD" />
                        }

                        { _HISTORY_PLAYLIST === false && vod.file_type !=="CATCH" && 
                            <button type="button" onClick={onCallBackChangeLayer("LIST", vod.title_no)}>
                                <span>{i18next.t(`재생목록에 담기`)}</span>
                            </button>
                        }

                        <button type="button" onClick={handleClickLayer('SHARE', 0)}>
                            <span>{i18next.t(`공유하기`)}</span>
                        </button>

                        { _FAVORITE && 
                            <>
                                {vod.copyright  && <button type="button" onClick={onCallBackChangeLayer("FAVORITELIST", vod.copyright.user_id )}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                                {vod.original_user_id  &&<button type="button" onClick={onCallBackChangeLayer("FAVORITELIST", vod.original_user_id )}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                                {!vod.copyright && !vod.original_user_id &&vod.user_id && <button type="button" onClick={onCallBackChangeLayer("FAVORITELIST", vod.user_id )}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                            </>
                        }

                        { _HISTORY_UP_VOD && 
                            <button type="button" onClick={handleUpDeleteClick(vod.user_id, vod.title_no)}>
                                <span>{i18next.t(`UP 취소`)}</span>
                            </button>
                        }

                        { _HISTORY_RECENT_VOD && 
                            <button type="button" onClick={handleRecentVodDeleteClick(vod.title_no)}>
                                <span>{i18next.t(`최근 본 VOD 삭제`)}</span>
                            </button>
                        }

                        { _HISTORY_LATER && 
                            <button type="button" onClick={handleLaterDeleteClick(isLogin, vod.title_no, "VOD")}>
                                <span>{i18next.t(`나중에 보기 삭제`)}</span>
                            </button>
                        } 

                    </ThreeDotHistoryButton>}

                </h3>
                {layer === 'SHARE' && <ShareButton item={vod} top={top} type="VOD" layer={layer} setLayer={setLayer} onChangeLayer={handleChangeLayer}/>}
                
                <div className="vod_info">
                        <span className="views">{numberCommaString(vod.read_cnt)}</span>
                        <span className="date">{regDateConvert(vod.reg_date)}</span> 
                    </div>
                {/** 고정 태그, 카테고리 태그 추가 */}
                <div className="tag_wrap">
                    {auto_hashtags.length > 0 && auto_hashtags.map((hash, idx) => {
                        let tagStyle = getTagStyle(hash);
                        return hash !== "" && <a key={idx} href={hashtagUrl(hash, "VOD")} style={tagStyle.style} 
                            onMouseOver={handleChangeStyle(true, tagStyle.style, tagStyle.events)} 
                            onMouseOut={handleChangeStyle(false, tagStyle.style, tagStyle.events)}>#{hash}</a>
                    })}
                    {category_tags.length > 0 && category_tags.map((category, idx) => {
                        // [""] 으로 빈 값으로 들어오는 케이스가 있음
                        return category !== "" && <a key={idx} href={hashtagUrl(category, "VOD")}>#{category}</a>
                    })}
                    {hash_tags.length > 0 && hash_tags.map((hash, idx) => {
                        return hash !== "" && <a key={idx} href={hashtagUrl(hash, "VOD")}>#{hash}</a>
                    })}           
                </div>
            </div>
           
        </>
    );
}
