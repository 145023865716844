import { toast } from "afreecatv-react-toast";
import { VOD_URL, ROOT_URL } from "constant/config";
import { goLogin, handleFetchErrors } from "helpers";
import i18next from "i18next";
import React from "react";
import { useAuth } from "react-navigationbar";
import { VODS } from "actions/vods.action";

import { POST } from "constant/method";
import querystring from "querystring";

const handleClick = (isLogin, title_no, type) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!isLogin) {
        if (window.confirm(i18next.t("로그인이 필요한 기능입니다.\n로그인 하시겠습니까?"))) {
            goLogin();
        }
        return false;
    }

    const url = `${VOD_URL}/api/later_view_api.php?szWork=ADD&nTitleNo=${title_no}&szVodType=${type}&szSysType=web&szLocation=list&_=${Math.random()}`;
    fetch(url, {
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            if (response.RESULT === 1) {
                toast.success(i18next.t("나중에 보기에 등록되었습니다."));
            } else if (response.RESULT === -2) {
                toast.error(i18next.t(response.MSG));
            } else {
                toast.error(i18next.t(response.MSG));
            }
        })
        .catch(() => {
            toast.error(i18next.t(`잠시후 다시 시도해주세요.`));
        });
};

const handleLaterAddClick = (isLogin, no, type) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    if(!isLogin) {
        if (window.confirm(i18next.t(`로그인이 필요한 기능입니다.\n로그인 하시겠습니까?`))) {
            goLogin();
        }
        return false;
    }

    const url = `${ROOT_URL}/history/later/${type}/${no}`;

    fetch(url, {
        method: "POST",
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            toast.success(i18next.t(response.message));

        })
        .catch((error) => {
            toast.error(i18next.t(error.message));
        });
}

export const fetchLaterDelete = (isLogin, no, type) => {

    if(!isLogin) {
        if (window.confirm(i18next.t(`로그인이 필요한 기능입니다.\n로그인 하시겠습니까?`))) {
            goLogin();
        }
        return false;
    }

    return (dispatch) => {
            fetch( `${ROOT_URL}/history/later/${type}/${no}`, {
                method: POST,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: querystring.stringify({ _method: 'delete', }),
                credentials: "include",
            })
                .then(handleFetchErrors)
                .then((response) => response.json())
                .then((response) => {
                    
                    dispatch({
                        type: VODS.VOD_FETCH_DELETE_SUCCESS,
                        payload: response
                    });
                    toast.success(i18next.t(response.message));
                })
                .catch((error) => {
                    toast.error(i18next.t(error.message));
                });
        }
}

export const fetchLaterVodPlay = (titleNo) => {
    return (dispatch) => {
        fetch( `${ROOT_URL}/history/later/vod/${titleNo}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: 'PUT', }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                // toast.success(i18next.t(response.message));
                dispatch({
                    type: "LATER_VOD_PLAY",
                    payload: response
                });
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}

/**
 * 방송국 새 글 나중에보기
 * @param {*} param0
 */
export default function LaterViewButton({ title_no, type = "VOD", className = "btn-later" }) {
    const { isLogin } = useAuth();
    return (
        <button type="button" className={className} tip={i18next.t("나중에 보기")} onClick={handleLaterAddClick(isLogin, title_no, type)}>
            <span>{i18next.t("나중에 보기")}</span>
        </button>
    );
}

/**
 * 라이브버튼
 * @param {*} param0
 */
export function LiveLaterViewButton({ title_no, type = "VOD", className = "btn-later" }) {
    return <LaterViewButton title_no={title_no} className={className} type={"LIVE"} />;
}

/**
 * 즐찾쪽 나중에보기
 * @param {*} param0
 */
export function LaterWatchButton({ type, no }) {
    const { isLogin } = useAuth();
    return (
        <span className="laterwatch">
            <a href="#" onClick={handleClick(isLogin, no, type)} className="icon">
                <span className="btn">{i18next.t(`추가하기`)}</span>
                <em className="ttip">
                    {i18next.t(`나중에 보기`)}
                    <span className="arr"></span>
                </em>
            </a>
        </span>
    );
}

export function LaterViewAddButton({no, type}) {
    const {isLogin} = useAuth();
    return (
        <button type="button" onClick={handleLaterAddClick(isLogin, no, type)}>
            <span>{i18next.t(`나중에 보기`)}</span>
        </button>
    );
}

// export function LaterViewDeleteButton({no, type}) {
//     const {isLogin} = useAuth();
//     return (
//         <button type="button" onClick={handleLaterDeleteClick(isLogin, no, type)}>
//             <span>{i18next.t(`나중에 보기 삭제`)}</span>
//         </button>
//     );
// }