import { fetchBroad } from "actions/broading.action";
import { fetchSubscribe } from "actions/subscribe.action";
import { fetchVod } from "actions/vods.action";
import BroadingSection from "components/section/BroadingSection";
import TitleSection from "components/section/TitleSection";
import VodSection from "components/section/VodSection";
import useCustomListModal from "components/vods/useCustomListModal";
import ListLayer from "components/vods/ListLayer";
import AddLayer from "components/vods/AddLayer";

import { SUBSCRIBE_ROUTE } from "constant/routeUrl";
import { goLogin } from "helpers";
import i18next from "i18next";
import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "react-navigationbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import SubscribeListSection from "./SubscribeListSection";
import useAdBanner from "components/hook/useAdBanner";
import MidBanner from "pages/banner/MidBanner";

export default function Subscribe() {
    const { pathname, key } = useLocation();
    const [isLoad, setIsLoad] = useState(false);


    // dispatch
    const dispatch = useDispatch();
    const { isLogin } = useAuth();

    // useSelector
    const { data } = useSelector((state) => state.subscribeReducer, shallowEqual);

    const { 
        customLayer, customTop, customLeft, titleNo,
        setCustomLayer, setTitleNo,
        handleCustomLayer, handleCustomPosition, handleCustomCallbackLayer
    } = useCustomListModal();

    const { renderBanner } = useAdBanner();

    // useEffect
    useEffect(() => {
        initSetting();
        setIsLoad(true);
    }, [dispatch, isLogin, pathname]);

    //최초 진입 이후 LNB 동일 영역 클릭시 동작
    useEffect(()=>{
        if(isLoad && pathname === SUBSCRIBE_ROUTE){
            window.location.reload();
        }
    }, [key])

    /**
     * 구독데이터가 변경되면 방송중데이터 갱신
     */
    useEffect(() => {
        dispatch(fetchBroad(data, pathname));
    }, [data, dispatch]);

    const initSetting = ()=>{
        if (isLogin) {
            // 구독 정보 조회
            dispatch(fetchSubscribe());
            // 구독한 BJ의 VOD 조회
            dispatch(fetchVod(pathname));
           
        } else {
            goLogin();
        }
    }


    return (
        <>
            <div id="list-section">
                <TitleSection big className="line_none">
                    {i18next.t(`구독한 BJ`)}
                </TitleSection>
                {/* 서브브랜딩 배너 추가 */}
                <MidBanner content={renderBanner}></MidBanner>
                {data.length > 0 && (
                    <>
                        <BroadingSection layer={setCustomLayer} handlePosition={handleCustomPosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer} />
                        <VodSection layer={setCustomLayer} handlePosition={handleCustomPosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer}  />
                    </>
                )}
                <SubscribeListSection />
            </div>

            {customLayer === "LIST" && <ListLayer layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo}  pathname={pathname} top={customTop} left={customLeft} />}
            {customLayer === "ADD" && <AddLayer layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo} top={customTop} left={customLeft} />}
        </>
    );
}
