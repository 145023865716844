import { FEED } from "actions/feed.action";
import { FEED_LIKE } from "actions/feed.like.action";
import produce from 'immer';

const initialState = {
    loading: false,
    meta: {},
    data: [],
    has_more: true,
    index_reg_date: null,
    error: null
};

const feedReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEED.INIT:
            return initialState;
        case FEED.LOADING_START:
            return { ...state, loading: true };

        case FEED.LOADING_DONE:
            return { ...state, loading: false };

        case FEED.FETCH_SUCCESS:
            return produce(state, (draftState) => {
                draftState.has_more = action.payload.has_more
                draftState.meta = action.payload.meta
                draftState.data = state.data.concat(action.payload.data);
                if (draftState.data.length) {
                    draftState.index_reg_date = draftState.data[draftState.data.length - 1].index_reg_date
                }
            });

        case FEED.FETCH_ERROR:
            return { ...state, error: action.payload };

        /**
         * 조회수 올리고 올린시간 저장
         */
        case FEED.HIT:
            return produce(state, (draftState) => {
                let feed = draftState.data[draftState.data.findIndex(todo => todo.title_no === action.title_no)]
                feed.hit_time = action.time;
            });
        /**
         * 좋아요
         */
        case FEED_LIKE.LIKE:
            return produce(state, (draftState) => {
                let feed = draftState.data[draftState.data.findIndex(todo => todo.title_no === action.title_no)]
                if (feed) {
                    feed.is_like = true;
                }
            });
        /**
         * 좋아요 취소
         */
        case FEED_LIKE.UNLIKE:
            return produce(state, (draftState) => {
                let feed = draftState.data[draftState.data.findIndex(todo => todo.title_no === action.title_no)]
                if (feed) {
                    feed.is_like = false;
                }
            });



        default:
            return state;
    }
};

export default feedReducer;