import { useEffect, useState, useMemo, useCallback } from 'react'
import { shallowEqual,useDispatch, useSelector } from "react-redux";
import { fetchAutoHashTag } from "actions/tag.action";

const useAutoHashTag = () => {
    const dispatch = useDispatch();
    const [ dark, setDark ] = useState(window.isDark);

    const { data:autoHashTagStyle } = useSelector((state) => (state.autoHashTagReducer), shallowEqual);

    const lang = document.documentElement.lang;
    
    const initAutoHashStyle = () => {
        if(autoHashTagStyle.length < 1) {
            dispatch(fetchAutoHashTag());
        }
    }
    // 스타일 가져오기
    const getTagStyle = (key) => {
        let styles = { style: null, events: null };
        
        if(autoHashTagStyle && autoHashTagStyle.length > 0) {
            autoHashTagStyle.find((styled) => {
                if(styled.tag_name === key) {
                    if(dark && lang == 'ko') {
                        styles = {
                            'style': styled.dark.pc.style,
                            'events': styled.dark.pc.event
                        }
                    } else {
                        styles = {
                            'style': styled.light.pc.style,
                            'events': styled.light.pc.event
                        }
                    }
                }        
            });
        } 
        return styles;
    }

    // Cookie값 가져오기 (body나, 다크모드 체크박스 값 가져올수 있으면 그걸로 사용예정)
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    useEffect(() => {
        setDark(getCookie('theme') === 'dark');
    }, [setDark, getCookie]);

    useEffect(() => {
        // 다크모드 변환 체크 
        let darkCheckbox = document.querySelector("#modecheck");

        const onDark = () => {
            setDark(getCookie('theme') === 'dark');
        }
        
        darkCheckbox && darkCheckbox.addEventListener('change', onDark);

        return () => darkCheckbox && darkCheckbox.removeEventListener('change', onDark);
    }, []);
    
    return useMemo(() => ({
        dark,
        initAutoHashStyle,
        getTagStyle
    }), [dark, initAutoHashStyle, getTagStyle]);
};

export default useAutoHashTag;