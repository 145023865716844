import React, { useEffect, useState } from 'react'
import { fetchHashtags } from 'actions/feed.action'
import { Link } from 'react-router-dom';
import { FEED_ROUTE } from 'constant/routeUrl';
import { Trans } from 'react-i18next';


/**
 * 핫키워드
 */
export default function HotKeyword() {

    const [hashtags, setHashtags] = useState([])

    useEffect(() => {
        fetchHashtags().then((response) => {
            setHashtags(response.data);
        });

    }, [])

    return (
        <div className="keyword_box">
            <h3 className="title"><Trans>핫 키워드</Trans></h3>
            <div className="keyword_list">
                {hashtags.map((hashtag, index) => <Link key={index} to={`${FEED_ROUTE}/hashtag/${hashtag}`}><span>{`#${hashtag}`}</span></Link>)}
            </div>
        </div>
    )
}
