import React, { useCallback, useEffect, useMemo, useState } from "react";
import i18next from "i18next";
import classNames from "classnames";
import {isAdultType} from "helpers";

export default function VodThumbnail({ href, onClick, vod, count = {}, display = {} }) {
    const [imgStatus, setImgStatus] = useState(true);
    const [nonDisplay, setNonDisplay] = useState(false);
    const [webpView, setWebpView] = useState(false);

    const { vertical_thumb, grade, category, vertical_webp_path, thumb, webp_path, thumb_type, ucc_type, auth_no, resolution_type } = vod;

    const isAdult = isAdultType(grade,category);

    const handleError = useCallback(() => {
        setImgStatus(false);
    }, []);

    /**
     * 마우스 진입
     */
    const onMouseEnter = useCallback((e) => {
        setNonDisplay(true);
    }, [setNonDisplay]);

    const onMouseLeave = useCallback((e) => {
        setNonDisplay(false);
    }, [setNonDisplay]);
    
    const thumbnail = useMemo(() => {

        if (isAdult === true) {
            setWebpView(false);
            return (
                <span className="thumb-adult"></span>
            );
        } else if (imgStatus === false) {
            setWebpView(false);
            return (
                <span className="thumb-default"></span>
            );
        } else {
            if(vertical_webp_path) {
                //setWebpView(true);
                setWebpView(false);
                if(parseInt(ucc_type) === 22) {
                    setWebpView(false);
                }
            } else {
                setWebpView(false);
            }
        }
    }, [vod, imgStatus, href, onClick, handleError, setWebpView]);

    const nonViewCheck = useMemo(() => {
        return false;
        
        if (isAdult === true) {
            return true;
        }

        if(parseInt(ucc_type) === 22) {
            return true;
        }
        
        if(vertical_webp_path) {
            return false;
        } else {
            return true;
        }

    }, [vertical_webp_path, isAdult]);

    return (
        <>
            {thumbnail}
            {!thumbnail && 
                <span className={classNames("thumb", {thumb_16_9: resolution_type == "horizontal"})} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <img src={resolution_type == "horizontal" ? (nonDisplay && webpView) ? webp_path : thumb : (nonDisplay && webpView) ? vod.vertical_webp_path : vertical_thumb} onError={handleError} alt="" loading="lazy" />
                    {nonViewCheck && (<span className="nonView" style={ nonDisplay ? {display: 'flex'} : {display: 'none'}}>{i18next.t(`미리보기를 제공하지 않는 VOD`)}</span>)}
                    {resolution_type == "horizontal" && (
                        <div className="thumb_blur">
                            <img src={(nonDisplay && webpView) ? webp_path : thumb} onError={handleError} alt="" loading="lazy" />
                        </div>
                    )}
                </span>}
        </>
    );
}