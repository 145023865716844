import produce from "immer";

const initialState = {
    modal:{
        listgroup :{
            type: "",
            menuTitle : "",
            onModal : false,
            isHidden : false,
        },
        fixgroup:{
            type: "",
            selIdx:0,
            itemTitle : "",
            menuTitle : "",
            onModal : false,
        }
    },
};

export const GLOBALSTATE ={
    SET_GLOBAL_LISTGROUP_MODAL:"global/SET_GLOBAL_LISTGROUP_MODAL",
    SET_GLOBAL_FIXGROUP_MODAL:"global/SET_GLOBAL_FIXGROUP_MODAL"
}


const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case GLOBALSTATE.SET_GLOBAL_LISTGROUP_MODAL:
            return produce(state, (draftState)=>{
                draftState.modal.listgroup = action.payload
            });

        case GLOBALSTATE.SET_GLOBAL_FIXGROUP_MODAL:
            return produce(state, (draftState)=>{
                draftState.modal.fixgroup = action.payload
            });

        default:
            return state;
    }
};

export default globalReducer;
