import { setMobilePushFlag } from "actions/alarm.action";
import classNames from "classnames";
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function AlarmButton({ item }) {
    const { t } = useTranslation();
    //dispatch
    const dispatch = useDispatch();
    /**
     * 알림 받지 않기 버튼
     */
    const handleClickAlarm = useCallback((item) => (e) => {
        e.preventDefault();
        const flag = item.is_mobile_push !== "Y" ? 1 : 0;

        dispatch(setMobilePushFlag(item.user_id, flag, item.user_nick));
    }, [dispatch]);

    const tip = useMemo(() => (item.is_mobile_push === "Y" ? t("알림 받지 않기") : t("알림 받기")), [item.is_mobile_push, t]);

    return (
		<button type="button" className={classNames("btn-alarm ", { on: item.is_mobile_push === "Y" })} tip={tip} onClick={handleClickAlarm(item)}>
			<span>{tip}</span>
		</button>
	);
}
