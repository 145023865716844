import React, { useCallback, useEffect, useMemo, useState } from "react";
import LaterViewButton from "components/vods/LaterViewButton";
import i18next from "i18next";
import { useLocation } from "react-router";

import {SUBSCRIBE} from "constant/auth";
import {JAVASCRIPT_VOID} from "constant/config";
import {isAdultType} from "helpers";

export default function VodThumbnail({ href, onClick, vod, count = {}, display = {} }) {
    const [imgStatus, setImgStatus] = useState(true);
    const [nonDisplay, setNonDisplay] = useState(false);
    const [webpView, setWebpView] = useState(false);

    const { pathname } = useLocation();

    const { playlist_cnt = {} } = count;
    const { thumb, pc_thumb, grade, category, webp_path, thumb_type, ucc_type, auth_no } = vod;

    const isAdult = isAdultType(grade,category);

    const handleError = useCallback(() => {
        setImgStatus(false);
    }, []);

    /**
     * 마우스 진입
     */
    const onMouseEnter = useCallback((e) => {
        setNonDisplay(true);
    }, [setNonDisplay]);

    const onMouseLeave = useCallback((e) => {
        setNonDisplay(false);
    }, [setNonDisplay]);

    const probar = useMemo(() => {
        let probar = 0;
        probar = vod.last_view_duration / vod.total_duration * 100;

        return probar;
    }, [vod.last_view_duration, vod.total_duration]);

    const handleLinkVod = (url) => {
        window.open(url, "_blank","noopener");
        
        onClick();
        return false;
    };
    
    const thumbnail = useMemo(() => {

        if (isAdult === true) {
            setWebpView(false);
            return (
                <a href={href} onClick={(event) => { event.preventDefault(); handleLinkVod(href);}} className="thumb-adult">
                    19금
                </a>
            );
        } else if (imgStatus === false) {
            setWebpView(false);
            return (
                <a href={href} onClick={(event) => { event.preventDefault(); handleLinkVod(href);}} className="thumb-default">
                    기본
                </a>
            );
        } else {
            if(webp_path) {
                setWebpView(true);
                if(vod.file_type === "REVIEW") { // 다시 보기 일떄는
                    setWebpView(false);
                } 
                if(parseInt(ucc_type) === 22) {
                    setWebpView(false);
                } 
                if(auth_no === SUBSCRIBE) {
                    setWebpView(false);
                }
            } else {
                setWebpView(false);
            }
        }
    }, [vod, imgStatus, href, onClick, handleError, setWebpView]);

    const laterViewCheck = useMemo(() => {
        if(pathname === "/history/later") {
            return false;
        } else {
            return true;
        }
    }, [pathname]);

    const nonViewCheck = useMemo(() => {
        if (isAdult === true) {
            return true;
        }

        if(parseInt(ucc_type) === 22) {
            return true;
        }

        if(vod.file_type === "REVIEW") {
            return true;
        }

        if(auth_no === SUBSCRIBE) {
            return true;
        }

        if(webp_path) {
            return false;
        } else {
            return true;
        }

    }, [webp_path, isAdult]);

    const subscribeCheck = useMemo(() => {
        if(auth_no === SUBSCRIBE) {
            return true;
        }
    },[auth_no]);

    const pcThumb = useMemo(() => {
        let thumbnail = pc_thumb ? pc_thumb : thumb;
        
        if(typeof thumbnail === 'string') {
            thumbnail = thumbnail.replace(/(_r)/g, "_l");
        }

        return thumbnail;
    }, [pc_thumb, thumb]);
    return (
        <div className="thumbs-box" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {thumbnail}
            {!thumbnail && 
                <a href={href} onClick={(event) => { event.preventDefault(); handleLinkVod(href);}} data-webp={vod.webp_path}>
                    <img src={(nonDisplay && webpView) ? vod.webp_path : pcThumb} onError={handleError} alt="" loading="lazy" />
                </a>}
            {vod.file_type === "HIGHLIGHT" && <span className="hilight">하이라이트</span>}
            {vod.file_type === "REVIEW" && <span className="replay">{i18next.t(`다시보기`)}</span>}
            {subscribeCheck && <span className="subscribe">{i18next.t(`구독전용`)}</span>}
            {nonViewCheck && (<span className="nonView" style={ nonDisplay ? {display: 'flex'} : {display: 'none'}}>{i18next.t(`미리보기를 제공하지 않는 VOD`)}</span>)}
            {vod.file_type === "NORMAL" && vod.is_ppv === true && <span className="ppv">{i18next.t(`유료`)}</span>}
            {/**<span className="vr360">vr 방송</span>*/}
            {vod.file_type !== "CATCH" ? <span className="time">{vod.duration}</span> : <span className="ic_catch">캐치</span>}

            {laterViewCheck && vod.file_type !== "CATCH" && <LaterViewButton type="VOD" title_no={vod.title_no} />}
            
            {vod.last_view_duration > 0 && vod.file_type !== "CATCH" && ( 
                <span className="prograss">
                    <em className="status" style={{width: `${probar}%`}} ></em>
                </span>
            )}
        </div>
    );
}
