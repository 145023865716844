import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { fetchVod } from "actions/vods.action";
import { compare_broad } from "helpers";
import SortButtonGroup from "components/button/SortButtonGroup";
import VodItem from "components/item/VodItem";
import LiveItem from "components/item/LiveItem";
import EmptySection from "components/section/EmptySection";
import i18next from "i18next";
import { DESC, getLaterSort, OFFSET_SIZE, LAST_VOD_START } from "constant/sort";
import ListLayer from "components/vods/ListLayer";
import AddLayer from "components/vods/AddLayer";
import { fetchLaterDelete } from "components/vods/LaterViewButton";
import { useAuth } from "react-navigationbar";
import LoadingSection from "components/section/LoadingSection";
import { VODS } from "actions/vods.action";

export default function HistoryLaterVodSection () {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(LAST_VOD_START);
    const [limit, setLimit] = useState(30);
    const [options, setOptions] = useState(getLaterSort);

    const [layer, setLayer] = useState("");
    const [titleNo, setTitleNo] = useState(0);

    const [ top, setTop ] = useState(0);
    const [ left, setLeft ] = useState(0);

    const { isLogin } = useAuth();

    const { data, hasMore = false, loading = false, status = '', currentPage = 0 } = useSelector(state => (state.vodsReducer), shallowEqual);

    const filteredData = useMemo(() => {
        return data.slice().sort(compare_broad(sortColumn, sortOrder)).slice(0, limit * currentPage);
    }, [data, limit, sortColumn, sortOrder, currentPage]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, [setSortColumn, setSortOrder]);

    useEffect(() => {
        function onScroll() {
            if(window.scrollY + document.documentElement.clientHeight > document.documentElement.scrollHeight - 10) {
                if(hasMore && !loading) {
                    dispatch(fetchVod('/history/later', "?page="+(currentPage + 1)));
                }
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [loading, hasMore, currentPage]);

    const handleLaterDelete = useCallback((deleteType) => (e) => {
        let confirmMsg = deleteType == "view" ? i18next.t(`재생한 VOD를 모두 삭제하겠습니까?`) : i18next.t(`나중에 보기 목록을 모두 삭제하시겠습니까?`);

        if (!window.confirm(i18next.t(confirmMsg))) {
            return;
        }

        dispatch(fetchLaterDelete(isLogin, deleteType, "VOD"));

        if(deleteType === 'all') {
            dispatch({ type: VODS.VOD_INIT });

            dispatch({ type: VODS.VOD_LOADING_DONE });
        }
    }, [dispatch]);

    const handleLayer = useCallback((layer, titleNo) => (event) => {
        setLayer(layer);
        setTitleNo(titleNo);
    }, [setLayer, setTitleNo]);

    const handlePosition = useCallback((top, left) => {
        setTop(top);
        setLeft(left);
    }, [setTop, setLeft]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        setLayer(layer);
        setTitleNo(titleNo);
    },[setLayer, setTitleNo]);

    useEffect(() => {
        dispatch(fetchVod('/history/later', "?page=1"));
    },[dispatch, fetchVod]);

    if (loading && status === 'LOADING' && currentPage < 1) {
        return <LoadingSection />;
    } else if (!loading && status === 'CLEAR' || currentPage >= 1) {
        if(filteredData.length < 1 ) {
            return (
                <EmptySection />
            );
        }
        return (
            <>
                <div className="sort-wrap" onClick={ () => setLayer('')}>
                    <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                    
                    <div className="rt_group">
                        <div className="btns" >
                            <button type="button" onClick={handleLaterDelete("view")} >{i18next.t(`재생한 VOD 삭제`)}</button>
                            <button type="button" onClick={handleLaterDelete("all")} >{i18next.t(`전체삭제`)}</button>
                        </div>
                    </div>  
                </div>

                <div className="cBox-list">
                    <ul>
                        {filteredData.map((later, index) => {
                            if(later.type === 'LIVE') {
                                return( 
                                    <li key={index} data-type="cBox" class="live-block">
                                        <LiveItem broad={later} layer={layer} onChangeLayer={handleLayer} onPosition={handlePosition} />
                                    </li>
                                )
                            } else {
                                return (
                                    <li key={index} data-type="cBox">
                                        <VodItem vod={later} layer={layer} path3="afterward" page={Math.floor(index/30 + 1)} sortColumn={sortColumn} onCallBackChangeLayer={handleLayer} onChangeLayer={handleChangeLayer} onPosition={handlePosition}/>
                                    </li>)
                            }
                        })}
                    </ul>
                </div>
                {layer === "LIST" && <ListLayer layers={layer} title_no={titleNo} onLayer={setLayer} onTitle={setTitleNo}  pathname={pathname} top={top} left={left} />}
                {layer === "ADD" && <AddLayer layers={layer} title_no={titleNo} onLayer={setLayer} onTitle={setTitleNo} top={top} left={left} />}
            </>
        );
    } else {
        return null;
    }
}