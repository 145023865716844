import React, { useState, useEffect, useCallback } from 'react'

export const FavoriteGroupContext = React.createContext({});

/**
 * 즐찾 그룹핑 데이터
 * @use const windowSize = useContext(FavoriteGroupContext);
 * @param {*} param0 
 */
export default function FavoriteGroupProvider({ children }) {

    const [groupIdx, setGroupIdx] = useState(0);
    const [groupTitle, setGroupTitle] = useState("");

    return (
        <FavoriteGroupContext.Provider value={{groupIdx:groupIdx, groupTitle:groupTitle, setGroupIdx:setGroupIdx, setGroupTitle:setGroupTitle}} >
            {children}
        </FavoriteGroupContext.Provider>
    )
}