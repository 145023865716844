import React, { useCallback } from 'react';
import { Trans } from "react-i18next";

export default function ErrorSection() {
    const onClickRefresh = useCallback((e) => {
        e.preventDefault();
        window.location.reload();
    })

    return (
		<div className="reloading">
			<p>
				<Trans>
					일시적으로 정보를 불러 오지 못했습니다.
					<br />
					잠시 후 다시 새로고침 해 주세요.
				</Trans>
			</p>
			<button className="icon" onClick={onClickRefresh}>
				<span className="ir">새로고침</span>
			</button>
		</div>
	);
}
