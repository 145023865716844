import { FAVORITE_EXT } from "actions/favorite.action";

import { FAVORITE_REMOVE } from "actions/feed.favorite.action";
import produce from "immer";

const initialState = {
    isFavorite: true,
    loading: true,
    extdata: [],
    poolCheck: 0,
    error: null
};

const favoriteExtReducer = (state = initialState, action) => {
    let data, item = null;
    switch (action.type) {
        case FAVORITE_EXT.LOADING_START:
            return { ...state, loading: true, extdata: [] };

        case FAVORITE_EXT.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_EXT.FETCH_EXT_SUCCESS:
            return { ...state, extdata : action.payload.data, poolCheck : action.payload.pool_check, error:null};

        case FAVORITE_EXT.FETCH_ERROR:
            return { ...state, error: action.payload };
        case FAVORITE_EXT.FILTER_PIN:
            return produce(state, (draftState) => {
                const index = draftState.extdata.findIndex(item => item.favorite_no === action.payload.no)
                if (index > - 1) {
                    draftState.extdata[index].is_pin = action.payload.flag;
                }
            });
        case FAVORITE_REMOVE:
            return produce(state, (draftState) => {
                const index = draftState.extdata.findIndex(item => item.user_id === action.payload.favorite_id)
                if (index > -1) {
                    draftState.extdata.splice(index, 1);
                }
            });
        
        
        case FAVORITE_EXT.SET_MOBILE_PUSH_SUCCESS:
            return produce(state, (draftState) => {
                const favorite_id = action.payload.favorite_id;
                const is_mobile_push = action.payload.flag === 1 ? "Y" : "N";
                if (favorite_id === "all") {
                    draftState.extdata.forEach((item) => item.is_mobile_push = is_mobile_push);
                } else {
                    const index = draftState.extdata.findIndex((item) => item.user_id === action.payload.favorite_id);
                    if (index > -1) {
                        draftState.extdata[index].is_mobile_push = is_mobile_push;
                    }
                }
            });
    
        default:
            return state;
    }
};

export default favoriteExtReducer;