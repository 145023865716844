import { sendLogApi, handleFetchErrors } from "helpers";
import { ROOT_URL } from "constant/config";
import { API } from "./api.action";
import i18next from "i18next";
import { POST, DELETE, PUT } from "constant/method";
import querystring from "querystring";
import cookie from "react-cookies";
import { toast } from "afreecatv-react-toast";



/**
 * 즐찾 그룹 상수
 */
 export const FAVORITE_GROUP = {
	LOADING_START: "favorite_group/LOADING_START",
	LOADING_DONE: "favorite_group/LOADING_DONE",
	FETCH_SUCCESS: "favorite_group/FETCH_SUCCESS",
	FETCH_ERROR: "favorite_group/FETCH_ERROR",
    FETCH_DATA: "favorite_group/FETCH_DATA",
    FETCH_ADD_DATA :"favorite_group/FETCH_ADD_DATA",
    FETCH_DEL_DATA : "favorite_group/FETCH_DEL_DATA",
    FETCH_UPD_DATA : "favorite_group/FETCH_UPD_DATA",
};


/**

/**
 * 즐찾 그룹 목록 가져오기
 */
export function fetchFavoriteGroup(favorite_id="") {
    let url = favorite_id!=="" ? `${ROOT_URL}/favorite/group/list?favorite_id=${favorite_id}` :`${ROOT_URL}/favorite/group/list`;
    return (dispatch) => {
        dispatch({ type: FAVORITE_GROUP.LOADING_START });
        
        fetch(url, {

            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FAVORITE_GROUP.FETCH_SUCCESS,
                    payload: response,
                });
             
                dispatch({ type: FAVORITE_GROUP.LOADING_DONE });
            })
            .catch((error) => {
                dispatch({
                    type: FAVORITE_GROUP.FETCH_ERROR,
                    payload: error,
                });
                dispatch({ type: FAVORITE_GROUP.LOADING_DONE });
            });
    };
}

/**
 * 즐찾 그룹 추가
 */

export const  storeFavoriteGroup = (title, favorite_id_list) => {
    return (dispatch) =>{
        fetch(`${ROOT_URL}/favorite/group/add`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                title: title,
                favorite_id_list : favorite_id_list
            }),
            credentials: "include",
        })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            dispatch({type:FAVORITE_GROUP.FETCH_ADD_DATA,payload:response})
            toast.success(response.message);
        })
        .catch((error) => {
            dispatch({ type: API.LOADING_DONE });
            toast.error(i18next.t(error.message));
        });
    }
}

/**
 * 즐찾 그룹 삭제
 */
export const destoryFavoriteGroup =(group_idx)=>{
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/group/delete/`+group_idx, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            credentials: "include",
            body :querystring.stringify({
                _method:'delete',
            })
        })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            dispatch({type:FAVORITE_GROUP.FETCH_DEL_DATA,payload:group_idx})
            toast.success(response.message);
        })
        .catch((error) => {
            toast.error(error.message);
        });
    }
}


/**
 * 즐찾 그룹 내 아이템 추가
 */
 export const addFavoriteGroup =(group_idx_list, favorite_id)=>{
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/group/put`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method:'put',
                favorite_id: favorite_id,
                group_idx_list : group_idx_list
            }),
            credentials: "include",
        })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            toast.success(response.message);
        })
        .catch((error) => {
            toast.error(error.message);
        });
    }
}


/**
 * 즐찾 그룹 변경
 */
 export const updFavoriteGroup =(group_idx, title, favorite_id_list)=>{
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/group/update/`+group_idx, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method:'put',
                title: title,
                favorite_id_list : favorite_id_list
            }),
            credentials: "include",
        })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            toast.success(response.message);
        })
        .catch((error) => {
            toast.error(error.message);
        });
    }
}


/**
 * 즐찾 그룹 변경
 */
 export const updFavoriteGroupSort =(group_idx_list)=>{
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/group/updgroup`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method:'put',
                
                group_idx_list : group_idx_list
            }),
            credentials: "include",
        })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            toast.success(response.message);
        })
        .catch((error) => {
            toast.error(error.message);
        });
    }
}


