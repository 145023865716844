import { FAVORITE_CLIP } from "actions/favorite.clip.action";

const initialState = {
    loading: false,
    data: [],
    error: null
};

const favoriteClipReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAVORITE_CLIP.LOADING_START:
            return { ...state, loading: true };

        case FAVORITE_CLIP.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_CLIP.FETCH_SUCCESS:
            const data = action.payload.map(item => {
                if(typeof item.read_cnt==="string")
                    item.read_cnt = parseInt(item.read_cnt.replace(/,/g, ''))
                return item;
            })
            return { ...state, data: data, error: null };

        case FAVORITE_CLIP.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default favoriteClipReducer;
