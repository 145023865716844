import { toast } from 'afreecatv-react-toast';
import { ROOT_URL } from "constant/config";
import { DELETE, POST } from 'constant/method';
import { handleFetchErrors } from "helpers";
import i18next from 'i18next';
import querystring from "querystring";


/**
 * 즐찾 상수
 */
export const FEED_FAVORITE = {
    LOADING_START: "FEED_FAVORITE.favorite/LOADING_START",
    LOADING_DONE: "FEED_FAVORITE.favorite/LOADING_DONE",
    FETCH_SUCCESS: "FEED_FAVORITE.favorite/FETCH_SUCCESS",
    FETCH_SUCCESS_MORE: "FEED_FAVORITE.favorite/FETCH_SUCCESS_MORE",
    FETCH_ERROR: "FEED_FAVORITE.favorite/FETCH_ERROR",
}


export const FAVORITE_ADD = "FAVORITE_ADD";
export const FAVORITE_REMOVE = "FAVORITE_REMOVE";


/**
 * 즐찾여부 체크
 */
export function existFavorite() {
    return fetch(`${ROOT_URL}/feed/favorite/exist`, {
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json())
}
/**
 * 즐찾 목록 가져오기
 */
export function fetchFeedFavorite(page = 1) {
    return (dispatch) => {
        dispatch({ type: FEED_FAVORITE.LOADING_START });

        fetch(`${ROOT_URL}/feed/favorite?page=${page}`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FEED_FAVORITE.LOADING_DONE });

                // 1페이지는 append 가 아님
                let fetch_type = FEED_FAVORITE.FETCH_SUCCESS;

                if (page > 1) fetch_type = FEED_FAVORITE.FETCH_SUCCESS_MORE;
                    
                dispatch({
                    type: fetch_type,
                    payload: response,
                });
                
            })
            .catch((error) => {
                dispatch({ type: FEED_FAVORITE.LOADING_DONE });
                dispatch({
                    type: FEED_FAVORITE.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}


/**
    * 즐겨찾기 추가
    */
export const storeFavorite = (user_id) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                user_id,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {

                toast.success(response.message);
                dispatch({
                    type: FAVORITE_ADD,
                    payload: response
                });
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }
};


/**
 * 즐겨찾기 제거
 */
export const destroyFavorite = (user_id, user_nick) => {
    return (dispatch) => {
            fetch(`${ROOT_URL}/favorite/${user_id}`, {
                method: POST,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: querystring.stringify({
                    _method: DELETE,

                }),
                credentials: "include",
            })
                .then(handleFetchErrors)
                .then((response) => response.json())
                .then((response) => {

                    toast.success(response.message);
                    //Redux dispatch
                    dispatch({
                        type: FAVORITE_REMOVE,
                        payload: response
                    });

                })
                .catch((error) => {
                    toast.success(error.message);
                });
    }
};