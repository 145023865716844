import React from 'react';
import useModal from "components/modal/useModal";

import AddFavoriteGroupModal from "./AddFavoriteGroupModal";

import { useDispatch } from "react-redux";
import UpdFavoriteGroupModal from './UpdFavoriteGroupModal';
import UpdFavoriteGroupsModal from './UpdFavoriteGroupsModal';


export default function DefaultModal() {

    const {modal, handleFixFavGroupModal, handleListFavGroupModal} = useModal();
   
    if(modal){
        return (
            
            <div className="ui_modal_wrap" style ={{display: modal.listgroup.onModal || modal.fixgroup.onModal ? "block" :'none'}}>
                <div className="flex_box">
                    <div  className ="modal_contents">
                    
                            {( modal.fixgroup.onModal && (modal.fixgroup.type === "addgroup" || modal.fixgroup.type === "updaddgroup")) &&<AddFavoriteGroupModal 
                                modal={modal} 
                                handleFixFavGroupModal={handleFixFavGroupModal}
                                handleListFavGroupModal={handleListFavGroupModal}
                            />}
                            { modal.fixgroup.onModal && modal.fixgroup.type ==="updgroup" && <UpdFavoriteGroupModal
                                modal={modal} 
                                handleFixFavGroupModal={handleFixFavGroupModal}
                                handleListFavGroupModal={handleListFavGroupModal}
                            />}
                            { modal.listgroup.onModal && modal.listgroup.type ==="updgroups" &&<UpdFavoriteGroupsModal
                                modal={modal} 
                                handleFixFavGroupModal={handleFixFavGroupModal}
                                handleListFavGroupModal={handleListFavGroupModal}
                            />}
                    </div>
                </div>
            </div>

        );
    }
    else return null;
}