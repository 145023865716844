import { ROOT_URL, STBBS_URL } from "../constant/config";
import { POST } from "constant/method";
import querystring from "querystring";
import { toast } from "afreecatv-react-toast";
import i18next from "i18next";

export const PLAYLISTS = {
    FETCH_PLAYLIST_LAYER : "FETCH_PLAYLIST_LAYER",
    FETCH_PLAYLIST_LAYER_MORE : "FETCH_PLAYLIST_LAYER_MORE",
    FETCH_PLAYLIST_LAYER_LOADING_DONE : "FETCH_PLAYLIST_LAYER_LOADING_DONE",
    FETCH_PLAYLIST_LAYER_SUCCESS : "FETCH_PLAYLIST_LAYER_SUCCESS",
    FETCH_PLAYLIST_LAYER_MORE_SUCCESS : "FETCH_PLAYLIST_MORE_SUCCESS",
    FETCH_PLAYLIST_LAYER_ERROR : "FETCH_PLAYLIST_ERROR",
    
    CLEAR_PLAYLIST_LAYER : "CLEAR_PLAYLIST_LAYER",
    
    CHECK_PLAYLIST_LAYER : "CHECK_PLAYLIST_LAYER",
    
    UPDATE_PLAYLIST_LAYER_SUCCESS : "UPDATE_PLAYLIST_LAYER_SUCCESS",
    UPDATE_PLAYLIST_LAYER_ERROR : "UPDATE_PLAYLIST_LAYER_ERROR",
    FETCH_DELETE_PLAYLIST_SUCCESS : "FETCH_DELETE_PLAYLIST_SUCCESS"
}

export const fetchPlaylistLayer = (title_no) => (dispatch) => {
    dispatch({ type: PLAYLISTS.FETCH_PLAYLIST_LAYER });

    return fetch(`${ROOT_URL}/history/playlist?title_no=${title_no}`, {
        credentials: "include"
    })
        .then((response) => response.json()) 
        .then((response) => {
            dispatch({type: PLAYLISTS.FETCH_PLAYLIST_LAYER_LOADING_DONE});
            dispatch({
                type: PLAYLISTS.FETCH_PLAYLIST_LAYER_SUCCESS,
                payload: response
            })
        });
}

export const fetchDeletePlaylist = (playlistIdx) => {
    const data = {
		szWork: "del_playlist_list",
		nPlaylistIdx: playlistIdx,
    };

    return (dispatch) => {
        fetch( `${STBBS_URL}/vodplaylist/api/api.php`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify(data),
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                dispatch({type: PLAYLISTS.FETCH_PLAYLIST_LAYER_LOADING_DONE});
                dispatch({
                    type: PLAYLISTS.FETCH_DELETE_PLAYLIST_SUCCESS,
                    payload: {response, list_idx: playlistIdx}
                });

                toast.success(i18next.t(response.msg));
            })
            .catch((error) => {
                toast.error(i18next.t(error.msg));
            });
    }
}

// 재생목록 레이어에 체크
export const checkPlaylistLayer = (idx) => (dispatch) => {
	dispatch({ type: PLAYLISTS.CHECK_PLAYLIST_LAYER, payload: idx });
};

export const updatePlaylistLayer = (titleNo, playlistIdx) => {
    let data = {
        szWork: "ins_playlist_info",
		title_no: titleNo,
		"nPlaylistIdx[]": playlistIdx,
    }

    return (dispatch) => {
        
        fetch(`${STBBS_URL}/vodplaylist/api/api.php`,{
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify(data),
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                toast.success(i18next.t(response.msg));
                dispatch({
                    type: PLAYLISTS.UPDATE_PLAYLIST_LAYER_SUCCESS,
                    payload: response
                });
            })
            .catch((error) => {
                toast.error(i18next.t(error.msg));
            });
    }
}

export const storePlaylistLayer = (title, display, share) => {
    const data = {
		szWork: "ins_playlist_list",
		title,
		share_yn: share,
		display: display
    };
    return  async (dispatch) => {
        fetch(`${STBBS_URL}/vodplaylist/api/api.php`,{
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify(data),
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: PLAYLISTS.UPDATE_PLAYLIST_LAYER_SUCCESS,
                    payload: response
                });
                toast.success(i18next.t(response.msg));
            })
            .catch((error) => {
                toast.error(i18next.t(error.msg));
            });
    }
}
