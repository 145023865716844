import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchFeedFavorite } from 'actions/feed.favorite.action';
import classNames from "classnames";
import FavoriteScroll from './FavoriteScroll';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { FEED_ROUTE } from 'constant/routeUrl';
import { destroyFavoritePin, storeFavoritePin } from 'actions/favorite.action';
import { Trans } from 'react-i18next';
import ProfileImage from "components/image/ProfileImage";
import { makeProfileUrl } from 'helpers';

const defaultTikcSize = 116;
export default function FeedFavoriteList() {

    let history = useHistory();
    let loction = useLocation();
    const [page, setPage] = useState(1)
    const [totalTick, setTotalTick] = useState(1)
    const [extend, setExtent] = useState(false)
    const [height, setHeight] = useState(defaultTikcSize)
    const [tick, setTick] = useState(1)
    const dispatch = useDispatch();
    const { loading, meta, data = [], error } = useSelector(state => (state.feedFavoriteReducer));
    const [user_id, setUserId] = useState()

    // 즐찾 bj 클릭시 history.push (BJ 목록을 가져와야 하는 경우: 최초 진입, page + 1)
    const { reload_favorite_bj = true } = useLocation();

    /**
     * 최초 즐찾리스트 가져오기
     */
    useEffect(() => {
        if (reload_favorite_bj) {
            dispatch(fetchFeedFavorite(page));
        }
       
    }, [dispatch, page, reload_favorite_bj])

    /**
     * 활서화
     */
    useEffect(() => {
        const [, feed, user_id] = loction.pathname.split('/');
        if (`/${feed}` === FEED_ROUTE) {
            setUserId(user_id);
        }
    }, [loction.pathname])


    useEffect(() => {
        const extendLow = (data.length > 8 && data.length <= 16) ? 2 : 3;
        setHeight(extend ? (defaultTikcSize * extendLow) : defaultTikcSize);
    }, [data.length, extend, height.length])

    const handleFavoriteClick = (user_id, index) => (e) => {
        e.preventDefault();
        const tick = Math.ceil((index + 1) / 8) || 1;
        setTick(tick)
        setExtent(false)
        // 즐겨찾는 BJ 클릭 시 체크한 BJ 기록 
        history.push({ pathname: `${FEED_ROUTE}/${user_id}`, reload_favorite_bj : false});
    }

    const handleExtend = (e) => {
        e.target.blur();
        setExtent(!extend)
    }

    const handleTotalChange = (totalTick) => {
        setTotalTick(totalTick)
    }
    const handleEnd = useCallback(() => {
        if (loading === false && meta.current_page < meta.last_page) {
            setPage(page + 1);
        }
    }, [loading, meta.current_page, meta.last_page, page]);


    /**
     * 상단 목록 고정 클릭
     */
    const handlePin = useCallback((favorite) => (e) => {
        if (favorite.is_pin) {
            dispatch(destroyFavoritePin(favorite.favorite_no, favorite.user_id))
        } else {
            dispatch(storeFavoritePin(favorite.favorite_no, favorite.user_id))
        }
    }, [dispatch]);


    return (
        <div className="favor_list">

            <div className="scroll_wrap" >
                {/** 전체 */}
                <div className={classNames("list_all", { checked: !user_id })} >
                    <Link to={{
                        pathname: FEED_ROUTE,
                        reload_favorite_bj : false,
                    }} 
                        onClick={() => {setExtent(false);}}>
                        <span className="thum"></span>
                        <p><Trans>전체보기</Trans></p>
                    </Link>
                </div>
                <FavoriteScroll
                    speed={2}
                    vertical
                    horizontal={false}
                    smoothScrolling
                    stopScrollPropagation={true}
                    extend={extend}
                    isTick={!extend}
                    height={height}
                    tick={tick}
                    style={{ transition: `height 0.6s`, overflow: 'hidden' }}
                    onTotalChange={handleTotalChange}
                    onEnd={handleEnd}
                    tickSize={defaultTikcSize}
                >
                    <ul >
                        {data.map((favorite, index) => (
                            <li key={index} className={classNames({ pin: favorite.is_pin, checked: favorite.user_id === user_id, favor_live: favorite.is_live })}>
                                <a href="" onClick={handleFavoriteClick(favorite.user_id, index)}>
                                    <ProfileImage 
                                        tag="span"
                                        className="thum"
                                        user_id={favorite.user_id}
                                        src={makeProfileUrl(favorite.user_id)}
                                        childrenTag="img"
                                        childrenTagProps = {{
                                            src:favorite.profile_image,
                                            alt:"프로필",
                                            loading: "lazy"
                                        }}
                                        children={favorite.is_live && <i className="favor_live">라이브</i>}
                                        />
                                    <p>{favorite.user_nick}</p>
                                </a>
                                <button type="button" className="btn-pin" onClick={handlePin(favorite)}>고정해제</button>
                            </li>
                        ))}
                    </ul>
                    {data.length > 8 && <button type="button" className={classNames("btn_more", { active: extend })} onClick={handleExtend}>더보기</button>}
                </FavoriteScroll>
            </div>


        </div>
    )
}
