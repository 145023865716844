import React, { useCallback, useMemo, useState } from "react";
import i18next from "i18next";
import { useLocation } from "react-router";
import {JAVASCRIPT_VOID} from "constant/config";
import {isAdultType} from "helpers";

export default function PlaylistVodThumbnail({ href, onClick, vod, count = {}, display = {} }) {
    const [imgStatus, setImgStatus] = useState(true);

    const { pathname } = useLocation();

    const { playlist_cnt = {} } = count;
    const { grade, thumb, category, thumb_type } = vod;

    const handleError = useCallback(() => {
        setImgStatus(false);
    }, []);
    
    const thumbnail = useMemo(() => {
        const isAdult = isAdultType(grade,category); //성인여부
        if (thumb_type === 'ADULT_LOCK') {
            return ( <a href={href} onClick={onClick} target="_blank"  className="thumb-lock_adult">
                 연령제한 + 비공개
                 <div className="count">
                     <span>{playlist_cnt}</span>
                 </div>
             </a>
            );
        } else if (isAdult === true) {
            return (
                <a href={href} onClick={onClick} target="_blank" className="thumb-adult">
                    19금
                    <div className="count">
                        <span>{playlist_cnt}</span>
                    </div>
                </a>
            );
        } else if (imgStatus === false) {
            return (
                <a href={href} onClick={onClick} target="_blank" className="thumb-default">
                    기본
                    <div className="count">
                        <span>{playlist_cnt}</span>
                    </div>
                </a>
            );
        } else if (playlist_cnt === 0) {
            return (
                <a href={JAVASCRIPT_VOID} onClick={onClick}  className="thumb-default">
                    기본
                    <div className="count">
                        <span>{playlist_cnt}</span>
                    </div>
                </a>
            );
        } else if (thumb_type === 'LOCK') {
            return (
                <a href={href} onClick={onClick} target="_blank"  className="thumb-lock">
                    비번방
                    <div className="count">
                        <span>{playlist_cnt}</span>
                    </div>
                </a>
            );
        } else if(thumb_type === "DELETE") {
            return (
                <a href={href} onClick={onClick} target="_blank"  className="thumb-default">
                    기본
                    <div className="count">
                        <span>{playlist_cnt}</span>
                    </div>
                </a>
            );
        } else if(!thumb) {
            return (
                <a href={href} onClick={onClick} target="_blank"  className="thumb-default">
                    기본
                    <div className="count">
                        <span>{playlist_cnt}</span>
                    </div>
                </a>
            );
        }
    }, [vod, imgStatus, href, onClick, handleError]);

    return (
        <div className="thumbs-box" >
            {thumbnail}

            {!thumbnail && <a onClick={onClick} href={href} target="_blank" >
                <img src={ vod.thumb} onError={handleError} alt="" loading="lazy" />
                <div className="count">
                    <span>{playlist_cnt}</span>
                </div>
            </a>}
        </div>
    );
}
