exports.lang = {
    kr: "en",
    아프리카TV: "AfreecaTV",
    방송국: "Blog",
    전체: "All",
    탐방허용: "Peek Allowed",
    "쪽지 보내기": "Send a Message",
    즐겨찾기: "Favorites",
    "차단된 팝업을 허용해주세요.": "Please enable pop-ups",
    "즐겨찾기가 추가되었습니다.": "Added to Favorites!",
    "잠시후 다시 시도해주세요.": "Server disconnected. Please try again later.",
    "즐겨찾기가 삭제되었습니다.": "Removed from Favorites!",
    "목록 상단 고정 완료 되었습니다.": "Pinned to top of the list.",
    "목록 상단 고정 해제 되었습니다.": "Unpin from list.",
    "이미 처리중 입니다.": "It is already being processed.",
    "나중에 보기 목록에 등록 되었습니다.\n목록에서 바로 확인 하시겠습니까?":
        "The video has been added to your Watch Later playlist.\nWould you like to see your playlist?",
    "방송 중": "Currently Streaming",
    정렬보기: "Sort by",
    참여인원순: "By Viewers",
    최신등록순: "By Upload Date",
    최신방송순: "By Stream Date",
    새로고침: "Refresh",
    방송시작: "Start Time",
    "즐겨찾기 삭제": "Delete",
    추가하기: "Add",
    "나중에 보기": "Watch Later",
    더보기: "Show All",
    이전: "Back",
    다음: "Next",
    다시보기: "Replays",
    하이라이트: "Highlights",
    "팬가입한 BJ": "Fan Club",
    "팬가입한 BJ 전체": "All Fan Club Streamers",
    "팬클럽 가입순": "By Join Date",
    닉네임순: "By Nickname",
    아이디순: "By ID",
    "팬클럽 가입일": "Fan Club Join Date",
    최근방송: "Recent Streams",
    "즐겨찾기 해제": "Remove from Favorites",
    "즐겨찾기 추가": "Add to Favorites",
    "LIVE 참여하기": "Watch Live",
    "목록 상단해제": "Unpin from top list",
    "목록 상단고정": "Pin to top list",
    "가입한 팬클럽이 없습니다.": "No Fan Club Joined",
    "즐겨찾기한 BJ": "Favorites",
    "즐겨찾기 전체": "All Favorites",
    "{{num}}명": "{{num}}",
    "즐겨찾기한 BJ가 없습니다.": "You have yet to add a favorite streamer!",
    "최근 본 BJ의 LIVE": "Recent Streamer LIVE",
    최근참여순: "Recently Watched",
    "최근 본 BJ의 LIVE 삭제": "Delete Recent Streamer LIVE",
    "최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?" : "Would you like to delete your Recent Streamer LIVE history?",
    "해당 방송이 최근 본 방송에서 삭제되었습니다.": "This stream has been deleted from Recently Watched.",
    "지금 바로, 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "Have fun exploring diverse contents by our users!",
    닫기: "Close",
    "즐겨찾기한 BJ의 VOD": "VODs from Favorited Streamers",
    "즐겨찾기한 BJ의 Catch": "Favorite Streamer Catch",
    "팬가입한 BJ의 VOD": "Fan Club Streamer VOD",
    "구독한 BJ의 VOD": "Subscribed Streamer VOD",
    "즐겨찾기 할 만한 방송": "Recommended",
    "즐겨찾기할 만한 BJ": "Recommended Streamers",
    "BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!<br/>팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.":
        "If you donate Star Balloons, you will join their Fan Club! <br/>You will be able to post to the Fan Club board <br/>Your chat will be highlighted in a special Fan Club color.",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다.":
        "If you add streamers to Favorites, you will receive notifications when they go live!",
    "즐겨찾기 BJ글": "What's New",
    "나중에 보기에 등록되었습니다.": "Added to Watch Later",
    "보고 싶은 VOD나, 아직 보지 않은<br/>VOD를 목록에 등록해 두고  언제든 간편하게 찾아보세요!":
        "Conveniently watch videos<br/> by saving it to your Watch Later list!",
    "라이브 방송을 참여할 수 없는 상황일 때<br/>목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!":
        "If you are unable to watch live streams,<br/> add it to your Watch Later list and watch it at your convenience!",
    "<0>VOD의 “나중에 보기” 메뉴</0>에서<br/>등록 한 영상을 확인 하실 수 있습니다.":
        "You can watch videos through <br/><0>our Watch Later</0> feature.",
    다시보지않기: "Do not view",
    "레이어 닫기": "Close Layer",
    "나중에 보기에 등록된 VOD입니다.": "This video has already been added to your Watch Later playlist.",
    "일시적인 오류 입니다. 잠시 후 다시 시도해 주세요": "Temporary error. Please try again later",
    "나중에 보기 리스트는 최대 1,000개 까지 등록 가능합니다.": "You may only add up to 1,000 videos on your Watch Later playlist.",
    "나중에 보기를 지원하지 않는 영상입니다.": "The VOD does not support Watch Later function",
    "VOD 정보가 없습니다.": "No video information.",
    "게시물이 존재하지 않습니다": "No results found.",
    "처리 되었습니다.": "Successfully applied!",
    "방송국 바로가기": "Go to Blog",
    "즐겨찾기한 BJ의 유저클립": "User Clips from Favorited Streamers",
    "추천받지 않기": "Delete",
    "해당 방송을 더 이상 추천하지 않습니다.": "We will no longer recommend this stream.",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다. ":
        "Follow streamers to get <br/> \n updates from their blogs on your feed.",
    "BJ가 등록한 게시물이 없습니다.": "There are no posts uploaded by the streamer.",
    "구독한 BJ": "Subscribed Streamer",
    "구독한 BJ 전체": "All Subscribed Streamers",
    "구독 결제정보": "Subscription Payment Information",
    최신구독순: "Lastest Subscription",
    "구독 닉네임": "Subs Nickname",
    구독일: "Subs Date",
    "{{month}}개월": "{{month}} Mos",
    "회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)":
        "Would you like to use your default nickname {{default_nick}}? \n(You can change again after 24 hours)",
    "구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다":
        "Your subscriber nickname has changed. \nIf you are currently watching the stream, your changed nickname will be applied when you re-enter the stream.",
    "구독한 BJ가 없습니다.": "There are no Subscribed Streamers.",
    "내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br/>구독팬만을 위한 아주 특별한 혜택을 만나보세요!":
        "Subscribing to a streamer will grant you main room entrance,<br/>subscriber-only nickname, signature emoticons, and other special benefits for subscribers!",
    유료: "Paid",

    "구독팬 전용 닉네임 변경": "Change Subscriber-Only Nickname",
    "구독팬 전용 닉네임을 입력해주세요.": "Please enter your subscriber-only nickname.",
    중복확인: "Confirm",
    "한글 6자(영문 12자)까지 입력 가능": "You can enter up to 12 English letters (6 Korean letters)",
    "변경 시 24시간 후 재변경 가능": "Once changed, you can change again after 24 hours.",
    "구독한 BJ방송 채팅에서 사용됩니다.": "You can use this at your subscribed streamer's chat.",
    변경: "Change",
    "기본 닉네임 사용": "Use Basic Nickname",

    "현재 방송 중인 BJ가 없습니다.": "No streamers are currently LIVE.",
    "방송국에 등록된 공지글이 없습니다.": "There are no registered notices in the blog.",
    "방송국에 등록된 게시글이 없습니다.": "There are no registered posts in the blog.",
    "방송국에 업로드된 VOD가 없습니다.": "There are no uploaded VODs in the blog.",
    "공지글 보기": "See Notices",
    "게시글 보기": "See Posts",

    "즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.": "No posts have been uploaded.",
    "BJ글만 보기": "Streamer Posts only",
    전체보기: "All",
    "글만 보기": "Texts only",
    "이미지만 보기": "Images only",
    "VOD만 보기": "VOD only",
    "태그에 대한 검색 결과가 없습니다.": "No results were found for this tag.",
    "{{num}}명 참여중": "{{num}} Viewers",
    "{{num}}건": "{{num}}",
    "조회 {{count}}": "Views {{count}}",
    "핫 키워드": "Hot Keyword",
    "실시간 인기글": "Hot Posts",
    "전체글 보기": "All",
    "방송국 새 글": "Feed",
    "해외에서 재생이 불가한 VOD입니다.": "VOD could not be played in your region.",
    "이어서 재생을 원하실 경우,<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "To continue watching, <br/> go to the player page to play.",
    "오류가 발생하여 재생이 불가합니다.": "",
    "<0>{{nickname}}({{id}})</0><1>님의 방송국</1>": "<0>{{nickname}}({{id}})</0><1>'s Blog</1>",
    고정하기: "Pin",
    공유하기: "Share",
    "주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요": "URL copied. Paste (Ctrl + V) wherever you want",
    공지: "Notice",
    "미리보기가 원활하지 않습니다.<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "Preview is not smooth.<br/>go to the player page to play.",

    "아프리카TV에서 생긴 일": "Issues in AfreecaTV",
    "아.생": "Issue",
    투데이: "Today",
    "즐겨찾기한 BJ 글": "Favorite Streamer Posts",
    "실시간 인기글 TOP 100": "TOP 100 Hot Posts",
    "TOP 100": "TOP 100",
    "{{rank}}위": "Rank {{rank}}",
    "방송국 가기": "Go to Blog",
    "게시물 안보기": "Hide",
    "게시물 안보기가 설정된 게시물입니다.": "This post is set as hidden.",
    "실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.": "The streamer's post will not appear on Hot Posts section.",
    "게시물 안보기 해제": "Unhide",
    "핫이슈 동영상": "Hot Issue VOD",
    "실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?":
        "Would you like to hide {{user_nick}}'s posts from your Hot Posts?",
    "지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?":
        "The streamer you just hid is your favorite streamer.\nWould you like to remove this streamer from Favorites?",
    "로그인이 필요한 기능입니다.\n로그인 하시겠습니까?": "Log in required.\nWould you like to log in?",
    "{{user_nick}}님의 게시물을 다시 보시겠습니까?": "Would you like to see nickname's posts again?",
    "{{user_nick}}님의 게시물 안보기": "Hide nickname's Posts",
    "네트워크에 문제가 있습니다.<br> 네트워크 상태를 확인해주세요.":
        "There is a problem with the network.<br>Please check your network status.",
    "오늘의 움짤&유저클립": "Today's GIF & User Clip",
    "닉네임 검색": "Search Nickname",
    "아이디 검색": "Search ID",

    스토리: "Story",
    "내 글·댓글": "My Posts·Comments",
    "스토리 내용이 없습니다.": "There are no story contents.",
    "작성한 게시글, 댓글, 답글이 없습니다.": "There are no posts, comments, or replies you have written.",
    "작성한 게시글이 없습니다.": "There are no posts you have written.",
    "작성한 댓글이 없습니다.": "There are no comments you have written.",
    "작성한 답글이 없습니다.": "There are no replies you have written.",
    "댓글 보기": "See Comments",
    "답글 보기": "See Replies",
    "{{nickname}}<1>님의 방송국</1>": "{{nickname}}<1>'s Blog</1>",
    "즐겨찾기 BJ별 알림 설정을<br/>PC에서도 할 수 있어요!":
        "You can adjust your notification settings<br/>for favorite streamers on PC as well.",
    "모든 즐겨찾기 BJ에게 알림을 받습니다.": "Receive notifications from all favorite streamers.",
    "모든 즐겨찾기 BJ에게 알림을 받지 않습니다.": "Do not receive notifications from all favorite streamers.",
    "{{favorite_nick}}님에게 알림을 받습니다.": "Receive notification from {{favorite_nick}}.",
    "{{favorite_nick}}님에게 알림을 받지 않습니다.": "Do not receive notifications from {{favorite_nick}}.",
    "알림 받지 않기": "Do Not Receive Notification",
    "알림 받기": "Receive Notification",
    "전체 알림": "Alert All",

    "선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?":
        "Are you sure you want to delete the selected posts (including comments and replies)?",
    "선택하신 댓글(답글 포함)을 삭제하시겠습니까?": "Would you like to delete the selected comments(and replies)?",
    "선택하신 답글을 삭제하시겠습니까?": "Would you like to delete the selected replies?",
    "삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.":
        "Once you delete it, it will be completely deleted from your story and blog, and cannot be restored.",
    "게시글(댓글, 답글 포함) 삭제가 완료되었습니다.": "You have successfully deleted the posts (including comments and replies).",
    "댓글(답글 포함) 삭제가 완료되었습니다.": "You have deleted the comments(and replies).",
    "답글 삭제가 완료되었습니다.": "You have deleted the replies.",
    삭제하기: "Delete",

    "최근 본 방송": "Recently Watched",
    "최근 본 VOD": "Recent VOD",
    "UP한 VOD": "UPed VOD",
    재생목록: "Playlist",
    "최근 본 VOD 삭제": "Delete Recent VOD",
    "아직 참여한 방송이 없습니다.": "There is no Watch History.",
    "지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "Come and enjoy AfreecaTV's fun and diverse LIVE streams right now!",
    "즐겨찾기한 VOD 보러가기": "Go to Favorite VOD",
    "LIVE 보러가기": "Go to LIVE",
    "VOD 보러가기": "Go to VOD",
    "최근 본 VOD가 없습니다.": "There are no Recent VODs.",
    "나중에 보기 한 VOD가 없습니다.": "There are no Watch Later VODs.",
    "UP 한 VOD가 없습니다.": "There are no UPed VODs.",
    "재생목록이 없습니다.": "There is no playlist.",
    "VOD 위치 변경 안내": "VOD Location Change Notice",
    "즐겨찾기한 BJ의 유저클립과 VOD는 새로워진 VOD 메뉴에서 만나보세요!": "Check out your Favorite User Clips and VODs at the new VOD menu!",
    "UP 취소": "Cancel UP",
    "나중에 보기 삭제": "Remove from Watch Later",
    "재생한 VOD 삭제": "Delete VOD History",
    전체삭제: "Delete All",
    조회순: "Views",
    UP순: "UPed",
    댓글순: "Comments",
    "VOD 추가순": "Added Date",
    최신순: "Recent",
    비공개: "Private",
    업데이트: "Update",
    "재생목록에 담기": "Add to Playlist",
    수정하기: "Edit",

    
    "내 마음대로 만드는 플레이리스트!":"Create a playlist of your own!",
    "테마/BJ별로 재생목록에 담아 편하게 시청하세요.":"Enjoy your playlist organized by each theme and streamer.",

    "새 재생목록 만들기": "Create New Playlist",
    "제목을 입력해주세요.": "Please enter a title.",
    "목록 공개": "Make List Public",
    "목록 비공개": "Make List Private",
    "재생목록 저장 허용": "Allow Save",
    "재생목록 저장 비허용": "Do Not Allow Save",
    "미리보기를 제공하지 않는 VOD": "No Preview",
    "추가": "Add",

    "지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!": "Check out AfreecaTV's fun and diverse VODs!",
    "해당 BJ를 즐겨찾기에서 삭제하시겠습니까?" : "Would you like to delete this streamer from your Favorites?",
    "재생목록을 삭제하시겠습니까?":"Would you like to delete the playlist?",
    "편집": "Edit",
    "즐찾BJ 편집" : "Edit Favorite Streamer",
    "새 그룹 만들기" : "Create New Group",
    "그룹명" : "Group Name",
    "그룹명을 입력해주세요." :"Please enter the name of the group.",
    "BJ 추가" : "Add Streamer",
    "BJ 추가하기" : "Add Streamer",
    "그룹에서 삭제" : "Delete from the group",
    "즐찾BJ 그룹에 담기" : "Add to Favorite Streamer Group",
    "즐찾BJ 그룹" : "Favorite Streamer Group",
    "{{num}}명 추가" :"Add {{num}}",
    "해당 그룹을 삭제하시겠습니까?" : "Are you sure you want to delete this group?",
    "그룹 추가는 최대 150명까지입니다." : "You can add up to 150 accounts.",
    "BJ를 추가해주세요." : "Add Streamer",
    "편집할 그룹이 없습니다." : "here is no group to edit.",
    "BJ 그룹 기능을 이용하여<br/>원하는 주제별로 BJ를 정리해보세요!" : "Using the Stramer Group function<br/>organize streamers by category you want!",
    "삭제" : "Delete",
    "그룹 수정" : "Edit Group",
    "확인" : "Confirm",

    "구독전용": "Subscription-Only",
    "드롭스 이벤트 진행 중" : "Drops event in progress",
    "%d분 전": "%d Minutes Ago",
    "%d시간 전": "%d Hours Ago",
    "%d일 전": "%d Days Ago",
    "%d초 전": "%d Seconds Ago",
    "Catch 스토리": "Catch Story",
    "월": "\/",
    "일": "Day",
};
