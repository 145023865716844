import React, { useCallback, useMemo, useState } from "react";
import CatchStoryThumbnail from "components/thumbnail/CatchStoryThumbnail";
import { FAVORITE_ROUTE } from "constant/routeUrl";
import i18next from "i18next";
import { vodInflowLog } from "libs/log";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import { VOD_URL } from "constant/config";
import ThreeDotHistoryButton from "components/button/ThreeDotHistoryButton";
import ShareButton from "components/button/ShareButton";

export default function CatchStoryItem({ vod, aStoryIdxList, onChangeLayer, onPosition, onCallBackChangeLayer }) {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [layer, setLayer] = useState('');
    const [top, setTop] = useState(0);
    const [offset, setOffset] = useState(0);

     /**
     * 즐찾 그룹 담기 
     */
    const handlePosition = useCallback((top, left) => {
        onPosition(top, left);
        setTop(top);
    }, [onPosition]);

    const handleClickLayer = useCallback((layer, titleNo) => (event) => {
        event.stopPropagation();
        setLayer(layer);
        setOffset(event.nativeEvent.pageX);
    }, [setLayer]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        onChangeLayer(layer, titleNo);
        setLayer(layer);
    }, [onChangeLayer, setLayer]);


    /**
     * 영상 진입 로그 호출
     */
    const onClickVodLink = useCallback(
        (data, catchStoryUrl) => {

            let pathType = "";
            let appendData = {};
            switch (pathname) {
                case FAVORITE_ROUTE:
                    pathType = "fav";
                    appendData['story_no'] = data.story_idx;
                    break;
                default:
                    return false;
            }
            vodInflowLog(data.copyright_user_id, data.title_no, ["my", pathType, "fav_story"], appendData);
        },
        [pathname, dispatch],
    );

    /**
     * 동영상 플레이어페이지 URL
     */
    const url = useMemo(() => {
        return `${VOD_URL}/player/${vod.story_idx}/catchstory?szLocation=my&aStoryListIdx=${aStoryIdxList.join("-")}`;
    }, [vod.story_idx, pathname]);

    const storyTitle = useMemo(() => {
        if(!vod.broad_date) {
            return '';
        }

        let [month, day] = ['N', 'N'];
        const broadDateArr = vod.broad_date.split("-");
        month = broadDateArr[1].startsWith('0') ? broadDateArr[1].substring(1) : broadDateArr[1]; // 앞에 0 제거
        day = broadDateArr[2].startsWith('0') ? broadDateArr[2].substring(1) : broadDateArr[2]; // 앞에 0 제거

        return `${month}월 ${day}일 스토리`;
    }, [vod.broad_date]);
    
    return (
        <>
        <a style={{cursor:'pointer'}} onClick={(event) => {
                event.preventDefault();
                onClickVodLink(vod, url);
                window.open(url, "_blank", "noopener");
            }} pathname={pathname}>
            <CatchStoryThumbnail href={url} vod={vod} onClick={(event) => {
                    event.preventDefault();
                    onClickVodLink(vod, url);
                    window.open(url, "_blank", "noopener");
                }} pathname={pathname} />
            <div className="info_area">
                <ThreeDotHistoryButton
                    trigger={
                        <button type="button" className="more_dot">
                            <span>더보기 메뉴</span>
                        </button>
                    }
                    onPosition={handlePosition}
                    onChangeLayer={handleChangeLayer}
                >
                    <button type="button" onClick={handleClickLayer('SHARE', 0)}>
                        <span>{i18next.t(`공유하기`)}</span>
                    </button>
                        
                    { <button type="button" onClick={  onCallBackChangeLayer("FAVORITELIST", vod.copyright_user_id)}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                </ThreeDotHistoryButton>
                <p className="tit" title={vod.copyright_user_nick}>{vod.copyright_user_nick}</p>
                <p className="date">{storyTitle}</p>
            </div>
            <div className="story_profile">
                <div className="area">
                    <img src={vod.profile_img} alt="BJ 프로필" />
                </div>
            </div>
            <div className="ic-catch_story">스토리</div>

            {layer === 'SHARE' && <ShareButton item={vod} top={top} offset={offset} type="CATCHSTORY" layer={layer} setLayer={setLayer} onChangeLayer={handleChangeLayer}/>}
        </a>
        </>
    );
}
