
export const BROADING = {
    FETCH_SUCCESS: "broading/FETCH_SUCCESS",
    SORT: "broading/SORT",
}

/**
 * 방송중 데이터 리스트 설정
 * @param  data 방송중인 데이터
 * @return
 */
export const fetchBroad = (data,pathname) => {

    return (dispatch) => {
        if(window.location.pathname == pathname){
            dispatch({
                type: BROADING.FETCH_SUCCESS,
                payload: data.filter(item => item.is_live === true)
            });
        }

    }
};


