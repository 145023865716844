import SortButtonGroup from "components/button/SortButtonGroup";
import FanclubItem from "components/item/FanclubItem";
import TitleSection from "components/section/TitleSection";
import { FIRST_DATE, getFanclubSort, DESC, OFFSET_SIZE } from "constant/sort";
import { compare_favorite, numberCommaString } from "helpers";
import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import EmptySection from "components/section/EmptySection";
import LoadingSection from "components/section/LoadingSection";
import ErrorSection from "components/section/ErrorSection";
import MoreButton from "components/button/MoreButton";



export default function FanclubListSection() {

    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(FIRST_DATE);
    const [limit, setLimit] = useState(OFFSET_SIZE)

    const { data, loading, error } = useSelector(state => (state.fanclubReducer), shallowEqual);

    // useMemo
    const filteredData = useMemo(() => {
        return data.slice().sort(compare_favorite(sortColumn, sortOrder)).slice(0, limit);
    }, [data, limit, sortColumn, sortOrder]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);

    if (error) {
        return <ErrorSection />
    }

    if (loading) {
        return <LoadingSection />;
    } else {
        if (data.length > 0) {
            return (
                <div className="favor-wrap">
                    <TitleSection>
                        {i18next.t('팬가입한 BJ 전체')}
                        <span className="total">
                            ({i18next.t(`{{num}}명`, { num: numberCommaString(data.length) })})
                        </span>
                    </TitleSection>


                    <div className="sort-wrap">
                        <SortButtonGroup options={getFanclubSort()} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                    </div>


                    <div className="favor-list fanClub">
                        <ul>
                            {filteredData.map((fanclub, index) =>
                                <li key={index}>
                                    <FanclubItem fanclub={fanclub} />
                                </li>
                            )}
                        </ul>


                        {data.length > limit && <MoreButton onClick={onClickMore} />}
                    </div>
                </div>
            );
        } else {
            return <EmptySection />;
        }
    }
}
