exports.lang = {
    kr: "th",
    아프리카TV: "AfreecaTV",
    방송국: "บล็อก",
    전체: "ทั้งหมด",
    탐방허용: "ถ่ายต่อได้",
    "쪽지 보내기": "ส่งข้อความ",
    즐겨찾기: "รายการโปรด",
    "차단된 팝업을 허용해주세요.": "กรุณาเปิดการใช้งานป็อบอัพ",
    "즐겨찾기가 추가되었습니다.": "เพิ่มไปยังรายการโปรด",
    "잠시후 다시 시도해주세요.": "สิ้นสุดการเชื่อมต่อกับเซิร์ฟเวอร์ กรุณาลองใหม่ในภายหลัง",
    "즐겨찾기가 삭제되었습니다.": "ลบจากรายการโปรด",
    "목록 상단 고정 완료 되었습니다.": "ปักหมุดไว้ที่รายการแล้ว",
    "목록 상단 고정 해제 되었습니다.": "เอาออกจากรายการ",
    "이미 처리중 입니다.": "อยู่ระหว่างดำเนินการ",
    "나중에 보기 목록에 등록 되었습니다.\n목록에서 바로 확인 하시겠습니까?":
        'วิดีโอนี้ได้ถูกเพิ่มเข้าไปยังรายการ "รับชมภายหลัง" แล้ว ท่านต้องการที่จะดูรายการรับชมภายหลังของท่านหรือไม่',
    "방송 중": "กำลังถ่ายทอดสด",
    정렬보기: "เรียงลำดับ",
    참여인원순: "เรียงตามยอดผู้ชม",
    최신등록순: "เรียงตามล่าสุด",
    최신방송순: "เรียงตามวันที่",
    새로고침: "รีเฟรช",
    방송시작: "เวลาที่เริ่ม",
    "즐겨찾기 삭제": "ลบรายการโปรด",
    추가하기: "เพิ่ม",
    "나중에 보기": "รับชมภายหลัง",
    더보기: "ดูเพิ่มเติม",
    이전: "กลับ",
    다음: "ต่อไป",
    다시보기: "ย้อนหลัง",
    하이라이트: "ไฮไลต์",
    "팬가입한 BJ": "BJ ที่เป็นแฟนคลับอยู่",
    "팬가입한 BJ 전체": "BJ ที่เป็็นแฟนคลับอยู่ทั้งหมด",
    "팬클럽 가입순": "สมัครเมื่อ",
    닉네임순: "เรียงตามชื่อเล่น",
    아이디순: "เรียงตามไอดี",
    "팬클럽 가입일": "วันที่สมัครเป็นแฟนคลับ",
    최근방송: "วิดีโอล่าสุด",
    "즐겨찾기 해제": "ลบออกจากรายการโปรด",
    "즐겨찾기 추가": "รายการโปรด",
    "LIVE 참여하기": "รับชม Live",
    "목록 상단해제": "ลบออกจากส่วนบนสุดของรายการ",
    "목록 상단고정": "เพิ่มไปยังส่วนบนสุดของรายการ",
    "가입한 팬클럽이 없습니다.": "ไม่พบแฟนคลับที่สมัครติดตามอยู่",
    "즐겨찾기한 BJ": "รายการโปรด",
    "즐겨찾기 전체": "เพิ่มเข้ารายการโปรดทั้งหมด",
    "{{num}}명": "{{num}} คน",
    "즐겨찾기한 BJ가 없습니다.": "ไม่มี BJ ที่เพิ่มไว้ในรายการโปรด",
    "최근 본 BJ의 LIVE": "รายการสด BJ ล่าสุด",
    최근참여순: "รับชมไปล่าสุด",
    "최근 본 BJ의 LIVE 삭제": "ลบประวัติรับชมรายการสดของ BJ ล่าสุด",
    "최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?" : "ท่านต้องการจะลบประวัติรับชมรายการสดของท่าน BJ ล่าสุดหรือไม่",
    "해당 방송이 최근 본 방송에서 삭제되었습니다.": "รายการสดนี้ถูกลบออกจากประวัติรับชมล่าสุดแล้ว",
    "지금 바로, 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "สนุกกับการรับชมการถ่ายทอดสดและวิดีโอได้ที่ AfreecaTV!",
    닫기: "ปิด",
    "즐겨찾기한 BJ의 VOD": "วิดีโอจาก BJ ในรายการโปรด",
    "즐겨찾기한 BJ의 Catch": "Catch ของ BJ โปรด",
    "팬가입한 BJ의 VOD": "VOD ของ BJ ที่เป็นแฟนคลับอยู่",
    "구독한 BJ의 VOD": "วิดีโอของ BJ ที่เป็นสมาชิก",
    "즐겨찾기 할 만한 방송": "แนะนำ",
    "즐겨찾기할 만한 BJ": "BJ แนะนำ",
    "BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!<br/>팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.":
        "เมื่อส่ง Star Balloon ให้ BJ จะถือว่าสมัครเป็นแฟนคลับของ BJ ดังกล่าวอัตโนมัติ<br/>แฟนคลับจะสามารถใช้งานบอร์ดสนทนาเฉพาะของแฟนคลับได้<br/>และข้อความในแชทจะเป็นสีเฉพาะของแฟนคลับอีกด้วย",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다.":
        "ติดตามผลงานของ BJ ที่คุณชื่นชอบได้ง่ายๆ เพียงแค่เพิ่มลงในรายการโปรด เพื่อรับการแจ้งเตือนเมื่อมีการถ่ายทอดสดจาก BJ",
    "즐겨찾기 BJ글": "ความเคลื่อนไหวจาก BJ",
    "나중에 보기에 등록되었습니다.": "เพิ่มเข้ารายการรับชมย้อนหลัง",
    "보고 싶은 VOD나, 아직 보지 않은<br/>VOD를 목록에 등록해 두고  언제든 간편하게 찾아보세요!":
        "เก็บรายการถ่ายทอดสดที่เราพลาดรับชมไปดูย้อนหลังได้ง่ายๆ<br/> เพียงแค่เพิ่มในรายการรับชมย้อนหลัง",
    "라이브 방송을 참여할 수 없는 상황일 때<br/>목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!":
        "หากไม่สะดวกที่จะรับชมถ่ายทอดสด<br/> คุณสามารถเพิ่มวิดีโอนี้ในรายการรับชมภายหลัง เพื่อรับชมในเวลาอื่นได้ตามสะดวก",
    "<0>VOD의 “나중에 보기” 메뉴</0>에서<br/>등록 한 영상을 확인 하실 수 있습니다.":
        "<0>คุณสามารถรับชมวิดีโอต่างๆ</0> ย้อนหลังได้<br/> ในรายการรับชมภายหลัง",
    다시보지않기: "ไม่สามารถรับชมได้",
    "레이어 닫기": "ปิดเลเยอร์",
    "나중에 보기에 등록된 VOD입니다.": "ท่านได้เพิ่มวิดีโอนี้เข้าไปยังรายการรับชมภายหลังแล้ว",
    "일시적인 오류 입니다. 잠시 후 다시 시도해 주세요": "มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่ภายหลัง",
    "나중에 보기 리스트는 최대 1,000개 까지 등록 가능합니다.": "ท่านสามารถเพิ่มวิดีโอเข้ารายการรับชมภายหลังได้สูงสุด 1,000 วิดีโอ",
    "나중에 보기를 지원하지 않는 영상입니다.": "วิดีโอนี้ไม่สนับสนุนระบบรับชมภายหลัง",
    "VOD 정보가 없습니다.": "ไม่มีข้อมูลวิดีโอนี้",
    "게시물이 존재하지 않습니다": "ไม่พบการค้นหา",
    "처리 되었습니다.": "ใช้งานเรียบร้อยแล้ว!",
    "방송국 바로가기": "ไปยังบล็อก",
    "즐겨찾기한 BJ의 유저클립": "วดิ โีอจากผใู้ชง้านของ BJ ใน รายการโปรด",
    "추천받지 않기": "ไม่ได้รับวิดีโอแนะนำ",
    "해당 방송을 더 이상 추천하지 않습니다.": "ไม่แนะนำรายการสดนี้อีกต่อไป",

    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다. ":
        "กดติดตาม BJ ที่ชื่นชอบ <br/> เพื่อรับข่าวสารใหม่ๆ บนหน้าบล็อกได้",
    "BJ가 등록한 게시물이 없습니다.": "ไม่มีกระทู้ที่ตั้งโดย BJ",
    "구독한 BJ": "BJ ที่เป็นสมาชิกอยู่",
    "구독한 BJ 전체": "BJ ที่เป็นสมาชิกอยู่ทั้งหมด",
    "구독 결제정보": "ข้อมูลการชำระเงินสมาชิก",
    최신구독순: "การสมัครสมาชิกล่าสุด",
    "구독 닉네임": "ชื่อเล่นสมาชิก",
    구독일: "วันเริ่ม",
    "{{month}}개월": "{{month}} เดือน",
    "회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)":
        "ท่านต้องการจะใช้ชื่อเล่นปกติหรือไม่ {{default_nick}}? \n(ท่านสามารถเปลี่ยนได้อีกครั้งใน 24 ชั่วโมง)",
    "구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다":
        "ชื่อเล่นเฉพาะสมาชิกของท่านได้ถูกเปลี่ยนแปลงแล้ว \nหากท่านกำลังรับชมรายการอยู่ ชื่อเล่นที่ถูกเปลี่ยนไปจะแสดงผลเมื่อท่านทำการเข้ารายการใหม่อีกครั้ง",
    "구독한 BJ가 없습니다.": "ยังไม่มี BJ ที่สนับสนุน",
    "내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br/>구독팬만을 위한 아주 특별한 혜택을 만나보세요!":
        "การสมัครเป็นสมาชิกของ BJ จะมอบสิทธิในการเข้าห้องหลัก ได้รับชื่อเล่นเฉพาะสมาชิก อีโมติค่อนสำหรับสมาชิก<br/>อีโมติค่อนพิเศษและผลประโยชน์อื่นๆ สำหรับสมาชิกเท่านั้น!",
    유료: "มีค่าใช้จ่าย",

    "구독팬 전용 닉네임 변경": "เปลี่ยนชื่อเล่นเฉพาะสมาชิก",
    "구독팬 전용 닉네임을 입력해주세요.": "กรุณาระบุชื่อเล่นสมาชิกของท่าน",
    중복확인: "ยืนยัน",
    "한글 6자(영문 12자)까지 입력 가능": "ท่านสามารถใช้ตัวอักษรภาษาอังกฤษได้ 12 ตัวอักษร (6 ตัวอักษรสำหรับภาษาเกาหลี)",
    "변경 시 24시간 후 재변경 가능": "เมื่อทำการเปลี่ยนชื่อเล่นแล้ว ท่านสามารถเปลี่ยนได้อีกครั้งหลังจากผ่านไป 24 ชั่วโมง",
    "구독한 BJ방송 채팅에서 사용됩니다.": "ท่านสามารถใช้ชื่อนี้ได้ในช่องสนทนาของ BJ ที่ท่านทำการสมัครสมาชิกไว้",
    변경: "เปลี่ยน",
    "기본 닉네임 사용": "ใช้ชื่อเล่นปกติ",

    "현재 방송 중인 BJ가 없습니다.": "ไม่มี BJ กำลังถ่ายทอดสดอยู่",
    "방송국에 등록된 공지글이 없습니다.": "ไม่มีประกาศในบล็อก",
    "방송국에 등록된 게시글이 없습니다.": "ไม่มีกระทู้ในบล็อก",
    "방송국에 업로드된 VOD가 없습니다.": "ไม่มี VOD ที่ถูกอัพโหลดในบล็อก",
    "공지글 보기": "อ่านประกาศ",
    "게시글 보기": "อ่านกระทู้",

    "즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.": "ไม่พบกระทู้ที่อัพโหลดขึ้นบนบล็อกของ BJ ในรายการโปรด",
    "BJ글만 보기": "ความคิดเห็น BJ เท่านั้น",
    "글만 보기": "ตัวอักษรเท่านั้น",
    "이미지만 보기": "รูปภาพเท่านั้น",
    "VOD만 보기": "VOD เท่านั้น",
    "태그에 대한 검색 결과가 없습니다.": "ไม่พบการค้นหาใดๆ บนแท็กนี้",
    "{{num}}명 참여중": "กำลังดู {{num}}คน",
    "{{num}}건": "{{num}}",
    "조회 {{count}}": "คนดู {{count}}",
    "핫 키워드": "คีย์เวิร์ดเด่น",
    "실시간 인기글": "กระทู้ยอดนิยมล่าสุด",
    "전체글 보기": "ทั้งหมด",
    "방송국 새 글": "กระดานข่าว",
    "해외에서 재생이 불가한 VOD입니다.": "VOD นี้ไม่สามารถทำการเล่นซ้ำในต่างประเทศได้",
    "이어서 재생을 원하실 경우,<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "หากต้องการให้เล่นซ้ำ<br/> \n กรุณาไปยังหน้าี่แสดงรายละเอียดแล้วกดเล่นซ้ำอีกครั้ง",
    "오류가 발생하여 재생이 불가합니다.": "",
    "<0>{{nickname}}({{id}})</0><1>님의 방송국</1>": "<1>บล็อกของ</1><0>{{nickname}}({{id}})</0>",
    고정하기: "ปักหมุด",
    공유하기: "แชร์",
    "주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요": "คัดลอกที่อยู่ URL แล้ว นำไปวาง (Ctrl+V) ได้ตามที่ต้องการ",
    공지: "ประกาศ",
    "미리보기가 원활하지 않습니다.<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "พรีวิวมีการกระตุกเกิดขึ้น<br/>กรุณาไปยังหน้าี่แสดงรายละเอียดแล้วกดเล่นซ้ำอีกครั้ง",

    "아프리카TV에서 생긴 일": "ข่าวสารของ AfreecaTV",
    "아.생": "ข่าวสาร",
    투데이: "วันนี้",
    "즐겨찾기한 BJ 글": "กระทู้ของ BJ โปรด",
    "실시간 인기글 TOP 100": "100 กระทู้ยอดนิยม",
    "TOP 100": "100 อันดับ",
    "{{rank}}위": "อันดับ {{rank}}",
    "방송국 가기": "ไปยังบล็อก",
    "게시물 안보기": "ซ่อนกระทู้",
    "게시물 안보기가 설정된 게시물입니다.": "กระทู้นี้ถูกซ่อนแล้ว",
    "실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.": "กระทู้ของ BJ นี้จะไม่ถูกแสดงบน ส่วนกระทู้ยอดนิยม",
    "게시물 안보기 해제": "ยกเลิกการซ่อน",
    "핫이슈 동영상": "VOD ยอดนิยม",
    "실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?":
        "ท่านต้องการจะซ่อนกระทู้ของ {{user_nick}} จากหมวดหมู่กระทู้ยอดนิยมของท่านหรือไม่",
    "지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?":
        "BJ ที่ท่านทำการซ่อนไปเป็นหนึ่งใน BJ โปรดของท่าน\nท่านต้องการจะลบ BJ คนดังกล่าวออกจากรายการ BJ โปรดด้วยหรือไม่",
    "로그인이 필요한 기능입니다.\n로그인 하시겠습니까?": "ต้องทำการล็อกอินก่อน\nท่านต้องการจะล็อกอินหรือไม่",
    "{{user_nick}}님의 게시물을 다시 보시겠습니까?": "ท่านต้องการที่จะยกเลิกการซ่อน กระทู้ของ {{user_nick}} หรือไม่",
    "{{user_nick}}님의 게시물 안보기": "ซ่อนกระทู้ของ {{user_nick}}",
    "네트워크에 문제가 있습니다.<br> 네트워크 상태를 확인해주세요.": "เกิดข้อผิดพลาดขึ้นเกี่ยวกับเครือข่าย<br>กรุณาตรวจสอบเครือข่ายของท่าน",
    "오늘의 움짤&유저클립": "GIF & คลิปวันนี้",
    "닉네임 검색": "ค้นหาจากชื่อเล่น",
    "아이디 검색": "ค้นหาไอดี",

    스토리: "สตอรี่",
    "내 글·댓글": "โพสต์ของฉัน·ความคิดเห็น",
    "스토리 내용이 없습니다.": "ไม่พบสตอรี่ใดๆ บันทึกไว้",
    "작성한 게시글, 댓글, 답글이 없습니다.": "ไม่พบโพสต์ ความคิดเห็น หรือข้อความตอบกลับใดๆ ที่เคยบันทึกไว้",
    "작성한 게시글이 없습니다.": "ไม่พบโพสต์ใดๆ ที่เคยบันทึกไว้",
    "작성한 댓글이 없습니다.": "ไม่พบความคิดเห็นใดๆ ที่เคยบันทึกไว้",
    "작성한 답글이 없습니다.": "ไม่พบข้อความตอบกลับใดๆ ที่เคยบันทึกไว้",
    "댓글 보기": "ดููความคิดเห็น",
    "답글 보기": "ดูข้อความตอบกลับ",
    "{{nickname}}<1>님의 방송국</1>": "<1>บล็อกของ</1>{{nickname}}",
    "즐겨찾기 BJ별 알림 설정을<br/>PC에서도 할 수 있어요!": "ท่านสามารถปรับการแจ้งเตือนสำหรับ<br/>BJ คนโปรดของท่านบน PC ได้เช่นกัน",
    "모든 즐겨찾기 BJ에게 알림을 받습니다.": "รับการแจ้งเตือนทั้งหมดจาก BJ",
    "모든 즐겨찾기 BJ에게 알림을 받지 않습니다.": "ไม่รับการแจ้งเตือนทั้งหมดจาก BJ",
    "{{favorite_nick}}님에게 알림을 받습니다.": "รับการแจ้งเตือนจาก {{favorite_nick}}",
    "{{favorite_nick}}님에게 알림을 받지 않습니다.": "ไม่รับการแจ้งเตือนจาก {{favorite_nick}}",
    "알림 받지 않기": "ไม่รับการแจ้งเตือน",
    "알림 받기": "รับการแจ้งเตือน",
    "전체 알림": "เตือนทั้งหมด",

    "선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?": "ท่านต้องการจะลบกระทู้นี้ใช่หรือไม่(รวมทั้งความเห็นและการตอบกลับทั้งหมด)",
    "선택하신 댓글(답글 포함)을 삭제하시겠습니까?": "ท่านต้องการจะลบความเห็น(และการตอบกลับ)ที่เลือกหรือไม่",
    "선택하신 답글을 삭제하시겠습니까?": "ท่านต้องการจะลบการตอบกลับที่เลือกหรือไม่",
    "삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.": "เมื่อท่านทำการลบแล้ว ท่านจะไม่สามารถกู้ข้อความที่ลบไปกลับมาได้อีก",
    "게시글(댓글, 답글 포함) 삭제가 완료되었습니다.": "ท่ท่านได้ทำการลบกระทู้นี้เรียบร้อยแล้ว (รวมทั้งความเห็นและการตอบกลับทั้งหมด)",
    "댓글(답글 포함) 삭제가 완료되었습니다.": "ท่านได้ทำการลบความเห็น(และการตอบกลับ)แล้ว",
    "답글 삭제가 완료되었습니다.": "ท่านได้ทำการลบการตอบกลับแล้ว",
    삭제하기: "ลบ",
    "최근 본 방송": "รับชมล่าสุด",
    "최근 본 VOD": "วิดีโอล่าสุด",
    "UP한 VOD": "วิดีโอที่ UP",
    재생목록: "รายการเล่น",
    "최근 본 VOD 삭제": "ลบวิดีโอล่าสุด",
    "아직 참여한 방송이 없습니다.": "ไม่มีประวัติรับชม",
    "지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "เข้ามายัง AfreecaTVเพื่อรับชมรายการสดที่สนุกสนานและหลากหลาย!",
    "LIVE 보러가기": "ไปยังรายการสด",
    "VOD 보러가기": "ไปยังวิดีโอ",
    "최근 본 VOD가 없습니다.": "ไม่มีวิดีโอล่าสุด",
    "나중에 보기 한 VOD가 없습니다.": "ไม่มีวิดีโอรับชมภายหลัง",
    "UP 한 VOD가 없습니다.": "ไม่มีวิดีโอที่ UP",
    "재생목록이 없습니다.": "ไม่มีรายการเล่น.",
    "VOD 위치 변경 안내": "แจ้งเตือนการเปลี่ยนที่อยู่ของวิดีโอ",
    "즐겨찾기한 BJ의 유저클립과 VOD는 새로워진 VOD 메뉴에서 만나보세요!": "รับชมคลิปผู้ใช้และวิดีโอที่ท่านชื่นชอบได้ที่หมวด VOD",
    "즐겨찾기한 VOD 보러가기": "ไปยังวิดีโอโปรด",

    "UP 취소": "ยกเลิก UP",
    "나중에 보기 삭제": "ลบจากรับชมภายหลัง",
    "재생한 VOD 삭제": "ลบจากรับชมภายหลัง",
    전체삭제: "ลบทั้งหมด",
    조회순: "รับชมสูงสุด",
    UP순: "UP",
    댓글순: "คอมเม้นท์",
    "VOD 추가순": "เรียงตามวัน",
    오래된순: "เก่าสุด",
    최신순: "ล่าสุด",
    전체보기: "ดูทั้งหมด",
    비공개: "ส่วนตัว",
    업데이트: "อัพเดท",
    "재생목록에 담기": "เพิ่มเข้ารายการเล่น",
    수정하기: "แก้ไข",

    "내 마음대로 만드는 플레이리스트!":"สร้างรายการเล่นของท่านเอง!",
    "테마/BJ별로 재생목록에 담아 편하게 시청하세요.":"เพลิดเพลินไปกับรายการเล่นของท่านที่เรียงตามธีมหรือ BJ ที่ท่านต้องการ.",

    "새 재생목록 만들기": "สร้างรายการเล่นใหม่",
    "제목을 입력해주세요.": "กรุณาระบุชื่อ.",
    "목록 공개": "ตั้งรายชื่อให้เป็นสาธารณะ",
    "목록 비공개": "ตั้งรายชื่อให้เป็นส่วนตัว",
    "재생목록 저장 허용": "อนุญาตให้บันทึกได้",
    "재생목록 저장 비허용": "ไม่อนุญาตให้บันทึก",
    추가: "เพิ่ม",
    "미리보기를 제공하지 않는 VOD": "ไม่มีพรีวิว",
    "지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!": "รับชมวิดีโอที่สนุกสนานและหลากหลายได้ ที่ AfreecaTV!",
    "해당 BJ를 즐겨찾기에서 삭제하시겠습니까?" : "ท่านต ้องการจะลบBJ ท่านนี้ออกจากรายการโปรดหรือไม่",
    "재생목록을 삭제하시겠습니까?" : "ท่านต้องการจะลบรายการเล่นหรือไม่",
    "편집": "แก้ไข",
    "즐찾BJ 편집" : "แก้ไข้ BJ โปรด",
    "새 그룹 만들기" : "สร้างกลุ่มใหม่",
    "그룹명" : "ชื่อกลุ่ม",
    "그룹명을 입력해주세요." :"กรุณาระบุชื่อกลุ่ม",
    "BJ 추가" : "เพิ่ม BJ",
    "BJ 추가하기" : "เพิ่ม BJ",
    "그룹에서 삭제" : "ลบจากกลุ่ม",
    "즐찾BJ 그룹에 담기" : "เพิ่มเข้ากลุ่ม BJ โปรด",
    "즐찾BJ 그룹" : "กลุ่ม BJ โปรด",
    "{{num}}명 추가" : "เพิ่ม {{num}}",
    "해당 그룹을 삭제하시겠습니까?" : "ท่านต้องการจะลบกลุ่มนี้หรือไม่",
    "그룹 추가는 최대 150명까지입니다." : "สามารถเพิ่มเข้ากลุ่มได้สูงสุด 150 คน",
    "BJ를 추가해주세요." : "เพิ่ม BJ",
    "편집할 그룹이 없습니다." : "ไม่มีกลุ่มให้แก้ไข",
    "BJ 그룹 기능을 이용하여<br/>원하는 주제별로 BJ를 정리해보세요!" : "ใช้ระบบจัดกลุ่ม BJ<br/>จัด BJ ตามหมวดที่ท่านต้องการ!",
    "삭제" : "ลบ",
    "그룹 수정" : "เปลี่ยนกลุ่ม",
    "확인" : "ยืนยัน",
    "구독전용": "เฉพาะสมาชิกเท่านั้น",
    "드롭스 이벤트 진행 중" : "กำลังดำเนินกิจกรรม Drops อยู",
    "%d분 전": "ก่อนหน้า %d วินาที",
    "%d시간 전": "ก่อนหน้า %d ชั่วโมง",
    "%d일 전": "ก่อนหน้า %d วัน",
    "%d초 전": "หลังจาก %d นาที",
    "Catch 스토리": "Catch สตอรี่",
    "월": "เดือน",
    "일": "วัน",
};
