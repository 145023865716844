import classNames from "classnames";
import useWideAreaClass from "components/hook/useWideAreaClass";
import { HOTISSUE_POPULAR_ROUTE, HOTISSUE_ROUTE } from "constant/routeUrl";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import HotissuePopular from "./HotissuePopular";

export default function Hotissue() {
    const { t } = useTranslation();
    let location = useLocation();
    const sizeClass = useWideAreaClass();
    /* MY 개편시 핫이슈(today) 탭 제거
     * today 메뉴 제거
     */
    return (
        <div id="wideArea" className={classNames("content_area", "af-issue", sizeClass)}>
            <h2>{t("실시간 인기글")}</h2>

            <Switch>
                <Route path={HOTISSUE_POPULAR_ROUTE} component={HotissuePopular} />
                <Redirect strict push={false} from={HOTISSUE_ROUTE} to={HOTISSUE_POPULAR_ROUTE} />
            </Switch>
        </div>
    );
}
