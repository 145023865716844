import { destoryStory } from "actions/story.action";
import ThreeDotButton from "components/button/ThreeDotButton";
import ContextMenu from "components/ict/ContextMenu";
import StoryImage from "components/image/StoryImage";
import StoryVodThumbnail from "components/thumbnail/StoryVodThumbnail";
import { CHILD_COMMENT, PARENT_COMMENT, PHOTO, POST, VOD, BJSUPPORT } from "constant/feedType";
import { makeStationUrl, nl2br, numberCommaString, makeProfileUrl } from "helpers";
import { decodeEmoticon } from "libs/emoticon";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ProfileImage from "components/image/ProfileImage";

export default function StroyItem({ measure, index, item, style, onPosition, onChangeLayer }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ layer, setLayer ] = useState("");
    const [ no, setNo ] = useState(0);

    const optionalItem = useCallback(
        (item) => {
            if (item.comment === "") {
                return { comment: item.feed_type === PARENT_COMMENT ? t("이미지만 첨부된 댓글입니다") : t("이미지만 첨부된 답글입니다") };
            } else {
                return item;
            }
        },
        [t],
    );

    const handleDestoryStory = useCallback(
        (story) => () => {
            let deleteMessage;
            switch (story.feed_type) {
                case POST:
                case PHOTO:
                    deleteMessage = t("선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?");
                    break;
                case PARENT_COMMENT:
                    deleteMessage = t("선택하신 댓글(답글 포함)을 삭제하시겠습니까?");
                    break;
                case CHILD_COMMENT:
                    deleteMessage = t("선택하신 답글을 삭제하시겠습니까?");
                    break;
                default:
                    break;
            }
            deleteMessage += "\n";
            deleteMessage += t("삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.");
            if (window.confirm(deleteMessage)) {
                dispatch(destoryStory(story));
            }
        },
        [dispatch, t],
    );
    
    const nickname = item.station_user_nick;
    if (item.feed_type === POST || item.feed_type === PHOTO) {
        return (
            <li className="post" style={style}>
                <div className="wrap">
                    {item.station_user_id !== item.user_id && (
                        <div className="autor_wrap other-bs">
                            <ProfileImage 
                                    tag="a" 
                                    className="thum" 
                                    src={makeProfileUrl(item.station_user_id)}
                                    url={makeStationUrl(item.station_user_id)}
                                    user_id={item.station_user_id}
                                />
                            <div className="info_box">
                                <ContextMenu user_id={item.station_user_id} user_nick={item.station_user_nick}>
                                    <button type="button" className="nick">
                                        <Trans nickname={nickname}>
                                            {{ nickname }}
                                            <em>님의 방송국</em>
                                        </Trans>
                                    </button>
                                </ContextMenu>
                            </div>
                        </div>
                    )}

                    <span className="thum_icon"></span>
                    {item.photos.length > 0 && (
                        <div className="post-img">
                            <StoryImage href={item.url} photo={item.photos} type={item.feed_type} target="_blank" />
                        </div>
                    )}
                    <div className="post-conts">
                        <a href={item.url} target="_blank">
                            <div className="title">
                                <h3>
                                    {item.is_notice && (
                                        <i>
                                            <Trans>공지</Trans>
                                        </i>
                                    )}
                                    {item.title_name}
                                </h3>
                            </div>
                            <p className="detail_text" dangerouslySetInnerHTML={{ __html: decodeEmoticon(item.content) }}></p>
                        </a>
                        <div className="post-infos">
                            <span className="date">{item.reg_date}</span>
                            <em className="views">{numberCommaString(item.count.read_cnt)}</em>
                            <em className="cmt">{numberCommaString(item.count.comment_cnt)}</em>
                        </div>
                    </div>
                    <ThreeDotButton
                        trigger={
                            <button type="button" className="btn-detail">
                                <span>더보기 메뉴</span>
                            </button>
                        }
                    >
                        <button type="button" onClick={handleDestoryStory(item)}>
                            <span>
                                <Trans>삭제하기</Trans>
                            </span>
                        </button>
                    </ThreeDotButton>
                </div>
            </li>
        );
    } else if (item.feed_type === PARENT_COMMENT) {
        return (
            <li className="comment" style={style}>
                <div className="wrap">
                    <span className="thum_icon"></span>
                    {item.photo && (
                        <div className="post-img">
                            <StoryImage href={item.url} photo={item.photo} type={item.feed_type} target="_blank" />
                        </div>
                    )}
                    <div className="post-conts">
                        <a href={item.url} target="_blank">
                            <div className="title">
                                <h3 dangerouslySetInnerHTML={{ __html: decodeEmoticon(nl2br(optionalItem(item).comment), true) }}></h3>
                            </div>
                        </a>
                        <div className="post-infos">
                            <span className="date">{item.reg_date}</span>
                        </div>
                    </div>

                    {/** 댓글의 게시글 */}
                    {item.parent && (
                        <>
                            {(item.parent.feed_type === POST || item.parent.feed_type === PHOTO || item.parent.feed_type === BJSUPPORT) && (
                                <div className="post-box">
                                    <div className="lt">
                                        <div className="autor_wrap">
                                            <ProfileImage 
                                                tag="a" 
                                                className="thum" 
                                                src={makeProfileUrl(item.parent.user_id)}
                                                url={makeStationUrl(item.parent.user_id)}
                                                user_id={item.parent.user_id}
                                            />

                                            <div className="info_box">
                                                <ContextMenu user_id={item.parent.user_id} user_nick={item.parent.user_nick}>
                                                    <button type="button" className="nick">
                                                        {item.parent.user_nick}
                                                    </button>
                                                </ContextMenu>
                                                <div className="info">
                                                    <span className="date">{item.parent.reg_date}</span>
                                                    <em className="views">{numberCommaString(item.parent.count.read_cnt)}</em>
                                                    <em className="cmt">{numberCommaString(item.parent.count.comment_cnt)}</em>
                                                </div>
                                            </div>
                                        </div>
                                        <a href={item.parent.url} target="_blank" className="title">
                                            <h3>
                                                {item.parent.is_notice && (
                                                    <i>
                                                        <Trans>공지</Trans>
                                                    </i>
                                                )}
                                                {item.parent.title_name}
                                            </h3>
                                        </a>
                                    </div>
                                    {item.parent.photos.length > 0 && (
                                        <div className="rt">
                                            <StoryImage
                                                href={item.parent.url}
                                                photo={item.parent.photos[0]}
                                                type={item.feed_type}
                                                target="_blank"
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            {item.parent.feed_type === VOD && (
                                <div className="post-box vod">
                                    <StoryVodThumbnail href={item.url} vod={item.parent.ucc} target="_blank" />
                                    <div className="lt">
                                        <a href={item.parent.url} target="_blank" className="title">
                                            <h3>{item.parent.title_name}</h3>
                                        </a>
                                        <div className="autor_wrap">
                                            <ContextMenu user_id={item.parent.user_id} user_nick={item.parent.user_nick}>
                                                <button type="button" className="nick">
                                                    {item.parent.user_nick}
                                                </button>
                                            </ContextMenu>
                                            <div className="info">
                                                <em className="views">{numberCommaString(item.parent.count.read_cnt)}</em>
                                                <span>{item.parent.reg_date}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <ThreeDotButton
                        trigger={
                            <button type="button" className="btn-detail">
                                <span>더보기 메뉴</span>
                            </button>
                        }
                    >
                        <button type="button" onClick={handleDestoryStory(item)}>
                            <span>
                                <Trans>삭제하기</Trans>
                            </span>
                        </button>
                    </ThreeDotButton>
                </div>
            </li>
        );
    } else if (item.feed_type === CHILD_COMMENT) {
        return (
            <li className="reply" style={style}>
                <div className="wrap">
                    <span className="thum_icon"></span>
                    {item.photo && (
                        <div className="post-img">
                            <StoryImage href={item.url} photo={item.photo} type={item.feed_type} target="_blank" />
                        </div>
                    )}
                    <div className="post-conts">
                        <a href={item.url} target="_blank">
                            <div className="title">
                                <h3 dangerouslySetInnerHTML={{ __html: decodeEmoticon(nl2br(optionalItem(item).comment), true) }}></h3>
                            </div>
                        </a>
                        <div className="post-infos">
                            <span className="date">{item.reg_date}</span>
                        </div>
                    </div>

                    {item.parent && (
                        <div className="comment-box">
                            <div className="lt">
                                <div className="autor_wrap">
                                    <ProfileImage 
                                        tag="a" 
                                        className="thum" 
                                        src={makeProfileUrl(item.parent.user_id)}
                                        url={makeStationUrl(item.parent.user_id)}
                                        user_id={item.parent.user_id}
                                    />
                                    <div className="info_box">
                                        <ContextMenu user_id={item.parent.user_id} user_nick={item.parent.user_nick}>
                                            <button type="button" className="nick">
                                                {item.parent.user_nick}
                                            </button>
                                        </ContextMenu>
                                        <span>{item.parent.reg_date}</span>
                                    </div>
                                </div>
                                <a href={item.parent.url} target="_blank" className="title">
                                    <h3
                                        dangerouslySetInnerHTML={{ __html: decodeEmoticon(nl2br(optionalItem(item.parent).comment), true) }}
                                    ></h3>
                                </a>
                            </div>
                            {item.parent.photo && (
                                <div className="rt">
                                    <StoryImage href={item.parent.url} photo={item.parent.photo} type={item.feed_type} target="_blank" />
                                </div>
                            )}
                        </div>
                    )}
                    <ThreeDotButton
                        trigger={
                            <button type="button" className="btn-detail">
                                <span>더보기 메뉴</span>
                            </button>
                        }
                    >
                        <button type="button" onClick={handleDestoryStory(item)}>
                            <span>
                                <Trans>삭제하기</Trans>
                            </span>
                        </button>
                    </ThreeDotButton>
                </div>
            </li>
        );
    } else {
        return null;
    }
}
