import i18next from "i18next";
import { ROOT_URL } from "../constant/config";
import { API, recommandDelApi } from "./api.action";
import { handleFetchErrors } from "../helpers";
import { toast } from "afreecatv-react-toast";


export const RECOMMANDS = {
    LOADING_START: "recommands/LOADING_START",
    LOADING_DONE: "recommands/LOADING_DONE",
    FETCH_SUCCESS: "recommands/FETCH_SUCCESS",
    FETCH_ERROR: "recommands/FETCH_ERROR",
    FILTER_RECOMMANDS: "recommands/FILTER_RECOMMANDS",
    FETCH_REJECT_SUCCESS: "recommands/FETCH_REJECT_SUCCESS",
};

/**
 * 추천방송 필터링 처리
 * @param  user_id 제거할 추천방송 유저
 * @return
 */
//export const filterRecommand = (user_id) => ({ type: RECOMMANDS.FILTER_RECOMMANDS, payload: user_id });

/**
 * 추천 방송 목록 가져오기
 */
export const fetchRecommand = (type, group_idx) => {
    return (dispatch) => {
        let url = `${ROOT_URL}/favorite/recommand` + (group_idx > 0 ? `/${group_idx}` :''); 
        dispatch({ type: RECOMMANDS.LOADING_START });

        fetch(url, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: RECOMMANDS.LOADING_DONE });
                if (type === 'reject') {
                    dispatch({
                        type: RECOMMANDS.FETCH_REJECT_SUCCESS,
                        payload: response,
                    });
                } else {
                    dispatch({
                        type: RECOMMANDS.FETCH_SUCCESS,
                        payload: response,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: RECOMMANDS.LOADING_DONE });
                dispatch({
                    type: RECOMMANDS.FETCH_ERROR,
                    payload: error,
                });
            });
    }
}

/**
 * 추천 방송 추천받지 않기
 */
export const recommandDel = (bj_id, broad_no, logic, recommandIds) => {
    return (dispatch) => {
        dispatch(recommandDelApi(bj_id, broad_no, logic, recommandIds))
            .then((response) => {
                dispatch({
                    type: RECOMMANDS.FETCH_REJECT_SUCCESS,
                    payload: response,
                });
                toast.success(i18next.t(`해당 방송을 더 이상 추천하지 않습니다.`));
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}
