import { destroyFavorite, storeFavorite } from 'actions/feed.favorite.action';
import i18next from 'i18next';
import React, { useCallback,useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from "classnames";
import FavoriteGroupProvider, {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";
import { FAVORITE_GROUP } from "actions/favorite.group.action";


/**
 * 즐겨찾기 아이콘
 * @param {*} param0 
 */
export default function FavoriteIcon({item, className = "cast_fav" }) {
    // dispatch
    const dispatch = useDispatch();
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);

    const { group_data } = useSelector((state) => state.favoriteGroupReducer, shallowEqual);
    const { extdata, error, loading } = useSelector(state => (state.favoriteExtReducer), shallowEqual);

    /**
     * 즐겨찾기 추가/삭제
     */
    const onClickFavorite = useCallback((item) => e => {
        e.preventDefault();
        if (item.is_favorite) {
            //그룹 값 1개이면서 해당 그룹에서 즐찾 지울경우 전체로 가게끔 설정(이거 어디서 묶어서 할수있을거같은데 그룹핑 구조 잘못짠거같은데)
        
            if(window.confirm(i18next.t("해당 BJ를 즐겨찾기에서 삭제하시겠습니까?"))){
                let group = group_data.filter((item=>item.idx ===groupIdx))
                //즐찾 그룹에 한명만 있을 경우 즐찾 그룹 삭제 UI 로직 동작
                if(extdata.length ===1){
                    if(group.length ===1) setGroupIdx(0);
                    dispatch({type:FAVORITE_GROUP.FETCH_DEL_DATA,payload:groupIdx})
                }
                dispatch(destroyFavorite(item.user_id, item.user_nick))
            }
        } else {
            dispatch(storeFavorite(item.user_id, item.user_nick))
        }

    }, [dispatch,extdata]);

    return (
        <button type="button" className={classNames("btn-fav", { on: item.is_favorite })} tip={item.is_favorite ? i18next.t(`즐겨찾기 해제`) : i18next.t(`즐겨찾기 추가`)} onClick={onClickFavorite(item)}>
            <span>{item.is_favorite ? i18next.t(`즐겨찾기 해제`) : i18next.t(`즐겨찾기 추가`)}</span>
        </button>
    )
}
