import { ROOT_URL } from "constant/config";
import { handleFetchErrors } from "helpers";


/**
 * 즐찾 상수
 */
export const HASHTAG = {
    LOADING_START: "feed/LOADING_START",
    LOADING_DONE: "feed/LOADING_DONE",
    FETCH_SUCCESS: "feed/FETCH_SUCCESS",
    FETCH_ERROR: "feed/FETCH_ERROR",
    INIT: "feed/INIT",
}

/**
 * 해시태그
 * @param {*} hashtag 
 */
export function fetchHashtag(hashtag, index_reg_date = 0) {
    return (dispatch) => {
        dispatch({ type: HASHTAG.LOADING_START });

        fetch(`${ROOT_URL}/hashtag/${hashtag}?index_reg_date=${index_reg_date}`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: HASHTAG.LOADING_DONE });
                dispatch({
                    type: HASHTAG.FETCH_SUCCESS,
                    payload: response,
                    index_reg_date
                });
            })
            .catch((error) => {
                dispatch({ type: HASHTAG.LOADING_DONE });
                dispatch({
                    type: HASHTAG.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}


export function initFeedHashtag() {
    return (dispatch) => {
        dispatch({ type: HASHTAG.INIT });
    };
}
