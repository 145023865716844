exports.lang = {
    kr: "zh",
    아프리카TV: "AfreecaTV",
    방송국: "直播间",
    전체: "全部",
    탐방허용: "允许连麦直播",
    "쪽지 보내기": "发送短信",
    즐겨찾기: "关注",
    "차단된 팝업을 허용해주세요.": "请允许阻止的弹窗。",
    "즐겨찾기가 추가되었습니다.": "已关注",
    "잠시후 다시 시도해주세요.": "服务器链接失败。稍后请重新尝试。",
    "즐겨찾기가 삭제되었습니다.": "删除了关注",
    "목록 상단 고정 완료 되었습니다.": "置顶设置成功。",
    "목록 상단 고정 해제 되었습니다.": "解除置顶设置",
    "이미 처리중 입니다.": "正在处理中",
    "나중에 보기 목록에 등록 되었습니다.\n목록에서 바로 확인 하시겠습니까?": "添加成功。\n要现在确认吗?",
    "방송 중": "直播中",
    정렬보기: "排列方式",
    참여인원순: "收看人数排序",
    최신등록순: "最新上传排序",
    최신방송순: "最新直播排序",
    새로고침: "刷新",
    방송시작: "开始直播",
    "즐겨찾기 삭제": "刪除",
    추가하기: "",
    "나중에 보기": "下次再看",
    더보기: "查看更多",
    이전: "上一个",
    다음: "下一个",
    다시보기: "观看回放",
    하이라이트: "精简视频",
    "팬가입한 BJ": "已加入(的)粉丝团",
    "팬가입한 BJ 전체": "所有主播",
    "팬클럽 가입순": "加入日期排序",
    닉네임순: "昵称排序",
    아이디순: "账号字母排序",
    "팬클럽 가입일": "加入日期",
    최근방송: "最近直播",
    "즐겨찾기 해제": "删除关注",
    "즐겨찾기 추가": "添加关注",
    "LIVE 참여하기": "观看直播",
    "목록 상단해제": "解除置顶",
    "목록 상단고정": "置顶",
    "가입한 팬클럽이 없습니다.": "暂无粉丝团。",
    "즐겨찾기한 BJ": "关注",
    "즐겨찾기 전체": "全部",
    "{{num}}명": "{{num}}人",
    "즐겨찾기한 BJ가 없습니다.": "没有关注的主播。",
    "최근 본 BJ의 LIVE": "最近观看主播的直播",
    최근참여순: "最近收看排序",
    "최근 본 BJ의 LIVE 삭제": "删除最近观看主播的直播",
    "최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?" : "确定删除最近观看主播的直播内容吗？ ",
    "해당 방송이 최근 본 방송에서 삭제되었습니다.": "删除成功。",
    "지금 바로, 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "现在马上体验AfreecaTV的多彩而有趣的直播。",
    닫기: "关闭",
    "즐겨찾기한 BJ의 VOD": "已关注主播的视频",
    "즐겨찾기한 BJ의 Catch": "关注主播的捕捉",
    "팬가입한 BJ의 VOD": "加入粉丝团(的)主播的视频",
    "구독한 BJ의 VOD": "订阅主播的视频",
    "즐겨찾기 할 만한 방송": "您可能感兴趣的直播。",
    "즐겨찾기할 만한 BJ": "推荐关注",
    "BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!<br/>팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.":
        "赠送主播星气球即可加入主播的粉丝团！<br/>粉丝团可以使用粉丝团专用告示板，<br/>并且在聊天栏内会赋予区别于其他用户的字体颜色。",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다.":
        "关注喜欢的主播可以随时方便的查询直播，<br/>直播开始时会提示您收看。",
    "즐겨찾기 BJ글": "关注主播的文章",
    "나중에 보기에 등록되었습니다.": "添加成功。",
    "보고 싶은 VOD나, 아직 보지 않은<br/>VOD를 목록에 등록해 두고  언제든 간편하게 찾아보세요!":
        "添加感兴趣的或还未观看的，<br/>视频到列表，有空时再观看！",
    "라이브 방송을 참여할 수 없는 상황일 때<br/>목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!":
        "不方便观看直播时，<br/> 可以添加到列表，有空时在观看！",
    "<0>VOD의 “나중에 보기” 메뉴</0>에서<br/>등록 한 영상을 확인 하실 수 있습니다.":
        '<0>添加视频到"下次再看"后，</0> 在<br/>列表中可以确认。',
    다시보지않기: "不再显示",
    "레이어 닫기": "关闭画面",
    "나중에 보기에 등록된 VOD입니다.": "成功設定下次再看此視頻。",
    "일시적인 오류 입니다. 잠시 후 다시 시도해 주세요": "發生錯誤。請稍後重新嘗試。",
    "나중에 보기 리스트는 최대 1,000개 까지 등록 가능합니다.": "添加下次再看的直播上線為1,000個。",
    "나중에 보기를 지원하지 않는 영상입니다.": "此影片不支持[下次再看]。",
    "VOD 정보가 없습니다.": "沒有影片信息。",
    "게시물이 존재하지 않습니다": "公告不存在。",
    "처리 되었습니다.": "设置成功",
    "방송국 바로가기": "前往直播间",
    "즐겨찾기한 BJ의 유저클립": "关注主播的用户剪辑",
    "추천받지 않기": "刪除",
    "해당 방송을 더 이상 추천하지 않습니다.": "不再推荐此直播。",

    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다. ":
        "关注主播，即可随时在Feed上<br>\n 确认此主播上传的所有内容。",
    "BJ가 등록한 게시물이 없습니다.": "暂无主播上传的文章。",
    "구독한 BJ": "订阅主播",
    "구독한 BJ 전체": "订阅的全部主播",
    "구독 결제정보": "订阅结算信息",
    최신구독순: "最新订阅排序",
    "구독 닉네임": "订阅昵称",
    구독일: "订阅日",
    "{{month}}개월": "{{month}} 个月",
    "회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)":
        "确定使用您的基本昵称{{default_nick}}吗？ \n(经过24小时才能重新更改)",
    "구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다":
        "订阅者昵称更改成功。\n重新进入直播间即可使用更改的昵称。",
    "구독한 BJ가 없습니다.": "暂无订阅主播。",
    "내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br/>구독팬만을 위한 아주 특별한 혜택을 만나보세요!":
        "订阅我喜欢的主播，享受直接进入主播的直播间，<br/>使用订阅者昵称，专用表情等订阅者独有的优惠。",
    유료: "付费",

    "구독팬 전용 닉네임 변경": "更改订阅者专用昵称",
    "구독팬 전용 닉네임을 입력해주세요.": "请输入订阅者专用昵称。",
    중복확인: "重复确认",
    "한글 6자(영문 12자)까지 입력 가능": "最多能输入汉字6字(英文12字)",
    "변경 시 24시간 후 재변경 가능": "需经过24小时才能重新更改",
    "구독한 BJ방송 채팅에서 사용됩니다.": "用于订阅主播的聊天栏内。",
    변경: "更改",
    "기본 닉네임 사용": "使用基本昵称",

    "현재 방송 중인 BJ가 없습니다.": "目前没有直播",
    "방송국에 등록된 공지글이 없습니다.": "暂无公告。",
    "방송국에 등록된 게시글이 없습니다.": "暂无文章。",
    "방송국에 업로드된 VOD가 없습니다.": "暂无上传视频。",
    "공지글 보기": "确认公告",
    "게시글 보기": "确认文章",

    "즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.": "关注主播的直播间内暂无任何文章。",
    "BJ글만 보기": "只显示主播的文章",
    전체보기: "查看全部",
    "글만 보기": "只显示文章",
    "이미지만 보기": "只显示图片",
    "VOD만 보기": "查看视频",
    "태그에 대한 검색 결과가 없습니다.": "没有标签的相关搜索结果。",
    "{{num}}명 참여중": "{{num}}人观看中",
    "{{num}}건": "{{num}}个",
    "조회 {{count}}": "查询 {{count}}",
    "핫 키워드": "热门搜索词",
    "실시간 인기글": "实时人气文章",
    "전체글 보기": "查看全部",
    "방송국 새 글": "最新动态",
    "해외에서 재생이 불가한 VOD입니다.": "韩国境外用户限制观看此视频。",
    "이어서 재생을 원하실 경우,<br/>상세페이지로 이동 후 재생해주시기 바랍니다.": "如要继续观看，<br/> 请前往详细页面后播放。",
    "오류가 발생하여 재생이 불가합니다.": "",
    "<0>{{nickname}}({{id}})</0><1>님의 방송국</1>": "<0>{{nickname}}({{id}})</0><1>的直播间</1>",
    고정하기: "固定",
    공유하기: "分享",
    "주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요": "复制成功。请粘贴到想要粘贴的位置(Ctrl+V)。",
    공지: "公告",
    "미리보기가 원활하지 않습니다.<br/>상세페이지로 이동 후 재생해주시기 바랍니다.": "预览失败。<br/>请前往详细页面后播放。",

    "아프리카TV에서 생긴 일": "在AfreecaTV发生的事情",
    "아.생": "爱菲喀故事",
    투데이: "今日",
    "즐겨찾기한 BJ 글": "关注主播的文章",
    "실시간 인기글 TOP 100": "实时人气文章 TOP 100",
    "TOP 100": "TOP 100",
    "{{rank}}위": "第{{rank}}",
    "방송국 가기": "前往直播间",
    "게시물 안보기": "隐藏此文章",
    "게시물 안보기가 설정된 게시물입니다.": "此文章已被隐藏",
    "실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.": "实时人气文章中不再显示此主播的文章。",
    "게시물 안보기 해제": "解除隐藏",
    "핫이슈 동영상": "热门视频",
    "실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?": "确定不再显示{{user_nick}}的文章吗?",
    "지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?":
        "设置为隐藏的主播是已关注的主播。\n从关注列表中删除此主播吗?",
    "로그인이 필요한 기능입니다.\n로그인 하시겠습니까?": "需要登录才能使用此功能。\n要登录吗?",
    "{{user_nick}}님의 게시물을 다시 보시겠습니까?": "确定显示{{user_nick}}的文章吗?",
    "{{user_nick}}님의 게시물 안보기": "开始显示{{user_nick}}的文章。",
    "네트워크에 문제가 있습니다.<br> 네트워크 상태를 확인해주세요.": "网络发生问题。<br>请确认网络环境。",
    "오늘의 움짤&유저클립": "今日精彩GIF&用户剪辑",
    "닉네임 검색": "搜索昵称",
    "아이디 검색": "搜索账号",

    스토리: "故事",
    "내 글·댓글": "我的帖子·评论",
    "스토리 내용이 없습니다.": "暂无任何故事。",
    "작성한 게시글, 댓글, 답글이 없습니다.": "暂无任何文章，留言和回复。",
    "작성한 게시글이 없습니다.": "暂无文章。",
    "작성한 댓글이 없습니다.": "暂无留言。",
    "작성한 답글이 없습니다.": "暂无回复。",
    "댓글 보기": "查看留言",
    "답글 보기": "查看回复",
    "{{nickname}}<1>님의 방송국</1>": "{{nickname}}<1>的直播间</1>",
    "즐겨찾기 BJ별 알림 설정을<br/>PC에서도 할 수 있어요!": "在电脑端也能个别设定<br/>关注主播的提示。",
    "모든 즐겨찾기 BJ에게 알림을 받습니다.": "接收所有主播的提示音。",
    "모든 즐겨찾기 BJ에게 알림을 받지 않습니다.": "不接收所有主播的提示音。",
    "{{favorite_nick}}님에게 알림을 받습니다.": "开始接收{{favorite_nick}}发送的提示。",
    "{{favorite_nick}}님에게 알림을 받지 않습니다.": "开始关闭{{favorite_nick}}发送的提示。",
    "알림 받지 않기": "关闭提示",
    "알림 받기": "开启提示",
    "전체 알림": "全部提示",

    "선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?": "确定删除已选文章(含留言及回复)吗？",
    "선택하신 댓글(답글 포함)을 삭제하시겠습니까?": "确定删除已选留言(包含回复)吗？",
    "선택하신 답글을 삭제하시겠습니까?": "确定删除已选回复吗？",
    "삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.": "选择删除后，我的故事和直播间内也将永久被删除并无法恢复。",
    "게시글(댓글, 답글 포함) 삭제가 완료되었습니다.": "文章(含留言及回复)删除成功。",
    "댓글(답글 포함) 삭제가 완료되었습니다.": "成功删除留言(包含回复)。",
    "답글 삭제가 완료되었습니다.": "成功删除回复。",
    삭제하기: "删除",
    
    "최근 본 방송": "最近观看",
    "최근 본 VOD": "最近观看视频",
    "UP한 VOD": "点赞视频",
    재생목록: "播放列表",
    "최근 본 VOD 삭제": "删除最近观看视频",
    "아직 참여한 방송이 없습니다.": "暂无参与直播的记录。",
    "지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "现在就去观看AfreecaTV有趣并且多样的直播把！",
    "LIVE 보러가기": "观看直播",
    "VOD 보러가기": "前往观看视频",
    "최근 본 VOD가 없습니다.": "暂无最近观看视频。",
    "나중에 보기 한 VOD가 없습니다.": "暂无下次再看的视频。",
    "UP 한 VOD가 없습니다.": "暂无点赞视频。",
    "재생목록이 없습니다.": "播放列表为空。",
    "VOD 위치 변경 안내": "视频位置变更提示",
    "즐겨찾기한 BJ의 유저클립과 VOD는 새로워진 VOD 메뉴에서 만나보세요!": "请前往最新版的视频菜单观看关注主播的视频及用户剪辑！",
    "즐겨찾기한 VOD 보러가기": "前往观看关注主播的视频",
    "UP 취소": "取消UP",
    "나중에 보기 삭제": "删除",
    "재생한 VOD 삭제": "删除已播放视频",
    전체삭제: "全部删除",
    조회순: "播放排序",
    UP순: "点赞数",
    댓글순: "留言数",
    "VOD 추가순": "视频添加排序",
    오래된순: "时间排序",
    최신순: "最新",
    비공개: "非公开",
    업데이트: "更新",
    "재생목록에 담기": "添加到播放列表",
    수정하기: "修改",

    "내 마음대로 만드는 플레이리스트!":"能创建我的专属播放列表！",
    "테마/BJ별로 재생목록에 담아 편하게 시청하세요.":"请按主题/主播添加到播放列表后观看。",
    
    "새 재생목록 만들기": "创建新播放列表",
    "제목을 입력해주세요.": "请输入标题。",
    "목록 공개": "公开目录",
    "목록 비공개": "隐藏目录",
    "재생목록 저장 허용": "允许转载列表",
    "재생목록 저장 비허용": "不允许转载列表",
    "추가": "添加",
    "미리보기를 제공하지 않는 VOD": "不能预览",
    "지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!": "现在马上就去体验AfreecaTV 有趣并多样的视频节目吧！",
    "해당 BJ를 즐겨찾기에서 삭제하시겠습니까?" : "要删除关注吗？ ",
    "재생목록을 삭제하시겠습니까?" : "确定要删除播放列表吗？",
    "편집" :"编辑",
    "즐찾BJ 편집" : "编辑关注主播",
    "새 그룹 만들기" : "创建新群组",
    "그룹명" : "群组名",
    "그룹명을 입력해주세요." :"请输入群组名。",
    "BJ 추가" : "添加主播",
    "BJ 추가하기" : "添加主播",
    "그룹에서 삭제" : "从群组删除",
    "즐찾BJ 그룹에 담기" : "添加至关注主播群组",
    "즐찾BJ 그룹" : "关注主播群组",
    "{{num}}명 추가" : "添加{{num}}人",
    "해당 그룹을 삭제하시겠습니까?" : "确定删除此群组吗？",
    "그룹 추가는 최대 150명까지입니다." : "每个群组最多不能超过150人。",
    "BJ를 추가해주세요." : "添加主播",
    "편집할 그룹이 없습니다." : "尚无可编辑的群组。",
    "BJ 그룹 기능을 이용하여<br/>원하는 주제별로 BJ를 정리해보세요!" : "使用主播分类功能<br/>按注意分类主播吧！",
    "삭제" : "删除",
    "그룹 수정" : "修改群组",
    "확인" : "确认",
    "구독전용": "订阅专用",
    "드롭스 이벤트 진행 중" : "Drops活动进行中",
    "%d분 전": "%d分前",
    "%d시간 전": "%d小时前",
    "%d일 전": "%d天前",
    "%d초 전": "%d秒前",
    "Catch 스토리": "Catch Story",
    "월": "月",
    "일": "日",
};
