import { fetchFavoriteFeeds } from "actions/favorite.content.action";
import usePostsSildeCount from "components/hook/usePostsSildeCount";
import { makeProfileUrl, numberCommaString } from "helpers";
import React, { useCallback, useEffect, useRef, useContext, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";
import {JAVASCRIPT_VOID} from "constant/config";
import ProfileImage from "components/image/ProfileImage";

export default function FavoriteFeedSection() {
    const ref = useRef(null);
    const [swiper, setSwiper] = useState(null); // 슬라이드 index 값 조정
    const count = usePostsSildeCount();
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const { data } = useSelector((state) => state.favoriteContentReducer, shallowEqual);

    useEffect(() => {
        if (window.oSimplePage && window.oSimplePage.fav.fav_vod === 1) {
            dispatch(fetchFavoriteFeeds(groupIdx));
        }
    }, [dispatch]);

    /**
     * 이전 버튼
     */
    const onClickPrevButton = useCallback((e) => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    }, []);

    /**
     * 다음 버튼
     */
    const onClickNextButton = useCallback((e) => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    }, []);

    const handleLinkBoard = (url) => {
        window.open(url, '_blank');

        return false;
    };

    
  
    useEffect(() => {
        // loop를 사용하면 duplicate slides가 loopAdditionalSlides default 3 setting 됨
        // 이 과정에서 1개나 2개인경우에도 붙게 되고, 10개여도 duplicate prev, active, next index가 맨 앞으로 위치하다보니 해당 슬라이드 이동 됨
        // 스와이퍼 렌더링 시 강제로 index 0으로 이동
        
        if (swiper && data.length > 0) {
            swiper.slideToLoop(0);
        }
    }, [data, swiper])

    if (data.length > 0) {
        return (
            <div className = "bj_notice_wrap">
                <div className="slide-bj_notice">
                <Swiper
                    ref={ref}
                    wrapperTag="ul"
                    spaceBetween={0}
                    slidesPerView={count}
                    breakpoints= {{
                        // 1280px 보다 클 경우
                        2560: {
                          slidesPerView: 4,
                        },
                        640 :{
                            slidesPerView:3,
                        }
                      }
                    }
                    allowTouchMove={false}
                    className={"swiper-container"}
                    loop={true}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => { console.log(swiper); setSwiper(swiper); }}
                    
                >
                    {data.map((item, index) => {
                        return (
                            <SwiperSlide tag="li" key={index}>
                                <a href={item.url.replace("http:", "")} onClick={(event) => {event.preventDefault(); handleLinkBoard(item.url.replace("http:", ""))}}>
                                    <div className="box">
                                        <ProfileImage 
                                            tag="span"
                                            className="thumb"
                                            user_id={item.user_id} 
                                            src={makeProfileUrl(item.user_id)}/>

                                        <div className="conts">
                                            <em className="nick">{item.user_nick}</em>
                                            <span className="date">{item.reg_date}</span>
                                            <strong className="title">{item.title_name}</strong>
                                            <div className="info">
                                                <span className="up">
                                                    {numberCommaString(item.count.like_cnt + (item.is_like ? 1 : 0))}
                                                </span>
                                                <span className="cmmt">{numberCommaString(item.count.comment_cnt)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {item.photos[0] && (
                                        <div
                                            className="img"
                                            style={{
                                                backgroundImage: `url(${item.photos[0].url}`,
                                            }}
                                        ></div>
                                    )}
                                </a>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                <a href={JAVASCRIPT_VOID} className="btn_prev" onClick={onClickPrevButton}>
                    <span>이전배너보기</span>
                </a>
                <a href={JAVASCRIPT_VOID} className="btn_next" onClick={onClickNextButton}>
                    <span>다음배너보기</span>
                </a>
            </div>
        </div>
        );
    } else {
        return null;
    }
}
