import { AUTOHASHTAG } from "actions/tag.action";

const initialState = {
    loading: false,
    data: []
};

const autoHashTagReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTOHASHTAG.LOADING_START:
            return { ...state, loading: true };

        case AUTOHASHTAG.LOADING_DONE:
            return { ...state, loading: false };

        case AUTOHASHTAG.SUCCESS_DATA:
            return { ...state, data: action.payload };

        case AUTOHASHTAG.FAIL_DATA:
            return initialState;

        default:
            return state;
    }
};

export default autoHashTagReducer;
