import ContextMenu from "components/ict/ContextMenu";
import FeedHashImage from "components/image/FeedHashImage";
import FeedHashVodThumbnail from "components/thumbnail/FeedHashVodThumbnail";
import { STATION_URL } from "constant/config";
import { VOD } from "constant/feedType";
import { numberCommaString, makeProfileUrl } from "helpers";
import { vodInflowLog } from "libs/log";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ProfileImage from "components/image/ProfileImage";

export default function HashItem({ measure, index, item, style }) {
    const { t } = useTranslation();

    const handleLog = useCallback(
        (item) => (e) => {
            if (item.feed_type === VOD) {
                //유입로그
                vodInflowLog(item.station_user_id, item.title_no, ["my", "feed", "hashtag"]);
            }
        },
        [],
    );

    return (
        <div style={style} className="list-item">
            <div className="autor_wrap">
                <ProfileImage 
                    tag="span"
                    className="thum"
                    user_id={item.user_id}
                    src={makeProfileUrl(item.user_id)}
                    childrenTag="a"
                    innerChildrenTag="img"
                    childrenTagProps = {{
                        href:`${STATION_URL}/${item.user_id}`,
                        target:"_blank"
                    }}
                    innerChildrenTagProps = {{
                        src:item.profile_image,
                        loading: "lazy"
                    }}
                />
                <div className="info_box">
                    <ContextMenu user_id={item.user_id} user_nick={item.user_nick}>
                        <button type="button" className="nick">
                            <p>{item.user_nick}</p>
                        </button>
                    </ContextMenu>
                    <span>{item.reg_date}</span>
                    <span>{t("조회 {{count}}", { count: numberCommaString(item.count.read_cnt) })}</span>
                </div>
            </div>

            <div className="item_contents">
                <a href={item.url} target="_blank" onClick={handleLog(item)}>
                    {item.board_type === 103 && item.photos.length && <FeedHashImage photo={item.photos} onLoad={measure} />}

                    {item.board_type === 105 && <FeedHashVodThumbnail vod={item.ucc} titleNo={item.title_no} onLoad={measure} />}
                    <div className="item_text">
                        <h3>{item.title_name}</h3>
                        <p className="text" dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                </a>
            </div>
        </div>
    );
}
