import React, { useState, useEffect } from "react";
import { makeProfileUrl } from 'helpers';

/**
 * 프로필 이미지
 * @param {*} props
 */
export default function ProfileImage ({ className = "thumb", user_id = "", tag = "", src = '', url = '', childrenTag = null, innerChildrenTag = null, childrenTagProps = null, innerChildrenTagProps = null, children = null}) {
    const [imgError, setImgError] = useState(false);

    /**
     * 이미지 에러 체크
     */
    useEffect(() => {
        let loading = true;

        if(!src) {
            loading = false;
        } 

        let img = new Image();
        img.src = src;
        
        img.onerror = () => {
            if(loading) {
                setImgError(true);
            }
        }

        return () => {
            loading = false;
        }
        
    }, [src, setImgError]);

    /**
      * 기존 프로필 이미지 영역에 태그가 여러가지라 어쩔수 없이 분기처리, 추후 UI 작업 시 태그 하나로 통일되도록 수정 필요
      */
    const attribute = () => {
        let attributeImg = src;

        if(imgError) {
            attributeImg = makeProfileUrl(user_id, true);
        }
        let attribute = {
            className: className,
            style: {
                backgroundImage : !childrenTag ? (
                    `url(${attributeImg})`
                ) : ""
            }
        };

        if(tag === 'a') {
            attribute.href = url;
            attribute.target = "_blank";
        }

        return attribute;
    };

    /**
     * 자식 노드 생성 및 attribute 추가 
     */
    const childComponent = () => {
        let childProfileImg = src;
        
        if(imgError) {
            childProfileImg = makeProfileUrl(user_id, true);
        }
        
        if(childrenTag) {
            let parentChild = React.createElement(childrenTag, childrenTagProps, null);

            if(innerChildrenTag) {
                innerChildrenTagProps.src = childProfileImg;
                let parentChild2 = React.createElement(innerChildrenTag, innerChildrenTagProps, null);

                return React.cloneElement(
                    parentChild, 
                    parentChild.props,
                    React.cloneElement(parentChild2, parentChild2.props, null)
                );

            } else {
                childrenTagProps.src = childProfileImg;

                // props 변경해서 재생성
                return React.createElement(childrenTag, childrenTagProps, null);

            }
        }
        return null;
    };

    /**
     * 부모 노드 생성
     */
    const profileDom = () => {
        return React.createElement(tag, attribute(), [childComponent(), children]);
    };

    return profileDom();
};