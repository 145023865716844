import { FEED_FAVORITE, FAVORITE_REMOVE, FAVORITE_ADD } from "actions/feed.favorite.action";
import produce from 'immer';
import { FAVORITE } from "actions/favorite.action";
import { compare_favorite } from "helpers";


const initialState = {
    loading: false,
    meta: {},
    data: [],
    error: null
};

const feedFavoriteReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEED_FAVORITE.LOADING_START:
            return { ...state, loading: true };

        case FEED_FAVORITE.LOADING_DONE:
            return { ...state, loading: false };
        case FEED_FAVORITE.FETCH_SUCCESS:
            return produce(state, (draftState) => {
                draftState.meta = action.payload.meta
                draftState.data = action.payload.data;
            });
        case FEED_FAVORITE.FETCH_SUCCESS_MORE:
            return produce(state, (draftState) => {
                draftState.meta = action.payload.meta
                draftState.data = state.data.concat(action.payload.data);
            });
        case FEED_FAVORITE.FETCH_ERROR:
            return { ...state, error: action.payload };


        /**
         * 즐찾 추가
         */
        case FAVORITE_ADD:
            return produce(state, (draftState) => {
                draftState.data.push(action.payload);
            });

        /**
         * 즐찾 삭제
         */
        case FAVORITE_REMOVE:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(favorite => favorite.user_id === action.payload.favorite_id);
                if (index > -1) {
                    draftState.data.splice(index, 1);
                }
            });


        /**
         * 핀 고정해제
         */
        case FAVORITE.FILTER_PIN:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(favorite => favorite.favorite_no === action.payload.no);
                if (index > -1) {
                    draftState.data[index].is_pin = action.payload.flag;
                    //재정렬
                    draftState.data.sort(compare_favorite("favorite_no", "desc"));
                }
            });


        default:
            return state;
    }
};

export default feedFavoriteReducer;