import { ROOT_URL } from "constant/config";
import { POST, PUT } from "constant/method";
import { handleFetchErrors } from "helpers";
import i18next from "i18next";
import querystring from "querystring";
import { FAVORITE } from "./favorite.action";
import { toast } from "afreecatv-react-toast";


/**
 * 즐겨찾기 알림 ON/OFF 액션
 * @param string favorite_id 
 * @param integer flag 
 * @param string? favorite_nick 
 */
export const setMobilePushFlag = (favorite_id, flag=1, favorite_nick="") => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/${favorite_id}/push?flag=${flag}`, {
			method: POST,
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: querystring.stringify({
				_method: PUT,
			}),
			credentials: "include",
		})
			.then(handleFetchErrors)
			.then((response) => {
				dispatch({
					type: FAVORITE.SET_MOBILE_PUSH_SUCCESS,
					payload: { favorite_id: favorite_id, flag: flag },
                });
                
                if (favorite_id === "all") {
                    if (flag === 1) {
						toast.success(i18next.t(`모든 즐겨찾기 BJ에게 알림을 받습니다.`));
					} else {
						toast.success(i18next.t(`모든 즐겨찾기 BJ에게 알림을 받지 않습니다.`));
					}
                } else {
                    if (flag === 1) {
                        toast.success(i18next.t(`{{favorite_nick}}님에게 알림을 받습니다.`, {favorite_nick: favorite_nick}));
                    } else {
                        toast.success(i18next.t(`{{favorite_nick}}님에게 알림을 받지 않습니다.`, {favorite_nick: favorite_nick}));
                    }
                }
			})
			.catch((error) => {
				toast.error(i18next.t(error.message));
			});
    }
}
