import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";

export default function MidBanner({ content }) {
    useEffect(()=>{
        if(content){
            try {
                //기존이벤트 그대로 바인딩 하기위해서 바닐라 스크립트로 append
                let isShow = true;
                if(typeof content ==='object' && content.hasOwnProperty('exist')){
                    isShow = Boolean(content.exist)
                }
                if(isShow) document.getElementById('sub_banner_wrap').append(content);
            } catch (error) {
                
            }
        }
    },[content])
    if (!content) return null; // 컨텐츠가 없을 경우 빈 값 반환
    return content && <div id="sub_banner_wrap"></div>
}