import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { fetchVod, fetchRecentVodDelete } from "actions/vods.action";
import { compare_broad } from "helpers";
import SortButtonGroup from "components/button/SortButtonGroup";
import VodItem from "components/item/VodItem";
import EmptySection from "components/section/EmptySection";
import i18next from "i18next";
import { DESC, getRecentVodSort, OFFSET_SIZE, RECNT_VOD_ADD } from "constant/sort";
import useCustomListModal from "components/vods/useCustomListModal";
import ListLayer from "components/vods/ListLayer";
import AddLayer from "components/vods/AddLayer";
import LoadingSection from "components/section/LoadingSection";
import { VODS } from "actions/vods.action";

export default function HistoryRecentVodSection ({ref}) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(RECNT_VOD_ADD);
    const [limit, setLimit] = useState(OFFSET_SIZE);
    const [options, setOptions] = useState(getRecentVodSort);


    const { data, hasMore = false, loading = false, status = "", currentPage = 0  } = useSelector(state => (state.vodsReducer), shallowEqual);

    const filteredData = useMemo(() => {
        return data.slice().sort(compare_broad(sortColumn, sortOrder)).slice(0, limit * currentPage);
    }, [data, limit, sortColumn, sortOrder, currentPage]);

    // 3dot modal page hook
    const { 
        customLayer, customTop, customLeft, titleNo,
        setCustomLayer, setCustomTop, setCustomLeft, setTitleNo,
        handleCustomLayer, handleCustomPosition, handleCustomCallbackLayer
    } = useCustomListModal();

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, [setSortColumn, setSortOrder ]);

    useEffect(() => {
        function onScroll() {
            if(hasMore && !loading) {
                if((window.scrollY + document.documentElement.clientHeight) > (document.documentElement.scrollHeight - 10)) {
                    dispatch(fetchVod('/history/recent', "?page="+(currentPage + 1)));
                }
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [loading, hasMore, currentPage, dispatch]);

    const handleAllDeleteRecentVod = useCallback((e) => {
        e.preventDefault();
        if (!window.confirm(i18next.t(`최근 본 VOD를 모두 삭제하시겠습니까?`))) {
            return;
        }
        dispatch(fetchRecentVodDelete("all"));

        dispatch({ type: VODS.VOD_INIT });
    
        dispatch({ type: VODS.VOD_LOADING_DONE });

    }, [dispatch, fetchRecentVodDelete]);

    

    useEffect(() => {
        dispatch(fetchVod('/history/recent', "?page=1"));
    },[dispatch, fetchVod]);

    if (loading && status === 'LOADING' && currentPage < 1) {
        return <LoadingSection />;
    } else if (!loading && status === 'CLEAR' || currentPage >=1) {
        if(filteredData.length < 1) {
            return (
                <EmptySection />
            );
        }
        return (
            <>
                <div className="sort-wrap">
                    <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />

                    <div className="rt_group">
                        <div className="btns" >
                            <button type="button" onClick={handleAllDeleteRecentVod} >{i18next.t(`전체삭제`)}</button>
                        </div>
                    </div>  
                </div>

                <div className="cBox-list" data-broadcast="vod">
                    <ul>
                        {filteredData.map((vod, index) => (
                            <li key={index} data-type="cBox">
                                <VodItem path3="recent" vod={vod} layer={customLayer} onCallBackChangeLayer={handleCustomCallbackLayer} onChangeLayer={handleCustomLayer} onPosition={handleCustomPosition}/>
                            </li>
                        )
                        )}
                    </ul>

                </div>
            {customLayer === "LIST" && <ListLayer ref={ref} layers={customLayer} title_no={titleNo}  onLayer={setCustomLayer} onTitle={setTitleNo} pathname={pathname} top={customTop} left={customLeft} />}
            {customLayer === "ADD" && <AddLayer ref={ref} layers={customLayer} title_no={titleNo}  onLayer={setCustomLayer} onTitle={setTitleNo} top={customTop} left={customLeft} />}
            </>

        );
    } else {
        return null;
    }

    
}