import { PLAYLISTS } from "actions/playlist.action";

const initialState = {
    loading: false,
    data: [],
    error: null
};

const playlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case PLAYLISTS.FETCH_PLAYLIST_LAYER:
            return { ...state, loading: true };

        case PLAYLISTS.FETCH_PLAYLIST_LAYER_LOADING_DONE:
            return { ...state, loading: false };

        case PLAYLISTS.FETCH_PLAYLIST_LAYER_SUCCESS:
            return { ...state, data: action.payload.data, error: null };

        case PLAYLISTS.FETCH_PLAYLIST_LAYER_ERROR:
            return { ...state, error: action.payload };

        case PLAYLISTS.FETCH_DELETE_PLAYLIST_SUCCESS:
            return {...state, loading : false};

        default:
            return state;
    }
};

export default playlistReducer;
