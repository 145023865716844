import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchVod } from "actions/vods.action";
import { compare_broad } from "helpers";
import SortButtonGroup from "components/button/SortButtonGroup";
import MoreButton from "components/button/MoreButton";
import PlayListItem from "components/item/PlayListItem";
import EmptySection from "components/section/EmptySection";
import { DESC, getPlayListSort, OFFSET_SIZE, ADD_VOD } from "constant/sort";

import LoadingSection from "components/section/LoadingSection";
export default function HistoryPlayListSection () {
    const dispatch = useDispatch();

    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(ADD_VOD);
    const [limit, setLimit] = useState(OFFSET_SIZE);
    const [options, setOptions] = useState(getPlayListSort);

    const [ top, setTop ] = useState(0);
    const [ left, setLeft ] = useState(0);

    const [layer, setLayer] = useState('');
    const [titleNo, setTitleNo] = useState(0);

    
    const { data, loading, status = "", error } = useSelector(state => (state.vodsReducer), shallowEqual);

    useEffect(() => {
        dispatch(fetchVod('/history/playlist'));
    },[dispatch, fetchVod]);

    const filteredData = useMemo(() => {
        return data.slice().sort(compare_broad(sortColumn, sortOrder)).slice(0, limit);
    }, [data, limit, sortColumn, sortOrder]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);

    const handlePosition = useCallback((top, left) => {
        setTop(top);
        setLeft(left);
    }, [setTop, setLeft]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        setLayer(layer);
        setTitleNo(titleNo);
    }, [setLayer, setTitleNo]);

    if (loading && status === 'LOADING') {
        return <LoadingSection />;
    } else if (!loading && status === 'CLEAR') {
        if(data.length < 1) {
            return (
                <EmptySection />
            );
        }
        return (
            <>
                <div className="sort-wrap">
                    <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                </div>

                <div className="PlayList cBox-list">
                <ul>
                    {filteredData.map((vod, index) => (
                        <li key={index} data-type="cBox">
                            <PlayListItem vod={vod} path3="playlist" layer={layer} onPosition={handlePosition} onChangeLayer={handleChangeLayer}/>
                        </li>
                    )
                    )}
                </ul>

                {data.length > limit && <MoreButton onClick={onClickMore} />}
                </div>
            </>
        );
    } else {
        return null;
    }
}