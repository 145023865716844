import React, { useState, useCallback } from "react";
import { secondToTime } from "helpers";

export default function StoryVodThumbnail({ href, vod }) {
	const [imgStatus, setImgStatus] = useState(true);
	const handleError = useCallback(() => {
		setImgStatus(false);
	}, []);

	if (imgStatus === false) {
		return (
			<div className="thum">
				<a href={href} className="thumb-default">
					기본
					{vod.file_type !== "CATCH" ? <span className="time">{secondToTime(vod.total_file_duration / 1000)}</span> : <span className="ic_catch">캐치</span>}
				</a>
			</div>
		);
	} else if (vod.is_adult === true) {
		return (
			<div className="thum">
				<a href={href} className="thumb-adult">
					19금
					{vod.file_type !== "CATCH" ? <span className="time">{secondToTime(vod.total_file_duration / 1000)}</span> : <span className="ic_catch">캐치</span>}
				</a>
			</div>
		);
	} else {
		return (
			<div className="thum">
				<a href={href} target="_blank">
					<img src={vod.thumb} onError={handleError} alt="" loading="lazy" />
                                        {vod.file_type !== "CATCH" ? <span className="time">{secondToTime(vod.total_file_duration / 1000)}</span> : <span className="ic_catch">캐치</span>}
				</a>
			</div>
		);
	}
}
