import { storeFavoriteGroup, updFavoriteGroup } from 'actions/favorite.group.action';
import { fetchFavoriteGroupItem } from 'actions/favorite.groupitem.action';
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { makeProfileUrl, makeStationUrl } from 'helpers';
import useMouseDownClick from "components/hook/useMouseDownClick";
import { DESC, FAV_RECENT,getFavGroupListSort  } from "constant/sort";
import SortButtonGroup from "components/button/SortButtonGroup";
import { compare_favorite, numberCommaString } from "helpers";
import i18next from "i18next";
import { toast } from "afreecatv-react-toast";
import ProfileImage from "components/image/ProfileImage";

export default function UpdFavoriteGroupModal({modal, handleFixFavGroupModal,handleListFavGroupModal}){
    const currentRef = useRef(null);
    const dispatch = useDispatch();
    const [title, setTitle] = useState(modal.fixgroup.itemTitle);
    const [onAddBjList, setOnAddBjList] = useState(false);
    const {loading, data} = useSelector(state => (state.favoriteReducer), shallowEqual);
    const {itemloading, groupitem} = useSelector(state => (state.favoriteGroupItemReducer), shallowEqual);
    
    
    const [bjList, setBjList] = useState([]);//추가하려는 즐찾 BJ 리스트
    const [currentBjList, setCurrentBjList] = useState([])//BJ 추가 직전 유동 데이터 리스트
    const [completeBjList, setCompleteBjList] = useState([]);//최종 저장하려는 즐찾 BJ 리스트(저장직전 버전)
    const [delBjList, setDelBjList] = useState([]);
    const [isTitleEmpty, setIsTitleEmpty] = useState(false);//타이틀 빈값 여부 확인
    
    const [isDelMode, setIsDelMode] = useState(false);
    const [onListUpdate, setOnListUpdate] = useState(false);
    
    const [options, setOptions] = useState(getFavGroupListSort);
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(FAV_RECENT);

    
    const filteredData = useMemo(() => {
        return bjList.slice().sort(compare_favorite(sortColumn, sortOrder));
    }, [bjList,  sortColumn, sortOrder]);


    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    
    // useOutsideClick(currentRef, () => {
    //     handleFixFavGroupModal(modal.fixgroup.type,false,"",0,false);
    //     handleListFavGroupModal(modal.listgroup.type,false)
    //     document.querySelector("html").style = '';
      
    // });
    
    useMouseDownClick(currentRef, ()=>{
        handleFixFavGroupModal(modal.fixgroup.type,false,"",0,false);
        handleListFavGroupModal(modal.listgroup.type, false, true);
    }, setCompleteBjList);

    //추가하려는 BJ 체크 박스 이벤트(갯수 세기)
    const handleAddBjCheckBox = (ischecked,inputitem)=>{
        inputitem['check']=ischecked;
        

        if(completeBjList.length + bjList.filter((item)=>item.check).length > 150){ // 150명 이상 일경우 exception 처리  
            let temp = bjList.filter((item)=>{
                if(item['favorite_no'] == inputitem['favorite_no'])
                    item['check'] = false;
                return item;
            })
            alert(i18next.t("그룹 추가는 최대 150명까지입니다."));
            let currentTemp = currentBjList.filter((item)=>item.favorite_no !== inputitem.favorite_no)
            setBjList(temp);
            setCurrentBjList(currentTemp);
            return;
        }

        let list  = []
        if(ischecked){ 
            list = currentBjList.slice();
            list.push(inputitem);
        }
        else {
            list =currentBjList.filter(item=>item.user_id !== inputitem.user_id);
        }

        setCurrentBjList(list);
        
      
    }

    const handleDelCheckBox = (ischecked, inputitem) =>{
        inputitem['check']=ischecked;
        let templist = JSON.parse(JSON.stringify(delBjList));
        if(ischecked){ 
            templist = delBjList.slice();
            templist.push(inputitem);
        }
        else {
            templist =templist.filter(item=>item.user_id !== inputitem.user_id);
        }
        
        setDelBjList(templist);

        if(templist.length >0){ 
            setIsDelMode(true);
        }
        else{ 
            setIsDelMode(false);
        }

    }

    const handleDelBjList = ()=>{
        const list = completeBjList.filter((item)=>{
            if(!delBjList.some(item2=>item.user_id ===item2.user_id )){
                item['check'] = false;
                return item;
            }
        });
        const bjlist = data.filter(item=>!list.some(item2=>item.user_id ===item2.user_id));
        setBjList(bjlist);
        setIsDelMode(false);
        setDelBjList([]);
        setCompleteBjList(list);
        if(list.length > 0 && title.length >0) setOnListUpdate(true);
        else setOnListUpdate(false);
     
    }

    

    const handleCurrentBjList = (event)=>{

        event.nativeEvent.stopImmediatePropagation();
        const list = bjList.filter((item,index)=>{
            if(item.check){
                item.check=false;//체크박스가 state에 머물러서 초기화
                return item;
            } 
        });
        let combineData = [...currentBjList, ...completeBjList];
        
        setBjList(bjList.filter((item)=>!item.check));
        setCompleteBjList(combineData);
        setCurrentBjList([]);
        setOnAddBjList(false);
        setOnListUpdate(true);
    }

    const handleAddBjList = ()=>{
        let sendList = [];
        sendList = completeBjList.map((item)=>{
            return item['user_id'];
        })
        if(title.length === 0){
            setIsTitleEmpty(true);
            return false; 
        }
        dispatch(updFavoriteGroup(modal.fixgroup.selIdx,title, sendList.join(',')));
        handleFixFavGroupModal("updgroup", false, title, 0);
        handleListFavGroupModal("updgroups",true, false);
    }


    const handleOpenAddBjList = (event,open)=>{
        event.nativeEvent.stopImmediatePropagation();
        //기존 값 check값 갱신 위함
        
        if(open){
            const list = bjList.filter(item=>{
                const isCheck= completeBjList.some(item2=>item.user_id ===item2.user_id);
                if(!isCheck)
                {
                    item['check'] = false;
                    return item; 
                }            
            });
            setCompleteBjList(completeBjList);
            setBjList(list);
        }
        else{
            setCurrentBjList([]);
        }
        setOnAddBjList(open);
    }

    // 제목 변경
	const handleChangeTitle = useCallback(
		(event) => {
			const value = event.target.value.trimStart().trimEnd();
			if (value.length > 10) {
                event.target.value = value.substr(0,10);
                setTitle(event.target.value);
            	return false;
			}
            else if(value.length ===0 ||completeBjList.length ===0 ){
                setOnListUpdate(false);
            }
           
            else{
                setOnListUpdate(true);
            }

            if(value.length > 0 )
            {
                setIsTitleEmpty(false);
            }

			setTitle(value);
		},
		[completeBjList],
	);

    const handleCloseModal = useCallback((e)=>
    {
        handleFixFavGroupModal("updgroup", false, title, 0)
        handleListFavGroupModal("updgroups",true, false);
    }
    ,[])

    useEffect(()=>{
        if(data.length>0 && modal.fixgroup.selIdx >0){//즐찾 리스트 로딩 및 그룹 내 리스트 데이터 로딩이 끝났을 경우
           
            let completelist = []
            groupitem.filter((group)=>{
                let tempdata = data.filter((item)=>groupitem.some(item2=>item.user_id ===item2.user_id)).find(item3=>group.user_id ===item3.user_id);
                completelist.push(tempdata);
            });
            let list = data.filter((item, index)=>!groupitem.some(item2=>item.user_id ===item2.user_id));

            
            
            const tempdata = JSON.parse(JSON.stringify(list));
            const tempcomplete = JSON.parse(JSON.stringify(completelist));
            
            if(title.length > 0 && tempcomplete.length >0) setOnListUpdate(true);
            setBjList(tempdata);
            setCompleteBjList(tempcomplete);
        }
        else if(!loading && modal.fixgroup.selIdx === 0){
            const list = data.map((item, index)=>{
                item['check'] = false;
                return item;
            })
            const tempdata = JSON.parse(JSON.stringify(list));
            setBjList(tempdata);

            
        }
       
    },[data, groupitem])

    useEffect(()=>{
        dispatch(fetchFavoriteGroupItem(modal.fixgroup.selIdx));
    },[])
    

    return(
        
        <div ref={currentRef} className = {"FavGroup_layer " +  (onAddBjList ? "bjList" :"")}>
            <div className="group_inner">
                <h2>{modal.fixgroup.menuTitle}</h2>

                <div className="addBox">
                    <dl className="name">
                        <dt>{i18next.t("그룹명")}</dt>
                        <dd className={isTitleEmpty ? "error":""}>
                            <input type="text" name="" id="fav_group_title" value={title} placeholder={i18next.t("그룹명을 입력해주세요.")} onChange ={(e)=>handleChangeTitle(e)}/>
                            
                                <div className="info">
                                    {isTitleEmpty &&    
                                    <p>{i18next.t("그룹명을 입력해주세요.")}</p>
                                    }
                                    <span className="byt">{title.length}/10</span>
                                </div>
                            
                        </dd>
                    </dl>
            
                    <dl className="bj_list">
                        <dt>BJ {completeBjList.length > 0 && <em>({i18next.t(`{{num}}명`, { num: numberCommaString(completeBjList.length) })})</em>}
                            <button type="button" className="btn_bjList" onClick = {(e)=>handleOpenAddBjList(e,true)}>+ {i18next.t("BJ 추가")}</button>
                        </dt>
                        {completeBjList.length >0 &&
                            <dd>
                                <ul>{
                                    completeBjList.map((item,index)=>{
                                        return(
                                            
                                            <li  key={"updcomBj"+item.favorite_no}>
                                                <input type="checkbox" name="" id={"updcomBj"+item.favorite_no} onClick = {(e)=>handleDelCheckBox(e.target.checked, item)} checked={item['check']}/>
                                                <label htmlFor={"updcomBj"+item.favorite_no}>
                                                    <ProfileImage 
                                                        tag="span"
                                                        className="thumb"
                                                        user_id={item.user_id}
                                                        src={makeProfileUrl(item.user_id)}
                                                        childrenTag="img"
                                                        childrenTagProps = {{
                                                            src:makeProfileUrl(item.user_id),
                                                            loading: "lazy"
                                                        }}
                                                        />
                                                    <p>{item.user_nick}</p>
                                                </label>
                                            </li>
                                            
                                        );
                                    })
                                    }
                                </ul>
                            </dd>
                        }
                        
                        {completeBjList.length <=0 &&
                            <dd>
                                <button type="button" className="btn_bjList" onClick = {(e)=>handleOpenAddBjList(e,true)}  ><p>{i18next.t("BJ를 추가해주세요.")}</p></button>
                            </dd>
                        }
                    </dl>
                </div>

                <div className="btns">
                    <button type="button" className="del" disabled={isDelMode ? false: true} onClick={()=>handleDelBjList()}>{i18next.t("삭제")}</button>
                    <button type="submit" className="submit" disabled={onListUpdate ? false:true} onClick={()=>handleAddBjList()}>{i18next.t("저장")}</button>
                </div>
                <button type="button" className="close" onClick = {(e)=> {handleCloseModal(e)}}>레이어 닫기</button>
            </div>  
            {onAddBjList &&
                <div className="bjList_box">
                <h2>{i18next.t("BJ 추가하기")}</h2>
                <div className="sort-wrap">
                    <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                </div>
    
                <div className="list_scroll">
                    <ul>
                        {filteredData.map((item,index)=>{
                            return(
                                <li  key={"bjChk"+(item.favorite_no)} className={item.is_pin ? "pin" :""}><input  type="checkbox" name="" id={"bjChk"+(item.favorite_no)}  onChange={(e) => handleAddBjCheckBox(e.target.checked, item)} checked={(item['check'])}/>
                                    <label htmlFor={"bjChk"+(item.favorite_no)}>
                                            <ProfileImage 
                                                tag="span" 
                                                className="thumb" 
                                                user_id={item.user_id}
                                                src={makeProfileUrl(item.user_id)}
                                                childrenTag="img"
                                                childrenTagProps = {{
                                                    src:makeProfileUrl(item.user_id),
                                                    loading: "lazy"
                                                }}/>
                                            <div className="nickname_wrap">
                                                <div className="nickname">
                                                    <p>{item.user_nick}</p>
                                                    {item.is_subscrible && <span className="ico-subscribe">{i18next.t("구독")}</span>}
                                                    {item.is_fanclub && <span className="ico-fan">{i18next.t("팬")}</span>}
                                                </div>
                                                <em className="id">{item.user_id}</em>
                                            </div>
                                    </label>
                                </li>
                            )
                        })}
                        
                        
                    </ul>
                </div>
    
                <div className="btn">
                    <button type="button" onClick={(e)=>handleCurrentBjList(e)} disabled={currentBjList.length ===0 ? true :false}>{i18next.t(`{{num}}명 추가`, { num: numberCommaString(currentBjList.length) })}</button>
                </div>
                <button type="button" className="close" onClick = {(e)=>handleOpenAddBjList(e,false)}>레이어 닫기</button>
            </div>
            
            }

        </div>
    )

}