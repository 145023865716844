import { FAVORITE_CONTENT } from "actions/favorite.content.action";

const initialState = {
    loading: false,
    data: [],
    error: null
};

const favoriteContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAVORITE_CONTENT.LOADING_START:
            return { ...state, loading: true };

        case FAVORITE_CONTENT.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_CONTENT.FETCH_SUCCESS:
            return { ...state, data: action.payload, error: null };

        case FAVORITE_CONTENT.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default favoriteContentReducer;
