import { fetchHashtag, initFeedHashtag } from 'actions/feed.hashtag.action';
import React, { useCallback, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import CellMeasurer from "react-virtualized/dist/es/CellMeasurer";
import CellMeasurerCache from "react-virtualized/dist/es/CellMeasurer/CellMeasurerCache";
import List from "react-virtualized/dist/es/List";
import WindowScroller from "react-virtualized/dist/es/WindowScroller";
import HashItem from './item/HashItem';
import { numberCommaString, documentScrollTop } from 'helpers';
import { Trans, useTranslation } from 'react-i18next';


const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 202,
});


export default function FeedHashTag() {

    let { hashtag } = useParams();
    // dispatch
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading, meta, data = [], index_reg_date, has_more, total } = useSelector(state => (state.feedHashtagReducer));
    const windowScrollerRef = useRef(null)
    const listRef = useRef(null)
    const isEmpty = useMemo(() => meta.total === 0 && has_more === false, [has_more, meta.total])


    /**
     * 최초로딩
     */
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
            //https://developer.mozilla.org/ko/docs/Web/API/History/scrollRestoration
        }
        _cache.clearAll();
        dispatch(initFeedHashtag());
        dispatch(fetchHashtag(hashtag));
        //최상위로 이동
        documentScrollTop();
        listRef.current.scrollToPosition(0)
    }, [dispatch, hashtag])

    /**
     * 더보기
     * @param {*} param0 
     */
    const handleRowRendered = ({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex }) => {
        if (loading === false
            && overscanStopIndex >= (data.length - 1)
            && has_more) {
            dispatch(fetchHashtag(hashtag, index_reg_date));
        }

    }


    const _rowRenderer = useCallback((opt) => {
        const { index, key, style, parent } = opt;
        return (
            <CellMeasurer cache={_cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
                {({ measure }) => <HashItem
                    measure={measure}
                    index={index}
                    item={data[index]}
                    style={style}
                />
                }
            </CellMeasurer>
        );
    }, [data]);


    /**
     * 게시물이 없는 경우
     */
    const _emptyRender = useCallback((opt) => {
        const { index, key, parent, } = opt;
        return <CellMeasurer cache={_cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
            {() => (
                <div className="list-item">
                    <p className="no_list"><Trans>태그에 대한 검색 결과가 없습니다.</Trans></p>
                </div>)
            }
        </CellMeasurer>
    }, []);

    return (

        <section className="feed-contents">
            <div className="feed-wrap search">
                <div className="feed_heading">
                    <div className="lt">
                        <h2 className="title"><strong>{`#${hashtag}`}</strong><span>&nbsp;({t('{{num}}건', { num: numberCommaString(total) })})</span></h2>
                    </div>
                </div>

                <WindowScroller ref={windowScrollerRef} >
                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                        <AutoSizer disableHeight >
                            {({ width }) => {
                                return (
                                    <div ref={registerChild}>
                                        <List
                                            height={height}
                                            isScrolling={isScrolling}
                                            onScroll={onChildScroll}
                                            scrollTop={scrollTop}
                                            ref={listRef}
                                            autoHeight
                                            width={width}
                                            estimatedRowSize={3}
                                            rowRenderer={isEmpty ? _emptyRender : _rowRenderer}
                                            overscanRowCount={1}
                                            rowCount={isEmpty ? 1 : data.length}
                                            rowHeight={_cache.rowHeight}
                                            onRowsRendered={handleRowRendered}
                                        />
                                    </div>
                                );
                            }}
                        </AutoSizer>
                    )}
                </WindowScroller>

                {loading && <div className="af_loading more"></div>}
            </div>
        </section>
    )
}
