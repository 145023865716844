import { makePlayUrl, sendLogApi } from "helpers";
import { liveInflowLog } from "libs/log";
import React, { useCallback } from "react";

export default function LiveWatchButton({ broad }) {
    /**
     * 방송 진입 로그 호출
     */
    const onClickPlayLink = useCallback(
        (broad) => (e) => {
            // watch_type=live&bj_id=ksks0908&broad_no=215326485&category_no=00130000&bps=5000&resolution=1920x1080
            let sendData = {
                watch_type: "live",
                bj_id: broad.user_id,
                broad_no: broad.broad_no,
                category_no: broad.broad_cate_no,
                bps: broad.broad_bps,
                resolution: broad.broad_resolution,
            };
          
            // inflow_path add data
            let etcData = {
                //'tag': broad.hash_tags.join("|"),
                //'category_tag': broad.category_tags.join("|"),
            }

            sendLogApi("main_00000006", sendData);
            liveInflowLog(broad.user_id, broad.broad_no, ["my", "fav", "total"], etcData);
        },
        [],
    );

    return (
        <div className="btns">
            <a href={makePlayUrl(broad.user_id, broad.broad_no)} onClick={onClickPlayLink(broad)} target="_blank" className="live">
                LIVE
            </a>
        </div>
    );
}
