import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'

export const PopupContext = React.createContext([]);

/**
 * 팝업 id관리
 * @use const [id, setId] = useContext(PopupContext);
 * @param {*} param0 
 */
export default function PopupProvider({ children }) {
    const [popupId, setPopupId] = useState(-1)


    useEffect(() => {
        function handleDocumentClick() {
            setPopupId(-1);
        }
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, [])





    return (
        <PopupContext.Provider value={[popupId, setPopupId]} >
            {children}
        </PopupContext.Provider>
    )
}

/**
 *  const { isActivePopup, togglePopup } = usePopup();
 */
export function usePopup() {
    const [popupId, setPopupId] = useContext(PopupContext);
    const [currentId] = useState(Math.random())


    /**
     * Toggle
     */
    const togglePopup = useCallback(() => {
        setPopupId(currentId === popupId ? -1 : currentId);
    }, [currentId, popupId, setPopupId]);

    /**
     * 열기
     */
    const openPopup = useCallback(() => {
        setPopupId(currentId);
    }, [currentId, setPopupId]);

    /**
     * 열려있는거 닫기
     */
    const closePopup = useCallback(() => {
        setPopupId(-1);
    }, [setPopupId]);


    const isActivePopup = useMemo(() => currentId === popupId, [currentId, popupId]);


    return { isActivePopup, togglePopup, openPopup, closePopup };
}
