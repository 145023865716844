import { FANCLUB_ROUTE, FAVORITE_ROUTE, HISTORY_RECENT_ROUTE, RECENT_ROUTE, SUBSCRIBE_ROUTE, HISTORY_RECENT_VOD_ROUTE, HISTORY_LATER_ROUTE, HISTORY_UP_VOD_ROUTE, HISTORY_PLAYLIST_ROUTE, HISTORY_ROUTE } from "constant/routeUrl";
import i18next from 'i18next';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { sendLogApi } from "helpers";

export default function LiveEmptySection() {

    const { pathname } = useLocation();

    /**
     * 영상 진입 로그 호출
     */
    const onClickLiveLink = useCallback(
        () => {
            let sendData = {
                watch_type: "live"
            };
            let codeType = "";
            
            switch (pathname) {
                case HISTORY_RECENT_ROUTE:
                    codeType = "my_history_recent";
                    sendData['button_type'] = "to_live";
                    break;
                default:
                    return false;
            }
            sendLogApi(codeType, sendData, 'web');
        },
        [pathname]
    );

    switch (pathname) {
        case HISTORY_RECENT_ROUTE:
        case HISTORY_ROUTE:
        case RECENT_ROUTE:
            return (
                <div className="nt_area">
                    <span className="ic_vcast"></span>
                    <p className="txt01">{i18next.t('아직 참여한 방송이 없습니다.')}</p>
                    <p className="txt02">{i18next.t('지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!')}</p>
                    <a href="//www.afreecatv.com/" onClick={onClickLiveLink} target="_blank"  className="link">{i18next.t(`LIVE 보러가기`)}</a>
                </div>
            );

        case SUBSCRIBE_ROUTE:
        case FANCLUB_ROUTE:
        case FAVORITE_ROUTE:
        default:
            return (
                <p className="favor_noLst">{i18next.t('현재 방송 중인 BJ가 없습니다.')}</p>
            );
    }

}
