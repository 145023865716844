import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { fetchVod } from "actions/vods.action";
import { compare_broad } from "helpers";
import SortButtonGroup from "components/button/SortButtonGroup";
import VodItem from "components/item/VodItem";
import EmptySection from "components/section/EmptySection";
import { DESC, getUpVodSort, OFFSET_SIZE, UP_VOD } from "constant/sort";
import ListLayer from "components/vods/ListLayer";
import AddLayer from "components/vods/AddLayer";
import LoadingSection from "components/section/LoadingSection";
import useCustomListModal from "components/vods/useCustomListModal";


export default function HistoryUpVodSection ({ref}) {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(UP_VOD);
    const [limit, setLimit] = useState(OFFSET_SIZE);
    const [options, setOptions] = useState(getUpVodSort);

    const { data, hasMore = false, loading = false, status = "", currentPage = 0  } = useSelector(state => (state.vodsReducer), shallowEqual);

    // 3dot modal page hook
    const { 
        customLayer, customTop, customLeft, titleNo,
        setCustomLayer, setCustomTop, setCustomLeft, setTitleNo,
        handleCustomLayer, handleCustomPosition, handleCustomCallbackLayer
    } = useCustomListModal();


    const filteredData = useMemo(() => {
        return data.slice().sort(compare_broad(sortColumn, sortOrder)).slice(0, limit * currentPage);
    }, [data, limit, sortColumn, sortOrder, currentPage]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, [setSortColumn, setSortOrder]);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);
    

    useEffect(() => {
        dispatch(fetchVod('/history/up', "?page=1"));
    },[dispatch, fetchVod]);

    useEffect(() => {
        function onScroll() {
            if(window.scrollY + document.documentElement.clientHeight > document.documentElement.scrollHeight - 10) {
                if(hasMore && !loading) {
                    dispatch(fetchVod('/history/up', "?page="+(currentPage + 1)));
                }
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [loading, hasMore, currentPage]);

    if (loading && status === 'LOADING' && currentPage < 1) {
        return <LoadingSection />;
    } else if (!loading && status === 'CLEAR' || currentPage >=1) {
        if(filteredData.length < 1) {
            return (
                <EmptySection />
            );
        }
        
        return (
            <>
                <div className="sort-wrap">
                    <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder=   {sortOrder} onChange={onClickOrdering} />
                </div>

                <div className="cBox-list" data-broadcast="vod">
                    <ul>
                        {filteredData.map((vod, index) => (
                            <li key={index} data-type="cBox">
                                <VodItem path3="up" vod={vod} layer={customLayer} onCallBackChangeLayer={handleCustomCallbackLayer} onChangeLayer={handleCustomLayer} onPosition={handleCustomPosition}/>
                            </li>
                        )
                        )}
                    </ul>

                </div>
                {customLayer === "LIST" && <ListLayer ref={ref} layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo} pathname={pathname} top={customTop} left={customLeft} />}
                {customLayer === "ADD" && <AddLayer ref={ref} layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo} top={customTop} left={customLeft} />}
            </>
        );
    } else {
        return null;
    }
}