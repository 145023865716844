import CatchStoryItem from "components/item/CatchStoryItem";
import TitleSection from "components/section/TitleSection";
import SwiperController from "components/swiper/SwiperController";
import i18next from "i18next";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

export default function UserCatchStorySection({handleLayer, handlePosition, handleCallbackLayer }) {

    const ref = useRef(null);

    // useSelector
    const { data } = useSelector(state => (state.favoriteCatchStoryReducer), shallowEqual);
    const [initSlidesPerView, setInitSlidesPerView] = useState(getInitialSlidesPerView());

    useEffect(() => {
        function handleResize() {
            setInitSlidesPerView(getInitialSlidesPerView());
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function getInitialSlidesPerView() {
        if (window.innerWidth >= 2560) {
            return 14;
        } else if (window.innerWidth >= 2370) {
            return 13;
        } else if (window.innerWidth >= 2200) {
            return 12;
        } else if (window.innerWidth >= 2090) {
            return 11;
        } else if (window.innerWidth >= 1920) {
            return 10;
        } else if (window.innerWidth >= 1800) {
            return 9;
        } else if (window.innerWidth >= 1601) {
            return 8;
        } else if (window.innerWidth >= 1450) {
            return 7;
        } else if (window.innerWidth >= 1280) {
            return 6;
        } else {
            return 6;
        }
    }

    if (data.length > 0) {
        const aStoryIdxList = [];
        {data.map((vod, index) => {
            aStoryIdxList.push(vod.story_idx);
        })}

        return (
            <>
                <TitleSection sectionType="CATCHSTORY" className="catch-story">{i18next.t(`Catch 스토리`)}</TitleSection>

                <div className="slide-catch-story">
                    {(data.length > initSlidesPerView) && <SwiperController swiperRef={ref} autoplay={false} />}
                    
                <div className="catch_hz_box catch_story_box">
                        <Swiper
                            ref={ref}
                            autoplay={false}
                            breakpoints= {{
                                // 2560px 보다 클 경우
                                2560: {
                                    slidesPerView: 14,
                                },
                                2370: {
                                    slidesPerView: 13,
                                },
                                2200: {
                                    slidesPerView: 12,
                                },
                                2090: {
                                    slidesPerView: 11,
                                },
                                1920: {
                                    slidesPerView: 10,
                                },
                                1800: {
                                    slidesPerView: 9,
                                },
                                1601 :{
                                    slidesPerView:8,
                                },
                                1450 :{
                                    slidesPerView:7,
                                },
                                1280: {
                                    slidesPerView:6
                                }
                              }
                            }
                            wrapperTag="ul"
                            spaceBetween={20}
                            className ={'catch-list catch_story_list'}
                            slidesPerView={6}
                            allowTouchMove={false}
                            loop={false}
                        >
                            {data.map((vod, index) => {
                                return (
                                    <SwiperSlide tag="li" key={index} data-type="cBox" id={vod.story_idx} className={vod.flag ? "play_on" : "play_end"}>
                                        <CatchStoryItem vod={vod} aStoryIdxList={aStoryIdxList} onChangeLayer={handleLayer} onPosition={handlePosition} onCallBackChangeLayer={handleCallbackLayer}/>
                                    </SwiperSlide>);
                            })}
                        </Swiper>
                    </div>    
                </div>
            </>
        );
    } else {
        return null;
    }
}