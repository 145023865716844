import { existFavorite } from "actions/feed.favorite.action";
import { FEED_ROUTE } from "constant/routeUrl";
import { goLogin } from "helpers";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAuth } from "react-navigationbar";
import { Route, Switch } from "react-router-dom";
import FeedContents from "./FeedContents";
import FeedHashTag from "./FeedHashTag";
import FeedFavoriteList from "./header/FeedFavoriteList";
import FeedSide from "./side/FeedSide";
import { useLocation } from "react-router";

export default function Feed() {
    const {pathname, key} = useLocation();
    const [exist, setExist] = useState(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { isLogin } = useAuth();
    const [isLoad, setIsLoad] = useState(false);
    /**
     * 최초로딩
     */
    useEffect(() => {
        initSetting();
        setIsLoad(true);
    }, [isLogin]);

    useEffect(()=>{
        if(isLoad && pathname === FEED_ROUTE){
            initSetting();
        }
    }, [key])


    const initSetting = () =>{
        if (isLogin) {
            setLoading(true);
            existFavorite()
                .then((response) => {
                    setExist(true);
                    setLoading(false);
                })
                .catch((error) => {
                    setExist(false);
                    setLoading(false);
                });
            
        } else {
            goLogin();
        }
    }


    if (loading) {
        return null;
    } else if (exist) {
        return (
            <div id="wideArea" className="content_area feed">
                {/** 즐겨찾기 리스트 */}
                <FeedFavoriteList />
                <div id="bs-contents">
                    <Switch>
                        <Route path={`${FEED_ROUTE}/hashtag/:hashtag`}>
                            <FeedHashTag />
                        </Route>
                        <Route path={`${FEED_ROUTE}/:user_id`}>
                            <FeedContents />
                        </Route>
                        <Route path={`${FEED_ROUTE}`} exact>
                            <FeedContents />
                        </Route>
                    </Switch>
                    <FeedSide />
                </div>
            </div>
        );
    } else {
        return (
            <div id="wideArea" className="content_area feed">
                <div className="nt_area">
                    <span className="ic_favrite"></span>
                    <p className="txt01">{t("즐겨찾기한 BJ가 없습니다.")}</p>
                    <p className="txt02">
                        <Trans>
                            좋아하는 BJ를 즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,
                            <br />
                            방송 시작 시 알림을 받을 수 있습니다.
                        </Trans>
                    </p>
                </div>
            </div>
        );
    }
}
