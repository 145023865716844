import { STORY } from "actions/story.action";
import produce from "immer";

const initialState = {
    loading: false,
    error: null,
    meta: {},
    data: [],
};

const storyReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORY.INIT:
            return initialState;
        case STORY.LOADING_START:
            return { ...state, loading: true };

        case STORY.LOADING_DONE:
            return { ...state, loading: false };

        case STORY.FETCH_SUCCESS:
            return produce(state, (draftState) => {
                draftState.meta = action.payload.meta;
                draftState.data = state.data.concat(action.payload.data);
            });

        case STORY.FETCH_ERROR:
            return { ...state, error: action.payload };

        case STORY.DELETE_TITLE_SUCCESS:
            return produce(state, (draftState) => {
                draftState.meta.total--;
                draftState.data = state.data.filter((item) => {
                    console.log(item.title_no, action.title_no, item.title_no !== action.title_no);
                    return item.title_no !== action.title_no;
                });
            });
        case STORY.DELETE_COMMENT_SUCCESS:
            return produce(state, (draftState) => {
                draftState.meta.total--;
                draftState.data = state.data.filter((item) => {
                    console.log(item.p_comment_no, action.p_comment_no, item.p_comment_no !== action.p_comment_no);
                    return item.p_comment_no !== action.p_comment_no;
                });
            });
        case STORY.DELETE_CHDILD_COMMENT_SUCCESS:
            return produce(state, (draftState) => {
                draftState.meta.total--;
                draftState.data = state.data.filter((item) => {
                    console.log(item.c_comment_no, action.c_comment_no, item.c_comment_no !== action.c_comment_no);
                    return item.c_comment_no !== action.c_comment_no;
                });
            });

        default:
            return state;
    }
};

export default storyReducer;
