import classNames from "classnames";
import { usePopup } from "components/context/PopupProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export default function SelectBox({ options, value, onChange, align, className, stopPropagation }) {
    const [selectValue, setSelectValue] = useState(value);
    const { isActivePopup, togglePopup, closePopup } = usePopup();


	/**
	 * value 로  Option 가져오기
	 * @param {*} value
	 */
    const getOptionByValue = useCallback((value) => {
        if (!options || options.length === 0 || Array.isArray(options) === false) {
            return "";
        }
        const option =
            options.find((option) => {
                return option.value === value;
            }) || options[0];

        return option;
    }, [options])



    useEffect(() => {
        setSelectValue(value)
    }, [value]);




    const handleClick = useCallback((e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        togglePopup();
    }, [togglePopup]);



    const handleOptionClick = useCallback((value) => (e) => {
        if (value !== selectValue) {
            setSelectValue(value);
            if (onChange) {
                onChange(value);
            }
        }
        closePopup();
    }, [closePopup, onChange, selectValue]);



    const { name, optional } = getOptionByValue(selectValue);
    const selectName = useMemo(() => optional && <em>{optional}</em>, [optional])


    return (
        <div className={classNames("select-box", { select_wrap: className !== '', active: isActivePopup })}>
            <button type="button" className="selected" onClick={handleClick}>
                <span>{name}</span>
                {selectName}
            </button>
            <ul className="select-layer" data-align={align}>
                {Array.isArray(options) && options.map((option, index) => (
                    <li key={index}>
                        <button type="button" onClick={handleOptionClick(option.value)}>
                            <span>{option.name} </span><em>{option.optional}</em>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}