import { DELETE, POST } from "constant/method";
import i18next from "i18next";
import querystring from "querystring";
import { ROOT_URL } from "../constant/config";
import { handleFetchErrors } from "../helpers";
import { toast } from "afreecatv-react-toast";


export const RECENT = {
    LOADING_START: "recent/LOADING_START",
    LOADING_DONE: "recent/LOADING_DONE",
    FETCH_SUCCESS: "recent/FETCH_SUCCESS",
    FETCH_ERROR: "recent/FETCH_ERROR",
    DESTORY_SUCCESS: "recent/DESTORY_SUCCESS",
    DESTORY_ALL_SUCCESS: "recent/DESTORY_ALL_SUCCESS"
};



/**
 * 최근 본 BJ의 LIVE 목록 조회
 */
export const fetchRecent = () => {
    return (dispatch) => {
        dispatch({ type: RECENT.LOADING_START });

        fetch(`${ROOT_URL}/recent`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: RECENT.LOADING_DONE });
                dispatch({
                    type: RECENT.FETCH_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({ type: RECENT.LOADING_DONE });
                dispatch({
                    type: RECENT.FETCH_ERROR,
                    payload: error,
                });
            });
    }
}

/**
 * 최근 본 BJ의 LIVE 삭제
 * @param  user_id, broad_no 방송 번호
 * @return
 */
export const destroyRecent = (user_id, broad_no) => {
    return (dispatch) => {
        dispatch({ type: RECENT.LOADING_START });
        fetch(`${ROOT_URL}/recent/${user_id}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: RECENT.DESTORY_SUCCESS, payload: broad_no });

                dispatch({ type: RECENT.LOADING_DONE });
                toast.success(i18next.t(response.message));
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}

/**
 * 최근 본 BJ의 LIVE 전체 삭제
 * @return
 */
 export const destroyAllRecent = () => {
    return (dispatch) => {
        dispatch({ type: RECENT.LOADING_START });
        fetch(`${ROOT_URL}/recent/delete/all`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: RECENT.DESTORY_ALL_SUCCESS });
                dispatch({ type: RECENT.LOADING_DONE });
                toast.success(i18next.t(response.message));
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}
