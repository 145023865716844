import { hitFeed } from "actions/feed.action";
import { feedLike, feedUnLike } from "actions/feed.like.action";
import classNames from "classnames";
import ShareButton from "components/button/ShareButton";
import ContextMenu from "components/ict/ContextMenu";
import FeedImage from "components/image/FeedImage";
import Player from "components/player/Player";
import FeedLiveThumbnail from "components/thumbnail/FeedLiveThumbnail";
import { STATION_URL } from "constant/config";
import { LIVE, PHOTO, POST, VOD } from "constant/feedType";
import { FEED_ROUTE } from "constant/routeUrl";
import { makePlayUrl, numberCommaString, sendLogApi } from "helpers";
import { decodeEmoticon } from "libs/emoticon";
import { liveInflowLog, vodInflowLog } from "libs/log";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ProfileImage from "components/image/ProfileImage";
import { makeProfileUrl } from 'helpers';

export default function FeedItem({ measure, index, item, style, playable, isRendered, onChangeLayer }) {
    let { user_id } = useParams();

    // dispatch
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const nickname = item.station_user_nick;
    const id = item.station_user_id;
    const [didMount, setDidMount] = useState(false);

    /**
     * componentDidMount 후 0.5후에 조회수 올림
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            setDidMount(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    /**
     * 조회수 올림
     */
    useEffect(() => {
        if (didMount === true && isRendered) {
            if (item.feed_type === POST || item.feed_type === PHOTO) {
                dispatch(hitFeed(item));
            }
        }
    }, [didMount, dispatch, isRendered, item, item.hit_time, item.title_no]);

    /**
     * 좋아요
     */
    const handleLike = useCallback(
        (is_like, station_user_id, title_no) => () => {
            if (is_like) {
                dispatch(feedUnLike(station_user_id, title_no));
            } else {
                dispatch(feedLike(station_user_id, title_no));
            }
        },
        [dispatch],
    );

    /**
     * 방송 진입 로그 호출
     */
    const handleLiveLog = useCallback(
        (broad) => (e) => {

            // inflow_path add data
            let etcData = {
                //'tag': broad.hash_tags.join("|"),
                //'category_tag': broad.category_tags.join("|"),
            }

            //클릭로그
            sendLogApi("main_00000006", {
                watch_type: "live",
                bj_id: broad.user_id,
                broad_no: broad.broad_no,
                category_no: broad.broad_cate_no,
                bps: broad.broad_bps,
                resolution: broad.broad_resolution,
            });
            //유입로그
            liveInflowLog(broad.user_id, broad.broad_no, user_id ? ["my", "feed", "bj"] : ["my", "feed"], etcData);
        },
        [user_id],
    );

    const handleLog = useCallback(
        (item) => (e) => {
            if (item.feed_type === VOD) {
                //유입로그
                vodInflowLog(item.station_user_id, item.title_no, user_id ? ["my", "feed", "bj"] : ["my", "feed"]);
            }
        },
        [user_id],
    );

    const handleClickLayer = useCallback((layer) => (event) => {
        
        onChangeLayer("", 0);
    }, [onChangeLayer]);

    if (item.feed_type === LIVE) {
        return (
            <div style={style} className="list-item" id={`feed-item${index}`}>
                <div className="item-wrap">
                    <div className="autor_wrap">
                        <ProfileImage 
                            tag="span"
                            className="thum"
                            user_id={item.user_id}
                            src={makeProfileUrl(item.user_id)}
                            childrenTag="img"
                            childrenTagProps = {{
                                src:item.profile_image,
                                loading: "lazy"
                            }}
                            />
                        <div className="info_box">
                            <ContextMenu user_id={item.user_id} user_nick={item.user_nick}>
                                <button type="button" className="nick">
                                    <p>
                                        {item.user_nick}({item.user_id})
                                    </p>
                                </button>
                            </ContextMenu>
                            <span>{t("{{num}}명 참여중", { num: numberCommaString(item.total_view_cnt) })}</span>
                        </div>
                    </div>

                    <FeedLiveThumbnail
                        href={makePlayUrl(item.user_id, item.broad_no)}
                        onClick={handleLiveLog(item)}
                        broad={item}
                        onLoad={measure}
                        target="_blank"
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div style={style} className="list-item" id={`feed-item${index}`}>
                <div className="item-wrap">
                    {item.station_user_id !== item.user_id && (
                        <div className="list-item-header">
                            <div className="autor_wrap">
                                <ProfileImage 
                                    tag="span"
                                    className="thum"
                                    user_id={item.station_user_id}
                                    src={makeProfileUrl(item.station_user_id)}
                                    childrenTag="a"
                                    innerChildrenTag="img"
                                    childrenTagProps = {{
                                        href:`${STATION_URL}/${item.station_user_id}`,
                                        target:"_blank"
                                    }}
                                    innerChildrenTagProps = {{
                                        src:item.profile_image,
                                        loading: "lazy"
                                    }}
                                    />
                                <div className="info_box">
                                    <ContextMenu user_id={item.station_user_id} user_nick={item.station_user_nick}>
                                        <button type="button" className="nick">
                                            <Trans nickname={nickname} id={id}>
                                                <p>
                                                    {{ nickname }}({{ id }})
                                                </p>
                                                <em>님의 방송국</em>
                                            </Trans>
                                        </button>
                                    </ContextMenu>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="autor_wrap">
                        <ProfileImage 
                            tag="span"
                            className="thum"
                            user_id={item.user_id}
                            src={makeProfileUrl(item.user_id)}
                            childrenTag="a"
                            innerChildrenTag="img"
                            childrenTagProps = {{
                                href:`${STATION_URL}/${item.user_id}`,
                                target:"_blank"
                            }}
                            innerChildrenTagProps = {{
                                src:item.profile_image,
                                loading: "lazy"
                            }}
                            />

                        <div className="info_box">
                            <ContextMenu user_id={item.user_id} user_nick={item.user_nick}>
                                <button type="button" className="nick">
                                    <p>
                                        {item.user_nick}({item.user_id})
                                    </p>
                                </button>
                            </ContextMenu>

                            <span>{item.reg_date_human}</span>
                            <span>{t("조회 {{count}}", { count: numberCommaString(item.count.read_cnt) })}</span>
                        </div>
                    </div>

                    <div className="item_contents">
                        <a href={item.url} target="_blank" onClick={handleLog(item)}>
                            <h3>
                                {item.is_notice && (
                                    <i className="ic_noti">
                                        <Trans>공지</Trans>
                                    </i>
                                )}
                                {item.title_name}
                            </h3>
                            <p className="text" dangerouslySetInnerHTML={{ __html: decodeEmoticon(item.content) }} />
                        </a>
                        {item.hashtags && (
                            <ul className="hasTag">
                                {item.hashtags.map((hashtag, index) => (
                                    <li key={index}>
                                        <Link to={`${FEED_ROUTE}/hashtag/${hashtag}`}>{`#${hashtag}`}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {item.board_type === 103 && item.photos.length && (
                        <div className="file">
                            {/**<img className="image" src={item.photos[0].url} alt="" loading="lazy" onLoad={measure} />**/}
                            <FeedImage href={item.url} photo={item.photos[0]} onLoad={measure} target="_blank" />
                            {item.photos.length > 1 && <span className="count">{item.photos.length}</span>}
                        </div>
                    )}

                    {item.board_type === 105 && item.ucc && (
                        <a href={item.url} target="_blank" onClick={handleLog(item)}>
                            <Player index={index} item={item} isRendered={isRendered} playable={playable} />
                        </a>
                    )}

                    <div className="item_util">
                        <button
                            type="button"
                            className={classNames("btn-basic up", { on: item.is_like })}
                            onClick={handleLike(item.is_like, item.station_user_id, item.title_no)}
                        >
                            <span>{t("up")}</span>
                            <em>{item.count.like_cnt + (item.is_like ? 1 : 0)}</em>
                        </button>
                        <button type="button" className="btn-basic cmmt_list" onClick={() => window.open(`${item.url}#comment`)}>
                            <span>{t("댓글")}</span>
                            <em>{item.count.comment_cnt}</em>
                        </button>

                        <ShareButton item={item} onChangeLayer={handleClickLayer}  />
                    </div>
                </div>
            </div>
        );
    }
}
