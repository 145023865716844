import { fetchRecent } from "actions/recent.action";
import BroadingSection from "components/section/BroadingSection";
import ErrorSection from "components/section/ErrorSection";
import LoadingSection from "components/section/LoadingSection";
import TitleSection from "components/section/TitleSection";
import { RECENT_ROUTE } from "constant/routeUrl";
import { goLogin } from "helpers";
import i18next from "i18next";
import React, { useEffect,useState } from "react";
import { useAuth } from "react-navigationbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

const Recent = () => {
    // useDispatch
    const dispatch = useDispatch();
    const { isLogin } = useAuth();
    const [isLoad, setIsLoad] = useState(false)
    const {pathname,key} = useLocation();

    // useSelector
    const { error, loading } = useSelector((state) => state.broadingReducer, shallowEqual);

    // useEffect
    useEffect(() => {
        initSetting();
        setIsLoad(true);
    }, [dispatch, isLogin]);

    //최초 진입 이후 LNB 동일 영역 클릭시 동작
    useEffect(()=>{
        if(isLoad && pathname === RECENT_ROUTE){
            window.location.reload();
        }
    }, [key])

    const initSetting = () =>{
        if (isLogin) {
            dispatch(fetchRecent());
        } else {
            goLogin();
        }
    }


    return (
        <div id="list-section">
            <TitleSection big className="line_none">
                {i18next.t(`최근 본 BJ의 LIVE`)}
            </TitleSection>

            {error && <ErrorSection />}
            {loading && <LoadingSection />}

            <BroadingSection />
        </div>
    );
};

export default Recent;
