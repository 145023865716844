import { combineReducers } from "redux";
import apiReducer from "./api.reducer";
import broadingReducer from "./broading.reducer";
import fanclubReducer from "./fanclub.reducer";
import favoriteClipReducer from "./favorite.clip.reducer";
import favoriteCatchReducer from "./favorite.catch.reducer";
import favoriteCatchStoryReducer from "./favorite.catchstory.reducer";
import favoriteContentReducer from "./favorite.content.reducer";
import favoriteReducer from "./favorite.reducer";
import favoriteExtReducer from "./favorite.ext.reducer";
import favoriteGroupReducer from "./favorite.group.reducer";
import favoriteGroupItemReducer from "./favorite.groupitem.reducer";
import feedFavoriteReducer from "./feed.favorite.reducer";
import feedHashtagReducer from "./feed.hashtag.reducer";
import feedReducer from "./feed.reducer";
import hotissueReducer from "./hotissue.reducer";
import recommandsReducer from "./recommands.reducer";
import subscribeReducer from "./subscribe.reducer";
import vodsReducer from "./vods.reducer";
import storyReducer from "./story.reducer";
import playlistReducer from "./playlist.reducer";
import globalReducer from "./global.reducer";
import autoHashTagReducer from "./autoHashTag.reducer";

const reducers = combineReducers({
    apiReducer,
    broadingReducer,
    fanclubReducer,
    favoriteReducer,
    favoriteExtReducer,
    favoriteGroupReducer,
    favoriteGroupItemReducer,
    favoriteClipReducer,
    favoriteCatchReducer,
    favoriteCatchStoryReducer,
    recommandsReducer,
    subscribeReducer,
    vodsReducer,
    feedReducer,
    storyReducer,
    feedFavoriteReducer,
    feedHashtagReducer,
    favoriteContentReducer,
    hotissueReducer,
    playlistReducer,
    globalReducer,
    autoHashTagReducer
});

export default reducers;
