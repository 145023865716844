import React, { useCallback, useMemo, useState,useContext } from "react";
import CatchThumbnail from "components/thumbnail/CatchThumbnail";
import { FAVORITE_ROUTE } from "constant/routeUrl";
import { makeVodUrl, numberCommaString, sendLogApi } from "helpers";
import { vodInflowLog } from "libs/log";
import { useLocation } from "react-router";
import { useDispatch } from 'react-redux';
import i18next from "i18next";
import ThreeDotHistoryButton from "components/button/ThreeDotHistoryButton";
import {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";
import ShareButton from "components/button/ShareButton";

export default function CatchItem({ vod, path3, page=1, onChangeLayer, onPosition, onCallBackChangeLayer, sortColumn="" }) {
    const { pathname } = useLocation();
    const [layer, setLayer] = useState('');
    const [titleNo, setTitleNo] = useState(0);
    const [top, setTop] = useState(0);
    const [offset, setOffset] = useState(0);
    const dispatch = useDispatch();
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);

    /**
     * 영상 진입 로그 호출
     */
    const onClickVodLink = useCallback(
        (data, title_no, catchUrl) => {

            // watch_type=vod&bj_id=youja2016&title_no=1000134416&vtype=NORMAL&location=subsbj
            let sendData = {
                watch_type: "vod",
                bj_id: data.user_id,
                title_no: data.title_no,
                vtype: data.file_type
            };
            let codeType = "";
            let pathType = "";
            let buttonType = "";
            let etcData ={
                //'tag': data.hash_tags.join("|"),
                //'category_tag': data.category_tags.join("|"),
            }
            switch (pathname) {
                case FAVORITE_ROUTE:
                    codeType = "main_00000006";
                    pathType = "fav";
                    etcData = {
                        ...etcData,
                        'group_type': groupIdx ===0 ? 'all' : 'custom',
                        'group_name': groupIdx > 0 ? groupTitle : '',
                    }
                    break;
                default:
                    return false;
            }
            sendLogApi(codeType, sendData, "web", buttonType,);
            vodInflowLog(data.user_id, data.title_no, ["my", pathType, path3 || "vod"], etcData);
        },
        [pathname, path3, groupIdx, groupTitle],
    );

    /**
     * 즐찾 그룹 담기 
     */
    const handlePosition = useCallback((top, left) => {
        onPosition(top, left);
        setTop(top);
    }, [onPosition]);

    const handleClickLayer = useCallback((layer, titleNo) => (event) => {
        event.stopPropagation();
        setLayer(layer);
        setTitleNo(titleNo);
        setOffset(event.nativeEvent.pageX);
    }, [setLayer, setTitleNo]);

    // 
    const handleChangeLayer = useCallback((layer, titleNo) => {
        onChangeLayer(layer, titleNo);
        setLayer(layer);
        setTitleNo(titleNo);
    }, [onChangeLayer, setLayer, setTitleNo]);


    /**
     * 동영상 플레이어페이지 URL
     */
    const url = useMemo(() => makeVodUrl(vod.title_no, pathname, sortColumn, page), [vod.title_no, pathname, sortColumn, page]);
    return (
        <>
            <a style={{cursor:'pointer'}}
                onClick={(event) => {
                    event.preventDefault();
                    onClickVodLink(vod, vod.title_no, url);
                    window.open(url, "_blank", "noopener");

                }} pathname={pathname}>
                <CatchThumbnail 
                    href={url} vod={vod} 
                    pathname={pathname}
                    onClick={(event) => {
                            event.preventDefault();
                            onClickVodLink(vod, vod.title_no, url);
                            window.open(url, "_blank", "noopener");
                        }} 
                    />
                    <div className="info_area">
                        
                        {//(pathname === FAVORITE_ROUTE)  &&
                            <ThreeDotHistoryButton

                                trigger={
                                    <button type="button" className="more_dot">
                                        <span>더보기 메뉴</span>
                                    </button>
                                }
                                onPosition={handlePosition}
                                onChangeLayer={handleChangeLayer}
                            >
                                <button type="button" onClick={handleClickLayer('SHARE', 0)}>
                                    <span>{i18next.t(`공유하기`)}</span>
                                </button>
                                    
                                { vod.copyright  && <button type="button" onClick={  onCallBackChangeLayer("FAVORITELIST", vod.copyright.user_id)}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                                { vod.original_user_id  &&<button type="button" onClick={ onCallBackChangeLayer("FAVORITELIST",  vod.original_user_id)}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                                {!vod.copyright && !vod.original_user_id &&vod.user_id && <button type="button" onClick={ onCallBackChangeLayer("FAVORITELIST", vod.user_id)}><span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span></button>}
                            </ThreeDotHistoryButton>
                        }
                        <p className="tit" title={vod.title_name}>{vod.title_name}</p>
                        <span className="view">{numberCommaString(vod.read_cnt)}</span>
                    </div>

                {layer === 'SHARE' && <ShareButton item={vod} top={top} offset={offset} type="CATCH" layer={layer} setLayer={setLayer} onChangeLayer={handleChangeLayer}/>}
            </a>
        </>
    );
}
