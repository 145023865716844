exports.lang = {
    kr: "ja",
    아프리카TV: "アフリカTV",
    방송국: "コミュニティ",
    전체: "全体",
    탐방허용: "巡回OK",
    "쪽지 보내기": "DM送信",
    즐겨찾기: "お気に入り",
    "차단된 팝업을 허용해주세요.": "遮断されたポップアップを許可してください。",
    "즐겨찾기가 추가되었습니다.": "お気に入りが追加されました。",
    "잠시후 다시 시도해주세요.": "サーバー接続が終了しました。 しばらくしてからもう一度お試しください",
    "즐겨찾기가 삭제되었습니다.": "お気に入りが解除されました。",
    "목록 상단 고정 완료 되었습니다.": "リストにピン留めしました。",
    "목록 상단 고정 해제 되었습니다.": "リストからピン留めを外しました。",
    "이미 처리중 입니다.": "処理中です。",
    "나중에 보기 목록에 등록 되었습니다.\n목록에서 바로 확인 하시겠습니까?": "後で見るリストへ登録完了しました。\nリストから確認しますか。",
    "방송 중": "放送中",
    정렬보기: "整列表示",
    참여인원순: "視聴者数順",
    최신등록순: "最新登録順",
    최신방송순: "最新放送順",
    새로고침: "更新",
    방송시작: "放送開始",
    "즐겨찾기 삭제": "お気に入り解除",
    추가하기: "追加",
    "나중에 보기": "後で見る",
    더보기: "もっと見る",
    이전: "前へ",
    다음: "次へ",
    다시보기: "放送をもう一度見る",
    하이라이트: "放送ハイライト",
    "팬가입한 BJ": "入会済みBJファンクラブ",
    "팬가입한 BJ 전체": "入会ファンクラブすべて",
    "팬클럽 가입순": "入会日順",
    닉네임순: "ニックネーム順",
    아이디순: "ID順",
    "팬클럽 가입일": "ファンクラブ入会日",
    최근방송: "視聴履歴",
    "즐겨찾기 해제": "お気に入り解除",
    "즐겨찾기 추가": "お気に入り追加",
    "LIVE 참여하기": "LIVEを見る",
    "목록 상단해제": "ピン止め解除",
    "목록 상단고정": "ピン止め固定",
    "가입한 팬클럽이 없습니다.": "入会したファンクラブがありません。",
    "즐겨찾기한 BJ": "お気に入り",
    "즐겨찾기 전체": "すべて",
    "{{num}}명": "{{num}}人",
    "즐겨찾기한 BJ가 없습니다.": "お気に入りした BJがありません。",
    "최근 본 BJ의 LIVE": "最近見たBJのLIVE",
    최근참여순: "最近視聴順",
    "최근 본 BJ의 LIVE 삭제": "最近見たBJのLIVE削除",
    "최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?" : "最近見たBJのLIVE内訳を 全て削除しますか？",
    "해당 방송이 최근 본 방송에서 삭제되었습니다.": "この放送が最近見た放送から削除されました。",
    "지금 바로, 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "さっそく アフリカTVの放送を見てみよう！",
    닫기: "閉じる",
    "즐겨찾기한 BJ의 VOD": "お気に入りした BJの動画",
    "즐겨찾기한 BJ의 Catch": "お気に入りしたBJのキャッチ",
    "팬가입한 BJ의 VOD": "ファンクラブBJの動画",
    "구독한 BJ의 VOD": "購読したBJの動画",
    "즐겨찾기 할 만한 방송": "おすすめ放送",
    "즐겨찾기할 만한 BJ": "あなたにおすすめのBJ",
    "BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!<br/>팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.":
        "BJへ星風船をプレゼントすることでファンクラブに入会することができます！<br/>ファンクラブはファンクラブ限定掲示板の利用や<br/>ファンクラブ限定チャット文字色でチャットが目立ちます。",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다.":
        "気に入ったBJを見つけてお気に入り 登録しましょう<br/>お気に入り登録したBJが放送を開始した際には通知受信ができます。",
    "즐겨찾기 BJ글": "お気に入りしたBJ告知",
    "나중에 보기에 등록되었습니다.": "[後で見る]に登録しました。",
    "보고 싶은 VOD나, 아직 보지 않은<br/>VOD를 목록에 등록해 두고  언제든 간편하게 찾아보세요!":
        "視聴したい動画やまだ視聴していない<br/> 動画をリストに登録していつでも視聴することができます。",
    "라이브 방송을 참여할 수 없는 상황일 때<br/>목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!":
        "LIVE放送を視聴できない場合<br/> リストへ登録するといつでも視聴することができます。",
    "<0>VOD의 “나중에 보기” 메뉴</0>에서<br/>등록 한 영상을 확인 하실 수 있습니다.":
        '<0>"ダイジェストの「後で見る」メニュー"</0> から<br/>登録した動画の確認ができます。',
    다시보지않기: "非表示にする",
    "레이어 닫기": "レイヤー閉じる",
    "나중에 보기에 등록된 VOD입니다.": "すでに後で見るリストに登録された動画です。",
    "일시적인 오류 입니다. 잠시 후 다시 시도해 주세요": "一時的エラーが発生しました。 しばらくしてからもう一度お試しください。",
    "나중에 보기 리스트는 최대 1,000개 까지 등록 가능합니다.": "後で見るリストは最大1,000個まで登録可能です。",
    "나중에 보기를 지원하지 않는 영상입니다.": "「後で見る」機能を利用できない動画です。",
    "VOD 정보가 없습니다.": "動画情報がありません。",
    "게시물이 존재하지 않습니다": "投稿がありません。",
    "처리 되었습니다.": "適用しました。",
    "방송국 바로가기": "コミュニティーへ",
    "즐겨찾기한 BJ의 유저클립": "お気に入りしたBJのユー ザークリップ",
    "추천받지 않기": "おすすめ非表示",
    "해당 방송을 더 이상 추천하지 않습니다.": "この放送今後おすすめしません。",

    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다. ":
        "BJをお気に入りするとそのBJのコミュニティの<br/> 全ての投稿をタイムラインで受け取ることができます。",
    "BJ가 등록한 게시물이 없습니다.": "BJが登録した投稿がありません。",
    "구독한 BJ": "購読したBJ",
    "구독한 BJ 전체": "全ての購読したBJ",
    "구독 결제정보": "購読決済情報",
    최신구독순: "最新購読順",
    "구독 닉네임": "購読ニックネーム",
    구독일: "購読日",
    "{{month}}개월": "{{month}} か月",
    "회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)":
        "会員様の基本ニックネーム @を使用しますか？ {{default_nick}}? \n(変更より24時間後に再変更可能)",
    "구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다":
        "購読者ニックネームが変更されました。 \n現在放送参加中の場合、変更されたニックネームは放送再入場の際に適用されます。",
    "구독한 BJ가 없습니다.": "購読したBJがいません。",
    "내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br/>구독팬만을 위한 아주 특별한 혜택을 만나보세요!":
        "あなたが好きなBJを購読してメイン放送入場、購読専用ニックネーム、シグネチャー絵文字など<br/>購読者だけのための特別な特典をお楽しみください！",
    유료: "有料",

    "구독팬 전용 닉네임 변경": "購読者専用ニックネーム変更",
    "구독팬 전용 닉네임을 입력해주세요.": "購読者専用ニックネームを入力してください。",
    중복확인: "重複確認",
    "한글 6자(영문 12자)까지 입력 가능": "全角6字(半角12字)まで入力可能",
    "변경 시 24시간 후 재변경 가능": "変更すると24時間後に再変更可能",
    "구독한 BJ방송 채팅에서 사용됩니다.": "購読したBJの放送チャットにて使用できます。",
    변경: "変更",
    "기본 닉네임 사용": "基本ニックネーム使用",

    "현재 방송 중인 BJ가 없습니다.": "現在放送中のBJがいません。",
    "방송국에 등록된 공지글이 없습니다.": "コミュニティにお知らせがありません。",
    "방송국에 등록된 게시글이 없습니다.": "コミュニティに投稿がありません。",
    "방송국에 업로드된 VOD가 없습니다.": "コミュニティにアップロード動画がありません。",
    "공지글 보기": "お知らせを見る",
    "게시글 보기": "投稿を見る",

    "즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.": "お気に入りしたBJのコミュニティにアップロードされた投稿がありません。",
    "BJ글만 보기": "BJの投稿のみ見る",
    전체보기: "全体を見る",
    "글만 보기": "投稿のみ見る",
    "이미지만 보기": "画像のみ見る",
    "VOD만 보기": "動画のみ見る",
    "태그에 대한 검색 결과가 없습니다.": "該当するタグの検索結果がありません。",
    "{{num}}명 참여중": "{{num}}人視聴中",
    "{{num}}건": "{{num}}件",
    "조회 {{count}}": "観覧数 {{count}}",
    "핫 키워드": "ホットキーワード",
    "실시간 인기글": "リアルタイム人気投稿",
    "전체글 보기": "全体を見る",
    "방송국 새 글": "投稿",
    "해외에서 재생이 불가한 VOD입니다.": "海外では再生できない動画です。",
    "이어서 재생을 원하실 경우,<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "続けて再生をする場合<br/> 詳細ページに移動後再生してください。",
    "오류가 발생하여 재생이 불가합니다.": "",
    "<0>{{nickname}}({{id}})</0><1>님의 방송국</1>": "<0>{{nickname}}({{id}})</0><1>さんのコミュニティ</1>",
    고정하기: "ピン留め",
    공유하기: "シェアする",
    "주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요": "アドレスがコピーされました。選択後貼り付け(Ctrl+V)してください。",
    공지: "お知らせ",
    "미리보기가 원활하지 않습니다.<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "プレビューが正常に表示できませんでした。<br/>詳細ページに移動後再生してください。",

    "아프리카TV에서 생긴 일": "アフリカTVでの出来事",
    "아.생": "イシュー",
    투데이: "TODAY",
    "즐겨찾기한 BJ 글": "お気に入りしたBJの投稿",
    "실시간 인기글 TOP 100": "トレンド投稿TOP100",
    "TOP 100": "TOP 100",
    "{{rank}}위": "{{rank}}位",
    "방송국 가기": "コミュニティに行く",
    "게시물 안보기": "投稿を見ない",
    "게시물 안보기가 설정된 게시물입니다.": "投稿を見ないに設定された投稿です。",
    "실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.": "トレンド投稿に該当BJの投稿が 表示されていません。",
    "게시물 안보기 해제": "投稿を見ない解除",
    "핫이슈 동영상": "ホットイシュー動画",
    "실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?":
        "トレンド投稿に{{user_nick}}さんの投稿をこれ以上表示しませんか？",
    "지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?":
        "投稿を見ないに設定したBJはお気に入りしたBJです。\nお気に入りから該当BJを削除しますか？",
    "로그인이 필요한 기능입니다.\n로그인 하시겠습니까?": "ログインが必要な機能です。\nログインしますか？",
    "{{user_nick}}님의 게시물을 다시 보시겠습니까?": "{{user_nick}}さんの投稿を再び表示にしますか？",
    "{{user_nick}}님의 게시물 안보기": "{{user_nick}}さんの投稿を見ない",
    "네트워크에 문제가 있습니다.<br> 네트워크 상태를 확인해주세요.": "ネットワークに問題があります。",
    "오늘의 움짤&유저클립": "今日のGIF＆ユーザークリップ",
    "닉네임 검색": "ニックネーム検索",
    "아이디 검색": "ID検索",

    스토리: "ストーリー",
    "내 글·댓글": "私の投稿・コメント",
    "스토리 내용이 없습니다.": "ストーリーの内容がありません。",
    "작성한 게시글, 댓글, 답글이 없습니다.": "作成した投稿、コメント、返信がありません。",
    "작성한 게시글이 없습니다.": "作成した投稿がありません。",
    "작성한 댓글이 없습니다.": "作成したコメントがありません。",
    "작성한 답글이 없습니다.": "作成した返信がありません。",
    "댓글 보기": "コメントを見る",
    "답글 보기": "返信を見る",
    "{{nickname}}<1>님의 방송국</1>": "{{nickname}}<1>さんのコミュニティ</1>",
    "즐겨찾기 BJ별 알림 설정을<br/>PC에서도 할 수 있어요!": "お気に入りBJ別の通知設定を<br/>PCで行うことができます!",
    "모든 즐겨찾기 BJ에게 알림을 받습니다.": "全てのBJの通知を受け取ります。",
    "모든 즐겨찾기 BJ에게 알림을 받지 않습니다.": "全てのBJの通知を受け取りません。",
    "{{favorite_nick}}님에게 알림을 받습니다.": "{{favorite_nick}}さんから通知を受け取ります。",
    "{{favorite_nick}}님에게 알림을 받지 않습니다.": "{{favorite_nick}}さんから通知を受け取りません。",
    "알림 받지 않기": "通知を受け取らない",
    "알림 받기": "通知を受け取る",
    "전체 알림": "すべてを通知",

    "선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?": "選択した投稿(コメント、返信含む)を削除しますか？",
    "선택하신 댓글(답글 포함)을 삭제하시겠습니까?": "選択したコメント(返信含む)を削除しますか？",
    "선택하신 답글을 삭제하시겠습니까?": "選択したコメントを削除しますか？",
    "삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.": "削除するとストーリーとコミュニティから完全に削除され復元できません。",
    "게시글(댓글, 답글 포함) 삭제가 완료되었습니다.": "投稿(コメント、返信含む)の削除が完了しました。",
    "댓글(답글 포함) 삭제가 완료되었습니다.": "コメント(返信含む)削除が完了しました。",
    "답글 삭제가 완료되었습니다.": "返信削除が完了しました。",
    삭제하기: "削除する",
    
    "최근 본 방송": "最近見た放送",
    "최근 본 VOD": "最近見た動画",
    "UP한 VOD": "UPした動画",
    재생목록: "再生リスト",
    "최근 본 VOD 삭제": "最近見た動画削除",
    "아직 참여한 방송이 없습니다.": "放送参加記録がありません。",
    "지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "今すぐアフリカTVの多様で楽しい放送をご覧ください！",
    "LIVE 보러가기": "LIVE見に行く",
    "VOD 보러가기": "動画を見に行く",
    "최근 본 VOD가 없습니다.": "最近見た動画がありません。",
    "나중에 보기 한 VOD가 없습니다.": "後で見る動画がありません。",
    "UP 한 VOD가 없습니다.": "UPした動画がありません。",
    "재생목록이 없습니다.": "There is no playlist.",
    "VOD 위치 변경 안내": "放送参加記録がありません。",
    "즐겨찾기한 BJ의 유저클립과 VOD는 새로워진 VOD 메뉴에서 만나보세요!": "お気に入りしたBJのユーザークリップと動画は新しくなった動画メニューからご覧ください。",
    "즐겨찾기한 VOD 보러가기": "お気に入りした動画を見に行く",

    "UP 취소": "UPキャンセル",
    "나중에 보기 삭제": "後で見る削除",
    "재생한 VOD 삭제": "再生した動画削除",
    전체삭제: "全体削除",
    조회순: "再生順",
    UP순: "UP順",
    댓글순: "コメント数順",
    "VOD 추가순": "動画追加順",
    오래된순: "過去動画順",
    최신순: "最新順",
    비공개: "非公開",
    업데이트: "アップデート",
    "재생목록에 담기": "プレイリストに保存",
    수정하기: "修正する",

    "내 마음대로 만드는 플레이리스트!":"あなたの思い通りに作れるプレイリスト!",
    "테마/BJ별로 재생목록에 담아 편하게 시청하세요.":"テーマ/BJ別にプレイリストに保存し更に便利にご視聴ください.",

    "새 재생목록 만들기": "新しいプレイリスト作成",
    "제목을 입력해주세요.": "タイトルを入力してください。",
    "목록 공개": "リスト公開",
    "목록 비공개": "リスト非公開",
    "재생목록 저장 허용": "リストのコピー許可",
    "재생목록 저장 비허용": "リストのコピー非許可",
    추가: "追加",
    "미리보기를 제공하지 않는 VOD": "レコメンドが反映されました。",
    "지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!": "今すぐアフリカTVの多様で面白い動画をご視聴ください！",
    "해당 BJ를 즐겨찾기에서 삭제하시겠습니까?" : "該当BJをお気に入りから削除しますか？ ",
    "재생목록을 삭제하시겠습니까?" : "プレイリストを削除しますか？ ",
    "편집": "編集",
    "즐찾BJ 편집" : "お気に入りBJ編集",
    "새 그룹 만들기" : "新グループ作成",
    "그룹명" : "グループ名",
    "그룹명을 입력해주세요." :"グループ名を入力してください。",
    "BJ 추가" : "BJ追加",
    "BJ 추가하기" : "BJを追加する",
    "그룹에서 삭제" : "グループから削除",
    "즐찾BJ 그룹에 담기" : "お気に入りBJをグループに保存",
    "즐찾BJ 그룹" : "お気に入りBJグループ",
    "{{num}}명 추가" : "{{num}}名追加",
    "해당 그룹을 삭제하시겠습니까?" : "このグループを削除しますか？",
    "그룹 추가는 최대 150명까지입니다." : "グループ追加は最大150人まで可能です。",
    "BJ를 추가해주세요." : "BJを追加する",
    "편집할 그룹이 없습니다." : "編集するグループがありません。",
    "BJ 그룹 기능을 이용하여<br/>원하는 주제별로 BJ를 정리해보세요!" : "BJグループ機能を利用し<br/>ご希望のテーマ別にBJを整理してください！",
    "삭제" : "削除",
    "그룹 수정" : "グループ修正",
    "확인" : "確認",
    "구독전용": "購読専用",
    "드롭스 이벤트 진행 중" : "Dropsイベント進行中",
    "%d분 전": "%d分前",
    "%d시간 전": "%d時間前",
    "%d일 전": "%d日前",
    "%d초 전": "%d秒前",
    "Catch 스토리": "Catchストーリー",
    "월": "月",
    "일": "日",
};
