import React, { useRef, useCallback } from 'react'
import classNames from "classnames";
import { useLocation } from "react-router";
import { FAVORITE_ROUTE} from "constant/routeUrl";
import { sendLogApi } from "helpers";

export default function TitleSection({ big, className, children, right, sectionType="" }) {
    const { pathname } = useLocation();
    const onClickVodLink = useCallback(
        () => {
            let sendData = {
                watch_type: "vod",
                button_type: "to_vod"
            };
            let codeType = "";
            
            switch (pathname) {
                case FAVORITE_ROUTE:
                    codeType = "my_fav_home_vod";
                    sendData['button_type'] = sectionType === "VOD" ? "more_btn_vod" : "more_btn_clip";
                    break;
                default:
                    return false;
            }
            sendLogApi(codeType, sendData, 'web');
        },
        [pathname]
    );
    return (
        <div className={classNames("title-wrap", className)} >
            <h2 className={classNames({ normal: !big, big: big })} >
                { (sectionType === "CLIP" || sectionType === "VOD") && pathname === FAVORITE_ROUTE ? <a href="//vod.afreecatv.com/FAV" onClick={onClickVodLink} target="_blank">{children}</a> : children}
            </h2>
            {
                right !== null &&
                <div className="rt_group">
                    {right}
                </div>
            }
        </div >
    )
}
