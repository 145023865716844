import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LAYER } from "components/vods/PlayListLayer";
import { fetchPlaylistLayer, checkPlaylistLayer, updatePlaylistLayer } from "actions/playlist.action";
import useOutsideClick from "components/hook/useOutsideClick";
import {JAVASCRIPT_VOID} from "constant/config";
import i18next from 'i18next';

export default function  ListLayer ({ref, layers, onLayer, onTitle, title_no, top, left}) {
    const dispatch = useDispatch();
    const currentRef = useRef();
    const [selectIdx, setSelectIdx] = useState([]);
    const [addSelectIdx, setAddSelectIdx] = useState([]);
    const [visible, setVisible] = useState(false);

    const { data = [] } = useSelector(state => (state.playlistReducer), shallowEqual);

    useEffect(() => {
        dispatch(fetchPlaylistLayer(title_no));
        setVisible(layers==='LIST' ? true : false);
    }, [dispatch, layers, title_no]);

    useEffect(() => {
        let selectIdxArr = [];
        data.map((playlist) => {
            if (playlist.playlist.is_checked_playlist) {
                //setSelectIdx([...selectIdx, playlist.list_idx]);
                selectIdxArr.push(playlist.list_idx);
            }
            return playlist;
        });

        setSelectIdx(selectIdxArr);

    }, [data]);
    
    useOutsideClick(currentRef, () => {
        onLayer('');
        onTitle(0);
    }, [onLayer, onTitle]);
    
    const handleClickLayer = useCallback(() => (event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }, []);

    

    	// 재생목록에 영상 담기 체크
	const handleCheckPlaylistLayer = (checked, idx) => {
        // dispatch(checkPlaylistLayer(idx));
        setAddSelectIdx([...addSelectIdx, idx]);

        if(checked) {
            setSelectIdx([...selectIdx, idx]);
        } else {
            setSelectIdx(selectIdx.filter(listIdx=>listIdx!==idx));
        }
    }
    
    const handleUpdatePlaylistLayer = useCallback(() => {
        if(addSelectIdx.length > 0) {
           dispatch(updatePlaylistLayer(title_no, addSelectIdx));
        }
        onLayer('');
        onTitle(0);
        setVisible(false);
    }, [onLayer, onTitle, dispatch, title_no, addSelectIdx]);

   
    return (
        <>
            <div ref={currentRef} className="ui-pop PlayListAdd" style={{ display: visible ? "block" : "none", position: "absolute", top: top, left: left}} onClick={handleClickLayer}>
                <div className="pop-title">
                    <h3>{i18next.t(`재생목록에 담기`)}</h3>
                </div>
                <div className="pop-body">
                    <ul className="PlayListBox">
                        {data.map((playlist) => (
                            playlist.add_id === playlist.copyright_id && (<li key={playlist.no}>
                                { console.log(playlist)}
                                <input 
                                    type="checkbox" 
                                    id={playlist.list_idx} 
                                    onChange={(e) => handleCheckPlaylistLayer(e.target.checked, playlist.list_idx)}
                                    checked={selectIdx.indexOf(playlist.list_idx) >= 0 ? true : false}
                                    />
                                <label htmlFor={playlist.list_idx} >{playlist.playlist.title}</label>
                                <span className={playlist.playlist.config.display === 'Y' ? '' : 'hidden'}>{playlist.playlist.config.display === 'Y' ? i18next.t(`공개`) : i18next.t(`비공개`)}</span>
                            </li>)
                        ))}       
                    </ul>
                    <button type="button" className="btn_NewAddList" onClick={() => {
                        onLayer("ADD");
                        onTitle(title_no);
                    }}>+ {i18next.t(`새 재생목록 만들기`)}</button>
                </div>
                <div className="pop-btn">
                    <button type="button" className="btn" onClick={handleUpdatePlaylistLayer}>{i18next.t(`확인`)}</button>
                </div>
                <a href="#" className="pop-close" onClick={(e) => {
                        e.preventDefault();
                        onLayer("");
                        onTitle(0);
                    }}><span>{i18next.t(`닫기`)}</span></a>
            </div>
        </>
    );
}