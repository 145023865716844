import ScrollBar from 'components/scrollbar/Scrollbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Motion, spring } from "react-motion";
import { useDebouncedCallback } from 'use-debounce';

export default function FavoriteScroll({ style: propsStyle, children, className, contentClassName, onTotalChange, onScroll, onEnd, extend, tick: propTick, height, tickSize = 116 }) {

    const containerRef = useRef(null);
    const realRef = useRef(null);
    const classes = `favorite-scroll ${className || ""}`;
    const contentClasses = `favorite-scroll-content ${contentClassName || ""}`;
    const [tick, setTick] = useState(propTick)
    const [totalTick, setTotalTick] = useState(propTick);
    const [topPosition, setTopPosition] = useState(0)
    const [realSize, setRealSize] = useState(0)
    const [containerSize, setContainerSize] = useState(0)
    const [onEndDebouncedCallback] = useDebouncedCallback((value) => {
        if (onEnd)
            onEnd();
    }, 150);
    /**
     * tick 따른 이동 (확장버튼 변경시에도)
     */
    useEffect(() => {
        setTick(propTick)
    }, [extend, propTick])


    const calcTick = useCallback((tick) => {
        const marginTick = height / tickSize - 1;
        return Math.max(Math.min(tick, (totalTick - marginTick)), 1);
    }, [height, tickSize, totalTick]);


    /**
     * 휠이동
     */
    const handleWheel = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        let newTick
        if (e.wheelDelta) {
            newTick = tick + (e.wheelDelta > 0 ? -1 : 1);
        } else {
            newTick = tick + (e.deltaY > 0 ? 1 : -1);
        }
        setTick(calcTick(newTick));
    }, [calcTick, tick]);


    /**
     * tick 움직임
     */
    const newTickPosition = useCallback((tick) => (prevTopPosition) => {

        let newTopPosition = (tick - 1) * tickSize;
        const realHeight = realRef.current.offsetHeight;
        if (realHeight > containerSize) {
            if (newTopPosition >= realHeight - containerSize) {
                newTopPosition = realHeight - containerSize;
                onEndDebouncedCallback()
            } else if (newTopPosition < 0) {
                newTopPosition = 0;
            }
        } else {
            newTopPosition = 0;
        }
        ;

        if (onScroll) {
            onScroll(newTopPosition);
        }
        return newTopPosition;
    }, [containerSize, onEndDebouncedCallback, onScroll, tickSize])


    /**
     * 전체 tick 갯수가 바뀌면
     */
    useEffect(() => {
        if (onTotalChange) {
            onTotalChange(totalTick)
        }
    }, [onTotalChange, totalTick])


    /**
     * tick 따른 이동
     */
    useEffect(() => {
        setTopPosition(newTickPosition(tick))
    }, [newTickPosition, tick, tickSize])

    /**
     * tick 따른 이동
     */
    useEffect(() => {
        setTick(calcTick(tick));
    }, [calcTick, height, tick, tickSize])


    /**
     * Wheel
     */
    useEffect(() => {
        containerRef.current.addEventListener("onwheel" in document ? "wheel" : "mousewheel", handleWheel);
        return () => {
            containerRef.current.removeEventListener("onwheel" in document ? "wheel" : "mousewheel", handleWheel);
        };
    }, [handleWheel])

    /**
     * Dom업데이트
     */
    useEffect(() => {
        function handleDomUpdate() {
            setRealSize(realRef.current.offsetHeight);
            setContainerSize(containerRef.current.offsetHeight);
            setTotalTick(Math.ceil(realRef.current.offsetHeight / tickSize));
        }

        containerRef.current.addEventListener("transitionend", handleDomUpdate);
        containerRef.current.addEventListener("DOMSubtreeModified", handleDomUpdate);
        return () => {
            containerRef.current.removeEventListener("DOMSubtreeModified", handleDomUpdate);
            containerRef.current.removeEventListener("transitionend", handleDomUpdate);
        };
    }, [onTotalChange, tickSize])


    /**
     * 스크롤바 드래그
     */
    const handleScrollbarMove = useCallback((deltaY, deltaX) => {
        const newTopPosition = topPosition - deltaY;
        const realHeight = realRef.current.offsetHeight;
        if (newTopPosition >= 0 && newTopPosition <= (realHeight - containerSize)) {
            setTopPosition(newTopPosition);
            if (newTopPosition >= (realHeight - containerSize - 3)) {
                onEndDebouncedCallback();
            }

        }
    }, [containerSize, onEndDebouncedCallback, topPosition]);

    /**
     * 스크롤바 드래그 종료
     */
    const handleScrollbarMouseUp = useCallback(() => {
        const newTick = (Math.round(topPosition / tickSize)) + 1;
        setTick(calcTick(newTick) * 1.00001); //force Update
    }, [calcTick, tickSize, topPosition]);

    return (<Motion
        style={{
            marginTop: spring(-topPosition),
        }}>
        {(style) => (
            <div
                ref={containerRef}
                className={classes}
                style={{ ...propsStyle, overflow: 'hidden', height }}
            >
                <div
                    ref={realRef}
                    className={contentClasses}
                    style={{ display: 'inline-block', ...style }}
                >
                    {children}
                    {(realSize && containerSize && realSize >= (containerSize + 2)) ? <ScrollBar
                        ownerDocument={document}
                        realSize={realSize}
                        containerSize={containerSize}
                        position={topPosition}
                        onMove={handleScrollbarMove}
                        onPositionChange={() => null}
                        smoothScrolling={true}
                        minScrollSize={0}
                        isDragging={true}
                        type="vertical"
                        onFocus={() => null}
                        onMouseUp={handleScrollbarMouseUp}
                    /> : null}
                </div>
            </div>
        )}
    </Motion>
    )
}
