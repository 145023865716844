import React, { Fragment, useCallback, useMemo, useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from "react-responsive-modal";
import { isIE } from "react-device-detect";

export default function ModalWindow({
	children = () => <span />,
	classNames = {
		overlay: "",
		modal: "",
	},
	trigger = null,
	onOpen = null,
	onClose = null,
}) {
	// 모달 레이어 활성 여부
	const [open, setOpen] = useState(false);

	// 모달 레이어 활성화
	const handleOpenModal = useCallback(
		(event) => {
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();

			setOpen(true);

			// onOpen 실행
			onOpen && onOpen();
		},
		[onOpen],
	);

	// 모달 레이어 비활성화
	const handleCloseModal = useCallback(() => {
		console.log('handleCloseModal')
		setOpen(false);

		// onClose 실행
		onClose && onClose();
	}, [onClose]);

	// 모달 레이어 활성화를 위한 trigger component
	const triggerComponent = useMemo(() => {
		if (!trigger) {
			return null;
		}

		const triggerProps = { key: "T", onClick: handleOpenModal };

		if (typeof trigger === "function") {
			return React.cloneElement(trigger(open), triggerProps);
		}

		return React.cloneElement(trigger, triggerProps);
	}, [handleOpenModal, open, trigger]);

	// 모달 레이어
	const layerComponent = useMemo(() => (typeof children === "function" ? children(handleCloseModal) : children), [
		children,
		handleCloseModal,
	]);

	return (
		<Fragment>
			{triggerComponent}
			<Modal
				closeOnOverlayClick={true}
				open={open}
				onClose={handleCloseModal}
				showCloseIcon={false}
				classNames={classNames}
				animationDuration={isIE ? 0 : 500}
				styles={{
					overlay: { zIndex: 1501 },
				}}
				center
			>
				{layerComponent}
			</Modal>
		</Fragment>
	);
};
