import { sendLogApi, handleFetchErrors } from "helpers";
import { ROOT_URL } from "constant/config";
import { API } from "./api.action";
import i18next from "i18next";
import { POST, DELETE } from "constant/method";
import querystring from "querystring";
import cookie from "react-cookies";
import { toast } from "afreecatv-react-toast";

/*
 * 즐찾 상수
 */
 export const FAVORITE_GROUPITEM = {
	LOADING_START: "favorite_groupitem/LOADING_START",
	LOADING_DONE: "favorite_groupitem/LOADING_DONE",
	FETCH_SUCCESS: "favorite_groupitem/FETCH_SUCCESS",
	FETCH_ERROR: "favorite_groupitem/FETCH_ERROR",
	ADD_SUCCESS: "favorite_groupitem/ADD_SUCCESS",
	REMOVE_SUCCESS: "favorite_groupitem/REMOVE_SUCCESS",
    SET_MOBILE_PUSH_SUCCESS: "favorite_groupitem/SET_MOBILE_PUSH_SUCCESS",
};


/**
 * 즐찾 그룹 BJ 리스트 가져오기
 */
export function fetchFavoriteGroupItem(group_idx) {
    return (dispatch) => {
        dispatch({ type: FAVORITE_GROUPITEM.LOADING_START });

        fetch(`${ROOT_URL}/favorite/groupitem/list/`+group_idx, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FAVORITE_GROUPITEM.FETCH_SUCCESS,
                    payload: response,
                });
                dispatch({ type: FAVORITE_GROUPITEM.LOADING_DONE });
            })
            .catch((error) => {
                dispatch({
                    type: FAVORITE_GROUPITEM.FETCH_ERROR,
                    payload: error,
                });
                dispatch({ type: FAVORITE_GROUPITEM.LOADING_DONE });
            });
    };
}


