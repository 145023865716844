import { useEffect } from "react";

const useMouseDownClick = (ref, callback, setState) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
            setState([]);
        };
    }, [])
};

export default useMouseDownClick;
