import { toast } from "afreecatv-react-toast";
import { ROOT_URL, STATION_API_URL } from "constant/config";
import { CHILD_COMMENT, PARENT_COMMENT, PHOTO, POST } from "constant/feedType";
import { DELETE } from "constant/method";
import { handleFetchErrors } from "helpers";
import i18next from "i18next";
import querystring from "querystring";

/**
 * 즐찾 상수
 */
export const STORY = {
    LOADING_START: "story/LOADING_START",
    LOADING_DONE: "story/LOADING_DONE",
    FETCH_SUCCESS: "story/FETCH_SUCCESS",
    FETCH_ERROR: "story/FETCH_ERROR",
    HIT: "story/HIT",
    INIT: "story/INIT",
    DELETE_TITLE_SUCCESS: "DELETE_TITLE_SUCCESS",
    DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
    DELETE_CHDILD_COMMENT_SUCCESS: "DELETE_CHDILD_COMMENT_SUCCESS",
};

/**
 * 즐찾 목록 가져오기
 */
let abortController;
export function fetchStory({ page = 1, feedType }) {
    return (dispatch) => {
        /**
         * 이전 호출 취소
         */
        if (abortController) {
            abortController.abort();
            abortController = null;
        }

        dispatch({ type: STORY.LOADING_START });
        const params = querystring.stringify({
            page,
            feed_type: feedType,
        });
        const url = `${ROOT_URL}/story?${params}`;

        abortController = typeof AbortController === "undefined" ? { abort: () => {}, hit_time: 0 } : new AbortController();
        fetch(url, {
            credentials: "include",
            signal: abortController.signal,
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: STORY.LOADING_DONE });
                dispatch({
                    type: STORY.FETCH_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: STORY.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}

/**
 * 스토리 삭제하기
 * @param {*} param0
 * @returns
 */
export function destoryStory(story) {
    return (dispatch) => {
        let url;
        switch (story.feed_type) {
            case POST:
            case PHOTO:
                url = `${STATION_API_URL}/story/${story.station_user_id}/title/${story.title_no}`;
                break;
            case PARENT_COMMENT:
                url = `${STATION_API_URL}/story/${story.station_user_id}/title/${story.title_no}/comment/${story.p_comment_no}`;
                break;
            case CHILD_COMMENT:
                url = `${STATION_API_URL}/story/${story.station_user_id}/title/${story.title_no}/comment/${story.p_comment_no}/reply/${story.c_comment_no}`;
                break;
            default:
                break;
        }

        fetch(url, {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                switch (story.feed_type) {
                    case POST:
                    case PHOTO:
                        dispatch({
                            type: STORY.DELETE_TITLE_SUCCESS,
                            title_no: story.title_no,
                        });
                        toast.success(i18next.t(`게시글(댓글, 답글 포함) 삭제가 완료되었습니다.`));
                        break;
                    case PARENT_COMMENT:
                        dispatch({
                            type: STORY.DELETE_COMMENT_SUCCESS,
                            p_comment_no: story.p_comment_no,
                        });
                        toast.success(i18next.t(`댓글(답글 포함) 삭제가 완료되었습니다.`));
                        break;
                    case CHILD_COMMENT:
                        dispatch({
                            type: STORY.DELETE_CHDILD_COMMENT_SUCCESS,
                            c_comment_no: story.c_comment_no,
                        });
                        toast.success(i18next.t(`답글 삭제가 완료되었습니다.`));
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };
}

export function initStory() {
    return (dispatch) => {
        dispatch({ type: STORY.INIT });
    };
}
