import React from 'react'
import HotKeyword from './HotKeyword'
import Popular from './Popular'

export default function FeedSide() {


    return (
        <section className="side-contents">
            <HotKeyword />

            <Popular />
        </section>
    )
}
