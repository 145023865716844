import { FAVORITE_GROUP } from "actions/favorite.group.action";

const initialState = {
    loading: false,
    group_data: [],
    error: null
};

const favoriteGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAVORITE_GROUP.LOADING_START:
            return { ...state, loading: true };

        case FAVORITE_GROUP.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_GROUP.FETCH_SUCCESS:
            return { ...state, group_data: action.payload.data, error: null };

        case FAVORITE_GROUP.FETCH_DATA:
            return { ...state, group_data: action.payload};
        case FAVORITE_GROUP.FETCH_ADD_DATA:
            let tempdata = {idx:action.payload.idx, sort:action.payload.sort, title:action.payload.title, is_select:action.payload.sort, total_cnt : action.payload.total_cnt}
            let list = JSON.parse(JSON.stringify(state.group_data));
            list.push(tempdata)
            return { ...state, group_data: list}
      

        case FAVORITE_GROUP.FETCH_DEL_DATA:
            let del_list = JSON.parse(JSON.stringify(state.group_data));
            return {...state, group_data :del_list.filter((item=>!(item.idx ===action.payload)))}

        case FAVORITE_GROUP.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default favoriteGroupReducer;
