exports.lang = {
    kr: "vi",
    아프리카TV: "AfreecaTV",
    방송국: "Blog",
    전체: "Toàn bộ",
    탐방허용: "Được khám phá",
    "쪽지 보내기": "Gửi lời nhắn",
    즐겨찾기: "Yêu thích",
    "차단된 팝업을 허용해주세요.": "Cho phép pop-up đã bị chặn",
    "즐겨찾기가 추가되었습니다.": "Đã thêm vào mục Yêu thích.",
    "잠시후 다시 시도해주세요.": "Vui lòng thử lại sau.",
    "즐겨찾기가 삭제되었습니다.": "Đã xóa khỏi mục Yêu thích.",
    "목록 상단 고정 완료 되었습니다.": "Đã ghim vào đầu mục lục.",
    "목록 상단 고정 해제 되었습니다.": "Đã gỡ khỏi đầu mục lục.",
    "이미 처리중 입니다.": "Đang xử lý.",
    "나중에 보기 목록에 등록 되었습니다.\n목록에서 바로 확인 하시겠습니까?":
        "Đã thêm vào mục Xem sau.\nBạn có muốn kiểm tra mục lục bây giờ không?",
    "방송 중": "Đang phát sóng",
    정렬보기: "Hiển thị sắp xếp",
    참여인원순: "Theo người xem",
    최신등록순: "Theo mới đăng ký",
    최신방송순: "Theo stream mới",
    새로고침: "Làm mới",
    방송시작: "start stream",
    "명 시청": "người đang xem",
    "즐겨찾기 삭제": "Xóa khỏi Yêu thích",
    추가하기: "Thêm ",
    "나중에 보기": "Xem sau",
    더보기: "Tìm hiểu thêm",
    이전: "Quay lại",
    다음: "Tiếp theo",
    다시보기: "Xem lại",
    하이라이트: "Điểm nổi bật",
    "팬가입한 BJ": "BJ mà bạn là fan",
    "팬가입한 BJ 전체": "Toàn bộ các BJ bạn đã gia nhập fanclub",
    "팬클럽 가입순": "Sắp xếp theo Thứ tự gia nhập fanclub",
    닉네임순: "Theo nick",
    아이디순: "Theo ID",
    "팬클럽 가입일": "Ngày gia nhập fanclub",
    최근방송: "Phát sóng gần đây",
    "즐겨찾기 해제": "Gỡ khỏi Yêu thích",
    "즐겨찾기 추가": "Thêm vào Yêu thích",
    "LIVE 참여하기": "Xem LIVE",
    "목록 상단해제": "Gỡ khỏi đầu mục lục",
    "목록 상단고정": "Ghim vào đầu mục lục",
    "가입한 팬클럽이 없습니다.": "Bạn chưa gia nhập fanclub nào.",
    "즐겨찾기한 BJ": "BJ yêu thích",
    "즐겨찾기 전체": "Toàn bộ mục Yêu thích",
    "{{num}}명": "{{num}}người",
    "즐겨찾기한 BJ가 없습니다.": "Bạn không có BJ yêu thích nào.",
    "최근 본 BJ의 LIVE": "BJ LIVE đã xem gần đây",
    최근참여순: "Theo tham gia gần đây",
    "최근 본 BJ의 LIVE 삭제": "Xóa BJ's LIVE đã xem gần đây",
    "최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?" : "Bạn có muốn xóa lịch sử LIVE của BJ gần đây của mình không?",
    "해당 방송이 최근 본 방송에서 삭제되었습니다.": "Chương trình phát sóng này đã bị xóa khỏi các chương trình phát sóng gần đây.",
    "지금 바로, 아프리카TV의 재미있고 다양한 방송을 만나보세요!":
        "Ngay bây giờ, hãy đến với những phát sóng đa dạng và thú vị của AfreecaTV!",
    닫기: "Đóng",
    "즐겨찾기한 BJ의 VOD": "VOD của BJ yêu thích",
    "즐겨찾기한 BJ의 Catch": "Bắt BJ yêu thích của bạn",
    "팬가입한 BJ의 VOD": "VOD của BJ bạn đã gia nhập fanclub.",
    "구독한 BJ의 VOD": "Các VOD BJ bạn đã đăng ký",
    "즐겨찾기 할 만한 방송": "Phát sóng đáng được yêu thích",
    "즐겨찾기할 만한 BJ": "Người nổi tiếng được đề xuất",
    "BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!<br/>팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.":
        "Nếu tặng cho BJ bóng sao, bạn sẽ được tham gia fanclub của BJ!<br/>Fanclub có quyền sử dụng bảng tin công khai của fanclub, có thể trò chuyện một cách nổi bật nhờ màu chữ trò chuyện riêng biệt.",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다.":
        "Nếu bạn nhấn Yêu thích BJ thì bạn sẽ có thể xem phát sóng một cách thoải mái,<br/>và được nhận thông báo khi phát sóng bắt đầu,",
    "즐겨찾기 BJ글": "Bài viết của BJ bạn yêu thích",
    "나중에 보기에 등록되었습니다.": "Đã đăng ký vào xem sau.",
    "보고 싶은 VOD나, 아직 보지 않은<br/>VOD를 목록에 등록해 두고  언제든 간편하게 찾아보세요!":
        "Để có thể xem bất kì lúc nào, hãy thêm vào mục lục  những VOD<br/>bạn chưa xem hay những VOD bạn muốn xem! ",
    "라이브 방송을 참여할 수 없는 상황일 때<br/>목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!":
        "Trong hoàn cảnh không thể xem được phát sóng trực tiếp<br/>bạn hãy lưu vào mục lục để có thể xem bất kì lúc nào!",
    "<0>VOD의 “나중에 보기” 메뉴</0>에서<br/>등록 한 영상을 확인 하실 수 있습니다.":
        "<0>Bạn có thể xem các video đã đăng kí<br/>trong</0>menu “Xem sau” của VOD.",
    다시보지않기: "Không xem lại",
    "레이어 닫기": "Đóng layer",
    "나중에 보기에 등록된 VOD입니다.": "VOD này đã được đăng kí vào Xem sau.",
    "일시적인 오류 입니다. 잠시 후 다시 시도해 주세요": "Xảy ra lỗi tạm thời. Vui lòng thử lại sau.",
    "나중에 보기 리스트는 최대 1,000개 까지 등록 가능합니다.": "Bạn có thể đăng ký tối đa 1.000 mục vào danh sách Xem sau.",
    "나중에 보기를 지원하지 않는 영상입니다.": "Video này không hỗ trợ Xem sau.",
    "VOD 정보가 없습니다.": "Không có thông tin của VOD.",
    "게시물이 존재하지 않습니다": "Bài đăng này không tồn tại.",
    "처리 되었습니다.": "Đã xử lý.",
    "방송국 바로가기": "Đến Đài truyền hình",
    "즐겨찾기한 BJ의 유저클립": "Clip người dùng của BJ bạn yêu thích",
    "추천받지 않기": "Không nhận gợi ý ",
    "해당 방송을 더 이상 추천하지 않습니다.": "Chương trình phát sóng này không còn được khuyến khích.",

    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다. ":
        "Nếu bạn nhấn Yêu thích BJ thì bạn sẽ có thể xem phát sóng một cách thoải mái,<br/>và được nhận thông báo khi phát sóng bắt đầu, ",
    "BJ가 등록한 게시물이 없습니다.": "BJ này không đăng bài nào.",
    "구독한 BJ": "BJ đã đăng ký",
    "구독한 BJ 전체": "Tất cả BJ đã đăng ký",
    "구독 결제정보": "Thông tin thanh toán đăng ký",
    최신구독순: "Theo đăng ký",
    "구독 닉네임": "Biệt hiệu đăng ký",
    구독일: "Ngày",
    "{{month}}개월": "{{month}} Tháng",
    "회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)":
        "Bạn có muốn sử dụng biệt hiệu mặc định của mình {{default_nick}} không? \n(Bạn có thể thay đổi lại sau 24 giờ)",
    "구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다":
        "Nick của người đăng ký đã thay đổi. \nNếu bạn hiện đang tham gia phát sóng, Nick đã thay đổi sẽ được áp dụng khi bạn vào lại phát sóng.",
    "구독한 BJ가 없습니다.": "Không có BJ đăng ký.",
    "내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br/>구독팬만을 위한 아주 특별한 혜택을 만나보세요!":
        "Đăng ký BJ yêu thích của bạn và tận hưởng các lợi ích đặc biệt chỉ dành cho người đăng ký, <br/> bao gồm đăng ký phát sóng chính, biệt hiệu chỉ dành cho đăng ký và ký tự tượng hình!",
    유료: "Trả",

    "구독팬 전용 닉네임 변경": "Thay đổi biệt hiệu chỉ dành cho người đăng ký",
    "구독팬 전용 닉네임을 입력해주세요.": "Vui lòng nhập biệt hiệu chỉ dành cho người đăng ký.",
    중복확인: "重複確認",
    "한글 6자(영문 12자)까지 입력 가능": "Bạn có thể nhập tối đa 6 ký tự toàn độ rộng (12 ký tự nửa độ rộng)",
    "변경 시 24시간 후 재변경 가능": "Nếu đã thay đổi thì có thể đổi lại sau 24 giờ",
    "구독한 BJ방송 채팅에서 사용됩니다.": "Có thể được sử dụng trong cuộc trò chuyện truyền phát của BJ đã đăng ký.",
    변경: "Thay đổi",
    "기본 닉네임 사용": "Sử dụng biệt hiệu cơ bản",

    "현재 방송 중인 BJ가 없습니다.": "Không có BJ hiện đang phát sóng.",
    "방송국에 등록된 공지글이 없습니다.": "Không có bài thông báo được đăng ký trên kênh phát sóng.",
    "방송국에 등록된 게시글이 없습니다.": "Không có bài viết được đăng ký trên kênh phát sóng.",
    "방송국에 업로드된 VOD가 없습니다.": "Không có  VOD được tải lên kênh phát sóng.",
    "공지글 보기": "Xem bài thông báo",
    "게시글 보기": "Xem bài đăng",

    "즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.": "Không có bài đăng nào được đăng tải lên Đài truyền hình của BJ bạn yêu thích.",
    "BJ글만 보기": "Chỉ hiển thị bài viết của BJ",
    전체보기: "Xem All",
    "글만 보기": "Chỉ hiển thị bài viết",
    "이미지만 보기": "Chỉ hiển thị ảnh ",
    "VOD만 보기": "Chỉ hiển thị VOD",
    "태그에 대한 검색 결과가 없습니다.": "Không có kết quả tìm kiếm về tag này.",
    "{{num}}명 참여중": "{{num}}người đang xem",
    "{{num}}건": "{{num}}lượt",
    "조회 {{count}}": "Kiểm tra {{count}}",
    "핫 키워드": "Từ khóa hot",
    "실시간 인기글": "Bài viết nổi tiếng theo thời gian thực",
    "전체글 보기": "Hiển thị toàn bộ bài viết",
    "방송국 새 글": "Bảng tin",
    "해외에서 재생이 불가한 VOD입니다.": "VOD này không khả dụng tại nước ngoài.",
    "이어서 재생을 원하실 경우,<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "Trong trường hợp muốn phát,<br/>vui lòng chuyển đến trang chi tiết để tiếp tục xem.",
    "오류가 발생하여 재생이 불가합니다.": "Không thể phát do xảy ra lỗi.",
    "<0>{{nickname}}({{id}})</0><1>님의 방송국</1>": "<0>{{nickname}}({{id}})</0> <1>trên Blog</1>",
    고정하기: "Ghim",
    공유하기: "Chia sẻ",
    "주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요": "Đã sao chép địa chỉ. Hãy dán(Ctrl+V) vào nơi bạn muốn",
    공지: "Thông báo",
    "미리보기가 원활하지 않습니다.<br/>상세페이지로 이동 후 재생해주시기 바랍니다.":
        "Tính năng Xem trước không ổn định.<br/>Vui lòng chuyển đến trang chi tiết để tiếp tục xem.",

    "아프리카TV에서 생긴 일": "Chuyện xảy ra ở AfreecaTV",
    "아.생": "Vấn đề",
    투데이: "Hôm nay",
    "즐겨찾기한 BJ 글": "Bài viết yêu thích của BJ",
    "실시간 인기글 TOP 100": "Bài viết phổ biến thời gian thực TOP100",
    "TOP 100": "TOP 100",
    "{{rank}}위": "Cấp {{rank}}",
    "방송국 가기": "Đi đến Blog",
    "게시물 안보기": "Đừng xem bài",
    "게시물 안보기가 설정된 게시물입니다.": "Đây là bài viết không có bài viết được thiết lập.",
    "실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.":
        "Trong các bài đăng phổ biến thời gian thực, bài viết tương ứng của BJ Không được tiếp xúc.",
    "게시물 안보기 해제": "Hủy đừng xem bài",
    "핫이슈 동영상": "VOD nổi bật",
    "실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?":
        "Trong bài đăng phổ biến thời gian thực của {{user_nick}}\nBạn không muốn xem bài viết nữa?",
    "지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?":
        "BJ đặt để không xem bài viết là BJ yêu thích của bạn\nBạn có muốn xóa BJ khỏi mục yêu thích của bạn?",
    "로그인이 필요한 기능입니다.\n로그인 하시겠습니까?": "chức năng yêu cầu đăng nhập. \nBạn có muốn đăng nhập không?",
    "{{user_nick}}님의 게시물을 다시 보시겠습니까?": "bài viết của {{user_nick}} Bạn có muốn xem lại không?",
    "{{user_nick}}님의 게시물 안보기": "Không xem bài viết của {{user_nick}}",
    "네트워크에 문제가 있습니다.<br> 네트워크 상태를 확인해주세요.": "Có vấn đề với mạng. <br>Vui lòng kiểm tra trạng thái mạng.",
    "오늘의 움짤&유저클립": " GIF hôm nay & Clip người dùng",
    "닉네임 검색": "Tìm kiếm Nick",
    "아이디 검색": "Tìm kiếm ID",

    스토리: "Câu chuyện",
    "내 글·댓글": "Bài viết của tôi · Nhận xét",
    "스토리 내용이 없습니다.": "Không có nội dung câu chuyện.",
    "작성한 게시글, 댓글, 답글이 없습니다.": "Không có bài viết, bình luận hoặc trả lời nào được tạo.",
    "작성한 게시글이 없습니다.": "Không có bài viết nào được tạo.",
    "작성한 댓글이 없습니다.": "Không có bình luận nào được tạo.",
    "작성한 답글이 없습니다.": "Không có câu trả lời nào được viết.",
    "댓글 보기": "Xem bình luận",
    "답글 보기": "Xem trả lời",
    "{{nickname}}<1>님의 방송국</1>": "{{nickname}}<1>trên Blog</1>",
    "즐겨찾기 BJ별 알림 설정을<br/>PC에서도 할 수 있어요!": "Bạn có thể đặt cài đặt thông báo<br/>cho từng BJ yêu thích trên PC của mình!",
    "모든 즐겨찾기 BJ에게 알림을 받습니다.": "Nhận thông báo từ tất cả các BJ yêu thích.",
    "모든 즐겨찾기 BJ에게 알림을 받지 않습니다.": "Không nhận thông báo từ tất cả các BJ yêu thích.",
    "{{favorite_nick}}님에게 알림을 받습니다.": "Nhận được thông báo từ {{favorite_nick}}.",
    "{{favorite_nick}}님에게 알림을 받지 않습니다.": "Không nhận được thông báo từ {{favorite_nick}}.",
    "알림 받지 않기": "Không nhận thông báo",
    "알림 받기": "Nhận thông báo",
    "전체 알림": "Thông báo All",

    "선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?": "Bạn có muốn xóa bài đăng đã chọn (bao gồm cả nhận xét và trả lời) không?",
    "선택하신 댓글(답글 포함)을 삭제하시겠습니까?": "Bạn có muốn xóa bình luận đã chọn (bao gồm cả trả lời) không?",
    "선택하신 답글을 삭제하시겠습니까?": "Bạn có muốn xóa bình luận đã chọn không?",
    "삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.":
        "Khi bị xóa, nó sẽ bị xóa hoàn toàn khỏi câu chuyện và blog và không thể khôi phục lại được.",
    "게시글(댓글, 답글 포함) 삭제가 완료되었습니다.": "Bài viết (bao gồm cả bình luận và trả lời) đã bị xóa.",
    "댓글(답글 포함) 삭제가 완료되었습니다.": "Bình luận (bao gồm cả trả lời) đã bị xóa.",
    "답글 삭제가 완료되었습니다.": "Quá trình xóa trả lời đã hoàn tất.",
    삭제하기: "Xóa",
    
    "최근 본 방송": "Đã xem gần đây",
    "최근 본 VOD": "VOD đã xem gần đây",
    "UP한 VOD": "VOD đã UP",
    재생목록: "Danh sách phát",
    "최근 본 VOD 삭제": "Xóa các VOD đã xem gần đây",
    "아직 참여한 방송이 없습니다.": "Không có hồ sơ tham gia phát sóng.",
    "지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "Gặp gỡ các phát sóng thú vị và đa dạng của Afreeca TV ngay bây giờ!!",
    "LIVE 보러가기": "Đi để xem LIVE",
    "VOD 보러가기": "Đi để xem VOD",
    "최근 본 VOD가 없습니다.": "Không có VOD gần đây.",
    "나중에 보기 한 VOD가 없습니다.": "Không có VOD để xem sau.",
    "UP 한 VOD가 없습니다.": "Không có VOD được UP",
    "재생목록이 없습니다.": "Không có hồ sơ tham gia phát sóng..",
    "VOD 위치 변경 안내": "VOD hướng dẫn thay đổi vị trí.",
    "즐겨찾기한 BJ의 유저클립과 VOD는 새로워진 VOD 메뉴에서 만나보세요!": "Xem các clip và VOD người dùng BJ yêu thích của bạn từ menu VOD mới.",
    "즐겨찾기한 VOD 보러가기": "Đi đến để xem các video yêu thích của bạn",

    "UP 취소": "Hủy UP",
    "나중에 보기 삭제": "Xóa mục Xem sau",
    "재생한 VOD 삭제": "Xóa VOD đã xem",
    전체삭제: "Xóa toàn bộ",
    조회순: "Theo lượt phát",
    UP순: "Theo lượt UP",
    댓글순: "Theo lượt bình luận",
    "VOD 추가순": "Theo bổ sung VOD",
    오래된순: "Theo cũ nhất",
    최신순: "Recent",
    비공개: "Riêng tư",
    업데이트: "Cập nhật",
    "재생목록에 담기": "Thêm vào danh sách phát",
    수정하기: "Sửa chữa",

    "내 마음대로 만드는 플레이리스트!":"Danh sách nhạc của riêng tôi!",
    "테마/BJ별로 재생목록에 담아 편하게 시청하세요.":"Hãy thưởng thức danh sách phát theo chủ đề / BJ.",

    "새 재생목록 만들기": "Tạo danh sách phát mới",
    "제목을 입력해주세요.": "Vui lòng nhập chủ đề.",
    "목록 공개": "Danh sách công bố",
    "목록 비공개": "Danh sách riêng",
    "재생목록 저장 허용": "Cho phép trải rộng danh sách",
    "재생목록 저장 비허용": "Không cho phép nhúng danh sách",
    추가: "Thêm vào",
    "미리보기를 제공하지 않는 VOD": "Không có xem trước",
    "지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!": "Ngay bây giờ, gặp gỡ VOD vui nhộn và đa dạng của Afreeca TV!",
    "해당 BJ를 즐겨찾기에서 삭제하시겠습니까?" : "Bạn có muốn xóaBJ này khỏi mụcyêu thích không?",
    "재생목록을 삭제하시겠습니까?" : "Bạn có muốn xóa danh sách phát?",
    "편집":"Biên tập",
    "즐찾BJ 편집" : "Biên tập BJ yêu thích",
    "새 그룹 만들기" : "Tạo một nhóm mới",
    "그룹명" : "Tên nhóm",
    "그룹명을 입력해주세요." :"Vui lòng nhập tên nhóm.",
    "BJ 추가" : "Thêm BJ",
    "BJ 추가하기" : "Thêm BJ",
    "그룹에서 삭제" : "Xóa khỏi nhóm",
    "즐찾BJ 그룹에 담기" : "Lưu BJ yêu thích của bạn vào nhóm",
    "즐찾BJ 그룹" : "Nhóm BJ yêu thích",
    "{{num}}명 추가" : "Thêm {{num}} người",
    "해당 그룹을 삭제하시겠습니까?" : "Bạn có chắc chắn muốn xóa nhóm không?",
    "그룹 추가는 최대 150명까지입니다." : "Nhóm có thể được thêm lên đến 150 người.",
    "BJ를 추가해주세요." : "Thêm BJ",
    "편집할 그룹이 없습니다." : "Không có nhóm nào để biên tập",
    "BJ 그룹 기능을 이용하여<br/>원하는 주제별로 BJ를 정리해보세요!" : "Sử dụng chức năng nhóm BJ<br/>để sắp xếp BJ của bạn theo chủ đề!",
    "삭제" : "Xóa bỏ",
    "그룹 수정" : "Chỉnh sửa nhóm",
    "확인" : "Xác nhận",
    "구독전용" : "Chỉ đăng ký",
    "드롭스 이벤트 진행 중" : "Sự kiện đang Drops",
    "%d분 전": "%d phút trước",
    "%d시간 전": "%d giờ trước",
    "%d일 전": "%d ngày trước",
    "%d초 전": "%d giây trước",
};
