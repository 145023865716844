import SortButtonGroup from "components/button/SortButtonGroup";
import SubscribeItem from "components/item/SubscribeItem";
import TitleSection from "components/section/TitleSection";
import { POINT_URL } from "constant/config";
import { DESC, getSubscribeSort, OFFSET_SIZE, SUBSCIBE_NO } from "constant/sort";
import { compare_favorite, numberCommaString } from "helpers";
import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ErrorSection from "components/section/ErrorSection";
import LoadingSection from "components/section/LoadingSection";
import EmptySection from "components/section/EmptySection";
import MoreButton from "components/button/MoreButton";





export default function SubscribeListSection() {

    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(SUBSCIBE_NO);
    const [limit, setLimit] = useState(OFFSET_SIZE);


    // useSelector
    const { data, error, loading } = useSelector(state => (state.subscribeReducer), shallowEqual);

    // useMemo
    let filteredData = useMemo(() => {
        return data.slice().sort(compare_favorite(sortColumn, sortOrder)).slice(0, limit);
    }, [data, limit, sortColumn, sortOrder]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);



    if (error) {
        return <ErrorSection />
    }

    if (loading) {
        return <LoadingSection />;
    } else {
        if (data.length > 0) {
            return (<div className="favor-wrap ">
                <TitleSection
                    right={
                        <button type="button" className="payInfo" onClick={() => window.open(`${POINT_URL}/Subscription/SubscriptionList.asp`)}><span>{i18next.t('구독 결제정보')}</span></button>
                    }
                >
                    {i18next.t('구독한 BJ 전체')}
                    <span className="total">
                        ({i18next.t(`{{num}}명`, { num: numberCommaString(data.length) })})
                    </span>
                </TitleSection>


                <div className="sort-wrap">
                    <SortButtonGroup options={getSubscribeSort()} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                </div>


                <div className="favor-list subscribe">
                    <ul>
                        {filteredData.map((subscribe, index) =>
                            <li key={index}>
                                <SubscribeItem subscribe={subscribe} />
                            </li>
                        )}
                    </ul>


                    {data.length > limit && <MoreButton onClick={onClickMore} />}
                </div>
            </div>)
        } else {
            return <EmptySection />;
        }
    }
}
