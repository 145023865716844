import { destroyRecent } from "actions/recent.action";
import { recommandDel } from "actions/recommands.action";
import ThreeDotHistoryButton from "components/button/ThreeDotHistoryButton";
import ContextMenu from "components/ict/ContextMenu";
import LiveThumbnail from "components/thumbnail/LiveThumbnail";
import LaterViewButton from "components/vods/LaterViewButton";
import ItemCommon from "components/item/ItemCommon";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, RECENT_ROUTE, SUBSCRIBE_ROUTE, HISTORY_RECENT_ROUTE, HISTORY_LATER_ROUTE } from "constant/routeUrl";
import { makePlayUrl, numberCommaString, sendLogApi, sendRecommandLogApi, onErrorThumb, makeProfileUrl, makeStationUrl, getLanguageStr } from "helpers";
import i18next from "i18next";
import { liveInflowLog } from "libs/log";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-navigationbar";
import { LaterViewAddButton, fetchLaterDelete } from  "components/vods/LaterViewButton";
import ShareButton from "components/button/ShareButton";
import ProfileImage from "components/image/ProfileImage";

import useAutoHashTag from "components/hook/useAutoHashTag";

export default function LiveItem({ broad, recommand, recommandIds, onChangeLayer, onPosition, onCallBackChangeLayer  }) {
    // dispatch
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { userId } = useAuth();
    const { isLogin } = useAuth();
    const [ layer, setLayer ] = useState("");
    const [ broadNo, setBroadNo ] = useState(0);
    const [top, setTop] = useState(0);
    
    const { hash_tags = [] , category_tags = [], auto_hashtags = [] } = broad; //해시태그, 고정태그
    const { hashtagUrl } = ItemCommon();

    const { getTagStyle } = useAutoHashTag();

    /**
     * 방송 진입 로그 호출
     */
    const onClickPlayLink = useCallback(
        (broad) => (e) => {
            // watch_type=live&bj_id=ksks0908&broad_no=215326485&category_no=00130000&bps=5000&resolution=1920x1080
            // m=rin&t=json&v=1.0&uid=youja2016&w=webk&bj=22222ban&bno=218370035&ref=youja2016&ut=rpfv&logic=TR01006502:1572405527:19
            let sendData = {
                watch_type: "live",
                bj_id: broad.user_id,
                broad_no: broad.broad_no,
                category_no: broad.broad_cate_no,
                bps: broad.broad_bps,
                resolution: broad.broad_resolution,
            };
            let codeType = "";
            let paths = [];

            // inflow_path add data
            let etcData = {
                //'tag': hash_tags.join("|"),
                //'category_tag': category_tags.join("|"),
            }

            switch (pathname) {
                case FAVORITE_ROUTE:
                    codeType = "main_00000006";
                    paths = ["my", "fav", "onair"];
                    // etcData = {
                    //     'group_type': groupIdx ===0 ? 'all' : 'custom',
                    //     'group_name': groupIdx > 0 ? groupTitle : '',
                    // }
                    break;

                case SUBSCRIBE_ROUTE:
                    codeType = "main_00000007";
                    sendData["location"] = "subsbj";
                    paths = ["my", "subbj", "onair"];
                    break;

                case FANCLUB_ROUTE:
                    codeType = "main_00000007";
                    sendData["location"] = "fanbj";
                    paths = ["my", "fanbj", "onair"];
                    break;

                case RECENT_ROUTE:
                    codeType = "main_00000007";
                    sendData["location"] = "recent";
                    paths = ["my", "recent"];
                    break;

                default:
                    return false;
            }

            //클릭로그
            sendLogApi(codeType, sendData);
            

            //라이브유입로그
            liveInflowLog(broad.user_id, broad.broad_no, paths, etcData);

            //즐겨찾기 할만한 방송 로그
            if (recommand) {
                let sendRecommandData = {
                    m: "rin",
                    t: "json",
                    v: "1.0",
                    uid: userId,
                    w: "webk",
                    bj: broad.user_id,
                    bno: broad.broad_no,
                    ref: userId,
                    ut: "rpfv",
                    logic: broad.logic,
                };
                sendRecommandLogApi(sendRecommandData);
            }
        },
        [category_tags, hash_tags, pathname, recommand, userId],
    );

    /**
     * 추천받지 않기
     */
    const onClickRecommandDel = useCallback(
        (broad, recommandIds) => (e) => {
            e.preventDefault();
            dispatch(recommandDel(broad.user_id, broad.broad_no, broad.logic, recommandIds));
        },
        [dispatch],
    );

    /**
     * 최근 본 BJ의 LIVE 삭제
     */
    const onClickRecentDel = useCallback(
        (user_id, broad_no) => (e) => {
            e.preventDefault();
            dispatch(destroyRecent(user_id, broad_no));
        },
        [dispatch],
    );

    const handleLaterDeleteClick = useCallback((isLogin, titleNo, type) => (event) => {
        dispatch(fetchLaterDelete(isLogin, titleNo, type));
    }, [dispatch]);

    const handleClickLayer = useCallback((layer, broadNo) => (event) => {
        setLayer(layer);
        setBroadNo(broadNo);
        onChangeLayer(layer, broadNo);
    }, [onChangeLayer, setLayer, setBroadNo]);

    const handlePosition = useCallback((top, left) => {
        onPosition(top, left);
        setTop(top);
    }, [onPosition]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        onChangeLayer(layer, titleNo);
    }, [onChangeLayer]);

    const handleChangeStyle = useCallback((hoverCheck, style, hover) => (event) => {
        if(hoverCheck) {
            if(hover) {
                event.target.style.background = hover.hover.background;
            }
        } else {
            if(style) {
                event.target.style.background = style.background;
            }
        }
    }, []);

    /**
     * 라이브페이지 URL
     */
    const url = useMemo(() => makePlayUrl(broad.user_id, broad.broad_no), [broad.broad_no, broad.user_id]);

    const fanclub = useMemo(() => pathname === FANCLUB_ROUTE, [pathname]);
    const subscribe = useMemo(() => pathname === SUBSCRIBE_ROUTE, [pathname]);
    const recent = useMemo(() => pathname === HISTORY_RECENT_ROUTE, [pathname]);
    const later = useMemo(() => pathname === HISTORY_LATER_ROUTE, [pathname]);
    const favorite = useMemo(()=> pathname === FAVORITE_ROUTE, [pathname]);
    return (
        <>
            <div className="thumbs-box">
                {later && ( <span className="hilight">
                    LIVE
                </span>)}
                <LiveThumbnail href={url} target="_blank" onClick={onClickPlayLink(broad)} broad={broad} />
                {broad.broad_type === "40" && <span className="ppv">{i18next.t(`유료`)}</span>}
                {broad.is_visit_broad === true && <span className="allow">{i18next.t(`탐방허용`)}</span>}
                {broad.broad_type === "22" && <span className="vr360">vr 방송</span>}

                {!later && <span className="time">
                    {broad.broad_start.substr(5)} {i18next.t(`방송시작`)}
                </span>}
                {!later && <LaterViewButton type="LIVE" title_no={broad.broad_no} />}
            </div>
            <div className="cBox-info">
                <ProfileImage 
                    tag="a" 
                    className="thumb" 
                    src={makeProfileUrl(broad.user_id)}
                    url={makeStationUrl(broad.user_id)}
                    user_id={broad.user_id}
                    childrenTag="img"
                    childrenTagProps = {{
                        src:makeProfileUrl(broad.user_id),
                        loading: "lazy"
                    }}
                />
                <div className="details">
                    <ContextMenu user_id={broad.user_id} user_nick={broad.user_nick}>
                        <a href="#" className="nick">
                            <span>{broad.user_nick}</span>
                        </a>
                        <>
                            {broad.is_subscribe && <span className="ico_subscribe">{i18next.t(`구독`)}</span>}
                            {broad.is_fanclub && <span className="ico_fan">{i18next.t(`팬`)}</span>}
                        </>
                    </ContextMenu>
                    <span className="views">
                        <em>{numberCommaString(broad.total_view_cnt)}</em>
                    </span>
                </div>
                <h3>
                    <a href={url} target="_blank" onClick={onClickPlayLink(broad)} className="title">
                        {broad.is_drops && (
                            <>
                                <em className="ic_itemdrop">
                                    <i />
                                </em>
                                <i className="tip" tip={i18next.t(`드롭스 이벤트 진행 중`)}></i>
                            </>
                        )}
                        {broad.broad_title}
                    </a>

                    {(favorite || recommand || recent || later || fanclub || subscribe) && (
                        <ThreeDotHistoryButton
                            onPosition={handlePosition}
                            trigger={
                                <button type="button" className="more_dot">
                                    <span>더보기 메뉴</span>
                                </button>
                            }
                            onChangeLayer={handleChangeLayer}
                        >
                            {later && (
                                <>
                               
                                    <button type="button" onClick={handleClickLayer("SHARE", 0)}>
                                        <span>{i18next.t(`공유하기`)}</span>
                                    </button>
                                    <button type="button" onClick={handleLaterDeleteClick(isLogin, broad.broad_no, "live")}>
                                        <span>{i18next.t(`나중에 보기 삭제`)}</span>
                                    </button>
                                </>
                            )}
                            {(favorite || fanclub || subscribe || recent) && (
                                <>
                                    {/** 나중에 보기 */}
                                    <LaterViewAddButton no={broad.broad_no} type="LIVE" />
                                    <button type="button" onClick={handleClickLayer("SHARE", 0)}>
                                        <span>{i18next.t(`공유하기`)}</span>
                                    </button>
                                </>
                            )}

                            {recommand && (
                                <button type="button" onClick={onClickRecommandDel(broad, recommandIds)}>
                                    <span>{i18next.t(`추천받지 않기`)}</span>
                                </button>
                            )}
                            
                            {recent && (
                                <button type="button" onClick={onClickRecentDel(broad.user_id, broad.broad_no)}>
                                    <span>{i18next.t(`최근 본 BJ의 LIVE 삭제`)}</span>
                                </button>
                            )}

                            {(favorite && broad && !recommand) && (
                                <button type ="button" onClick ={onCallBackChangeLayer("FAVORITELIST", broad.user_id)}>
                                    <span > 
                                        {i18next.t(`즐찾BJ 그룹에 담기`)}
                                    </span>
                                </button> 
                            )}
                        </ThreeDotHistoryButton>
                    )}
                </h3>
                { layer === "SHARE" && <ShareButton item={broad} top={top} type="LIVE" layer={layer} onChangeLayer={handleClickLayer} setLayer={setLayer}/>}

                    {/** 고정 태그, 카테고리 태그 추가 */}
                    <div className="tag_wrap">
                        {auto_hashtags.length > 0 && auto_hashtags.map((hash, idx) => {
                            let tagStyle = getTagStyle(hash);
                            return hash !== "" && <a key={idx} href={hashtagUrl(hash, "LIVE")} style={tagStyle.style} 
                                onMouseOver={handleChangeStyle(true, tagStyle.style, tagStyle.events)} 
                                onMouseOut={handleChangeStyle(false, tagStyle.style, tagStyle.events)}>#{hash}</a>
                        })}           
                        {category_tags.length > 0 && category_tags.map((category, idx) => {
                            // [""] 으로 빈 값으로 들어오는 케이스가 있음
                            return category !== "" && <a key={idx} href={hashtagUrl(category, "LIVE")}>#{category}</a>
                        })}
                        {hash_tags.length > 0 && hash_tags.map((hash, idx) => {
                            return hash !== "" && <a key={idx} href={hashtagUrl(hash, "LIVE")}>#{hash}</a>
                        })}           
                    </div>
            </div>
        </>
    );
}
