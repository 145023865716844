import { FAVORITE } from "actions/favorite.action";
import { FAVORITE_REMOVE } from "actions/feed.favorite.action";
import produce from "immer";

const initialState = {
    isFavorite: true,
    loading: true,
    data: [],
    extdata: [],
    poolCheck: 0,
    error: null
};

const favoriteReducer = (state = initialState, action) => {
    let data, item = null;
    switch (action.type) {
        case FAVORITE.LOADING_START:
            return { ...state, loading: true, data: [] };

        case FAVORITE.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE.FETCH_SUCCESS:
            return { ...state, data: action.payload.data,  poolCheck : action.payload.pool_check,  error: null };
        

        case FAVORITE.FETCH_ERROR:
            return { ...state, error: action.payload };



        case FAVORITE_REMOVE:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(item => item.user_id === action.payload.favorite_id)
                if (index > -1) {
                    draftState.data.splice(index, 1);
                }
            });

        case FAVORITE.FILTER_PIN:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(item => item.favorite_no === action.payload.no)
                if (index > - 1) {
                    draftState.data[index].is_pin = action.payload.flag;
                }
            });
        
        case FAVORITE.SET_MOBILE_PUSH_SUCCESS:
            return produce(state, (draftState) => {
                const favorite_id = action.payload.favorite_id;
                const is_mobile_push = action.payload.flag === 1 ? "Y" : "N";
                if (favorite_id === "all") {
                    draftState.data.forEach((item) => item.is_mobile_push = is_mobile_push);
                } else {
                    const index = draftState.data.findIndex((item) => item.user_id === action.payload.favorite_id);
					if (index > -1) {
						draftState.data[index].is_mobile_push = is_mobile_push;
					}
                }
            });

        default:
            return state;
    }
};

export default favoriteReducer;