import { useEffect, useState, useContext } from 'react'
import { WindowSizeContext } from 'components/context/WindowSizeProvider';

export default function usePostsSildeCount() {
    const size = useContext(WindowSizeContext)
    const [count, setCount] = useState(3);

    useEffect(() => {
        if (size.width >= 2560) {
            setCount(4);
        } else {
            setCount(3);
        }
    }, [size.width]);

    return count;
}
