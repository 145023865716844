import { usePopup } from "components/context/PopupProvider";
import React, { useCallback, useMemo, useState } from "react";

export default function ThreeDotHistoryButton({ trigger, children, onPosition, onChangeLayer }) {
    const { isActivePopup, togglePopup } = usePopup();

    const [ top , setTop ] = useState(0);

    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            
            let layerTop = e.nativeEvent.pageY - 150;
            let layerLeft = e.nativeEvent.pageX - 550;

            if(window.innerWidth < layerLeft + e.clientX + 30) {
                layerLeft = layerLeft - 80;
            } 
            
            if(window.scrollY + document.documentElement.clientHeight > document.documentElement.scrollHeight - 300) {
                layerTop = layerTop - 200;
                setTop(0);
            } 

            onPosition(layerTop, layerLeft);
            onChangeLayer('', 0);
            togglePopup();
        },
        [onPosition, onChangeLayer, setTop, togglePopup],
    );
    /**
     * 버튼 이벤트 추가
     */
    const renderTrigger = useMemo(() => {
        const triggerProps = { key: "T", onClick: handleClick };
        if (typeof trigger === "function") {
            return React.cloneElement(trigger(), triggerProps);
        } else if (trigger.length > 1) {
            return React.Children.map(trigger, (child, index) => React.cloneElement(child, { ...triggerProps, key: index }));
        } else {
            return React.cloneElement(trigger, triggerProps);
        }
    }, [handleClick, trigger]);

    return (
        <>
            {/**  버튼 */}
            {renderTrigger}
            {isActivePopup && <div className="more-layer">{children}</div>}
        </>
    );
}
