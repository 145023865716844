import React, { useCallback, useEffect, useMemo, useState } from "react";
import i18next from "i18next";
import {isAdultType} from "helpers";

export default function CatchStoryThumbnail({ href, onClick, vod }) {
    const [imgStatus, setImgStatus] = useState(true);

    const { vertical_thumb, thumb, grade, category } = vod;

    const isAdult = isAdultType(grade,category);

    const handleError = useCallback(() => {
        setImgStatus(false);
    }, []);
    
    const thumbnail = useMemo(() => {

        if (isAdult === true) {
            return (
                <span className="thumb-adult"></span>
            );
        } else if (imgStatus === false) {
            return (
                <span className="thumb-default"></span>
            );
        }

        if(vod.resolution_type == "vertical"){
            return (
                <span className="thumb">
                    <img src={vertical_thumb} alt="" onError={handleError} loading="lazy"/>
                    <div className="thumb_blur" style={{ backgroundImage: `url(${vertical_thumb})`}}></div>
                </span>
            );
        } else if(vod.resolution_type == "horizontal"){
            return (
                <span className="thumb thumb_16_9">
                    <img src={thumb} alt="" onError={handleError} loading="lazy"/>
                    <div className="thumb_blur">
                        <img src={thumb} alt=""/>
                    </div>
                </span>
            );
        }
    }, [vod, imgStatus, href, onClick, handleError]);

    return (
        <>
            {thumbnail}
        </>
    );
}