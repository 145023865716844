import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import useOutsideClick from "components/hook/useOutsideClick";
import { fetchFavoriteGroup,destoryFavoriteGroup,updFavoriteGroupSort, FAVORITE_GROUP } from 'actions/favorite.group.action';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import i18next from 'i18next';
import { ReactSortable } from "react-sortablejs";
import {  sendLogApi } from 'helpers';
import FavoriteGroupProvider, {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";

export default function UpdFavoriteGroupsModal({modal, handleFixFavGroupModal, handleListFavGroupModal}){
    
   
    const currentRef = useRef(null);
    const dispatch = useDispatch();
    const { group_data} = useSelector(state => (state.favoriteGroupReducer), shallowEqual);
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);
    const [sortData, setSortData] = useState([]);
    const [isLoadSort, setIsLoadSort] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const handleGroupUpdateModal = (e, idx,title)=>{
        handleFixFavGroupModal("updgroup",true,title,idx, true);
        handleListFavGroupModal("updgroups",false, false);
    }
    const handleGroupUpdate = ()=>{
        
        let list = sortData.map((item)=>{
            return item['idx'];
        })
        let sortList =sortData;

        dispatch(updFavoriteGroupSort(list.join(",")))
        dispatch({type:FAVORITE_GROUP.FETCH_DATA,payload:sortList})
        handleListFavGroupModal("updgroups",false, true);
    }

    const handleGroupAddModal = (e)=>{
        sendLogApi("fav_grouping", {
            button_type:'create_method2',
            location: 'favorite',
        });
        setSortData([]);
        handleFixFavGroupModal("updaddgroup",true,"",0, true);
        handleListFavGroupModal("updgroups",false, false);
    }

    const handleCloseModal = (e)=>{
        if(isUpdate)   handleGroupUpdate();
        handleListFavGroupModal("updgroups",false, true);
    }

    const handleDelGroup = (e,idx)=>{
        e.nativeEvent.stopImmediatePropagation();
        if(window.confirm(i18next.t("해당 그룹을 삭제하시겠습니까?"))){
            
            sendLogApi("fav_grouping", {
                button_type:'delete_group',
                location: 'favorite',
            });
            //삭제하려는 idx를 sort쪽에서 제거
            let list = sortData.filter((item)=>(((item.idx!==idx))));
            setSortData(list);
            setIsUpdate(true);
            if(groupIdx ===idx){
                setGroupIdx(0);
            }
            dispatch(destoryFavoriteGroup(idx));
            dispatch({type:FAVORITE_GROUP.FETCH_DATA,payload:list})
        }
    }

    const setCallbackSortData =(sorting)=>{
        setSortData(sorting);
        
        setIsLoadSort(true);
        if(isLoadSort && (JSON.stringify(sorting) !== JSON.stringify(group_data)) ) {//객체 비교로 하려니까 shallow copy라서 비교못함
            
            setIsUpdate(true)
        }
    }

    useEffect(()=>{
        dispatch(fetchFavoriteGroup());
    },[])

    useEffect(()=>{
        setCallbackSortData(Object.assign(group_data));
      
    },[group_data])
  

    useOutsideClick(currentRef, () => {
        if(isUpdate){
            handleGroupUpdate()
        }
        
        handleFixFavGroupModal(modal.fixgroup.type,false,"",0,false);
        handleListFavGroupModal(modal.listgroup.type,false, true)
    });

    return(
        <div ref={currentRef} className = "FavGroup_layer">
            <div className="group_inner">
                <h2>{modal.listgroup.menuTitle}</h2>

                <div className="makeGroupList">
                    <div className="btn_newGroup">
                        {group_data.length <10 && <button type="button" onClick={()=>handleGroupAddModal()} >{i18next.t("새 그룹 만들기")}</button>}
                    </div>
                    <div className={"list_scroll " + (sortData.length ===0 ? "none" :"")}>
                        {sortData.length >0 &&
                        <ReactSortable
                            id={"drag_area"}
                            group={'shared'}
                            handle={'.move'}
                            animation = {150}
                            list = {sortData}
                            setList ={setCallbackSortData}
                            delay={1}
                        >

              
                            {sortData.map((item,index)=>{
                                return(
                                    <li key={"li"+item.idx}>
                                    <button type="button" className="move">위치이동</button>
                                    <p>{item.title} <em>({item.total_cnt})</em></p>
                                    <div className="rt">
                                        <button type="button" className="modify" onClick = {(e)=>handleGroupUpdateModal(e, item.idx,item.title)} >{i18next.t("그룹 수정")}</button>
                                        <button type="button" className="del" onClick ={(e)=>handleDelGroup(e, item.idx)}>{i18next.t("그룹 삭제")}</button>
                                    </div>
                                </li>
                                )
                            })}
                           
                        
                        </ReactSortable>
                        }
                        {//데이터가 없을경우
                            group_data.length ===0 &&
                            <p>{i18next.t("편집할 그룹이 없습니다.")}</p> 
                        }
                    </div>
                </div>

                {/* {<div className="btns">
                    <button type="submit" className="submit" onClick = {(e)=>handleGroupUpdate()} disabled= {isUpdate? false :true}>{i18next.t("저장") }</button>
                </div>
                } */}
                <button type="button" className="close" onClick={(e)=>handleCloseModal()}>레이어 닫기</button>
            </div>
        </div>
    )

}