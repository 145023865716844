export const HOME_ROUTE = "/";
export const FAVORITE_ROUTE = "/favorite";
export const SUBSCRIBE_ROUTE = "/subscribe";
export const FANCLUB_ROUTE = "/fanclub";
export const RECENT_ROUTE = "/recent";
export const FEED_ROUTE = "/feed";
export const STORY_ROUTE = "/story";
export const HOTISSUE_ROUTE = "/hotissue";
export const HOTISSUE_TODAY_ROUTE = "/hotissue/today";
export const HOTISSUE_POPULAR_ROUTE = "/hotissue/popular";
export const HISTORY_ROUTE = "/history";
export const HISTORY_RECENT_ROUTE = "/history/recent";
export const HISTORY_RECENT_VOD_ROUTE = "/history/recent/vod";
export const HISTORY_LATER_ROUTE = "/history/later";
export const HISTORY_UP_VOD_ROUTE = "/history/up/vod";
export const HISTORY_PLAYLIST_ROUTE = "/history/playlist";