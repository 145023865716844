import React, { useState, useCallback } from 'react'
import classNames from "classnames";

export default function SwiperController({ swiperRef, autoplay = true, onAutoPlay }) {

    // useState
    const [play, setPlay] = useState(true);
    /**
     * 중지 버튼
     */
    const onClickStopButton = useCallback((e) => {
        e.preventDefault();
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            if (play) {
                swiperRef.current.swiper.autoplay.stop();
            } else {
                swiperRef.current.swiper.autoplay.start();
            }
            setPlay(!play);
            onAutoPlay(!play);
        }

    }, [play, swiperRef, onAutoPlay]);

    /**
     * 이전 버튼
     */
    const onClickPrevButton = useCallback((e) => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slidePrev();
        }
    }, [swiperRef]);

    /**
     * 다음 버튼
     */
    const onClickNextButton = useCallback((e) => {
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            swiperRef.current.swiper.slideNext();
        }
    }, [swiperRef]);

    /**
     * 마우스 진입시 멈추기
     */
    const onMouseEnter = useCallback((e) => {
        if (!autoplay) {
            return
        }
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            if (play) {
                swiperRef.current.swiper.autoplay.stop();
            }
        }
    }, [autoplay, swiperRef, play]);

    /**
     * 마우스 나갈시 다시 재생
     */
    const onMouseLeave = useCallback((e) => {
        if (!autoplay) {
            return
        }
        if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
            if (play) {
                swiperRef.current.swiper.autoplay.start();
            }
        }
    }, [autoplay, swiperRef, play]);

    return (
        <div className="controller">
            {autoplay && <button type="button" className={classNames("moving", { stop: play })} onClick={onClickStopButton} ><span>멈춤/재생</span></button>}
            <button type="button" className="arrow-prev slide_vod_arrow-prev" tabIndex="0" aria-label="Previous slide"
                onClick={onClickPrevButton} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <span>이전 목록</span>
            </button>
            <button type="button" className="arrow-next slide_vod_arrow-next" tabIndex="1" aria-label="Next slide"
                onClick={onClickNextButton} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <span>다음 목록</span>
            </button>
        </div>
    )
}
