export const ROOT_URL = "//myapi.afreecatv.com/api";
export const STATION_API_URL = "//bjapi.afreecatv.com/api";
export const AFREECATV_DOMAIN = "afreecatv.com";
export const RES_AFREECATV_URL = "//res.afreecatv.com";
export const PROFILE_IMG_URL = "//profile.img.afreecatv.com";
export const STIMG_URL = "//stimg.afreecatv.com";
export const POINT_URL = "https://point.afreecatv.com";
export const STATION_URL = "//bj.afreecatv.com";
export const ITEM_URL = "//item.afreecatv.com";
export const PLAY_URL = "//play.afreecatv.com";
export const VOD_URL = "//vod.afreecatv.com";
export const ST_URL = "//st.afreecatv.com";
export const LOGIN_URL = "https://login.afreecatv.com";
export const EVENTAPI_URL = "//eventapi.afreecatv.com";
export const RECOMMAND_API_URL = "//ddukbob.afreecatv.com";
export const STBBS_URL = "//stbbs.afreecatv.com";

export const POPULAR_URL = "//my.afreecatv.com/hotissue/popular";
export const NOTE_URL = "//note.afreecatv.com/app/index.php?page=write";

export const LIVEIMG_URL = "//liveimg.afreecatv.com";

export const JAVASCRIPT_VOID = "javascript:void(0)";

// es indexer type에 따라 true, false (방송국 es는 true / 통검 es일 경우 false)
export const ES_INDEX_STATION = true; 