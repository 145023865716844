import { SUBSCRIBE } from "actions/subscribe.action";
import { checkNickOverlab, updateInitSubscribeNick, updateSubscribeNick } from "actions/subscribe.nicklayer.action";
import { toast } from "afreecatv-react-toast";
import classNames from "classnames";
import i18next from "i18next";
import React, { useCallback, useState } from "react";
import { useAuth } from "react-navigationbar";
import { useDispatch } from "react-redux";

export default function SubscribeNickLayer({ onClose, bj_id, sub_nick, default_nick }) {
    // dispatch
    const dispatch = useDispatch();
    const { userId } = useAuth();

    // useState
    const [nickname, setNickname] = useState(() => {
        return sub_nick ? sub_nick : default_nick;
    });

    const [message, setMessage] = useState("");

    /**
     * 구독 닉네임 변경값 저장
     */
    const onChangeNickname = useCallback(
        (e) => {
            setNickname(e.target.value);
        },
        [setNickname],
    );

    /**
     * 구독 닉네임 중복 확인
     */
    const onClickOverlap = useCallback(
        (e) => {
            e.preventDefault();

            checkNickOverlab(bj_id, nickname, userId)
                .then((response) => {
                    console.log(response.MESSAGE);
                    setMessage(i18next.t(response.MESSAGE));
                })
                .catch((error) => {
                    toast.error(i18next.t(`잠시후 다시 시도해주세요.`));
                });
        },
        [bj_id, nickname, userId],
    );

    /**
     * 구독 닉네임 변경
     */
    const onClickChange = useCallback(
        (e) => {
            e.preventDefault();

            updateSubscribeNick(bj_id, nickname, userId)
                .then((response) => {
                    setMessage("");
                    alert(
                        i18next.t(`구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다`),
                    );
                    dispatch({ type: SUBSCRIBE.UPDATE_SUBNICK, payload: { user_id: bj_id, sub_nick: response.MESSAGE } });
                    onClose();
                })
                .catch((error) => {
                    setMessage(i18next.t(error.message));
                });
        },
        [bj_id, dispatch, nickname, onClose, userId],
    );

    /**
     * 기본 닉네임으로 변경
     */
    const onClickDefaultNick = useCallback(
        (e) => {
            e.preventDefault();
            if (
                window.confirm(
                    i18next.t(`회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)`, {
                        default_nick: default_nick,
                    }),
                )
            ) {
                updateInitSubscribeNick(bj_id, userId)
                    .then((response) => {
                        setMessage("");
                        alert(
                            i18next.t(
                                `구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다`,
                            ),
                        );
                        dispatch({ type: SUBSCRIBE.UPDATE_SUBNICK, payload: { user_id: bj_id, sub_nick: response.MESSAGE } });
                        onClose();
                    })
                    .catch((error) => {
                        setMessage(error.message);
                    });
            }
        },
        [bj_id, default_nick, dispatch, onClose, userId],
    );

    return (
        <div className="layer_s gdnick_layer" style={{ display: "block" }}>
            <div className="layer_in">
                <strong className="title">{i18next.t(`구독팬 전용 닉네임 변경`)}</strong>
                <div className="layer_ac">
                    <span className="fts11">{i18next.t(`구독팬 전용 닉네임을 입력해주세요.`)}</span>
                    <br />
                    <span className={classNames("color_red", "fts11", { hide: message === null })}>{message}</span>
                </div>
                <div className="layer_ac pdt10">
                    <input
                        type="text"
                        title={i18next.t(`닉네임 입력`)}
                        className="input_text"
                        style={{ width: "120px" }}
                        onChange={onChangeNickname}
                        value={nickname}
                    />
                    <a href="#" className="btn_st2" onClick={onClickOverlap}>
                        {i18next.t(`중복확인`)}
                    </a>
                </div>
                <ul className="sub_list">
                    <li>{i18next.t(`한글 6자(영문 12자)까지 입력 가능`)}</li>
                    <li>{i18next.t(`변경 시 24시간 후 재변경 가능`)}</li>
                    <li>{i18next.t(`구독한 BJ방송 채팅에서 사용됩니다.`)}</li>
                </ul>
                <div className="btn_wrap_line">
                    <a href="#" className="btn_st1" onClick={onClickChange}>
                        {i18next.t(`변경`)}
                    </a>
                    <a href="#" className="btn_st2" onClick={onClickDefaultNick}>
                        {i18next.t(`기본 닉네임 사용`)}
                    </a>
                </div>
                <a href="#" className="btn_close2" onClick={onClose}>
                    {i18next.t(`닫기`)}
                </a>
            </div>
        </div>
    );
}
