import { useEffect } from "react";

let subsribes = [];
const reduxSubscribeMiddleware = store => next => action => {
    let result = next(action);


    subsribes
        .filter((subsribe) => subsribe.type === action.type)
        .map((subsribe) => {
            return subsribe.callback.call(null, action)
        })

    return result;
};
export default reduxSubscribeMiddleware;

/**
 * @use 
 *  useReudxSubsribe(FAVORITE_ADD, useCallback(() => {
 * 
 *  }, []));
 * 
 * @param {string} type 
 * @param {function} useCallback
 */
export const useReudxSubsribe = (type, callback) => {

    useEffect(() => {
        const count = subsribes.push({ type, callback });
        return () => {
            subsribes.splice((count - 1), 1);
        }
    }, [callback, type])

}
