import React, { useState, useEffect, useCallback } from 'react'

export const WindowSizeContext = React.createContext({});
/**
 * Window.Size 크기
 * @use const windowSize = useContext(WindowSizeContext);
 * @param {*} param0 
 */
export default function WindowSizeProvider({ children }) {
    const isClient = typeof window === 'object';

    const getSize = useCallback(() => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }, [isClient]);

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [getSize, isClient]);

    return (
        <WindowSizeContext.Provider value={windowSize} >
            {children}
        </WindowSizeContext.Provider>
    )
}