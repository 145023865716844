import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import Hls from "hls.js";
import useInstance from "components/hook/useInstance";
import { secondToTime, sendVodLog } from "helpers";
import { spring, Motion } from "react-motion";
import classNames from "classnames";
import LaterViewButton from "components/vods/LaterViewButton";
import Badge from "components/vods/Badge";
import VrBadge from "components/vods/VrBadge";
import { Trans } from "react-i18next";
import { hitFeed } from "actions/feed.action";
import { useDispatch } from "react-redux";

const hlsConfig = {
	debug: false,
	nudgeOffset: 0.3, //default 0.1s 였는데 BUFFER_NUDGE_ON_STALL 발생했을 때 currentTime을 더 많이 움직여 더빨리 찾도록 0.5로 늘림
	highBufferWatchdogPeriod: 1, //default 3s 였는데 BUFFER_NUDGE_ON_STALL 발생했을 때 너무 오래걸려서 1s로 줄임
	liveSyncDurationCount: 1, // 3 default
	manifestLoadingTimeOut: 10000,
	fragLoadingMaxRetry: 2, //재시도 횟수 여부
	manifestLoadingMaxRetry: 2, //재시도 횟수 여부
	levelLoadingMaxRetry: 2, //재시도 횟수 여부

	maxBufferLength: 3, //(default 600s)
	maxMaxBufferLength: 3, //(default 600s)
	stretchShortVideoTrack: true,
	autoStartLoad: true, //Hls.Events.MANIFEST_PARSED 이벤트가 트리거 된 후 시작
};

const MAX_PLAY_TIME = 60;
const STATUS = {
	PLAY: "play",
	PAUSE: "pause",
	ENDED: "ended",
};
export default function Player({ isRendered, playable, item, index, onPlay, onPause }) {
	// dispatch
	const dispatch = useDispatch();
	const hls = useInstance(() => {
		const _hls = new Hls(hlsConfig);
		_hls.on(Hls.Events.MANIFEST_PARSED, () => {
			videoRef.current.play();
		});
		_hls.on(Hls.Events.ERROR, (type, error) => {
			if (error.fatal) {
				setError(true);
			}
		});
		return _hls;
	});
	const [played, setPlayed] = useState(false);
	const [error, setError] = useState(false);
	const videoRef = useRef(null);
	const progressRef = useRef(null);
	const [progressWith, setProgressWith] = useState(0);
	const [status, setStatus] = useState(STATUS.PAUSE);
	const [startTime, setStartTime] = useState(0);
	const [exceptThumStatus, setExceptThumStatus] = useState(true);
	/**
	 * 재생 불가항목
	 */
	const isBlocking = useMemo(() => {
		if (item.is_ppv || item.ucc.is_adult || item.ucc.is_password === true || item.ucc.file_type === "SMR") {
			return true;
		} else {
			return false;
		}
	}, [item.is_ppv, item.ucc.file_type, item.ucc.is_adult, item.ucc.is_password]);

	/**
	 * hls 정리
	 */
	useEffect(() => {
		return () => {
			hls.destroy();
		};
	}, [hls]);

	/**
	 * 플레이시작 playable && isRendered
	 */
	useEffect(() => {
		// console.log("useEffect", videoRef.current.duration, videoRef.current.played.length)
		if (exceptThumStatus === false) {
			if (playable && isRendered && !isBlocking && item.ucc.hls) {
				if (videoRef.current.duration) {
					videoRef.current.play();
				} else {
					hls.loadSource(item.ucc.hls);
					hls.attachMedia(videoRef.current);
				}
			} else {
				if (videoRef.current.played.length) {
					videoRef.current.pause();
				}
			}
		}
	}, [exceptThumStatus, hls, isBlocking, isRendered, item.ucc.hls, playable]);

	/**
	 * 랜덤 시작시간
	 */
	const handleLoadedMetadata = useCallback(() => {
		let startTime = 0;
		if (videoRef.current.duration > MAX_PLAY_TIME * 2) {
			startTime = Math.floor(Math.random() * (videoRef.current.duration - MAX_PLAY_TIME));
			setStartTime(startTime);
		}
		videoRef.current.currentTime = startTime;

		//로그 호출
		sendVodLog(item, startTime);
		dispatch(hitFeed(item));
	}, [dispatch, item]);

	/**
	 * 플레이 시간 표시
	 */
	const displayTime = useCallback(() => {
		return `${secondToTime((videoRef.current && videoRef.current.currentTime) || 0)}`;
	}, []);

	/**
	 * 시간 업데이트
	 */
	const handleTimeUpdate = useCallback(
		(event) => {
			if (videoRef.current) {
				const playTime = Math.min(videoRef.current.currentTime, startTime + MAX_PLAY_TIME);
				const duration = Math.min(videoRef.current.duration, MAX_PLAY_TIME) || 1;
				setProgressWith(((playTime - startTime) / duration) * progressRef.current.clientWidth);

				//MAX_PLAY_TIME초 이상이면 자동중지
				if (videoRef.current.currentTime >= startTime + MAX_PLAY_TIME) {
					videoRef.current.pause();
					setPlayed(true);
				}
			} else {
				setProgressWith(0);
			}
		},
		[startTime],
	);

	/**
	 * 플레이 상태변경
	 */
	const handleStatusChange = useCallback((event) => {
		setStatus(event.type);
	}, []);

	/**
	 * 플레이버튼 클릭
	 */
	const handlePlayButtonClick = useCallback(
		(event) => {
			if (!isBlocking && !error) {
				if (status === STATUS.PLAY) {
					videoRef.current.pause();
				} else {
					//재생시간이 지났을경우 처음으로 이동
					if (videoRef.current.currentTime >= startTime + MAX_PLAY_TIME) {
						videoRef.current.currentTime = startTime;
					}
					videoRef.current.play();
					setPlayed(false);
					sendVodLog(item);
				}
				event.preventDefault();
			}
		},
		[error, isBlocking, item, startTime, status],
	);

	/**
	 * 영상클릭
	 * (영상 재생 일시정지후  a 링크이동)
	 */
	const handleVideoClick = useCallback(() => {
		if (status === STATUS.PLAY) {
			videoRef.current.pause();
		}
	}, [status]);

	/**
	 * 썸네일
	 */
	const getThumbClassName = useCallback((item) => {
		let thumbCalss = "";
		if (item.is_adult && item.is_password) {
			thumbCalss = "thumb-lock_adult";
		} else if (item.is_password) {
			thumbCalss = "thumb-lock";
		} else if (item.is_adult) {
			thumbCalss = "thumb-adult";
		} else {
			setExceptThumStatus(false);
		}
		return thumbCalss;
	}, []);

	const handleError = (e) => {
		console.log(item.title_no, e, e.nativeEvent);
	};

	return (
		<div className={classNames("file vod", { load: playable, error: error || played || isBlocking })}>
			{!exceptThumStatus && (
				<video
					ref={videoRef}
					className="video"
					id="video_p"
					autoPlay={true}
					muted={true}
					playsInline={true}
					onPlay={handleStatusChange}
					onPause={handleStatusChange}
					onEnded={handleStatusChange}
					onLoadedMetadata={handleLoadedMetadata}
					onTimeUpdate={handleTimeUpdate}
					onError={handleError}
					onWaiting={() => console.log("onWaiting")}
					onPlaying={() => console.log("onPlaying")}
					controls={false}
					preload="metadata"
					onClick={handleVideoClick}
					poster={item.ucc.thumb}
				></video>
			)}
			{exceptThumStatus && (
				<div className="thumb_wrap">
					<span className={getThumbClassName(item.ucc)}></span>
				</div>
			)}
			<Badge file_type={item.ucc.file_type} />
			<span className="time">
				{isBlocking === false && displayTime() + " / "}
				{secondToTime(item.ucc.total_file_duration / 1000)}
			</span>
			{isBlocking === false && (
				<div className="ctrl">
					<Motion style={{ width: spring(progressWith) }}>
						{(style) => (
							<div className="progress" ref={progressRef}>
								<div className="watched" style={style}></div>
							</div>
						)}
					</Motion>
				</div>
			)}
			{status === STATUS.PAUSE && (
				<button type="button" className="btn-play" onClick={handlePlayButtonClick}>
					재생
				</button>
			)}
			{item.is_ppv && <p className="text">상세페이지를 통해 재생이 가능한 VOD입니다.</p>}
			{item.ucc_is_abroad_blocking && (
				<p className="text">
					<Trans>해외에서 재생이 불가한 VOD입니다.</Trans>
				</p>
			)}
			{false && (
				<p className="text">
					<Trans>
						이어서 재생을 원하실 경우,
						<br />
						상세페이지로 이동 후 재생해주시기 바랍니다.
					</Trans>
				</p>
			)}
			{error && (
				<p className="text">
					<Trans>
						미리보기가 원활하지 않습니다.
						<br />
						상세페이지로 이동 후 재생해주시기 바랍니다.
					</Trans>
				</p>
			)}
			{item.ucc.file_type === "SMR" && (
				<p className="text">
					<Trans>
						TV클립은 미리보기가 지원되지 않습니다.
						<br />
						상세페이지로 이동 후 재생해주시기 바랍니다.
					</Trans>
				</p>
			)}
			<LaterViewButton title_no={item.title_no} />
			<VrBadge ucc_type={item.ucc.ucc_type} />
		</div>
	);
}
