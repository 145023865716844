import ContextMenu from "components/ict/ContextMenu";
import LaterViewButton from "components/vods/LaterViewButton";
import { onErrorThumb, secondToTime } from "helpers";
import { vodInflowLog } from "libs/log";
import React, { useCallback } from "react";

export default function VodClip({ className = "issueVod", vod, paths }) {
    const handleLog = useCallback(
        (item) => (e) => {
            //유입로그
            vodInflowLog(item.station_user_id, item.title_no, paths);
        },
        [paths],
    );

    return (
        <div className={className}>
            <a href={vod.url} target="_blank" onClick={handleLog(vod)}>
                <div className="thumb">
                    <img src={vod.ucc.thumb} alt="" loading="lazy" onError={onErrorThumb} />
                    <span className="time">{secondToTime(vod.ucc.total_file_duration / 1000)}</span>
                    <LaterViewButton title_no={vod.title_no} />
                </div>
                <strong className="subject">{vod.title_name}</strong>
            </a>
            <div className="nick_wrap">
                {/* 유저클립 업로더 ICT */}
                <ContextMenu user_id={vod.user_id} user_nick={vod.user_nick}>
                    <a href="" className="nick">
                        {vod.user_nick}
                    </a>
                </ContextMenu>

                {/* 유저클립 원저작자 ICT */}
                {vod.copyright && <div className="clip_nick">
                    <ContextMenu user_id={vod.copyright.user_id} user_nick={vod.copyright.user_nick}>
                        <a href="">{vod.copyright.user_nick}</a>
                    </ContextMenu>
                </div>}
            </div>
        </div>
    );
}
