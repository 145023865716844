import { ROOT_URL } from "constant/config";
import { handleFetchErrors } from "helpers";
import { POST } from "constant/method";
import querystring from "querystring";
import cookie from "react-cookies";
import { toast } from "afreecatv-react-toast";


export const FEED_LIKE = {
    LIKE: "like/LIKE",
    UNLIKE: "like/UNLIKE",
}

/**
 * 방송국 새 글 좋아요
 */
export function feedLike(bj_id, title_no) {
    return (dispatch) => {


        fetch(`${ROOT_URL}/feed/like/${bj_id}/${title_no}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({}),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FEED_LIKE.LIKE,
                    title_no
                });
                toast.success(response.message);
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };
}

/**
 * 방송국 새 글 좋아요 해제
 */
export function feedUnLike(bj_id, title_no) {
    return (dispatch) => {


        fetch(`${ROOT_URL}/feed/like/${bj_id}/${title_no}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: 'delete', }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FEED_LIKE.UNLIKE,
                    title_no
                });
                toast.success(response.message);
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };
}
