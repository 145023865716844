export const ItemCommon = () => {


    // 해시태그 검색 페이지
    const hashtagUrl = (tagName, hashType) => {
        const _hashType = hashType.toLowerCase();

        return `https://www.afreecatv.com?hash=hashtag&tagname=${tagName}&hashtype=${_hashType.toLowerCase()}&stype=hash&acttype=total&location=my_main`;
    }


    return {hashtagUrl};
}

export default ItemCommon;
