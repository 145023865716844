import { fetchPopulars } from "actions/feed.action";
import ContextMenu from "components/ict/ContextMenu";
import Badge from "components/vods/Badge";
import LaterViewButton from "components/vods/LaterViewButton";
import VrBadge from "components/vods/VrBadge";
import { POPULAR_URL } from "constant/config";
import { secondToTime, numberCommaString } from "helpers";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { STATION_URL } from "constant/config";
import PopularImage from "components/image/PopularImage";
import { decodeEmoticon } from "libs/emoticon";
import ProfileImage from "components/image/ProfileImage";
import { makeProfileUrl } from 'helpers';

export default function Popular() {
	const [populars, setPopulars] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		fetchPopulars().then((response) => {
			setPopulars(response.data);
		});
	}, []);

	return (
		<div className="realTime_post">
			<h3 className="title">
				<Trans>실시간 인기글</Trans>
				{i18next.language !== "en" && i18next.language !== "th" && (
					<a href={POPULAR_URL} target="_blank">
						<Trans>전체글 보기</Trans>
					</a>
				)}
			</h3>
			{populars.length > 0 && (
				<div className="post_list">
					{populars.map((item, index) => (
						<div className="list-item" key={index}>
							<div className="autor_wrap">
								<ProfileImage 
                                    tag="span"
                                    className="thum"
                                    user_id={item.user_id}
									src={makeProfileUrl(item.user_id)}
                                    childrenTag="a"
                                    innerChildrenTag="img"
                                    childrenTagProps = {{
                                        href:`${STATION_URL}/${item.user_id}`,
                                        target:"_blank"
                                    }}
                                    innerChildrenTagProps = {{
                                        src:item.profile_image,
                                        loading: "lazy"
                                    }}
                                    />
								<div className="info_box">
									<ContextMenu user_id={item.user_id} user_nick={item.user_nick}>
										<button type="button" className="nick">
											<p>{item.user_nick}</p>
										</button>
									</ContextMenu>
									<span>{item.reg_date}</span>
									<span>{t("조회 {{count}}", { count: numberCommaString(item.count.read_cnt) })}</span>
								</div>
							</div>
							<div className="item_contents">
								<a href={item.url} target="_blank">
									<h3>{item.title_name}</h3>
									<p className="text" dangerouslySetInnerHTML={{ __html: decodeEmoticon(item.content) }}></p>
								</a>
							</div>

							{item.board_type === 105 && (
								<div className="file vod">
									<img className="image" src={item.ucc.thumb} alt="" loading="lazy" />
									<Badge file_type={item.ucc.file_type} />
									<span className="time">{secondToTime(item.ucc.total_file_duration / 1000)}</span>
									<button type="button" className="btn-play">
										재생
									</button>
									<LaterViewButton title_no={item.title_no} />
									<VrBadge ucc_type={item.ucc.ucc_type} />
								</div>
							)}

							<PopularImage href={item.url} photo={item.photos} target="_blank" />
						</div>
					))}
				</div>
			)}
		</div>
	);
}
