import { fetchStory, initStory } from "actions/story.action";
import SelectBox from "components/form/SelectBox";
import { CHILD_COMMENT, getStoryOptions, PARENT_COMMENT, POST_PHOTO } from "constant/feedType";
import { STORY_ROUTE } from "constant/routeUrl";
import { goLogin } from "helpers";
import querystring from "querystring";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-navigationbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import CellMeasurer from "react-virtualized/dist/es/CellMeasurer";
import CellMeasurerCache from "react-virtualized/dist/es/CellMeasurer/CellMeasurerCache";
import List from "react-virtualized/dist/es/List";
import WindowScroller from "react-virtualized/dist/es/WindowScroller";
import StoryItem from "./item/StroyItem";
import MidBanner from "pages/banner/MidBanner";
import useAdBanner from "components/hook/useAdBanner";

const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 123,
    defaultHeight: 220,
});

export default function Story() {
    const { t } = useTranslation();
    const history = useHistory();
    const { isLogin } = useAuth();
    const [isLoad, setIsLoad] = useState(false);
    // dispatch
    const dispatch = useDispatch();
    const { loading, meta, data = [] } = useSelector((state) => state.storyReducer);
    const windowScrollerRef = useRef(null);
    const listRef = useRef(null);
    const location = useLocation();
    const { renderBanner } = useAdBanner();
    const params = useMemo(() => querystring.parse(location.search.substr(1)), [location.search]);
    const [feedType, setFeedType] = useState(params.feedType || "");
    const isEmpty = useMemo(() => {
        return meta.total < 20 && data.length === 0; //삭제되었는데 검색엔진에 반영되었을 경우(meta.total < 20)
    }, [data.length, meta.total]);

    const [ top, setTop ] = useState(0);
    const [ left, setLeft ] = useState(0);

    const [layer, setLayer] = useState("");
    const [titleNo, setTitleNo] = useState(0);

    /**
     * 최초로딩
     */
    useEffect(() => {
        if (!isLogin) {
            goLogin();
        }
    }, [isLogin]);

    /**
     * 최초로딩
     */
    useEffect(() => {
        initSetting();
        setIsLoad(true);
    }, [dispatch, feedType]);

    //최초 진입 이후 LNB 동일 영역 클릭시 동작
    useEffect(()=>{
        if(isLoad && location.pathname === STORY_ROUTE){
            window.location.reload();
        }
    }, [location.key])

    const initSetting = () =>{
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
            //https://developer.mozilla.org/ko/docs/Web/API/History/scrollRestoration
        }
        _cache.clearAll();
        dispatch(initStory());
        dispatch(fetchStory({ page: 1, feedType }));
    
    }

    /**
     * 컨텐츠의 변경이 있으면
     */
    useEffect(() => {
        _cache.clearAll();
    }, [isEmpty]);

    /**
     * 더보기
     * @param {*} param0
     */
    const handleRowRendered = ({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex }) => {
        if (
            loading === false && //데이터 로딩중이 아니고
            overscanStopIndex >= data.length - 1 && //하단으로 스트롤 되고
            meta.current_page < meta.last_page
        ) {
            //다음페이지가 있을 경우
            dispatch(fetchStory({ page: meta.current_page + 1, feedType }));
        }
    };

    /**
     * 삭제등 데이터가 변경되었을때 위치조정
     */
    useEffect(() => {
        _cache.clearAll(); //Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
        if (listRef.current) {
            listRef.current.recomputeRowHeights(); //We need to recompute the heights
        }
    }, [data, dispatch, feedType, meta.current_page, meta.last_page]);

    const handlePosition = useCallback((top, left) => {
        setTop(top);
        setLeft(left);
    }, [setTop, setLeft]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        setLayer(layer);
        setTitleNo(titleNo);
    }, [setLayer, setTitleNo]);

    const _rowRenderer = useCallback(
        (opt) => {
            const { index, key, style, parent } = opt;
            const item = data[index];
            return (
                <CellMeasurer cache={_cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
                    {({ measure }) => <StoryItem measure={measure} index={index} item={item} style={style} onChangeLayer={handleChangeLayer} onPosition={handlePosition}/>}
                </CellMeasurer>
            );
        },
        [data],
    );    

    const noList = useMemo(() => {
        switch (feedType) {
            case POST_PHOTO:
                return (
                    <div className="non-story">
                        <p>{t("작성한 게시글이 없습니다.")}</p>
                    </div>
                );
            case PARENT_COMMENT:
                return (
                    <div className="non-story">
                        <p>{t("작성한 댓글이 없습니다.")}</p>
                    </div>
                );
            case CHILD_COMMENT:
                return (
                    <div className="non-story">
                        <p>{t("작성한 답글이 없습니다.")}</p>
                    </div>
                );
            default:
                return (
                    <div className="non-story">
                        <strong>{t("스토리 내용이 없습니다.")}</strong>
                        <p>{t("작성한 게시글, 댓글, 답글이 없습니다.")}</p>
                    </div>
                );
        }
    }, [feedType, t]);

    const handleFeedTypeChange = useCallback(
        (feedType) => {
            setFeedType(feedType);
            history.push({ search: `?feedType=${feedType}` });
        },
        [history],
    );

    return (
        <div id="list-section" className="my-story">
            <div className="title-wrap line_none">
                <h2 className="big">{t("내 글·댓글")}</h2>
                <div className="rt_group">
                    <SelectBox options={getStoryOptions()} value={feedType} onChange={handleFeedTypeChange} />
                </div>
            </div>
            {/* 서브브랜딩 배너 추가 */}
            <MidBanner content={renderBanner}></MidBanner>
            {loading && meta.current_page === undefined ? (
                <div className="af_loading story"></div>
            ) : (
                <>
                    {!isEmpty && (
                        <ul className="story-list" ref={windowScrollerRef}>
                            {/** 게시글 **/}
                            <WindowScroller>
                                {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                                    <AutoSizer disableHeight>
                                        {({ width }) => {
                                            return (
                                                <div ref={registerChild}>
                                                    <List
                                                        height={height}
                                                        isScrolling={isScrolling}
                                                        onScroll={onChildScroll}
                                                        scrollTop={scrollTop}
                                                        ref={listRef}
                                                        autoHeight
                                                        width={width}
                                                        estimatedRowSize={3}
                                                        rowRenderer={_rowRenderer}
                                                        overscanRowCount={3}
                                                        rowCount={data.length}
                                                        rowHeight={_cache.rowHeight}
                                                        onRowsRendered={handleRowRendered}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </AutoSizer>
                                )}
                            </WindowScroller>
                        </ul>
                    )}

                    {isEmpty && noList}
                </>
            )}
        </div>
    );
}
