exports.lang = {
    kr: "zh-TW",
    아프리카TV: "AfreecaTV",
    방송국: "直播間",
    전체: "全部",
    탐방허용: "允許參觀直播",
    "쪽지 보내기": "發送短信",
    즐겨찾기: "關注",
    "차단된 팝업을 허용해주세요.": "請允許阻止的視窗。",
    "즐겨찾기가 추가되었습니다.": "添加關注",
    "잠시후 다시 시도해주세요.": "伺服器連接失敗。稍後請重新嘗試。",
    "즐겨찾기가 삭제되었습니다.": "刪除關注成功",
    "목록 상단 고정 완료 되었습니다.": "置頂設置成功。",
    "목록 상단 고정 해제 되었습니다.": "解除置頂設置",
    "이미 처리중 입니다.": "正在處理中",
    "나중에 보기 목록에 등록 되었습니다.\n목록에서 바로 확인 하시겠습니까?": "添加成功。\n要現在確認嗎？",
    "방송 중": "直播中",
    정렬보기: "排列方式",
    참여인원순: "收看人數排序",
    최신등록순: "最新上傳排序",
    최신방송순: "最新直播排序",
    새로고침: "刷新",
    방송시작: "開始直播",
    "즐겨찾기 삭제": "刪除",
    추가하기: "",
    "나중에 보기": "下次再看",
    더보기: "查看更多",
    이전: "上一個",
    다음: "下一個",
    다시보기: "重播回放",
    하이라이트: "精華影片",
    "팬가입한 BJ": "加入粉絲團主播",
    "팬가입한 BJ 전체": "所有粉絲圖主播",
    "팬클럽 가입순": "加入日期排序",
    닉네임순: "暱稱排序",
    아이디순: "賬號字母排序",
    "팬클럽 가입일": "加入日期",
    최근방송: "最近直播",
    "즐겨찾기 해제": "刪除關注",
    "즐겨찾기 추가": "添加關注",
    "LIVE 참여하기": "收看直播",
    "목록 상단해제": "解除置頂",
    "목록 상단고정": "目錄置頂",
    "가입한 팬클럽이 없습니다.": "未加入粉絲團",
    "즐겨찾기한 BJ": "關注",
    "즐겨찾기 전체": "全部",
    "{{num}}명": "{{num}}人",
    "즐겨찾기한 BJ가 없습니다.": "沒有關注的主播",
    "최근 본 BJ의 LIVE": "最近觀看BJ的直播",
    최근참여순: "最近收看排序",
    "최근 본 BJ의 LIVE 삭제": "刪除最近收看的BJ直播",
    "최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?" : "最近要將最近收看的BJ直播內容全部刪除嗎？",
    "해당 방송이 최근 본 방송에서 삭제되었습니다.": "已將此直播從最近看過直播中刪除。",
    "지금 바로, 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "現在馬上體驗AfreecaTV的多彩而有趣的直播。",
    닫기: "關閉",
    "즐겨찾기한 BJ의 VOD": "已關注主播的影片",
    "즐겨찾기한 BJ의 Catch": "關注BJ的Catch",
    "팬가입한 BJ의 VOD": "粉絲團主播的影片",
    "구독한 BJ의 VOD": "訂閱BJ的VOD",
    "즐겨찾기 할 만한 방송": "你可以感興趣的直播",
    "즐겨찾기할 만한 BJ": "推薦關注",
    "BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!<br/>팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.":
        "贈送主播星氣球即可加入主播的粉絲團！<br/>粉絲團可以使用粉絲團專用告示板，<br/>並且在聊天欄內會賦予區別於其他用戶的字體顏色。",
    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다.":
        "關注喜歡的主播可以隨時方便的查詢直播，<br/>直播開始時提示您收看。",
    "즐겨찾기 BJ글": "關注主播的文章",
    "나중에 보기에 등록되었습니다.": "添加下次再看完成",
    "보고 싶은 VOD나, 아직 보지 않은<br/>VOD를 목록에 등록해 두고  언제든 간편하게 찾아보세요!":
        "添加感興趣的或還未收看的，<br/>影片到列表，有空時再收看！",
    "라이브 방송을 참여할 수 없는 상황일 때<br/>목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!":
        "不方便收看直播時，<br/> 可以添加到列表，有空時在收看！",
    "<0>VOD의 “나중에 보기” 메뉴</0>에서<br/>등록 한 영상을 확인 하실 수 있습니다.":
        '<0>添加影片到"下次再看"後，</0> 在<br/>列表中可以確認。',
    다시보지않기: "不再顯示",
    "레이어 닫기": "關閉畫面",
    "나중에 보기에 등록된 VOD입니다.": "成功設定下次再看此視頻。",
    "일시적인 오류 입니다. 잠시 후 다시 시도해 주세요": "發生錯誤。請稍後重新嘗試。",
    "나중에 보기 리스트는 최대 1,000개 까지 등록 가능합니다.": "添加下次再看的直播上線為1,000個。",
    "나중에 보기를 지원하지 않는 영상입니다.": "此影片不支持[下次再看]。",
    "VOD 정보가 없습니다.": "沒有影片信息。",
    "게시물이 존재하지 않습니다": "公告不存在。",
    "처리 되었습니다.": "設置成功",
    "방송국 바로가기": "訪問直播間",
    "즐겨찾기한 BJ의 유저클립": "關注主播的用戶剪輯",
    "추천받지 않기": "刪除",
    "해당 방송을 더 이상 추천하지 않습니다.": "不再推薦此直播。",

    "좋아하는 BJ를  즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,<br/>방송 시작 시 알림을 받을 수 있습니다. ":
        "關注主播，即可隨時在Feed上<br/> 確認此主播上傳的所有內容。",
    "BJ가 등록한 게시물이 없습니다.": "暫無主播上傳的文章。",
    "구독한 BJ": "訂閱BJ",
    "구독한 BJ 전체": "全部訂閱的BJ",
    "구독 결제정보": "訂閱結帳資訊",
    최신구독순: "最新訂閱排序",
    "구독 닉네임": "訂閱暱稱",
    구독일: "訂閱日",
    "{{month}}개월": "{{month}} 個月",
    "회원님의 기본 닉네임 \n{{default_nick}}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)":
        "繼續使用暱稱{{default_nick}}嗎？ \n(24小時後可以再更改)",
    "구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다":
        "已更改訂閱者暱稱。\n重新進入直播即可使用更改的暱稱。",
    "구독한 BJ가 없습니다.": "暫無訂閱的主播。",
    "내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br/>구독팬만을 위한 아주 특별한 혜택을 만나보세요!":
        "訂閱我喜歡的BJ，享受直接進入BJ直播間、<br/>使用訂閱者暱稱、專屬表情等訂閱者獨有的優惠！",
    유료: "付費",

    "구독팬 전용 닉네임 변경": "更改訂閱者專用暱稱",
    "구독팬 전용 닉네임을 입력해주세요.": "請輸入訂閱者專用暱稱。",
    중복확인: "重複確認",
    "한글 6자(영문 12자)까지 입력 가능": "最多可輸入6個中文字(12個英文字母)",
    "변경 시 24시간 후 재변경 가능": "須於24小時後才可再次更改",
    "구독한 BJ방송 채팅에서 사용됩니다.": "將適用於訂閱BJ的聊天室。",
    변경: "更改",
    "기본 닉네임 사용": "使用基本暱稱",

    "현재 방송 중인 BJ가 없습니다.": "目前沒有直播中的BJ",
    "방송국에 등록된 공지글이 없습니다.": "暫無公告",
    "방송국에 등록된 게시글이 없습니다.": "暫無文章",
    "방송국에 업로드된 VOD가 없습니다.": "暫無上傳影片。",
    "공지글 보기": "查看公告",
    "게시글 보기": "查看文章",

    "즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.": "關注主播的直播間內暫無任何文章。",
    "BJ글만 보기": "只顯示主播的文章",
    전체보기: "查看全部",
    "글만 보기": "只顯示文章",
    "이미지만 보기": "只顯示圖片",
    "VOD만 보기": "查看影片",
    "태그에 대한 검색 결과가 없습니다.": "沒有標籤的相關搜尋結果。",
    "{{num}}명 참여중": "{{num}}人收看中",
    "{{num}}건": "{{num}}項",
    "조회 {{count}}": "次 {{count}}",
    "핫 키워드": "熱門搜尋詞",
    "실시간 인기글": "實時人氣文章",
    "전체글 보기": "查看全部",
    "방송국 새 글": "最新信息",
    "해외에서 재생이 불가한 VOD입니다.": "韓國境外用戶限制收看此影片。",
    "이어서 재생을 원하실 경우,<br/>상세페이지로 이동 후 재생해주시기 바랍니다.": "如要繼續收看，<br/> 請前往詳細頁面後播放。",
    "오류가 발생하여 재생이 불가합니다.": "",
    "<0>{{nickname}}({{id}})</0><1>님의 방송국</1>": "<0>{{nickname}}({{id}})</0><1>的直播間</1>",
    고정하기: "固定",
    공유하기: "分享",
    "주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요": "已複製。請粘貼到想要粘貼的位置（Ctrl+V）。",
    공지: "公告",
    "미리보기가 원활하지 않습니다.<br/>상세페이지로 이동 후 재생해주시기 바랍니다.": "預覽失敗。<br/>請前往詳細頁面後播放。",

    "아프리카TV에서 생긴 일": "在AfreecaTV 發生的事情",
    "아.생": "愛菲咖故事",
    투데이: "今日",
    "즐겨찾기한 BJ 글": "關注主播的文章",
    "실시간 인기글 TOP 100": "實時人氣文章 TOP 100",
    "TOP 100": "TOP 100",
    "{{rank}}위": "第{{rank}}",
    "방송국 가기": "前往直播間",
    "게시물 안보기": "隱藏此文章",
    "게시물 안보기가 설정된 게시물입니다.": "此文章已設置為隱藏。",
    "실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.": "實時人氣文章中 不顯示此主播的文章。",
    "게시물 안보기 해제": "解除隱藏文章。",
    "핫이슈 동영상": "熱門影片",
    "실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?": "在實時人氣文章中不顯示 {{user_nick}}的 文章嗎？",
    "지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?":
        "設置為隱藏的主播是已關注的主播。\n從關注列表中刪除此主播嗎？",
    "로그인이 필요한 기능입니다.\n로그인 하시겠습니까?": "需要登入才能使用此功能。\n要登入嗎？",
    "{{user_nick}}님의 게시물을 다시 보시겠습니까?": "要查看{{user_nick}}的 文章嗎？",
    "{{user_nick}}님의 게시물 안보기": "不查看{{user_nick}}的文章。",
    "네트워크에 문제가 있습니다.<br> 네트워크 상태를 확인해주세요.": "網絡發生問題。<br>請確認網絡環境。",
    "오늘의 움짤&유저클립": "今天 模因&用戶編輯",
    "닉네임 검색": "暱稱搜尋",
    "아이디 검색": "ID搜索",

    스토리: "故事",
    "내 글·댓글": "我的帖子·評論",
    "스토리 내용이 없습니다.": "暫無故事。",
    "작성한 게시글, 댓글, 답글이 없습니다.": "暫無文章，留言和回覆。",
    "작성한 게시글이 없습니다.": "暫無文章。",
    "작성한 댓글이 없습니다.": "暫無留言。",
    "작성한 답글이 없습니다.": "暫無回覆。",
    "댓글 보기": "查看留言",
    "답글 보기": "查看回覆",
    "{{nickname}}<1>님의 방송국</1>": "{{nickname}}<1>的直播間</1>",
    "즐겨찾기 BJ별 알림 설정을<br/>PC에서도 할 수 있어요!": "關注BJ的提示<br/>也可以於PC中進行設定。",
    "모든 즐겨찾기 BJ에게 알림을 받습니다.": "接收所有主播的提示音。",
    "모든 즐겨찾기 BJ에게 알림을 받지 않습니다.": "不接收所有主播的提示音。",
    "{{favorite_nick}}님에게 알림을 받습니다.": "開啟來自於{{favorite_nick}}的提示。",
    "{{favorite_nick}}님에게 알림을 받지 않습니다.": "關閉來自於{{favorite_nick}}的提示。",
    "알림 받지 않기": "關閉提示",
    "알림 받기": "開啟提示",
    "전체 알림": "全部提示",

    "선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?": "確定刪除此文章（含留言及回覆）嗎？",
    "선택하신 댓글(답글 포함)을 삭제하시겠습니까?": "確定刪除所選留言(含回覆)嗎？",
    "선택하신 답글을 삭제하시겠습니까?": "確定刪除所選回覆嗎？",
    "삭제 시 스토리와 방송국에서 완전 삭제되며, 복구가 불가합니다.": "確認刪除後將在故事及直播間中永久刪除，且無法復原。",
    "게시글(댓글, 답글 포함) 삭제가 완료되었습니다.": "已刪除文章(含留言及回覆)。",
    "댓글(답글 포함) 삭제가 완료되었습니다.": "已成功刪除留言(含回覆)。",
    "답글 삭제가 완료되었습니다.": "已成功刪除回覆。",
    삭제하기: "刪除",
    
    "최근 본 방송": "最近收看的直播",
    "최근 본 VOD": "最近收看的VOD",
    "UP한 VOD": "點讚的VOD",
    재생목록: "播放列表",
    "최근 본 VOD 삭제": "刪除最近收看VOD",
    "아직 참여한 방송이 없습니다.": "並無參與直播的紀錄。",
    "지금 바로 아프리카TV의 재미있고 다양한 방송을 만나보세요!": "馬上就來看看AfreecaTV既有趣又多樣的直播吧！",
    "LIVE 보러가기": "前往收看直播",
    "VOD 보러가기": "往前收看VOD",
    "최근 본 VOD가 없습니다.": "並無最近收看的VOD。",
    "나중에 보기 한 VOD가 없습니다.": "並無下次再看的VOD。",
    "UP 한 VOD가 없습니다.": "並無點讚的VOD。",
    "재생목록이 없습니다.": "並無參與直播的紀錄。",
    "VOD 위치 변경 안내": "VOD位置更改說明",
    "즐겨찾기한 BJ의 유저클립과 VOD는 새로워진 VOD 메뉴에서 만나보세요!": "試試看在全新版本的VOD選單中收看關注BJ的用戶剪輯和VOD吧！",
    "즐겨찾기한 VOD 보러가기": "收看關注BJ的VOD",
    "UP 취소": "取消UP",
    "나중에 보기 삭제": "刪除",
    "재생한 VOD 삭제": "刪除已播放視頻",
    전체삭제: "全部刪除",
    조회순: "播放排序",
    UP순: "點讚數",
    댓글순: "留言數",
    "VOD 추가순": "時間排序",
    오래된순: "時間排序",
    최신순: "最新",
    비공개: "非公開",
    업데이트: "更新",
    "재생목록에 담기": "添加到播放列表",
    수정하기: "修改",

    "내 마음대로 만드는 플레이리스트!":"能創建我的專屬播放列表！",
    "테마/BJ별로 재생목록에 담아 편하게 시청하세요.":"請按主題/主播添加到播放列表後觀看。",

    "새 재생목록 만들기": "創建新播放列表",
    "제목을 입력해주세요.": "請輸入標題。",
    "목록 공개": "公開目錄",
    "목록 비공개": "隱藏目錄",
    "재생목록 저장 허용": "允許轉載列表",
    "재생목록 저장 비허용": "不允許轉載列表",
    추가: "添加",
    "미리보기를 제공하지 않는 VOD": "無法預覽",
    "지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!": "現在馬上就去體驗AfreecaTV 有趣並多樣的視頻節目吧！",
    "해당 BJ를 즐겨찾기에서 삭제하시겠습니까?" : "要刪除關注嗎？",
    "재생목록을 삭제하시겠습니까?" : "確定要刪除播放列表嗎？",
    "편집" :"編輯",
    "즐찾BJ 편집" : "編輯關注BJ",
    "새 그룹 만들기" : "創建新群組",
    "그룹명" : "群祖名",
    "그룹명을 입력해주세요." :"請輸入群祖名。",
    "BJ 추가" : "添加BJ",
    "BJ 추가하기" : "添加BJ",
    "그룹에서 삭제" : "從群組刪除",
    "즐찾BJ 그룹에 담기" : "添加至關注BJ群組",
    "즐찾BJ 그룹" : "關注BJ群組",
    "{{num}}명 추가" : "添加{{num}}人",
    "해당 그룹을 삭제하시겠습니까?" : "確定要刪除群組嗎？",
    "그룹 추가는 최대 150명까지입니다." : "群組最多可添加150人。",
    "BJ를 추가해주세요." : "添加BJ",
    "편집할 그룹이 없습니다." : "尚無可編輯的群組。",
    "BJ 그룹 기능을 이용하여<br/>원하는 주제별로 BJ를 정리해보세요!" : "利用BJ群組功能<br/>按照希望的主題來整理BJ吧！",
    "삭제" : "刪除",
    "그룹 수정" : "修改群組",
    "확인" : "確認",
    "구독전용": "訂閱專屬",
    "드롭스 이벤트 진행 중" : "Drops活動進行中",
    "%d분 전": "%d分前",
    "%d시간 전": "%d小時前",
    "%d일 전": "%d天前",
    "%d초 전": "%d秒前",
    "Catch 스토리": "Catch Story",
    "월": "月",
    "일": "天",
};
