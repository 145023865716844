
import { toast } from "afreecatv-react-toast";
import { DELETE, POST } from "constant/method";
import i18next from "i18next";
import querystring from "querystring";
import { ROOT_URL } from "../constant/config";
import { handleFetchErrors } from "../helpers";

export const HOTISSUE_CLEANUP = 'hotissue_cleanup';
export const HOTISSUE_TODAY = {
    LOADING_START: "today/LOADING_START",
    LOADING_DONE: "today/LOADING_DONE",
    FETCH_SUCCESS: "today/FETCH_SUCCESS",
    FETCH_ERROR: "today/FETCH_ERROR",
};

export const HOTISSUE_POPULAR = {
    LOADING_START: "popular/LOADING_START",
    LOADING_DONE: "popular/LOADING_DONE",
    FETCH_SUCCESS: "popular/FETCH_SUCCESS",
    FETCH_ERROR: "popular/FETCH_ERROR",
};

export const HOTISSUE_POPULAR_VOD = {
    FETCH_SUCCESS: "popular_vod/FETCH_SUCCESS",
    FETCH_ERROR: "popular_vod/FETCH_ERROR",
};


export const POPULAR_BLIND = {
    BLIND: "hotissue/BLIND",
    CANCEL: "hotissue/CANCEL",
}
/**
 * 아프리카에서 생인 일 - 투데이
 */
export const fetchHotissueToday = (page = 1) => {
    return (dispatch) => {
        dispatch({ type: HOTISSUE_TODAY.LOADING_START });

        fetch(`${ROOT_URL}/hotissue/today?page=${page}`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: HOTISSUE_TODAY.FETCH_SUCCESS,
                    payload: response,
                });
                dispatch({ type: HOTISSUE_TODAY.LOADING_DONE });
            })
            .catch((error) => {
                dispatch({
                    type: HOTISSUE_TODAY.FETCH_ERROR,
                    payload: error,
                });
                dispatch({ type: HOTISSUE_TODAY.LOADING_DONE });
            });
    }
}

/**
 * 아프리카에서 생인 일 - 실시간 인기글
 */
export const fetchHotissuePopular = () => {
    return (dispatch) => {
        dispatch({ type: HOTISSUE_POPULAR.LOADING_START });

        fetch(`${ROOT_URL}/hotissue/popular`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: HOTISSUE_POPULAR.FETCH_SUCCESS,
                    payload: response,
                });
                dispatch({ type: HOTISSUE_POPULAR.LOADING_DONE });
            })
            .catch((error) => {
                dispatch({
                    type: HOTISSUE_POPULAR.FETCH_ERROR,
                    payload: error,
                });
                dispatch({ type: HOTISSUE_POPULAR.LOADING_DONE });
            });
    }
}

/**
 * 아생~ 초기화
 */
export const cleanupHotissue = () => {
    return (dispatch) => {
        dispatch({ type: HOTISSUE_CLEANUP });
    }
}


/**
 * 아프리카에서 생인 일 - 실시간 인기글 - 핫이슈 동영상
 */
export const fetchHotissuePopularVod = () => {
    return (dispatch) => {
        dispatch({ type: HOTISSUE_POPULAR.LOADING_START });

        fetch(`${ROOT_URL}/hotissue/popular/vod`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: HOTISSUE_POPULAR_VOD.FETCH_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: HOTISSUE_POPULAR_VOD.FETCH_ERROR,
                    payload: error,
                });
            });
    }
}


/**
 * 실시간 인기글 > 안보기
 */
export function popularBlind(user_id) {
    return (dispatch) => {


        fetch(`${ROOT_URL}/hotissue/popular/blind`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ blind_id: user_id }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: POPULAR_BLIND.BLIND,
                    user_id: user_id,
                });
                toast.success(response.message);
                if (response.is_favorite) {
                    if (window.confirm(i18next.t('지금 안보기한 BJ는 즐겨찾기한 BJ 입니다.\n즐겨찾기에서 해당 BJ를 삭제하시겠습니까?'))) {
                        distoryFavorite(user_id);
                    }
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };
}

/**
 * 실시간 인기글 > 안보기 해제
 */
export function popularBlindCancel(user_id) {
    return (dispatch) => {


        fetch(`${ROOT_URL}/hotissue/popular/blind/${user_id}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: 'delete' }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: POPULAR_BLIND.CANCEL,
                    user_id: user_id,
                });
                toast.success(response.message);
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };
}


/**
 * 즐겨찾기 삭제
 * @param {*} user_id 
 */
export function distoryFavorite(user_id) {
    return fetch(`${ROOT_URL}/favorite/${user_id}`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({
            _method: DELETE,

        }),
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            toast.success(response.message);
        })
        .catch((error) => {
            toast.success(error.message);
        });
}