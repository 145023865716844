import React, { useCallback, useState, useEffect } from 'react'
import classNames from "classnames";
import { DESC, ASC } from 'constant/sort';
import { useLocation } from "react-router";

export default function SortButtonGroup({ options, sortColumn, sortOrder, onChange }) {
    const { pathname } = useLocation();
    console.log(sortColumn, sortOrder)
    const [column, setColumn] = useState(sortColumn);
    const [order, setOrder] = useState(sortOrder);

    const handleOptionChange = useCallback((value) => () => {
        if (column === value) {
            if(pathname === '/history/later') {
                setOrder(DESC);
            } else {
                setOrder(order === DESC ? ASC : DESC)
            }
        } else {
            setOrder(DESC)
            setColumn(value);
        }


    }, [column, order, pathname]);


    useEffect(() => {
        if (onChange) {
            onChange(column, order);
        }
    }, [column, onChange, order])

    return (
        <div className="sort-tab">
            {Array.isArray(options) &&
                options.map((option, index) => (
                    <button key={index} type="button" className="on" className={classNames({ "on": option.value === column })} onClick={handleOptionChange(option.value)}>
                        <span className={classNames({ "up": pathname !== '/history/later' && order === ASC, down: order === DESC })}>{option.name}</span>
                    </button>
                ))}
        </div>
    )
}
