import { ROOT_URL, STBBS_URL } from "../constant/config";
import { handleFetchErrors } from "../helpers";

import { POST } from "constant/method";
import querystring from "querystring";
import { toast } from "afreecatv-react-toast";
import i18next from "i18next";

export const VODS = {
    VOD_INIT: "vods/VOD_INIT",
    VOD_LOADING_START: "vods/VOD_LOADING_START",
    VOD_LOADING_DONE: "vods/VOD_LOADING_DONE",
    VOD_FETCH_SUCCESS: "vods/VOD_FETCH_SUCCESS",
    VOD_FETCH_ERROR: "vods/VOD_FETCH_ERROR",
    VOD_FETCH_DELETE_SUCCESS : "vods/VOD_FETCH_DELETE_SUCCESS",
    VOD_FETCH_MORE_SUCCESS : "vods/VOD_FETCH_MORE_SUCCESS",
};

/**
 * VOD 가져오기
 * @param  pathname URL pathname (/favorite, /fanclub, /subscribe)
 * @return
 */
export const fetchVod = (pathname, page = "", group_idx) => {
    let url = `${ROOT_URL}${pathname}${pathname === '/history/playlist' ? '' : '/vod'}${page !=='' ? page : ''}${group_idx > 0 ? '/'+group_idx :''}`;
    return (dispatch) => {
        dispatch({ type: VODS.VOD_LOADING_START });

        fetch(url, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                if(window.location.pathname.includes(pathname)){
                    dispatch({
                        type: page !== '' ? VODS.VOD_FETCH_MORE_SUCCESS : VODS.VOD_FETCH_SUCCESS,
                        payload: response,
                    });
                    dispatch({ type: VODS.VOD_LOADING_DONE });
                }

            })
            .catch((error) => {
                dispatch({ type: VODS.VOD_LOADING_DONE });
                dispatch({
                    type: VODS.VOD_FETCH_ERROR,
                    payload: error,
                });
            })
    }
}

export const fetchUpDeleteVod = (bjId, titleNo) => {

    return (dispatch) => {
        fetch( `${ROOT_URL}/history/up/${bjId}/${titleNo}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: 'delete', }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                toast.success(i18next.t(response.message));
                dispatch({
                    type: VODS.VOD_FETCH_DELETE_SUCCESS,
                    payload: response
                });
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}

export const fetchRecentVodDelete = (titleNo) => {
    return (dispatch) => {
        fetch( `${ROOT_URL}/history/recent/vod/${titleNo}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({ _method: 'delete', }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                toast.success(i18next.t(response.message));
                dispatch({
                    type: VODS.VOD_FETCH_DELETE_SUCCESS,
                    payload: response
                });

            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}

export const fetchDeletePlaylist = (playlistIdx, shareCheck = false) => {
    const data = {
		szWork: !shareCheck ? "share_playlist_bj_del_list" : "del_playlist_list",
		nPlaylistIdx: playlistIdx,
    };

    return (dispatch) => {
        fetch( `${STBBS_URL}/vodplaylist/api/api.php`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify(data),
            credentials: "include",
        })
            .then((response) => response.json())
            .then((response) => {

                if(response.result === 1) {
                    dispatch({
                        type: VODS.VOD_FETCH_DELETE_SUCCESS,
                        payload: {response, list_idx: playlistIdx}
                    });
                }

                toast.success(i18next.t(response.msg));
            })
            .catch((error) => {
                toast.error(i18next.t(error.msg));
            });
    }
}
