import { ST_URL } from "constant/config";
import { handleFetchErrors } from "helpers";
import querystring from "querystring";
import { POST } from "../constant/method";

/**
 * 구독 닉네임 중복 확인
 * @param  bj_id     변경하고자 하는 구독중인 BJ 아이디
 * @param  nick_name 변경하고자 하는 닉네임
 * @return
 */
export const checkNickOverlab = (bj_id, nick_name, userId) => {
    return fetch(`${ST_URL}/api/nickname_api.php`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({
            szWork: "SubscriptionNickCheck",
            szUId: userId,
            szBjId: bj_id,
            szNick: nick_name,
            szType: "json",
        }),
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json());
};

/**
 * 구독 닉네임 변경
 * @param  bj_id            변경하고자 하는 BJ의 아이디
 * @param  nick_name        변경하고자 하는 닉네임
 * @return
 */
export const updateSubscribeNick = (bj_id, nick_name, userId) => {
    return fetch(`${ST_URL}/api/nickname_api.php`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({
            szWork: "SubscriptionNickUpdate",
            szUId: userId,
            szBjId: bj_id,
            szNick: nick_name,
            szType: "json",
        }),
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            if (response.RESULT !== 1) {
                throw new Error(response.MESSAGE);
            }
            return response;
        });
};

/**
 * 구독 닉네임을 기본 닉네임으로 변경
 * @param  bj_id            변경하고자 하는 BJ의 아이디
 * @return
 */
export const updateInitSubscribeNick = (bj_id, userId) => {
    return fetch(`${ST_URL}/api/nickname_api.php`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({
            szWork: "SubscriptionNickInit",
            szUId: userId,
            szBjId: bj_id,
            szType: "json",
        }),
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json())
        .then((response) => {
            if (response.RESULT !== 1) {
                throw new Error(response.MESSAGE);
            }
            return response;
        });
};
