import FavoriteIcon from "components/box/FavoriteIcon";
import LiveWatchButton from "components/button/LiveWatchButton";
import PinButton from "components/button/PinButton";
import ModalWindow from "components/ModalWindow";
import { POINT_URL } from "constant/config";
import { makeProfileUrl, makeStationUrl } from "helpers";
import i18next from "i18next";
import SubscribeNickLayer from "pages/subscribe/SubscribeNickLayer";
import React, { useCallback } from "react";
import { useAuth } from "react-navigationbar";
import ProfileImage from "components/image/ProfileImage";

export default function SubscribeItem({ subscribe }) {
    const { userId } = useAuth();
    /**
     * 구독일 확인 기능
     */
    const onClickMyGd = useCallback(
        (subscribe) => (e) => {
            e.preventDefault();
            window.open(
                `${POINT_URL}/Subscription/SubscriptionDtl.asp?userid=${userId}&ruserid=${subscribe.user_id}`,
                "subscriptiondtl",
                "width=460,height=390,scrollbars=no,resizable=no,status=no,menubar=no,toolbar=no",
            );
        },
        [userId],
    );

    /**
     * 구독 기간 노출
     */
    const displayPaymentPeriod = useCallback((payment_count) => {
        let paymentYear = parseInt(payment_count / 12);
        let paymentMonth = payment_count - paymentYear * 12;
        let paymentText = "";

        if (paymentYear > 0) {
            paymentText = `${paymentYear}년`;
            if (paymentMonth !== 0) {
                paymentText += ` ${paymentMonth}개월`;
            }
        } else {
            paymentText = `${paymentMonth}개월`;
        }

        if (i18next.language !== "ko") {
            return i18next.t("{{month}}개월", { month: payment_count });
        } else {
            return paymentText;
        }
    }, []);

    return (
        <>
            <a href={makeStationUrl(subscribe.user_id)} className="wrap" target="_blank">
                <ProfileImage 
                    tag="div" 
                    className="thumb" 
                    src={makeProfileUrl(subscribe.user_id)}
                    user_id={subscribe.user_id}
                    url={makeStationUrl(subscribe.user_id)}/>
                <div className="nick">
                    <strong>{subscribe.user_nick}</strong>
                    {subscribe.is_subscribe && <span className="ico_subscribe">{i18next.t(`구독`)}</span>}
                    {subscribe.is_fanclub && <span className="ico_fan">{i18next.t(`팬`)}</span>}
                </div>
                <span className="id">{subscribe.user_id}</span>
                {!subscribe.is_live && (
                    <span className="last_live">
                        {i18next.t(`최근방송`)} <em>{subscribe.last_broad_start}</em>
                    </span>
                )}
            </a>
            <div className="info_box">
                <dl>
                    <dt>{i18next.t(`구독 닉네임`)}</dt>
                    <dd>
                        <strong className="nick">{`${subscribe.sub_nick ? subscribe.sub_nick : subscribe.default_nick}`}</strong>

                        <ModalWindow
                            classNames={{
                                modal: "modal_modal_style",
                            }}
                            trigger={
                                <button type="button" className="btn_nick">
                                    <span>{i18next.t(`구독 닉네임 변경`)}</span>
                                </button>
                            }
                        >
                            {(close) => (
                                <SubscribeNickLayer
                                    onClose={close}
                                    bj_id={subscribe.user_id}
                                    sub_nick={subscribe.sub_nick}
                                    default_nick={subscribe.default_nick}
                                />
                            )}
                        </ModalWindow>
                    </dd>
                </dl>
                <dl>
                    <dt>{i18next.t(`구독일`)} </dt>
                    <dd>
                        <div className="date">
                            <em>{subscribe.first_payment_date}</em>
                            <strong>({displayPaymentPeriod(subscribe.payment_count)})</strong>
                        </div>
                        <button type="button" className="btn_info" onClick={onClickMyGd(subscribe)}>
                            <span>{i18next.t(`구독정보 보기`)}</span>
                        </button>
                    </dd>
                </dl>
            </div>
            {subscribe.is_live && <LiveWatchButton broad={subscribe.broad_info[0]} />}
            <PinButton item={subscribe} />
            <FavoriteIcon item={subscribe} className="btn-fav" />
        </>
    );
}
