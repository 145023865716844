import { ROOT_URL } from "constant/config";
import { handleFetchErrors } from "helpers";
import querystring from "querystring";
import { POST, PUT } from 'constant/method';
import cookie from "react-cookies";
import { FAVORITE_GROUP } from "actions/favorite.group.action";


/**
 * 즐찾 상수
 */
export const FEED = {
    LOADING_START: "feed/LOADING_START",
    LOADING_DONE: "feed/LOADING_DONE",
    FETCH_SUCCESS: "feed/FETCH_SUCCESS",
    FETCH_ERROR: "feed/FETCH_ERROR",
    HIT: "feed/HIT",
    INIT: "feed/INIT",
}

/**
 * 즐찾 목록 가져오기
 */
let abortController;
export function fetchFeed({ user_id, index_reg_date = 0, isBjWrite, feedType }) {
    return (dispatch) => {
        /**
         * 이전 호출 취소
         */
        if (abortController) {
            abortController.abort();
            abortController = null;
        }

        dispatch({ type: FEED.LOADING_START });
        const params = querystring.stringify({
            index_reg_date,
            user_id,
            is_bj_write: isBjWrite ? 1 : '',
            feed_type: feedType
        })
        const url = user_id
            ? `${ROOT_URL}/feed/${user_id}?${params}`
            : `${ROOT_URL}/feed?${params}`

        abortController = typeof AbortController === 'undefined' ? { abort: () => { }, hit_time: 0 } : new AbortController();
        fetch(url, {
            credentials: "include",
            signal: abortController.signal
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FEED.LOADING_DONE });
                dispatch({
                    type: FEED.FETCH_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({ type: FEED.LOADING_DONE });
                console.log(error)
                dispatch({
                    type: FEED.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}


export function initFeed() {
    return (dispatch) => {
        dispatch({ type: FEED.INIT });
    };
}

/**
 * 해쉬태그 리스트 
 */
export function fetchHashtags(title_no) {

    return fetch(`${ROOT_URL}/hashtag`, {
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json());
}

/**
 * 실시간 인기글
 */
export function fetchPopulars(title_no) {

    return fetch(`${ROOT_URL}/popular`, {
        credentials: "include",
    })
        .then(handleFetchErrors)
        .then((response) => response.json());
}


/**
 * 게시물조회
 * @param {*} item 
 */
export function hitFeed(item) {

    return (dispatch) => {

        //60초동안 호출하지 않음
        const time = parseInt(new Date().getTime() / (60 * 1000));
        if (item.hit_time !== time) {

            const title_no = item.title_no;
            fetch(`${ROOT_URL}/feed/hit/${title_no}`, {
                method: POST,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: querystring.stringify({
                    _method: PUT,

                }),
                credentials: "include",
            })
                .then(handleFetchErrors)
                .then((response) => {
                    dispatch({ type: FEED.HIT, title_no, time });
                })
                .catch((error) => {
                    console.log(error)
                });;

        }

    };
}
