import { feedLike, feedUnLike } from "actions/feed.like.action";
import { popularBlind, popularBlindCancel } from "actions/hotissue.action";
import classNames from "classnames";
import ShareButton from "components/button/ShareButton";
import ThreeDotButton from "components/button/ThreeDotButton";
import ContextMenu from "components/ict/ContextMenu";
import { STATION_URL } from "constant/config";
import { goLogin, numberCommaString } from "helpers";
import { handleProfileOnerror } from "listeners/error.listener";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-navigationbar";
import { useDispatch } from "react-redux";
import { decodeEmoticon } from "libs/emoticon";
import ProfileImage from "components/image/ProfileImage";
import { makeProfileUrl } from 'helpers';

export default function HotissuePopularItem({ item, onChangeLayer, onPosition }) {
    const { t } = useTranslation();
    // dispatch
    const dispatch = useDispatch();
    const { isLogin } = useAuth();
    const [ layer, setLayer ] = useState('');
    const [ no, setNo ] = useState(0);
    /**
     * 좋아요
     */
    const handleLike = useCallback(
        (is_like, station_user_id, title_no) => () => {
            if (!isLogin) {
                if (window.confirm(t("로그인이 필요한 기능입니다.\n로그인 하시겠습니까?"))) {
                    goLogin();
                }
                return false;
            }
            if (is_like) {
                dispatch(feedUnLike(station_user_id, title_no));
            } else {
                dispatch(feedLike(station_user_id, title_no));
            }
        },
        [dispatch, isLogin, t],
    );

    /**
     * 게시물안보기
     */
    const handleBlind = useCallback(
        (user_id, user_nick) => () => {
            if (!isLogin) {
                if (window.confirm(t("로그인이 필요한 기능입니다.\n로그인 하시겠습니까?"))) {
                    goLogin();
                }
                return false;
            }
            const msg = t("실시간 인기글에서 {{user_nick}}님의 게시물을 더 이상 보지 않으시겠습니까?", { user_nick: user_nick });
            if (window.confirm(msg)) {
                dispatch(popularBlind(user_id));
            }
        },
        [dispatch, isLogin, t],
    );
    /**
     * 게시물안보기 해제
     */
    const handleBlindCancel = useCallback(
        (user_id, user_nick) => (e) => {
            e.preventDefault();
            const msg = t("{{user_nick}}님의 게시물을 다시 보시겠습니까?", { user_nick: user_nick });
            if (window.confirm(msg)) {
                dispatch(popularBlindCancel(user_id));
            }
        },
        [dispatch, t],
    );

    const handleClickLayer = useCallback((layer) => {
        onChangeLayer("", 0);
    }, [onChangeLayer]);

    if (item.is_blind) {
        return (
            <li className="hide_post">
                <div>
                    <strong>{t("게시물 안보기가 설정된 게시물입니다.")}</strong>
                    <p>{t("실시간 인기글에서 해당 BJ의 게시물이 노출되지 않습니다.")}</p>
                    <button type="button" className="btn-basic" onClick={handleBlindCancel(item.user_id, item.user_nick)}>
                        <span>{t("게시물 안보기 해제")}</span>
                    </button>
                </div>
            </li>
        );
    } else {
        return (
            <li>
                <em className={classNames("rank", { high: item.rank < 4 })}>{t("{{rank}}위", { rank: item.rank })} </em>
                <div className="autor_wrap">
                    <>
                    <ProfileImage 
                        tag="span"
                        className="thum"
                        user_id={item.user_id}
                        src={makeProfileUrl(item.user_id)}
                        childrenTag="a"
                        innerChildrenTag="img"
                        childrenTagProps = {{
                            href:`${STATION_URL}/${item.user_id}`,
                            target:"_blank"
                        }}
                        innerChildrenTagProps = {{
                            src:item.profile_image,
                            loading: "lazy"
                        }}
                        />
                    </>
                    <div className="info_box">
                        <div className="nick_wrap">
                            <div>
                                <ContextMenu user_id={item.user_id} user_nick={item.user_nick}>
                                    <button type="button" className="nick">
                                        <p>{item.user_nick}</p>
                                    </button>
                                </ContextMenu>
                            </div>
                            {item.station_user_id !== item.user_id && (
                                <div>
                                    <ContextMenu user_id={item.station_user_id} user_nick={item.station_user_nick}>
                                        <button type="button" className="nick2">
                                            {item.station_user_nick}
                                        </button>
                                    </ContextMenu>
                                </div>
                            )}
                        </div>
                        <span>{item.reg_date}</span>
                        <span>{t("조회 {{count}}", { count: numberCommaString(item.count.read_cnt) })}</span>
                    </div>
                </div>

                <div className="post-contents">
                    <a href={item.url} target="_blank">
                        <strong className="post-title">{item.title_name}</strong>
                        <div className="post-box">
                            <div className="text_wrap">
                                <p className="text" dangerouslySetInnerHTML={{ __html: decodeEmoticon(item.content) }} />
                            </div>
                            {item.photos.length > 0 && (
                                <div className="img_wrap">
                                    <div className="file">
                                        <img src={item.photos[0].url} alt="" loading="lazy" />
                                        {item.photos.length > 1 && <span className="count">{item.photos.length}</span>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </a>
                </div>

                <div className="item_util">
                    <button
                        type="button"
                        className={classNames("btn-basic up", { on: item.is_like })}
                        onClick={handleLike(item.is_like, item.station_user_id, item.title_no)}
                    >
                        <span>{t("up")}</span>
                        <em>{item.count.like_cnt + (item.is_like ? 1 : 0)}</em>
                    </button>
                    <button type="button" className="btn-basic cmmt_list" onClick={() => window.open(`${item.url}#comment`)}>
                        <span>{t("댓글")}</span>
                        <em>{item.count.comment_cnt}</em>
                    </button>
                    <div className="share_box">
                        <ShareButton item={item} onChangeLayer={handleClickLayer} />
                    </div>
                </div>

                <ThreeDotButton
                    trigger={
                        <button type="button" className="btn-detail">
                            <span>{t("상세정보")}</span>
                        </button>
                    }

                >
                    <button type="button" onClick={() => window.open(`${STATION_URL}/${item.station_user_id}`)}>
                        <span>{t("방송국 가기")}</span>
                    </button>
                    <button type="button" onClick={handleBlind(item.user_id, item.user_nick)}>
                        <span>{t("게시물 안보기")}</span>
                    </button>
                </ThreeDotButton>
            </li>
        );
    }
}
