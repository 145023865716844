import SortButtonGroup from "components/button/SortButtonGroup";
import AlarmButtonGroup from "components/button/AlarmButtonGroup";
import FavoriteItem from "components/item/FavoriteItem";
import EmptySection from "components/section/EmptySection";
import ErrorSection from "components/section/ErrorSection";
import LoadingSection from "components/section/LoadingSection";
import TitleSection from "components/section/TitleSection";
import { DESC, FAVORITE_NO, getFavoriteSort, OFFSET_SIZE } from "constant/sort";
import { compare_favorite, numberCommaString } from "helpers";
import i18next from "i18next";
import React, { useCallback, useMemo, useState, useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import MoreButton from "components/button/MoreButton";

import FavoriteGroupProvider, {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";



export default function FavoriteListSection({onChangeLayer, onPosition, onCallbackLayer}) {

    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);
    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(FAVORITE_NO);
    const [limit, setLimit] = useState(OFFSET_SIZE);

    // useSelector
    const { extdata, error, loading } = useSelector(state => (state.favoriteExtReducer), shallowEqual);

    /**
     * 정렬및 더보기된 데이터
     */
    const filteredData = useMemo(() => {
        return extdata.slice().sort(compare_favorite(sortColumn, sortOrder)).slice(0, limit);
    }, [extdata, limit, sortColumn, sortOrder]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);



    if (error) {
        return <ErrorSection />
    }

    if (loading) {
        return <LoadingSection />;
    } else {
        if (extdata.length > 0) {
            return (<div id="favorite_list" className="favor-wrap">
                <TitleSection>
                    { groupIdx === 0 ? i18next.t(`즐겨찾기 전체`) : groupTitle}
                    <span className="total">
                        ({i18next.t(`{{num}}명`, { num: numberCommaString(extdata.length) })})
                 </span>
                </TitleSection>


                <div className="sort-wrap">
                    <SortButtonGroup options={getFavoriteSort()} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                    <AlarmButtonGroup data={extdata} />
                </div>


                <div className="favor-list">
                    <ul>
                        {filteredData.map((favorite, index) =>
                            <li key={index}>
                                <FavoriteItem favorite={favorite} onChangeLayer={onChangeLayer} onPosition={onPosition} onCallBackChangeLayer={onCallbackLayer}  />
                            </li>
                        )}
                    </ul>

                    {extdata.length > limit && <MoreButton onClick={onClickMore} />}
                </div>
            </div>)
        } else {
            return <EmptySection />;
        }
    }
}
