import { BROADING } from "actions/broading.action";
import { RECENT } from "actions/recent.action";


const initialState = {
    data: [],
    error: null,
    loading: false,
    status : ""
};

const broadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECENT.LOADING_START:
            return { ...state, loading: true, status: "LOADING" };

        case RECENT.LOADING_DONE:
            return { ...state, loading: false, status: "CLEAR" };


        case BROADING.FETCH_SUCCESS:
            return {
                ...state,
                data: action.payload.reduce((accumulator, currentValue) => {
                    return accumulator.concat(currentValue['broad_info'])
                }, []),
                error: null,
            }

        case RECENT.FETCH_SUCCESS:
            return { ...state, data: action.payload.data, error: null };

        case RECENT.FETCH_ERROR:
            return { ...state, error: action.payload };


        case RECENT.DESTORY_SUCCESS:
            let data = state.data.filter(item => item.broad_no !== action.payload);
            return {
                ...state,
                data
            }
        case RECENT.DESTORY_ALL_SUCCESS:
            let arr = [];
            return {
                arr
            }

        case RECENT.INIT:
            return initialState;
        default:
            return state;
    }
};

export default broadingReducer;
