import { useEffect, useState } from 'react'
import { useLocation } from "react-router";

const useAdBanner = () => {
    const { pathname } =  useLocation();
    const [renderBanner,  setRenderBanner] = useState(null); // 광고요소 state
    const [banner, setBanner] = useState(null);

    // BannerLoader 객체 생성
    useEffect(()=>{
        if(typeof BannerLoader !== 'undefined' ){
            // eslint-disable-next-line
            setBanner(new BannerLoader());
        }
    },[])

    useEffect(()=>{
        if(banner){
            const category = pathname.split('/').filter(part => part !== '')[0];
            banner.loadCategorySubBanner({
                callback : (element)=>{
                    if(element){
                        setRenderBanner(element)
                    }
                },
                category : category,
            });
        }
    },[banner])
   

    const states = {
        banner,
        renderBanner
	};
    const handlers = {};

    return { ...states, ...handlers };
};

export default useAdBanner;