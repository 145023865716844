import { ROOT_URL, STBBS_URL } from "constant/config";
import { POST } from "constant/method";
import querystring from "querystring";
import { handleFetchErrors } from "../helpers";

export const FAVORITE_CLIP = {
    LOADING_START: "favorite_clip/LOADING_START",
    LOADING_DONE: "favorite_clip/LOADING_DONE",
    FETCH_SUCCESS: "favorite_clip/FETCH_SUCCESS",
    FETCH_ERROR: "favorite_clip/FETCH_ERROR",
};

/**
 * 즐찾 목록 가져오기
 */
export function fetchFavoriteClips() {
    return (dispatch) => {
        dispatch({ type: FAVORITE_CLIP.LOADING_START });

        fetch(`${STBBS_URL}/api/get_vod_list.php`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                szDataType: "FAVORITE",
                szDataDetailType: "fav_clip",
                nPage: 1,
                nLimit: 30,
                szOrder: "popular",
            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FAVORITE_CLIP.LOADING_DONE });
                if (response.result === 1) {
                    dispatch({
                        type: FAVORITE_CLIP.FETCH_SUCCESS,
                        payload: response.data.list,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: FAVORITE_CLIP.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CLIP.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}

export function fetchFavoriteUserClips(group_idx){
    const url = `${ROOT_URL}/favorite/userclip` + (group_idx > 0 ? `/${group_idx}` : ``);
    return (dispatch) => {
        dispatch({ type: FAVORITE_CLIP.LOADING_START });

        fetch(url, {
            
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
           
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FAVORITE_CLIP.LOADING_DONE });
                if (response.result === 1) {
                    dispatch({
                        type: FAVORITE_CLIP.FETCH_SUCCESS,
                        payload: response.list,
                    });
                }
            })
            .catch((error) => {
                dispatch({ type: FAVORITE_CLIP.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CLIP.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}