import React, { useMemo, useCallback, useState } from "react";
import { RES_AFREECATV_URL, LIVEIMG_URL } from "constant/config";

export default function FeedLiveThumbnail({ href, onClick, broad, onLoad }) {
	const { is_password, broad_grade, broad_title, thumb, broad_no } = broad;

	const invisibleImg = `${RES_AFREECATV_URL}/images/myservice/png.png`;
	const defaultImg = `${LIVEIMG_URL}/00/${broad_no}_480x270.webp`;
	const [imgStatus, setImgStatus] = useState(true);

	const handleError = useCallback((e) => {
		let errorCheck = false;
        let image = new Image();
        let gifThumb = `${LIVEIMG_URL}/00/${broad_no}_480x270.gif`;

        image.src = gifThumb;
        image.onerror = () => {
            errorCheck = true;
            setImgStatus(false);
        }
        
        if(!errorCheck) {
            e.target.src = gifThumb;
        }
	}, []);

	/**
	 * Class 분기
	 */
	const getThumbClassName = useCallback(() => {
		let thumbCalss = "";
		if (imgStatus === false) {
			thumbCalss = "thumb-default";
		} else if (broad_grade === 19 && is_password) {
			thumbCalss = "thumb-lock_adult";
		} else if (is_password) {
			thumbCalss = "thumb-lock";
		} else if (broad_grade === 19) {
			thumbCalss = "thumb-adult";
		}
		return thumbCalss;
	}, [broad_grade, imgStatus, is_password]);

	const feedLiveAirBox = useMemo(() => {
		if (getThumbClassName() !== "") {
			return (
				<a href={href} onClick={onClick} target="_blank" className={getThumbClassName()}>
					<span className="thum">
						<img src={invisibleImg} alt="" loading="lazy" onLoad={onLoad} />
					</span>
					<p className="sbj" title="">
						{broad_title}
					</p>
					<span className="feed_live">LIVE</span>
				</a>
			);
		} else {
			return (
				<a href={href} onClick={onClick} target="_blank">
					<span className="thum">
						<img src={defaultImg} alt="" loading="lazy" onLoad={onLoad} onError={handleError} />
					</span>
					<p className="sbj" title="">
						{broad_title}
					</p>
					<span className="feed_live">LIVE</span>
				</a>
			);
		}
	}, [getThumbClassName, href, onClick, invisibleImg, onLoad, broad_title, thumb, handleError]);

	return <div className="onAir_box">{feedLiveAirBox}</div>;
}
