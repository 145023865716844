import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { fetchRecent, destroyAllRecent } from "actions/recent.action";
import { compare_broad } from "helpers";
import SortButtonGroup from "components/button/SortButtonGroup";
import MoreButton from "components/button/MoreButton";
import i18next from "i18next";
import LiveItem from "components/item/LiveItem";
import LiveEmptySection from "components/section/LiveEmptySection";
import { DESC, getRecentLiveSort, OFFSET_SIZE, TOTAL_VIEW_CNT } from "constant/sort";
import LoadingSection from "components/section/LoadingSection";

export default function HistoryRecentBroadSection () {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(TOTAL_VIEW_CNT);
    const [limit, setLimit] = useState(OFFSET_SIZE);
    const [options, setOptions] = useState(getRecentLiveSort);

    const [ top, setTop ] = useState(0);
    const [ left, setLeft ] = useState(0);

    const [layer, setLayer] = useState("");
    const [broadNo, setBroadeNo] = useState(0);

    useEffect(() => {
        dispatch(fetchRecent());
    }, [dispatch]);

    // useSelector
    const { data = [], loading = false, status= "", error } = useSelector(state => (state.broadingReducer), shallowEqual);

    // useMemo
    const filteredData = useMemo(() => {
        return data.length > 0 ? data.slice().sort(compare_broad(sortColumn, sortOrder)).slice(0, limit) : [];
    }, [data, limit, sortColumn, sortOrder]);

    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);
    /**
     * 새로고침
     */
    const onClickRefresh = useCallback((e) => {
        e.preventDefault();
        dispatch(fetchRecent());
        
        setLimit(OFFSET_SIZE);
    }, [dispatch]);

    /**
     * 최근본방송 전체 삭제
     */
    const onClickDeleteAll = useCallback((e) => {
        e.preventDefault();

        if (!window.confirm(i18next.t(`최근 본 BJ의 LIVE 내역을 모두 삭제하시겠습니까?`))) {
            return;
        }        

        dispatch(destroyAllRecent());
    }, [dispatch]);

    const handlePosition = useCallback((top, left) => {
        setTop(top);
        setLeft(left);
    }, [setTop, setLeft]);

    const handleLayer = useCallback((layer, titleNo) => (event) => {
        setLayer(layer);
        setBroadeNo(titleNo);
    }, [setLayer, setBroadeNo]);

    if (loading && status === 'LOADING') {
        return <LoadingSection />;
    } else if (!loading && status === 'CLEAR') {
        if(data.length < 1) {
            return (
                <LiveEmptySection />
            );
        }
        return (
            <>
                <div className="sort-wrap">
                    <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                    <div className="rt_group">
                        <div className="btns">
                            <button type="button"  onClick={onClickDeleteAll}>{i18next.t(`전체삭제`)}</button>
                            <button type="button" className="reload" onClick={onClickRefresh}><span>{i18next.t(`새로고침`)}</span></button>
                        </div>
                    </div>
                </div>

                <div className="cBox-list" data-broadcast="live">
                    <ul>
                        {filteredData.map((broad, index) => (
                            <li key={index} data-type="cBox">
                                <LiveItem broad={broad} pathname={pathname} onChangeLayer={handleLayer} onPosition={handlePosition} />
                            </li>
                        )
                        )}
                    </ul>

                    {data.length > limit && <MoreButton onClick={onClickMore} />}
                </div>
            </>
        );
    } else {
        return null;
    }
}