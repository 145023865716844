import { DELETE, POST } from "constant/method";
import i18next from "i18next";
import querystring from "querystring";
import { ROOT_URL } from "../constant/config";
import { handleFetchErrors, sendLogApi } from "../helpers";
import { API } from "./api.action";
import { toast } from "afreecatv-react-toast";


export const SUBSCRIBE = {
    LOADING_START: "subscribe/LOADING_START",
    LOADING_DONE: "subscribe/LOADING_DONE",
    FETCH_SUCCESS: "subscribe/FETCH_SUCCESS",
    FETCH_ERROR: "subscribe/FETCH_ERROR",
    FILTER_PIN: "subscribe/FILTER_PIN",
    UPDATE_SUBNICK: "subscribe/UPDATE_SUBNICK",
};


/**
 * 구독 목록 가져오기
 */
export const fetchSubscribe = () => {
    return (dispatch) => {
        dispatch({ type: SUBSCRIBE.LOADING_START });

        fetch(`${ROOT_URL}/subscribe`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: SUBSCRIBE.FETCH_SUCCESS,
                    payload: response,
                });
                dispatch({ type: SUBSCRIBE.LOADING_DONE });
            })
            .catch((error) => {
                dispatch({
                    type: SUBSCRIBE.FETCH_ERROR,
                    payload: error,
                });
                dispatch({ type: SUBSCRIBE.LOADING_DONE });
            });
    }
}


/**
 * 핀 추가
 * @param  subscribeNo 구독 번호
 * @param  userId      유저 아이디
 * @return
 */
export const storeSubscribePin = (subscribeNo, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/subscribe/pin`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                common_no: subscribeNo,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({ type: API.LOADING_DONE });
                dispatch({
                    type: SUBSCRIBE.FILTER_PIN,
                    payload: { subscribeNo: subscribeNo, flag: true }
                });
                let sendData = {
                    location: 'subsbj',
                    action_type: 'add_fix',
                    bj_id: userId,
                };
                sendLogApi("main_00000008", sendData);
                toast.success(i18next.t(`목록 상단 고정 완료 되었습니다.`));
            })
            .catch((error) => {
                dispatch({ type: API.LOADING_DONE });
                toast.error(i18next.t(error.message));
            });
    }
}

/**
 * 핀 삭제
 * @param  subscribeNo 구독 번호
 * @param  userId      유저 아이디
 * @return
 */
export const destroySubscribePin = (subscribeNo, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/subscribe/pin/${subscribeNo}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
                common_no: subscribeNo,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({ type: API.LOADING_DONE });
                dispatch({
                    type: SUBSCRIBE.FILTER_PIN,
                    payload: { subscribeNo: subscribeNo, flag: false }
                });
                let sendData = {
                    location: 'subsbj',
                    action_type: 'del_fix',
                    bj_id: userId,
                };
                sendLogApi("main_00000008", sendData);
                toast.success(i18next.t(`목록 상단 고정 해제 되었습니다.`));
            })
            .catch((error) => {
                dispatch({ type: API.LOADING_DONE });
                toast.error(i18next.t(error.message));
            });
    }
}
