import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { GLOBALSTATE } from "reducers/global.reducer";
import i18next from "i18next";

export default function useModal(){

    const dispatch = useDispatch();

    const { modal } = useSelector((state) => state.globalReducer, shallowEqual);

    //그룹 추가/수정 관련 모달
    const handleFixFavGroupModal = useCallback((type, onModal, itemTitle, selIdx, listHidden)=>{
        let menutitle = ""
        
        if(onModal){
            switch(type){
                case 'addgroup' : 
                    menutitle = i18next.t('새 그룹 만들기');
                    break;
                case 'updgroup' :
                    menutitle = i18next.t('그룹 수정');
                    break;
                case 'updaddgroup':
                    menutitle = i18next.t('새 그룹 만들기');
                default:
                    break
                
            }
            document.querySelector("html").style.overflow="hidden";
        }
        else{
            switch(type){
                case 'addgroup':
                    document.querySelector("html").style="";
                    break;
                default:
                    break;
            }
        }
        dispatch({type:GLOBALSTATE.SET_GLOBAL_FIXGROUP_MODAL,payload:{...modal,type:type, menuTitle:menutitle, itemTitle:itemTitle,  onModal:onModal, selIdx: selIdx}});
        if(listHidden) {
            dispatch({type:GLOBALSTATE.SET_GLOBAL_LISTGROUP_MODAL,payload:{...modal.fixgroup, isHidden : true}})
        }
    },[])
    //그룹 편집 관련 모달
    const handleListFavGroupModal = useCallback((type, onModal, realClose)=>{
        let menutitle = i18next.t('즐찾BJ 편집');

        dispatch({type:GLOBALSTATE.SET_GLOBAL_LISTGROUP_MODAL,payload:{...modal.listgroup,type:type, menuTitle:menutitle,  onModal:onModal}});
        if(realClose){
            document.querySelector("html").style="";
        }
        else{
            document.querySelector("html").style.overflow="hidden";
        }
    },[])    

    return {modal, handleFixFavGroupModal, handleListFavGroupModal}

}