import { setMobilePushFlag } from "actions/alarm.action";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function AlarmButtonGroup({ data }) {
	const { t } = useTranslation();
    const dispatch = useDispatch();
    const is_all_alarm = useMemo(() => data.find((item) => item.is_mobile_push === "N"), [data]);

    const handleAllAlarmClick = useCallback(() => {
		const flag = typeof is_all_alarm === "object" ? 1 : 0;

        if (window.confirm(flag === 1 ? t(`모든 즐겨찾기 BJ에게 알림을 받습니다.`) : t(`모든 즐겨찾기 BJ에게 알림을 받지 않습니다.`))) {
            dispatch(setMobilePushFlag("all", flag));
        }
	}, [dispatch, t, is_all_alarm]);

	return (
		<div className="rt_group">
			<button type="button" className={classNames("all_alarm", {"active": typeof(is_all_alarm) !== "object"})} onClick={handleAllAlarmClick}>
				{t("전체 알림")}
			</button>
		</div>
	);
}
