import { destroyPin, storePin } from 'actions/pin.action';
import classNames from "classnames";
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function PinButton({ item }) {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    //dispatch
    const dispatch = useDispatch();
    /**
     * 상단 목록 고정 클릭
     */
    const onClickPin = useCallback((item) => (e) => {
        e.preventDefault();
        const no = item.favorite_no || item.fanclub_no || item.subscribe_no
        if (item.is_pin) {
            dispatch(destroyPin(pathname, no, item.user_id))
        } else {
            dispatch(storePin(pathname, no, item.user_id))
        }
    }, [dispatch, pathname]);

    const tip = useMemo(() => item.is_pin ? t("목록 상단해제") : t("목록 상단고정"), [item.is_pin, t]);

    return (
        <button type="button" className={classNames("btn-fix ", { on: item.is_pin })} tip={tip} onClick={onClickPin(item)}>
            <span>{tip}</span>
        </button>
    )
}
