import useSildeCount from "components/hook/useSildeCount";
import VodItem from "components/item/VodItem";
import TitleSection from "components/section/TitleSection";
import SwiperController from "components/swiper/SwiperController";
import i18next from "i18next";
import React, { useRef, useCallback, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";


SwiperCore.use([Autoplay]);
export default function UserClipSection({handleLayer, handlePosition, handleCallbackLayer}) {

    const ref = useRef(null);
    const [autoPlay, setAutoPlay] = useState(true);

    const count = useSildeCount();

    // useSelector
    const { data } = useSelector(state => (state.favoriteClipReducer), shallowEqual);

    // 마우스 진입시 멈추기
    const onMouseEnter = useCallback((e) => {
        if(autoPlay && ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.autoplay.stop();
        }
    }, [ref, autoPlay]);

    // 마우스 나갈시 다시 재생
    const onMouseLeave = useCallback((e) => {
        if(autoPlay && ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.autoplay.start();
        }
    }, [ref, autoPlay]);

    if (data.length > 0) {
        return (
            <>
                <TitleSection sectionType="CLIP" >{i18next.t(`즐겨찾기한 BJ의 유저클립`)}</TitleSection>

                <div className="slide-vod">
                    {(data.length > 6) && <SwiperController swiperRef={ref} autoplay={false} />}

                    <Swiper
                        ref={ref}
                        autoplay={false /*({
                            delay: 3000,
                            disableOnInteraction: false,
                        }*/}
                        breakpoints= {{
                            // 1280px 보다 클 경우
                            2183: {
                              slidesPerView: 6,
                            },
                            1921 :{
                                slidesPerView:5,
                            },
                            640: {
                                slidesPerView:4
                            }
                          }
                        }
                        wrapperTag="ul"
                        spaceBetween={20}
                        className={'swiper-container'}
                        slidesPerView={count}
                        allowTouchMove={false}
                        loop={data.length > 6}
                    >
                        {data.map((vod, index) => {
                            return (
                                <SwiperSlide tag="li" key={index} data-type="cBox"  /*onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}*/>
                                    <VodItem vod={vod} path3="clip" onChangeLayer={handleLayer} 
                                    onPosition={handlePosition} onCallBackChangeLayer={handleCallbackLayer}/>
                                </SwiperSlide>);
                        })}
                    </Swiper>
                </div>
            </>
        );
    } else {
        return null;
    }
}