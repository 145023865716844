import React, { useState, useCallback } from "react";
import { POST, PHOTO } from "constant/feedType";

export default function StoryImage({ href, photo, type }) {
	const [imgStatus, setImgStatus] = useState(true);
	const handleError = useCallback(() => {
		setImgStatus(false);
	}, []);

	// 다중첨부 타입
	const isManyType = type === POST || type === PHOTO;

	if (imgStatus === false) {
		return (
			<a href={href}>
				<span className="thumb-default">기본</span>
				{isManyType && photo.length > 1 && <span className="count">{photo.length}</span>}
			</a>
		);
	} else {
		if (isManyType) {
			return (
				<a href={href} target="_blank">
					<img src={photo[0].url} alt="" loading="lazy" onError={handleError} />
					{photo.length > 1 && <span className="count">{photo.length}</span>}
				</a>
			);
		} else {
			return (
				<a href={href} target="_blank">
					<img src={photo.url} alt="" loading="lazy" onError={handleError} />
				</a>
			);
		}
	}
}
