import React from 'react';
import i18next from 'i18next';

export default function Badge({ file_type }) {
    if (file_type === "REVIEW") {
        return <em className="ic-replay">{i18next.t(`다시보기`)}</em>;
    } else if (file_type === "HIGHLIGHT") {
        return <em className="ic-highlight">{i18next.t(`하이라이트`)}</em>;
    } else {
        return null;
    }
}
