import { API } from "actions/api.action";

const initialState = {
    loading: false,
    display: false,
    error: null,
};

const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case API.LOADING_START:
            return { ...state, loading: true };

        case API.LOADING_DONE:
            return { ...state, loading: false };


        default:
            return state;
    }
};

export default apiReducer;
