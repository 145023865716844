import { PopupContext } from 'components/context/PopupProvider';
import { toast } from 'afreecatv-react-toast';
import { UCC } from 'constant/board';
import i18next from 'i18next';
import React, { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Trans, useTranslation } from 'react-i18next';
import {sendVodUaLogBtnType} from 'libs/log'

export default function ShareButton({ item, layer, setLayer, className = "btn-basic share", type = "FEED", onChangeLayer, top = 0, offset=0}) {
    const [popupId, setPopupId] = useContext(PopupContext);
    const [currentId] = useState(Math.random());
    const { t } = useTranslation();

    const LayerStyle = useMemo(() => {

        // 캐치, 캐치스토리 영상일 경우 공유하기가 우측 레이어가 짤리는 이슈가 있어 강제 style ... ;;
        if (window.innerWidth <= offset + 250 && (type === "CATCH" || type === "CATCHSTORY")) {
            return {'right' : 0}
        }

        return {};
    }, [offset, type]);

    
    const shareUrl = useMemo(() => {
        if (!item.url) {
            switch (type) {
                case 'LIVE':
                    return "//play.afreecatv.com/"+ item.user_id + "/" +  item.broad_no;
                case 'CATCH':
                    return "//vod.afreecatv.com/ST/" + item.title_no + "/catch";
                case 'CATCHSTORY':
                    return "//vod.afreecatv.com/player/" + item.story_idx + "/catchstory?o=1";
                case 'VOD':
                case 'FEED': 
                default:
                    return "//vod.afreecatv.com/player/" + item.title_no;
            }
        }

        return item.url;
    }, [item, type]);


    // 개별 레이어 제어가 어려워서 공유하기 버튼에 event 추가
    useEffect(() => {
        function handleDocumentClick() {
            setLayer('');
        }
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    }, [setLayer]);


    /**
     * 공유될URL
     */
    const getShareUrl = () => {
        if(shareUrl.indexOf('https:') >=0 ||shareUrl.indexOf('http:') >=0){
            return global.encodeURIComponent(shareUrl);
        }
        else{   
            return global.encodeURIComponent("https:"+shareUrl);
        }
        
    };

    const getImageUrl = () => {
        if (item.board_type === UCC) {
            return item.ucc.thumb;
        } else if(type === 'CATCHSTORY') {
            return item.thumb;
        } else {
            return item.photos.length ? item.photos[0].url : "";
        }
    };

    const handleTwitterShare = (e) => {
        let title = '';

        // 공유하기 기능 확대로 type 별 title 값 전달
        switch (type) {
            case 'PLAYLIST':
                title = item.title;
                break;

            case 'LIVE':
                title = item.broad_title;
                break;
            case 'CATCHSTORY':
                title = Number(item.last_add_date.split("-")[1])+t("월")+" "+ Number(item.last_add_date.split("-")[2].split(" ")[0])+t("일")+" "+t("스토리")
                break;
            case 'VOD':
            case 'CATCH':
            case 'FEED': 
            default:
                title = item.title_name;
                break;
        }

        const encodeTitle = encodeURIComponent(removeTag(title));
        // VOD의 경우에만 로그 수집
        if (type === 'VOD' || type === "PLAYLIST" || type === "CATCHSTORY") {
            sendVodUaLogBtnType("twitter_btn");
        }
        window.open(`https://twitter.com/intent/tweet?text=${encodeTitle}&url=${getShareUrl()}`);
        setPopupId(-1);
    };

    const handleFacebookShare = (e) => {
        if(type === "VOD" || type === "CATCH" || type === "FEED")  {
            if (Number(item.auth_no) !== 101) {
                alert(i18next.t("페이스북 공유는 전체 공개인 게시글만 공유가 가능합니다."));
                return false;
            }
        }
        // VOD의 경우에만 로그 수집
        if (type === 'VOD' || type === "PLAYLIST" || type === "CATCHSTORY") {
            sendVodUaLogBtnType("facebook_btn");
        }
        window.open(`http://www.facebook.com/sharer/sharer.php?u=${getShareUrl()}`);
        setPopupId(-1);
    };

    const getWebChatQrCode = () => {
        const type = item.board_type === UCC ? "vod" : "item";
        return `http://afevent2.afreecatv.com:8120/api/get_qrcode.php?szWork=share&type=${type}&bjId=${item.user_id}&bbsNo=${
            item.bbs_no
            }&vNo=${item.title_no}`;
    };

    const handleChinaShare = (site) => (e) => {
        const encodeTitle = encodeURIComponent(removeTag(item.title_name));
        const shareImage = getImageUrl();
        const commat = site === "qq" ? " (@小蜜蜂U小飞)" : "";
        const shareDesc = encodeURIComponent(i18next.t("BJ {{nickname}}의 영상을 확인하세요!", { nickname: item.user_nick }) + commat);
        const shareSummary = i18next.t("아프리카 TV 사이트(www.afreecatv.com)에서 더 많은 컨텐츠를 즐길 수 있습니다!");
        const lang = document.documentElement.lang.toLowerCase();

        if (site === "qq") {
            window.open(
                `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${getShareUrl()}&title=${encodeTitle}&pics=${shareImage}&desc=${shareDesc}&summary=${shareSummary}`,
                "shareQ",
                "width=600,height=400",
            );
        } else {
            window.open(
                `http://service.weibo.com/share/share.php?url=${getShareUrl()}&title=${encodeTitle}&pics=${shareImage}&desc=${shareDesc}&language=${lang}`,
                "shareW",
                "width=600,height=400",
            );
        }
    };

    const removeTag = (html) => {
        // ie 브라우저에서 xss 공격으로 인식 하기때문에 이렇게 처리
        return html.replace(/(<([^>]+)>)/gi, "").replace(/"/g, "'");
    }

    const handleCopied = () => {
        // VOD의 경우에만 로그 수집
        if (type === 'VOD' || type === "PLAYLIST" || type === "CATCHSTORY") {
            sendVodUaLogBtnType("copy_btn");
        }
        toast.success(i18next.t("주소가 복사되었습니다. 원하는 곳에 붙여넣기(Ctrl+V)해주세요"));
    };

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setPopupId(currentId === popupId ? -1 : currentId);
    }, [currentId, popupId, setPopupId]);



    useEffect(() => {
        if(layer === 'SHARE') {
            setPopupId(currentId === popupId ? -1 : currentId);
        }
    }, [setPopupId,  currentId, layer]);
    return (
    <div className="share_box">
        {/** 공유하기 버튼 */}
        {type === "FEED" && <button type="button" className={className} onClick={handleClick}>
            <span><Trans>공유하기</Trans></span>
        </button>}

        {/** 공유하기 레이어 */}
        {currentId === popupId && <div className="ui-pop bs-sns_layer active" style={LayerStyle}>
            <div className="pop-title"><h3><Trans>공유하기</Trans></h3></div>
            <div className="pop-body">
                <div>
                    <>
                        <button type="button" className="tw" onClick={(e) => {e.stopPropagation(); handleTwitterShare(); }}><em></em></button>
                        <button type="button" className="fb" onClick={(e) => {e.stopPropagation(); handleFacebookShare(); }}><em></em></button>
                    </>
                    <CopyToClipboard text={shareUrl} onCopy={handleCopied}>
                        <button type="button" className="url" onClick={(e) => {e.stopPropagation(); }}><em></em></button>
                    </CopyToClipboard>
                    <button type="button" className="qq" onClick={handleChinaShare("qq")}><em></em></button>
                    <button type="button" className="weibo" onClick={handleChinaShare("weibo")}><em></em></button>
                    <button type="button" className="wechat"><em></em>
                        <div>
                            <img src={getWebChatQrCode()} alt="" loading="lazy" />
                        </div>
                    </button>
                </div>
            </div>
            <button className="pop-close" onClick={(e) => {  
                e.stopPropagation();
                setPopupId(-1);
                setLayer('');
            }}><span>닫기</span></button>
        </div>}
    </div>
    )
}