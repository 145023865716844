import { fetchFanclub } from "actions/fanclub.action";
import { fetchFavorite } from "actions/favorite.action";
import { fetchSubscribe } from "actions/subscribe.action";
import MoreButton from "components/button/MoreButton";
import SortButtonGroup from "components/button/SortButtonGroup";
import LiveItem from "components/item/LiveItem";
import TitleSection from "components/section/TitleSection";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, RECENT_ROUTE, SUBSCRIBE_ROUTE } from "constant/routeUrl";
import { DESC, getLiveSort, getRecentLiveSort, OFFSET_SIZE, TOTAL_VIEW_CNT } from "constant/sort";
import { compare_broad } from "helpers";
import i18next from "i18next";
import React, { useCallback, useMemo, useState,useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import LiveEmptySection from "./LiveEmptySection";
import { fetchRecent } from "actions/recent.action";

import {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";

export default function BroadingSection({handleLayer, handlePosition, handleCallbackLayer}) {
    // useDispatch
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    //useState
    const [sortOrder, setSortOrder] = useState(DESC);
    const [sortColumn, setSortColumn] = useState(TOTAL_VIEW_CNT);
    const [limit, setLimit] = useState(OFFSET_SIZE);
    const {groupIdx} = useContext(FavoriteGroupContext);


    // useSelector
    const { data } = useSelector(state => (state.broadingReducer), shallowEqual);

    // useMemo
    const filteredData = useMemo(() => {
        return data.slice().sort(compare_broad(sortColumn, sortOrder)).slice(0, limit);
    }, [data, limit, sortColumn, sortOrder]);




    /**
     * 정렬
     */
    const onClickOrdering = useCallback((sortColumn, sortOrder) => {
        setSortColumn(sortColumn);
        setSortOrder(sortOrder);
    }, []);

    /**
     * 더보기
     */
    const onClickMore = useCallback((e) => {
        setLimit(limit + OFFSET_SIZE);
    }, [limit]);


    /**
     * 새로고침
     */
    const onClickRefresh = useCallback((e, groupIdx) => {
        e.preventDefault();
        switch (pathname) {
            case FAVORITE_ROUTE:
                // 즐겨찾기 정보 조회
                dispatch(fetchFavorite(groupIdx));
                // 즐겨찾기 할 만한 방송 조회
                //dispatch(fetchRecommand());
                break;

            case SUBSCRIBE_ROUTE:
                // 구독 정보 조회
                dispatch(fetchSubscribe());
                break;

            case FANCLUB_ROUTE:
                // 팬클럽 정보 조회
                dispatch(fetchFanclub());
                break;

            case RECENT_ROUTE:
                // 최근본 방송 조회
                dispatch(fetchRecent());
                break;

            default:
                return null;
        }
        // VOD 조회
        //dispatch(fetchVod(pathname));
        setLimit(OFFSET_SIZE);
    }, [dispatch, pathname]);


    const options = useMemo(() => pathname === RECENT_ROUTE ? getRecentLiveSort() : getLiveSort(), [pathname]);

    return (
        <>
            <TitleSection className="line_none">{i18next.t(`방송 중`)}</TitleSection>
            {(data.length > 0) ? (
                <>
                    <div className="sort-wrap">
                        <SortButtonGroup options={options} sortColumn={sortColumn} sortOrder={sortOrder} onChange={onClickOrdering} />
                        <div className="rt_group">
                            <button type="button" className="reload" onClick={(e)=>{onClickRefresh(e,groupIdx);}}><span>{i18next.t(`새로고침`)}</span></button>
                        </div>
                    </div>


                    <div className="cBox-list" data-broadcast="live">
                        <ul>
                            {filteredData.map((broad, index) => (
                                <li key={index} data-type="cBox">
                                    <LiveItem broad={broad} pathname={pathname} onChangeLayer ={handleLayer} onPosition={handlePosition} onCallBackChangeLayer={handleCallbackLayer} />

                                </li>
                            )
                            )}
                        </ul>


                        {data.length > limit && <MoreButton onClick={onClickMore} />}
                    </div>
                </>
            ) : (
                    <LiveEmptySection />
                )
            }

        </>)
}

