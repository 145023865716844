import React, { useState, useCallback } from "react";

export default function FeedHashImage({ photo, onLoad }) {
	const [imgStatus, setImgStatus] = useState(true);
	const handleError = useCallback(() => {
		setImgStatus(false);
	}, []);

	if (photo.length > 0) {
		if (imgStatus === false) {
			return (
				<div className="item_thum">
					<div className="thum">
						<span className="thumb-default">기본</span>
					</div>
				</div>
			);
		} else {
			return (
				<div className="item_thum">
					<div className="thum">
						<img className="image" src={photo[0].url} alt="" loading="lazy" onLoad={onLoad} onError={handleError} />
						{photo.length > 1 && <span className="img_cnt">{photo.length}</span>}
					</div>
				</div>
			);
		}
	}
}
