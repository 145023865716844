import FavoriteIcon from 'components/box/FavoriteIcon';
import PinButton from 'components/button/PinButton';
import LiveWatchButton from "components/button/LiveWatchButton";
import AlarmButton from "components/button/AlarmButton";
import { makeProfileUrl, makeStationUrl } from 'helpers';
import i18next from 'i18next';
import React from 'react';
import { useLocation } from "react-router";

import ThreeDotHistoryButton from "components/button/ThreeDotHistoryButton";
import ProfileImage from "components/image/ProfileImage";

export default function FavoriteItem({ favorite, onChangeLayer, onPosition, onCallBackChangeLayer }) {
    const { pathname } = useLocation();
   
    return (
        <>
            <a href={makeStationUrl(favorite.user_id)} className="wrap" target="_blank" >
                <ProfileImage tag="div" src={makeProfileUrl(favorite.user_id)} user_id={favorite.user_id} />
                <div className="nick"><strong>{`${favorite.user_nick}`}</strong>
                    {favorite.is_subscribe && <span className="ico_subscribe">{i18next.t(`구독`)}</span>}
                    {favorite.is_fanclub && <span className="ico_fan">{i18next.t(`팬`)}</span>}
                </div>
                <span className="id">{favorite.user_id}</span>
                {!favorite.is_live && <span className="last_live">{i18next.t(`최근방송`)} <em>{favorite.last_broad_start}</em></span>}
            </a>
            {favorite.is_live && <LiveWatchButton broad={favorite.broad_info[0]} />}
            <PinButton item={favorite} />
            <AlarmButton item={favorite} />
            <FavoriteIcon  item={favorite}  className="btn-fav" />
            {(pathname === '/favorite') &&
                <ThreeDotHistoryButton

                    trigger={
                        <button type="button" className="more_dot">
                            <span>더보기 메뉴</span>
                        </button>
                    }
                    onPosition={onPosition}
                    onChangeLayer={onChangeLayer}
                >
                { 
                    <button type="button" onClick={onCallBackChangeLayer("FAVORITELIST", favorite.user_id)}>
                    <span>{i18next.t(`즐찾BJ 그룹에 담기`)}</span>
                    </button>
                }
                </ThreeDotHistoryButton>
            }
        </>
    )
}
