import { VODS } from "actions/vods.action";
import produce from "immer";

const initialState = {
    loading: false,
    status: "",
    data: [],
    error: null
};

const vodsReducer = (state = initialState, action) => {
    switch (action.type) {
            
        case VODS.VOD_LOADING_START:
            return { ...state, loading: true, status: "LOADING" };

        case VODS.VOD_LOADING_DONE:
            return { ...state, loading: false, status: "CLEAR"};

        case VODS.VOD_FETCH_MORE_SUCCESS: // 더보기시 concat 통해 데이터 추가
            return { ...state, hasMore:action.payload.has_more, data: state.data.concat(action.payload.data), error: null, currentPage:action.payload.current_page };

        case VODS.VOD_FETCH_SUCCESS: // 리스트시 새로운 데이터로 덮히도록 
            return { ...state, hasMore:action.payload.has_more, data: action.payload.data, error: null, currentPage:action.payload.current_page };

        case VODS.VOD_FETCH_DELETE_SUCCESS:
            return produce(state, draft => {
                let index = -1;
                if(action.payload.type === 'live') {
                    index = draft.data.findIndex(item => item.broad_no === parseInt(action.payload.no));
                } else if(action.payload.list_idx > 0) {
                    index = draft.data.findIndex(item => item.list_idx === parseInt(action.payload.list_idx));
                } else {
                    if(window.location.pathname === "/history/up/vod") {
                        index = draft.data.findIndex(item => item.title_no === parseInt(action.payload.title_no));
                    } else {
                        index = draft.data.findIndex(item => item.title_no === parseInt(action.payload.no));
                    }
                }

                if(index > -1) {
                    draft.data.splice(index, 1);
                }
            });

        case VODS.VOD_FETCH_ERROR:
            return { ...state, error: action.payload };
        case VODS.VOD_INIT:
            return initialState;
        default:
            return state;
    }
};

export default vodsReducer;
