import { AFREECATV_DOMAIN, EVENTAPI_URL } from "constant/config";
import querystring from "querystring";
import cookie from "react-cookies";
import { isMacOs, isMobile } from "react-device-detect";

export const liveInflowLog = (bj_id, broadNo, paths, etc={}) => {
    inflowLog("LIVE", bj_id, broadNo, paths,etc);
};
export const vodInflowLog = (bj_id, titleNo, paths, etc={}) => {
    inflowLog("VOD", bj_id, titleNo, paths, etc);
};
/**
 * 진입로그
 * @param {string} code_type 타입
 * @param {string} bj_id
 * @param {number} contentNo 콘텐츠번호
 * @param {array} paths
 */
export const inflowLog = (code_type, bj_id, contentNo, paths = [], etc) => {
    let cookieName = "";
    let inflowLogData = [];
    const tmToday = new Date();
    const _au = cookie.load("_au");
    const path_key = _au + "_" + (!!contentNo && Number(contentNo) !== 0 ? contentNo : bj_id) + "_" + tmToday.getTime();
    const inflowPath = paths.reduce((accumulator, value, index) => {
        accumulator[`path${index + 1}`] = value;
        return accumulator;
    }, []);

    const sysType = isMobile ? "webm" : "web";
    const os = isMacOs ? "mac" : "win";

    switch (code_type) {
        case "LIVE":
            cookieName = "LIN";
            inflowLogData = { bj_id, broad_no: contentNo, referer: window.location.href, ...inflowPath, os, ...etc };
            break;
        case "VOD":
            cookieName = "VIN";
            inflowLogData = { bj_id, title_no: contentNo, referer: window.location.href, ...inflowPath, os, ...etc };
            break;
        default:
            break;
    }

    /**
     * 로그호출
     */
    fetch("//eventapi.afreecatv.com/set_log_api.php", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({
            sztype: "INFLOW_PATH",
            code_type,
            path_key,
            send_data: querystring.stringify(inflowLogData),
            sys_type: sysType,
        }),
        credentials: "include",
    }).then(() => {});

    /**
     * 쿠키셋팅
     */
    tmToday.setTime(tmToday.getTime() + 10 * 1000); //10초 후 만료
    const cookieValue = querystring.stringify({ path_key, ...inflowPath, ...etc });
    cookie.save(cookieName, cookieValue, { path: "/", expires: tmToday, domain: AFREECATV_DOMAIN });
};

/**
 * VOD UA LOG
 * @param {string} btn_type 공유하기 버튼 이름
 */
export const sendVodUaLogBtnType = (btnType) => {
    const szEtcData = `location=MY&action=share&share_type=url&btn_type=${btnType}`;

    // 로그 호출
    fetch(`${EVENTAPI_URL}/set_log_api.php`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: querystring.stringify({
            sztype : "VOD_UA",
            send_data : szEtcData
        }),
        credentials: "include",
    }).then(() => {}).catch(err => {console.log(err);});
}