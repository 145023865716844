import { destroyFavorite, storeFavorite } from "actions/feed.favorite.action";
import { usePopup } from "components/context/PopupProvider";
import { NOTE_URL, ROOT_URL } from "constant/config";
import { goLogin, handleFetchErrors, makeStationUrl, recentCookieSet } from "helpers";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-navigationbar";
import { FAVORITE_GROUP } from "actions/favorite.group.action";
import FavoriteGroupProvider, {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export default function ContextMenu({ children, user_id, user_nick }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isFavorite, setIsFavorite] = useState(false);
    const { isActivePopup, togglePopup, closePopup } = usePopup();
    const { isLogin } = useAuth();
    
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);
    const { group_data } = useSelector((state) => state.favoriteGroupReducer, shallowEqual);
    const { extdata, error, loading } = useSelector(state => (state.favoriteExtReducer), shallowEqual);


    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            togglePopup();
        },
        [togglePopup],
    );

    useEffect(() => {
        if (isActivePopup) {
            /**
             * 즐착여부
             */
            if (isLogin) {
                fetch(`${ROOT_URL}/favorite/${user_id}/relation`, {
                    credentials: "include",
                })
                    .then(handleFetchErrors)
                    .then((response) => response.json())
                    .then((response) => {
                        setIsFavorite(response.RESULT);
                    })
                    .catch((error) => {
                        setIsFavorite(false);
                    });
            }
        }
    }, [isActivePopup, isLogin, user_id]);

    /**
     * 버튼 이벤트 추가
     */
    const renderChildren = () => {
        const childrenProps = { key: "T" };
        childrenProps.onClick = handleClick;
        if (typeof children === "function") {
            return React.cloneElement(children(), childrenProps);
        } else if (children.length > 1) {
            return React.Children.map(children, (child, index) => React.cloneElement(child, { ...childrenProps, key: index }));
        } else {
            return React.cloneElement(children, childrenProps);
        }
    };

    /**
     * 즐찾 추가
     */
    const handleAddFavorite = useCallback(
        (e) => {
            e.preventDefault();
            if (!isLogin) {
                if (window.confirm(t("로그인이 필요한 기능입니다.\n로그인 하시겠습니까?"))) {
                    goLogin();
                }
            } else {
                dispatch(storeFavorite(user_id));
                closePopup();
            }
        },
        [closePopup, dispatch, isLogin, t, user_id],
    );

    /**
     * 즐찾 제거
     */
    const handleRemoveFavorite = useCallback(
        (e) => {
            e.preventDefault();
            if(window.confirm(t("해당 BJ를 즐겨찾기에서 삭제하시겠습니까?"))){
                let group = group_data.filter((item=>item.idx ===groupIdx))
                //즐찾 그룹에 한명만 있을 경우 즐찾 그룹 삭제 UI 로직 동작
                if(extdata.length ===1) {
                    if(group.length ===1) setGroupIdx(0);
                    dispatch({type:FAVORITE_GROUP.FETCH_DEL_DATA,payload:groupIdx})
                }
                dispatch(destroyFavorite(user_id, user_nick));
            }
            closePopup();
        },
        [closePopup, dispatch, user_id, user_nick, extdata],
    );

    /**
     * 닉네임 검색
     */
    const handleNickSearch = useCallback((e) => {
        e.preventDefault();
        const szUserNick = encodeURIComponent(user_nick);
        recentCookieSet(user_nick);
        window.open(`//www.afreecatv.com/total_search.html?szSearchType=total&szStype=ni&szKeyword=${szUserNick}&szLocation=my_main`);
    });

    const handleGoStation = useCallback(
        (user_id) => (e) => {
            window.open(makeStationUrl(user_id));
        },
        [],
    );

    const handleSendNote = useCallback(
        (user_id) => (e) => {
            let oWindow = window.open(
                `${NOTE_URL}&id_list=${user_id}`,
                "noteWriteWindow",
                "left=10,top=10,width=400,height=440,marginwidth=0,marginheight=0,resizable=0,scrollbars=no",
            );
            if (!oWindow) alert(t("차단된 팝업을 허용해주세요."));
            else oWindow.focus();
        },
        [t],
    );

    return (
        <>
            {/**  버튼 */}
            {renderChildren()}

            {/**  ICT 레이어 */}
            {isActivePopup && (
                <div className="icon-menu">
                    <button type="button" className="bj_bs" tip={t("방송국")} onClick={handleGoStation(user_id)}>
                        <span>{t("방송국 바로가기")}</span>
                    </button>
                    <button type="button" className="send_memo" tip={t("쪽지 보내기")} onClick={handleSendNote(user_id)}>
                        <span>{t("쪽지 보내기")}</span>
                    </button>
                    {isFavorite ? (
                        <button type="button" className="favor on" tip={t("즐겨찾기 삭제")} onClick={handleRemoveFavorite}>
                            <span>{t("즐겨찾기")}</span>
                        </button>
                    ) : (
                        <button type="button" className="favor" tip={t("즐겨찾기 추가")} onClick={handleAddFavorite}>
                            <span>{t("즐겨찾기")}</span>
                        </button>
                    )}
                    <button type="button" className="srh" tip={t("닉네임 검색")} onClick={handleNickSearch}>
                        <span>{t("닉네임 검색")}</span>
                    </button>
                </div>
            )}
        </>
    );
}
