import { getLanguageStr, getLocale } from "helpers";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import {
    navigationBarEN,
    navigationBarJA,
    navigationBarTH,
    navigationBarVI,
    navigationBarZH,
    navigationBarZHTW,
} from "react-navigationbar/libs/locales";
import en from "./locales/en";
import ja from "./locales/ja";
import th from "./locales/th";
import vi from "./locales/vi";
import zh from "./locales/zh";
import zhTW from "./locales/zh-TW";

const lang = getLocale();

//<html lang="{lang}">
document.documentElement.lang = lang;

//<body class="{lang}">
const classString = document.body.className;
const classes = classString.split(" ");
classes.push(getLanguageStr(lang));
classes.filter((item, index) => classes.indexOf(item) !== index);
document.body.className = classes.join(" ");

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .on("languageChanged", (lng, ns, msg) => {
        document.title = i18next.t(`아프리카TV`);
    })
    .init({
        lng: lang,
        debug: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        nsSeparator: false, //allow keys to be phrases having `:`
        fallbackLng: "ko",
        react: {
            useSuspense: false,
        },
        resources: {
            ja: {
                translation: { ...ja.lang, ...navigationBarJA },
            },
            en: {
                translation: { ...en.lang, ...navigationBarEN },
            },
            th: {
                translation: { ...th.lang, ...navigationBarTH },
            },
            vi: {
                translation: { ...vi.lang, ...navigationBarVI },
            },
            zh: {
                translation: { ...zh.lang, ...navigationBarZH },
            },
            "zh-TW": {
                translation: { ...zhTW.lang, ...navigationBarZHTW },
            },
        },
    });
