import ReactToast from "afreecatv-react-toast";
import PopupProvider from "components/context/PopupProvider";
import FavoriteGroupProvider from "components/context/FavoriteGroupProvider";
import WindowSizeProvider from "components/context/WindowSizeProvider";
import ScrollToTop from "components/ScrollToTop";
import ButtonTop from "components/top/ButtonTop";
import DefaultModal from "components/modal/DefaultModal";
import loadScript from "load-script";
import Fanclub from "pages/fanclub/Fanclub";
import Favorite from "pages/favorite/Favorite";
import Feed from "pages/feed/Feed";
import Hotissue from "pages/hotissue/Hotissue";
import Recent from "pages/recent/Recent";
import Story from "pages/story/Story";
import Subscribe from "pages/subscribe/Subscribe";
import History from "pages/history/History";
import React, { useEffect } from "react";
import { AuthProvider, Footer, GNB, LNB, LnbMiniProvider } from "react-navigationbar";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, FEED_ROUTE, HOTISSUE_ROUTE, RECENT_ROUTE, STORY_ROUTE, SUBSCRIBE_ROUTE, HISTORY_ROUTE } from "./constant/routeUrl";

import useAutoHashTag from "components/hook/useAutoHashTag";

function App() {
    return (
        <AuthProvider>
            <WindowSizeProvider>
                <PopupProvider>
                <FavoriteGroupProvider>
                    <BrowserRouter>
                        <ScrollToTop />
                        <Pages />
                    </BrowserRouter>
                </FavoriteGroupProvider>
                </PopupProvider>
                
            </WindowSizeProvider>
        </AuthProvider>
    );
}

function Pages() {
    let location = useLocation();
    const { initAutoHashStyle } = useAutoHashTag();

    useEffect(() => {
        loadScript("//analysis.afreecatv.com/_au.js");
    }, [location]);

    const lang = document.documentElement.lang;

    useEffect(() => {
        if(lang != "ko"){
            // MY+ 서비스 국내만 적용
            // 기존 listWrap로 변경
            document.getElementById("listMain").setAttribute("id", "listWrap");
        }
        /**
         * 
         */
        initAutoHashStyle();
    }, [lang]);

    return (
        <>
            <div id="wrap">
                <LnbMiniProvider>
                    <GNB />
                    <LNB />
                </LnbMiniProvider>
                <div id="list-container">
                    <Switch>
                        <Route path={FAVORITE_ROUTE} component={Favorite} />
                        <Route path={SUBSCRIBE_ROUTE} component={Subscribe} />
                        <Route path={FANCLUB_ROUTE} component={Fanclub} />
                        <Route path={RECENT_ROUTE} component={Recent} />
                        <Route path={HISTORY_ROUTE} component={History} />
                        <Route path={FEED_ROUTE} component={Feed} />
                        <Route path={HOTISSUE_ROUTE} component={Hotissue} />
                        <Route path={STORY_ROUTE} component={Story} />
                        <Redirect path="*" to={FAVORITE_ROUTE} />
                    </Switch>
                    {lang === "ko" && (
                        <Footer />
                    )}
                </div>
            </div>
            <DefaultModal/>
            {lang != "ko" && (
                <Footer />
            )}
            <ButtonTop />
            <ReactToast />
        </>
    );
}

export default App;
