import i18next from "i18next";
// 라이브
export const LIVE = "LIVE";
// 게시글
export const POST_PHOTO = "POST_PHOTO";
// 글
export const POST = "POST";
// 이미지
export const PHOTO = "PHOTO";
// 영상
export const VOD = "VOD";
// 공지사항
export const NOTICE = "NOTICE";

// 방송국 후원게시글
export const BJSUPPORT = "BJSUPPORT";

// 댓글
export const PARENT_COMMENT = "PARENT_COMMENT";
// 답글
export const CHILD_COMMENT = "CHILD_COMMENT";



// 해시태그
export const HASHTAGS = "HASHTAGS";
// 실시간 핫이슈
export const HOTISSUE = "HOTISSUE";

export const getFeedOptions = () => {
    return [
        { value: null, name: i18next.t("전체보기") },
        { value: NOTICE, name: i18next.t("공지글 보기") },
        { value: POST_PHOTO, name: i18next.t("게시글 보기") },
    ]
}



export const getStoryOptions = () => {
    return [
        { value: '', name: i18next.t("전체보기") },
        { value: POST_PHOTO, name: i18next.t("게시글 보기") },
        { value: PARENT_COMMENT, name: i18next.t("댓글 보기") },
        { value: CHILD_COMMENT, name: i18next.t("답글 보기") },
    ]
}