import LiveItem from "components/item/LiveItem";
import TitleSection from "components/section/TitleSection";
import i18next from "i18next";
import React, { useMemo, useState, useCallback, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import SwiperController from "components/swiper/SwiperController";
import useSildeCount from "components/hook/useSildeCount";
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

export default function RecommandSection({ pathname,  onChangeLayer, onPosition, onCallbackLayer}) {
    // useSelector
    const { data } = useSelector(state => (state.recommandsReducer), shallowEqual);
    // useMemo
    const recommandIds = useMemo(() => data.map((info) => info.user_id), [data]);

    const ref = useRef(null);
    const count = useSildeCount();

    if (data.length > 0) {
        return (
            <>
                <TitleSection>{i18next.t(`즐겨찾기할 만한 BJ`)}</TitleSection>


                <div className="slide-vod">
                    {(data.length > 6) && <SwiperController swiperRef={ref} autoplay={false} />}
                    <Swiper
                        data-broadcast={'live'}
                        ref={ref}
                        autoplay={false}
                        breakpoints= {{
                            // 1280px 보다 클 경우
                            2183: {
                              slidesPerView: 6,
                            },
                            1921 :{
                                slidesPerView:5,
                            },
                            640: {
                                slidesPerView:4
                            }
                          }
                        }
                        
                        wrapperTag="ul"
                        spaceBetween={20}
                        className={'swiper-container'}
                        slidesPerView={count}
                        allowTouchMove={false}
                        loop={data.length > 6}
                    >
                        {data.map((broad, index) => {
                            return (
                                <SwiperSlide tag="li" key={index} data-type="cBox">
                                   <LiveItem broad={broad} recommand={true} recommandIds={recommandIds} onPosition={onPosition} onChangeLayer={onChangeLayer} onCallBackChangeLayer={onCallbackLayer} />
                                </SwiperSlide>);
                        })}
                    </Swiper>
                </div>
            </>
        );
    } else {
        return null;
    }
}