import { HOTISSUE_TODAY, HOTISSUE_POPULAR, POPULAR_BLIND, HOTISSUE_POPULAR_VOD, HOTISSUE_CLEANUP } from "actions/hotissue.action";
import produce from "immer";
import { FEED_LIKE } from "actions/feed.like.action";

const initialState = {
    loading: false,
    payload: { meta: {}, data: [] },
    vods: [],
    error: null
};


const hotissueReducer = (state = initialState, action) => {
    switch (action.type) {
        case HOTISSUE_TODAY.LOADING_START:
        case HOTISSUE_POPULAR.LOADING_START:
            return { ...state, loading: true };

        case HOTISSUE_TODAY.LOADING_DONE:
        case HOTISSUE_POPULAR.LOADING_DONE:
            return { ...state, loading: false };

        /**
         * 아생 > 투데이,실시간인기글
         */
        case HOTISSUE_POPULAR.FETCH_SUCCESS:
        case HOTISSUE_TODAY.FETCH_SUCCESS:
            return produce(state, (draftState) => {
                draftState.payload.refresh_time = action.payload.refresh_time
                draftState.payload.refresh_cycle = action.payload.refresh_cycle
                draftState.payload.meta = action.payload.meta
                draftState.payload.data = state.payload.data.concat(action.payload.data);
            });

        case HOTISSUE_TODAY.FETCH_ERROR:
            return { ...state, error: action.payload };


        /**
         * 핫이슈 동영상
         */
        case HOTISSUE_POPULAR_VOD.FETCH_SUCCESS:
            return { ...state, vods: action.payload.data };


        /**
         * 초기화
         */
        case HOTISSUE_CLEANUP:
            return initialState;

        /**
         * 좋아요
         */
        case FEED_LIKE.LIKE:
            return produce(state, (draftState) => {
                let item = draftState.payload.data[draftState.payload.data.findIndex(item => item.title_no === action.title_no)]
                if (item) {
                    item.is_like = true;
                }
            });
        /**
         * 좋아요 취소
         */
        case FEED_LIKE.UNLIKE:
            return produce(state, (draftState) => {
                let item = draftState.payload.data[draftState.payload.data.findIndex(item => item.title_no === action.title_no)]
                if (item) {
                    item.is_like = false;
                }
            });

        /**
         * 블라인드
         */
        case POPULAR_BLIND.BLIND:
            return produce(state, (draftState) => {
                draftState.payload.data.forEach((item) => {
                    if (item.user_id === action.user_id) {
                        item.is_blind = true;
                    }
                })
            });
        /**
         * 블라인드 취소
         */
        case POPULAR_BLIND.CANCEL:
            return produce(state, (draftState) => {
                draftState.payload.data.forEach((item) => {
                    if (item.user_id === action.user_id) {
                        item.is_blind = false;
                    }
                })
            });

        default:
            return state;
    }
};

export default hotissueReducer;
