import { fetchFavoriteGroupItem } from "actions/favorite.groupitem.action";
import { fetchFavoriteFeeds } from "actions/favorite.content.action";
import {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";

import usePostsSildeCount from "components/hook/usePostsSildeCount";
import useModal from "components/modal/useModal";
import { sendLogApi } from "helpers";
import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SwiperCore, {Controller, Navigation} from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import querystring from "querystring";
import { AFREECATV_DOMAIN } from "constant/config";
import i18next from "i18next";
import {JAVASCRIPT_VOID} from "constant/config";


export default function FavoriteGroupSection({children, favorite, fetchFavData}) {
    const ref = useRef(null);
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);
    
    const [menuSwiper, setMenuSwiper] = useState(null);
    const [loadSwiper, setLoadSwiper] = useState(null); //swiper 완전 로드 체크 
    // dispatch
    const dispatch = useDispatch();
    const [convertData, setConvertData] = useState([]);
    // useSelector

    const { group_data } = useSelector((state) => state.favoriteGroupReducer, shallowEqual);


    const {handleFixFavGroupModal, handleListFavGroupModal} = useModal();
    
     const swiperParam ={
        navigation :{
            nextEl : rightRef.current,
            prevEl : leftRef.current
        },
        onInit : (swiper) =>{
            setTimeout(function(){
                if(typeof swiper !=="undefined" && typeof swiper.params !== "undefined"){
                    swiper.params.navigation.nextEl = rightRef.current;
                    swiper.params.navigation.prevEl = leftRef.current;
                    swiper.navigation.update()
                    setLoadSwiper(true);
                }
            },200);
        }
    }


    const count = usePostsSildeCount();

    const handleSelectFavGroup = (idx, title)=>{
        dispatch(fetchFavoriteGroupItem(idx));
        fetchFavData(idx);
        setGroupTitle(title);
        setGroupIdx(idx);
        sendLogApi("fav_grouping", {
            button_type:idx===0 ? 'group_all' : 'group_custom',
            location: 'favorite',
            group_name:idx===0 ? '':title
        });
    }

    useEffect(()=>{
        let list =[];
        if(favorite.length > 0)
        {
            list = [{idx:0,is_select:false,sort:0,title:i18next.t("전체"),total_cnt:0}];
            list = [...list, ...group_data];
        }
        setConvertData(list)
    },[group_data])
    
        return (
            <>
            <div className="fixSection">
                {children}
                {convertData.length >0 &&
                <div className="favorGroup_wrap">
                    <div className="favorGroup" style={!loadSwiper ? {"display":"none"} : {}}>
                        <div className="posMenu" >
                            <Swiper 
                                {...swiperParam}
                                modules={[Controller,Navigation]}
                                onSwiper={setMenuSwiper}
                                controller={{ control: menuSwiper }}
                                className={"sub-tab-list"}
                                ref={ref}
                                wrapperTag="ul"
                                spaceBetween={12}
                                slidesPerGroup={1}
                                slidesPerView={"auto"}
                                allowTouchMove={false}
                                
                                
                                onSlideChange={() => console.log("slide change")}
                                onSwiper={(swiper) => { console.log(swiper)}}
                            >
                            {convertData.map((item, index) => {
                                return(
                                    <SwiperSlide className={groupIdx === item.idx ? "on" :""} tag="li" key={index}>
                                        <a  onClick = {(e)=>handleSelectFavGroup(item.idx, item.title)} href={JAVASCRIPT_VOID}>{item.title}</a>
                                    </SwiperSlide>
                                    )
                                })
                            }
                            {convertData.length<=10 &&
                                <SwiperSlide tag="li" className="addGroup" onClick={(e)=>{
                                    sendLogApi("fav_grouping", {
                                        button_type:'create_method1',
                                        location: 'favorite',
                                    });
                                    handleFixFavGroupModal("addgroup", true,"",0, false)}}>
                                        <a  href={JAVASCRIPT_VOID}>{}</a>
                                </SwiperSlide>
                            }      
                            </Swiper>
                        
                            <div ref={rightRef} className="swiper-button-next"></div>
                            <div ref={leftRef} className="swiper-button-prev" ></div>
                        </div>
                        {group_data.length> 0 && <button type="button" className="goupEdit" onClick={(e)=>handleListFavGroupModal("updgroups",true.valueOf, false)}>{i18next.t("편집")}</button>}
                    </div>
                </div>
                }
            </div>
            </>
        );
}
