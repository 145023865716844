import {
    AFREECATV_DOMAIN,
    EVENTAPI_URL,
    LOGIN_URL,
    PLAY_URL,
    PROFILE_IMG_URL,
    RECOMMAND_API_URL,
    RES_AFREECATV_URL,
    STATION_URL,
    VOD_URL,
    STIMG_URL
} from "constant/config";
import { GET, POST } from "constant/method";
import querystring from "querystring";
import cookie from "react-cookies";
import i18next from "i18next";

import { sprintf } from "sprintf-js";

//error 발생하면 같은 메시지로는 업데이트 되지 않음
export const numberCommaString = (value) => {
    
    if (!value) {
        return 0;
    }
    
    if(typeof(value) === 'string' && value.includes(',')) {
        return value;
    }

    return Number(value).toLocaleString();
};

/**
 * 초를 시간으로 표시
 * @param {*} seconds
 */
export function secondToTime(sec) {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = Math.floor(sec) - hours * 3600 - minutes * 60;
    let time = "";

    if (hours !== 0) {
        time = hours + ":";
    }

    time += hours > 0 ? String(minutes).padStart(2, '0') :  String(minutes);
    time += ":";
    time += String(seconds).padStart(2, '0');
    return time;
}

/**
 * IE11에서 localStorage가 안되는 경우가 있어 cookie로 맵핑합니다.
 * https://gist.github.com/juliocesar/926500
 */
export const localStorageFactory = (() => {
    try {
        return localStorage;
    } catch (e) {
        function Storage() {}
        Storage.prototype.setItem = function (sKey, sValue) {
            if (!sKey) {
                return;
            }
            document.cookie = escape(sKey) + "=" + escape(sValue) + "; expires=Tue, 19 Jan 2038 03:14:07 GMT; path=/";
        };
        Storage.prototype.getItem = function (sKey) {
            if (!sKey) {
                return null;
            }
            return unescape(
                document.cookie.replace(
                    new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"),
                    "$1",
                ),
            );
        };
        Storage.prototype.removeItem = function (sKey) {
            if (!sKey || !this.hasOwnProperty(sKey)) {
                return;
            }
            document.cookie = escape(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
            this.length--;
        };
        Storage.prototype.clear = function () {};
        return new Storage();
    }
})();

/**
 * 프로필 이미지 URL 생성
 * @param  user_id 유저 아이디
 * @return 프로필 이미지 URL
 */
export const makeProfileUrl = (user_id = "", errorCheck = false) => {
    if(errorCheck) {
        return `${PROFILE_IMG_URL}/LOGO/${user_id.substr(0, 2)}/${user_id}/${user_id}.jpg`;        
    }

    return `${STIMG_URL}/LOGO/${user_id.substr(0, 2)}/${user_id}/m/${user_id}.webp`;
};

/**
 * 방송국 링크 URL 생성
 * @param  user_id 유저 아이디
 * @return 방송국 링크 URL
 */
export const makeStationUrl = (user_id) => {
    return `${STATION_URL}/${user_id}`;
};

/**
 * 방송국 게시물 링크 URL 생성
 * @param  user_id  유저 아이디
 * @param  title_no 게시물 번호
 * @return
 */
export const makeStationPostUrl = (user_id, title_no) => {
    return `${STATION_URL}/${user_id}/post/${title_no}`;
};

/**
 * 라이브 링크 URL 생성
 * @param  bj_id    BJ 아이디
 * @param  broad_no 방송 번호
 * @return 라이브 링크 URL
 */
export const makePlayUrl = (bj_id, broad_no) => {
    return `${PLAY_URL}/${bj_id}/${broad_no}`;
};

/**
 * 동영상 링크 URL 생성 (방송국 동영상용)
 * @param  title_no 동영상 게시물 번호
 * @return 동영상 링크 URL 생성
 */
export const makeVodUrl = (title_no, pathname, sortColumn="", pageNo = 1) => {
    let sort = sortColumn === 'read_cnt' ? "VIEW_CNT" : "REG_DATE";

    if(pathname === '/history/later') {
        return `${VOD_URL}/player/${title_no}/later#${sort}/${pageNo}`;
    } else {
        return `${VOD_URL}/player/${title_no}`;
    }
};

/**
 * 재생목록 링크 URL 생성
 * @param title_no 게시글 번호 
 * @param playlist_idx 재생목록 번호 
 */
export const makePlaylistUrl = (playlist) => {
    /**
     * 서버쪽에서 url을 만들어 주기 때문에 return url만 진행
     */
    return playlist.url;
};

/**
 * 방송 리스트용 정렬 기능 (is_pin 기능 미포함)
 * @param  type  비교할 필드명
 * @param  order 오름차순, 내림차순 (desc, asc)
 * @return
 */
export const compare_broad = (type, order) => {
    return (a, b) => {
        if (a[type] < b[type]) {
            return order;
        } else if (a[type] > b[type]) {
            return order * -1;
        } else {
            return 0;
        }
    };
};

/**
 * 유저 리스트용 정렬 기능 (is_pin 기능 포함)
 * @param  type  비교할 필드명
 * @param  order 오름차순, 내림차순 (desc, asc)
 * @return
 */
export const compare_favorite = (type, order) => {
    return (a, b) => {
        if (a.is_pin ^ (b.is_pin === false)) {
            let compare_a =
                typeof a[type] !== "undefined" ? a[type] : a["broad_info"] !== null && type in a["broad_info"] ? a["broad_info"][type] : "";
            let compare_b =
                typeof b[type] !== "undefined" ? b[type] : b["broad_info"] !== null && type in b["broad_info"] ? b["broad_info"][type] : "";

            if (compare_a < compare_b) {
                return order;
            } else if (compare_a > compare_b) {
                return order * -1;
            } else {
                return 0;
            }
        } else {
            return b.is_pin - a.is_pin;
        }
    };
};

/**
 * 로그인으로 이동
 */
export const goLogin = () => {
    window.location.href = LOGIN_URL + "/afreeca/login.php?szFrom=full&request_uri=" + encodeURIComponent(window.location.href);
    return false;
};

/**
 * 클릭 로그 전송하기 (http://wiki.afreecatv.com/wiki/%EC%95%84%ED%94%84%EB%A6%AC%EC%B9%B4TV_%EC%84%9C%EB%B9%84%EC%8A%A4_%EB%A1%9C%EA%B7%B8%ED%91%9C#CLICK)
 * @param  code_type 코드 타입 (필수)
 * @param  send_data 전송할 데이터 (object)
 * @param  sys_type  시스템 타입 (web)
 * @return
 */
export const sendLogApi = (code_type, send_data = "", sys_type = "web") => {
    if (code_type) {
        fetch(`${EVENTAPI_URL}/set_log_api.php`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
            body: querystring.stringify({
                sztype: "CLICK",
                code_type,
                send_data: buildLogQuery(send_data),
                sys_type,
            }),
            credentials: "include",
        });
    }
};

/**
 * 추천방송 진입로그  전송하기 (http://wiki.afreecatv.com/wiki/Ddukbob:%EC%B6%94%EC%B2%9C%EB%B0%A9%EC%86%A1%EC%A7%84%EC%9E%85_%EB%A1%9C%EA%B7%B8%EC%88%98%EC%A7%91_-_API)
 * @param  send_data 전송할 데이터 (object)
 * @return
 */

export const sendRecommandLogApi = (send_data = "") => {
    fetch(`${RECOMMAND_API_URL}/api_rin.php?${buildLogQuery(send_data)}`, {
        method: GET,
        credentials: "include",
    });
};

export const sendVodLog = (data, videoStart) => {
    fetch(`//stbbs.afreecatv.com/api/video/set_video_log.php`, {
        method: POST,
        headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
        body: querystring.stringify({
            nStationNo: data.station_no,
            nTitleNo: data.title_no,
            szBjId: data.user_id,
            szCategory: data.ucc.category,
            szSysType: "web",
            szPart: data.ucc.file_type,
            is_autoplay: true,
            path1: "my",
            path2: "feed",
            videoStart,
            is_preview: true,
        }),
        credentials: "include",
    });
};

/**
 * 로그 전송 쿼리 스트링 만들기
 * @param  sendData 전송할 etcData 모음 (object)
 */
export const buildLogQuery = (sendData) => {
    if (typeof sendData === "string") {
        return sendData;
    }

    let aQuery = [];

    for (var key in sendData) {
        if (sendData.hasOwnProperty(key)) {
            aQuery.push(encodeURIComponent(key) + "=" + encodeURIComponent(sendData[key]));
        }
    }

    return aQuery.join("&");
};

export const handleFetchErrors = (response) => {
    if (!response.ok) {
        return response.json().then((json) => {
            return Promise.reject(json);
        });
    }
    return response;
};

/**
 * 허용하는 언어셋 정의
 */
const allowLanguage = {
    // en: "en",
    // en_us: "en", //영어, 기본값
    ko: "ko",
    ko_kr: "ko", //한국어
    // zh: "zh",
    // zh_cn: "zh",
    // zh_hans: "zh",
    // zh_sg: "zh", //중국어 간체
    // zh_tw: "zh-TW",
    // zh_hk: "zh-TW",
    // zh_mo: "zh-TW",
    // zh_hant: "zh-TW", //중국어 번체
    // ja: "ja",
    // ja_jp: "ja", //일본어
    // th: "th",
    // th_th: "th", //태국어
    // vi: "vi",
    // vi_vn: "vi", //베트남어
};

/**
 * 언어셋 중 허용하는 언어셋이 있는지 체크
 * @param {*} languages
 */
const getAllowLanguage = (languages = []) => {
    // 헤더값에서 허용하는 문자가 있는 순서대로 조회
    let locale = "";
    
    // 현재 노출 언어 정책은 사용자가 설정한 1순위 언어만 판단한다. (차선순위 고려 x) 23.01
    let item = languages[0];
    locale = getAllowLocale(item);
    
    if (locale !== "") {
        return locale;
    }

    return locale;
};

/**
 * 허용하는 언어셋이 있는지 체크
 * @param {*} locale
 */
const getAllowLocale = (locale = "") => {
    locale = locale.toLowerCase().replace("-", "_");
    if (locale in allowLanguage) {
        return allowLanguage[locale];
    }

    // '_'을 기준으로 뒤 부터 잘라가면서 계속 체크
    let localeCodes = locale.split("_");
    while (localeCodes.length > 1) {
        localeCodes.pop();
        let tmpLocale = localeCodes.join("_");

        if (tmpLocale in allowLanguage) {
            return allowLanguage[tmpLocale];
        }
    }

    return "";
};

/**
 * 쿠키상의 브라우저 언어 알아내기
 */
const getCookieAcceptLang = () => {
    let locale = cookie.load("_lang");

    if (locale !== undefined) {
        // 지원 가능한 언어인지 체크
        return getAllowLocale(locale);
    }

    return "";
};

/**
 * 지원가능한 언어셋
 */
export const getLocale = () => {
    let locale = getCookieAcceptLang();

    // 값이 없으면 navigator에서 가져오기
    if (locale === "") {
        let languages = navigator.languages || [navigator.language || navigator.userLanguage];
        locale = getAllowLanguage(languages);

        // 값이 없으면 기본값 가져오기
        if (locale === "") {
            locale = "ko";
        }
    }

    return locale;
};

export const documentScrollTop = () => {
    //IE & Edge 예외처리
    //const someElement = document.documentElement.scrollTop && isEdge === false ? document.documentElement : document.body;
    //someElement.scrollTop = 0;
    try {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    } catch (e) {}
};

/**
 * 로컬 세션 저장
 * @param {*} keyName
 * @param {*} defaultValue
 */
export function getLocalStorageItem(keyName, defaultValue) {
    let value = localStorage.getItem(`${keyName}`);
    if (value) {
        return JSON.parse(value);
    } else {
        return defaultValue;
    }
}

export function setLocalStorageItem(keyName, keyValue) {
    if (keyValue === undefined) {
        return false;
    }
    localStorage.setItem(`${keyName}`, JSON.stringify(keyValue));
    return keyValue;
}

/**
 * 썸네일 에러 처리
 */
export function onErrorThumb(e) {
    e.target.src = `${RES_AFREECATV_URL}/images/afmain/img_thumb_defalut.gif`;
}

export const recentCookieSet = (keyword) => {
    const DELIMITER = String.fromCharCode(12);

    if (keyword == "" || keyword == "undefined" || keyword == null) {
        return;
    }
    keyword = keyword.toString();

    let csk_value = unescape(cookie.load("_csk") || "");
    let csk_data = [];
    if (csk_value !== "") {
        csk_data = csk_value.split(DELIMITER);
    }

    csk_data.splice(0, 0, keyword);

    //중복제거
    const uniq = csk_data.reduce(function (a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);

    csk_value = uniq.slice(0, 10).join(DELIMITER); // 10개만 저장
    const expires = new Date();
    expires.setDate(new Date().getDate() + 1);
    cookie.save("_csk", csk_value, { domain: AFREECATV_DOMAIN, expires: expires, encode: escape });
};
/**
 * 줄바꿈을 <br> 태그로 변경
 * @param string str
 */
export function nl2br(str) {
    return str && str.replace(new RegExp("\r?\n", "g"), "<br />");
}

/**
 * 언어셋 코드값
 * @param {*} lang
 */
export const getLanguageStr = (lang) => {
    switch (lang) {
        case "en":
            return "en_US";
        case "ja":
            return "ja_JP";
        case "th":
            return "th_TH";
        case "zh":
            return "zh_CN";
        case "zh-TW":
            return "zh_TW";
        case "vi":
            return "vi_VN";
        default:
            return "ko_KR";
    }
};

const stringTimeToTimeStamp = (regDate, divide = true) => {
    let date = new Date(regDate);

    if(isNaN(date)) {
        let dateTime = regDate.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/);
        if(dateTime != null){
            date = new Date(dateTime[1],(dateTime[2]-1),dateTime[3],dateTime[4],dateTime[5],dateTime[6]);
        }else{
            return '';
        }
    }

    let timeStamp = date.getTime();

    return timeStamp;
}

/**
 * 동영상 업로드 시간을 3일전까지는 상대날짜, 이후는 절대 날짜 형식 yyyy-mm-dd 으로 보여주는 함수
 * @param string regDate
 */

export const regDateConvert = (regDate) => {
    if(typeof regDate === 'string') {
        regDate = stringTimeToTimeStamp(regDate, false) / 1000;
    }
    
    let now = new Date()/1000;
    let diff = Math.abs(now - regDate);
    let TTS = {
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    let day = Math.floor(diff/TTS.day);
    let hour = Math.floor(diff/TTS.hour);
    let minute = Math.floor(diff/TTS.minute);
    let second = Math.floor(diff/TTS.second);

    // console.log(now, regDate, diff, day, hour, minute, second);
    
    if(day < 4) { // 3일전
        if(day > 0) {
            return sprintf(i18next.t('%d일 전'), day);
        }
        if(hour > 0) {
            return  sprintf(i18next.t('%d시간 전'), hour);
        }
        if(minute > 0) {
            return sprintf(i18next.t('%d분 전'), minute);
        }
        if(second > 0) {
            return sprintf(i18next.t('%d초 전'), second);
        }
    }

    let date = new Date(regDate * 1000);
    let convertDate = [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + (date.getDate())).slice(-2)
    ].join('-');

    return convertDate;

}

/**
 * 성인 여부 판단 함수
 * @param string grade
 * @param string category
 */

export const isAdultType = (grade, category) =>{
    return  parseInt(grade) === 19 && parseInt(category) === 30000; //성인여부
}