import querystring from "querystring";
import { ROOT_URL } from "../constant/config";
import { POST } from "../constant/method";
import { handleFetchErrors } from "../helpers";


export const API = {
    LOADING_START: "api/LOADING_START",
    LOADING_DONE: "api/LOADING_DONE",
    FAVORITE_ADD_FAIL: "api/FAVORITE_ADD_FAIL",
};




/**
 * 추천방송 추천받지 않기
 * @param  bj_id    BJ 아이디
 * @param  broad_no 방송 번호
 * @param  logic    선택되는 방송의 logic ( 추천받을 방송 결과의 해당 logic값을 전달 )
 */
export const recommandDelApi = (bj_id, broad_no, logic, recommandIds) => {
    return (dispatch) => {
        dispatch({ type: API.LOADING_START });

        const url = `${ROOT_URL}/favorite/recommand/reject`;
        let recommand_id = JSON.stringify(recommandIds);
        return fetch(url, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                bj_id,
                broad_no,
                logic,
                recommand_id,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
    };
}
