import React, { useCallback } from 'react'
import i18next from 'i18next';

export default function MoreButton({ onClick }) {

    /**
     * 더보기
     */
    const handleMoreClick = useCallback((e) => {
        e.currentTarget.blur();
        if (onClick) {
            onClick(e);
        }
    }, [onClick]);


    return (
        <div className="btn-more">
            <button type="button" onClick={handleMoreClick}><span>{i18next.t(`더보기`)}</span></button>
        </div>
    )
}
