import { ROOT_URL } from "constant/config";
import { handleFetchErrors } from "../helpers";


export const FAVORITE_CONTENT = {
    LOADING_START: "favorite_content/LOADING_START",
    LOADING_DONE: "favorite_content/LOADING_DONE",
    FETCH_SUCCESS: "favorite_content/FETCH_SUCCESS",
    FETCH_ERROR: "favorite_content/FETCH_ERROR",
};

/**
 * 즐찾 목록 가져오기
 */
export function fetchFavoriteFeeds(group_idx) {
    return (dispatch) => {
        const url = `${ROOT_URL}/feed?index_reg_date=0&user_id=&is_bj_write=1&feed_type=POST_PHOTO&` + (group_idx >0 ? `group_idx=${group_idx}` :"");
        dispatch({ type: FAVORITE_CONTENT.LOADING_START });
        fetch(url, {
            cache: 'no-cache',
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FAVORITE_CONTENT.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CONTENT.FETCH_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({ type: FAVORITE_CONTENT.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CONTENT.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}
