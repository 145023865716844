import React, { useState, useCallback, useRef, useEffect } from 'react';

import useOutsideClick from "components/hook/useOutsideClick";
import i18next from 'i18next';
import {  sendLogApi } from 'helpers';
import { useDispatch } from "react-redux";
import { fetchFavoriteGroup, storeFavoriteGroup } from 'actions/favorite.group.action';

const LAYER = {
    FAVORITELIST : "FAVORITELIST",
    FAVORITEADD : "FAVORITEADD"
}

export default function FavoriteAddLayer({ref, layers, favoriteId, onLayer, onFavoriteId, top, left}) {
    const currentRef = useRef(ref);
    const titleRef = useRef(null);
    const [title, setTitle] = useState("");

    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [isError, setIsError] = useState(false);
    
    useOutsideClick(currentRef, () => {
        onLayer('');
        onFavoriteId(0);
    });

    useEffect(() => {
        if(layers === "FAVORITEADD") {
            setVisible(true);
        } 
    }, []);

    // 제목 변경
	const handleChangeTitle = useCallback(
		(event) => {
			const value = event.target.value.trimStart();
            if (value.length > 10) {
				return false;
			}

			setTitle(value);
		},
		[],
	);

	// 즐찾 목록 추가
	const handleChangeLayer = useCallback((layer, favoriteId) => (event) => {
        // // 즐찾 목록 추가
        event.nativeEvent.stopImmediatePropagation();
      
        if(title.length ===0){
            setIsError(true);
            event.preventDefault();
            return false;
        }
        else{
            setIsError(false);
        }
        dispatch(storeFavoriteGroup(title, favoriteId));

        // 즐찾 리스트 레이어로 이동
        if(title) {

            setTimeout(() => {
                sendLogApi("fav_grouping", {
                    button_type:'create_save',
                    location: 'favorite',
                });
                dispatch(fetchFavoriteGroup());
                onLayer('');
                onFavoriteId(favoriteId);
            }, 500);
        }
        event.preventDefault();
    }, [ dispatch, title, onLayer, onFavoriteId ]);

    const handleClickLayer = useCallback(() => (event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }, []);

    useEffect(()=>{
      
    },[isError])

    
    return (
        <>
            <div ref={currentRef} className={"ui-pop PlayListAdd AddList newGroupLayer " + (isError ? "error" : "")} style={{ display: visible ? "block" : "none", position: "absolute", top: top, left: left}} onClick={handleClickLayer}>
				<div className="pop-title">
                    <h3>{i18next.t(`새 그룹 만들기`)}</h3>
                </div>
                <div className="pop-body">
                    <div className="ListForm">
                        <div ref={titleRef} className="title">
                            <input  type="text" placeholder={i18next.t(`그룹명을 입력해주세요.`)} value={title}  onChange={handleChangeTitle} />
                            <span className="bytes"><em>{title.length}/</em>10</span>
                        </div>
                        
                    </div>
                </div>
                <div className="pop-btn">
					<a href="#" className="btn blue" onClick={handleChangeLayer('FAVORITELIST', favoriteId)}>{i18next.t(`추가`)}</a>
				</div>
                <a href="#" className="pop-close" onClick={(e) => { e.preventDefault(); onLayer("FAVORITELIST"); onFavoriteId('')}}><span>{i18next.t(`닫기`)}</span></a>
			</div>
        </>
    );
}