import { FAVORITE_CATCH_STORY } from "actions/favorite.catchstory.action";

const initialState = {
    loading: false,
    data: [],
    error: null,
    dataCnt: 0
};

const favoriteCatchStoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAVORITE_CATCH_STORY.LOADING_START:
            return { ...state, loading: true };

        case FAVORITE_CATCH_STORY.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_CATCH_STORY.FETCH_SUCCESS:
            return { ...state, data: action.payload, error: null, dataCnt: action.payload.length };

        case FAVORITE_CATCH_STORY.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default favoriteCatchStoryReducer;
