import i18next from "i18next";

export const DESC = 1;
export const ASC = -1;


export const OFFSET_SIZE = 60;


export const TOTAL_VIEW_CNT = 'total_view_cnt';
export const BROAD_START = 'broad_start';

export const FAVORITE_NO = "favorite_no";
export const USER_NICK = "user_nick";
export const USER_ID = "user_id";
export const LAST_BROAD_START = "last_broad_start";
export const FIRST_DATE = "first_date";
export const SUBSCIBE_NO = "subscribe_no";
export const ORDER_NO = "order_no";

export const RECNT_VOD_ADD= 'order_no';
export const LAST_VOD_START = 'later_reg_date';
export const UP_VOD = 'like_reg_date';
export const PLAY = 'read_cnt';
export const COMMENT = 'comment_cnt';
export const UP = 'like_cnt';
export const ADD_VOD = 'vod_add_date';
export const OLD_ADD_VOD = 'playlist.reg_date';
export const PLAYLIST_LAST_VOD_START = 'list_idx';

export const FAV_RECENT = 'favorite_no';
export const FAV_USER_ID = 'user_id';
export const FAV_USER_NICK = 'user_nick';


export const TOTAL_VIEW_CNT_NAME = () => i18next.t("참여인원순");
export const BROAD_START_NAME = () => i18next.t("최신방송순");


export const FAVORITE_NO_NAME = () => i18next.t(`최신등록순`);
export const USER_NICK_NAME = () => i18next.t(`닉네임순`);
export const USER_ID_NAME = () => i18next.t(`아이디순`);
export const LAST_BROAD_START_NAME = () => i18next.t(`최신방송순`);
export const FIRST_DATE_NAME = () => i18next.t(`팬클럽 가입순`);

export const SUBSCIBE_NO_NAME = () => i18next.t(`최신구독순`);

export const ORDER_NO_NAME = () => i18next.t(`최근참여순`);

export const RECNT_VOD_ADD_NAME = () => i18next.t(`최신순`);
export const LAST_VOD_START_NAME = () => i18next.t(`최신순`);
export const UP_VOD_NAME = () => i18next.t(`최신순`);
export const PLAY_NAME = () => i18next.t(`조회순`);
export const UP_NAME = () => i18next.t(`UP순`);
export const COMMENT_NAME = () => i18next.t(`댓글순`);
export const ADD_VOD_NAME = () => i18next.t(`VOD 추가순`);
export const OLD_ADD_VOD_NAME = () => i18next.t(`오래된순`);
export const PLAYLIST_LAST_VOD_START_NAME = () => i18next.t(`최신순`);

export const FAV_USER_NICK_NAME = ()=>i18next.t(`닉네임순`);
export const FAV_USER_ID_NAME = ()=>i18next.t(`아이디순`);
export const FAV_RECENT_NAME = () =>i18next.t(`최신등록순`);

export function getLiveSort() {
    return [
        { value: TOTAL_VIEW_CNT, name: TOTAL_VIEW_CNT_NAME() },
        { value: BROAD_START, name: BROAD_START_NAME() },
    ];
}

export function getRecentLiveSort() {
    return [
        { value: ORDER_NO, name: ORDER_NO_NAME() },
        { value: TOTAL_VIEW_CNT, name: TOTAL_VIEW_CNT_NAME() },
        { value: BROAD_START, name: BROAD_START_NAME() },
    ];
}


export function getFavoriteSort() {
    return [
        { value: FAVORITE_NO, name: FAVORITE_NO_NAME() },
        { value: USER_NICK, name: USER_NICK_NAME() },
        { value: USER_ID, name: USER_ID_NAME() },
        { value: LAST_BROAD_START, name: LAST_BROAD_START_NAME() },
        { value: TOTAL_VIEW_CNT, name: TOTAL_VIEW_CNT_NAME() },
    ];
}

export function getFanclubSort() {
    return [
        { value: FIRST_DATE, name: FIRST_DATE_NAME() },
        { value: USER_NICK, name: USER_NICK_NAME() },
        { value: USER_ID, name: USER_ID_NAME() },
        { value: LAST_BROAD_START, name: LAST_BROAD_START_NAME() },
        { value: TOTAL_VIEW_CNT, name: TOTAL_VIEW_CNT_NAME() },
    ];
}

export function getSubscribeSort() {
    return [
        { value: SUBSCIBE_NO, name: SUBSCIBE_NO_NAME() },
        { value: USER_NICK, name: USER_NICK_NAME() },
        { value: USER_ID, name: USER_ID_NAME() },
        { value: LAST_BROAD_START, name: LAST_BROAD_START_NAME() },
        { value: TOTAL_VIEW_CNT, name: TOTAL_VIEW_CNT_NAME() },
    ];
}

export function getRecentVodSort() {
    return [
        { value: RECNT_VOD_ADD, name: RECNT_VOD_ADD_NAME() },
        { value: PLAY, name: PLAY_NAME() },
    ];
}

export function getLaterSort() {
    return [
        { value: LAST_VOD_START, name: LAST_VOD_START_NAME() },
        { value: PLAY, name: PLAY_NAME() },
    ];
}

export function getUpVodSort() {
    return [
        { value: UP_VOD, name: UP_VOD_NAME() },
        { value: PLAY, name: PLAY_NAME() },
        { value: UP, name: UP_NAME() },
        { value: COMMENT, name: COMMENT_NAME() },
    ];
}

export function getPlayListSort() {
    return [
        { value: ADD_VOD, name: ADD_VOD_NAME() },
        { value: PLAYLIST_LAST_VOD_START, name: PLAYLIST_LAST_VOD_START_NAME() },
    ];
}

export function getFavGroupListSort(){
    return [
        {value : FAV_RECENT, name: FAV_RECENT_NAME()},
        {value : FAV_USER_ID, name: FAV_USER_ID_NAME()},
        {value : FAV_USER_NICK, name: FAV_USER_NICK_NAME()}
    ]
}