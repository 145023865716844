import React, { useState, useMemo, useCallback } from "react";
import { RES_AFREECATV_URL } from "constant/config";

export default function FeedImage({ href, photo, onLoad }) {
	const invisibleImg = `${RES_AFREECATV_URL}/images/myservice/png.png`;
	const [imgStatus, setImgStatus] = useState(true);
	const onErrorThumb = useCallback(() => {
		setImgStatus(false);
	}, []);

	const handleError = useCallback(() => {
		onLoad();
	}, [onLoad]);

	const height = useMemo(() => {
		let newHight = 0;
		if (photo.width > 560) {
			newHight = parseFloat((560 / photo.width) * photo.height).toFixed(2);
		} else if (photo.width > 0) {
			newHight = photo.height;
		}
		return newHight;
	}, [photo.height, photo.width]);

	if (imgStatus === false) {
		return (
			<a href={href} target="_blank" className="thumb-default">
				기본
				<img className="image" src={invisibleImg} alt="" loading="lazy" onLoad={onLoad} />
			</a>
		);
	} else {
		if (height > 0) {
			return (
				<a href={href} target="_blank">
					<img className="image" src={photo.url} height={height} alt="" loading="lazy" onLoad={handleError} onError={onErrorThumb} />
				</a>
			);
		} else {
			return (
				<a href={href} target="_blank">
					<img className="image" src={photo.url} alt="" loading="lazy" onLoad={onLoad} onError={onErrorThumb} />
				</a>
			);
		}
	}
}
