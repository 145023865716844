import React, { useCallback, useState } from "react";

export default function PopularImage({ href, photo }) {
    const [imgStatus, setImgStatus] = useState(true);
    const handleError = useCallback(() => {
        setImgStatus(false);
    }, []);

    if (photo.length > 0) {
        if (imgStatus === false) {
            return (
                <div className="file">
                    <a href={href} target="_blank" className="thumb-default">
                        기본
                    </a>
                </div>
            );
        } else {
            return (
                <div className="file">
                    <a href={href} target="_blank">
                        <img className="image" src={photo[0].url} alt="" loading="lazy" onError={handleError} />
                        {photo.length > 1 && <span className="count">{photo.length}</span>}
                    </a>
                </div>
            );
        }
    } else {
        return null;
    }
}
