import { fetchHotissuePopular, fetchHotissuePopularVod, cleanupHotissue } from 'actions/hotissue.action';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HotissuePopularItem from './HotissuePopularItem';
import VodClip from 'components/box/VodClip';
import SliderBox from 'components/box/SliderBox';
import { useTranslation } from 'react-i18next';
import { HOTISSUE_POPULAR_ROUTE } from 'constant/routeUrl';
import { useLocation } from 'react-router';


export default function HotissuePopular() {

    const {pathname, key} = useLocation();
    const [isLoad, setIsLoad] =useState(false);
    const { t } = useTranslation();
    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const { loading, payload, vods, error } = useSelector(state => (state.hotissueReducer));

    const [ top, setTop ] = useState(0);
    const [ left, setLeft ] = useState(0);

    useEffect(() => {
        initSetting();
        setIsLoad(true);
        return () => {
            dispatch(cleanupHotissue());
        }
    }, [dispatch]);

    useEffect(()=>{
        if(isLoad && pathname === HOTISSUE_POPULAR_ROUTE){
            initSetting()
            return () => {
                dispatch(cleanupHotissue());
            }
        }
    }, [key])

    const initSetting = () =>{
        dispatch(fetchHotissuePopular());
        dispatch(fetchHotissuePopularVod());
    }

    const handlePosition = useCallback((top, left) => {
        setTop(top);
        setLeft(left);
    }, [setTop, setLeft]);

    return (
        <>
            <article className="top100">
                <div className="top_title">
                    <h3>{t('TOP 100')}</h3>
                    <span className="refresh_time">{payload.refresh_time}<em> ({payload.refresh_cycle})</em></span>
                </div>

                {loading === true && <div className="loading">Loading...</div>}

                <ul className="hot-post">
                    {payload.data.slice(0, 6).map((item, index) => <HotissuePopularItem key={index} item={item} onPosition={handlePosition} />)}
                </ul>


                {loading === false && vods.length > 0 && <div className="hotIssue-vod">
                    <h3>{t('핫이슈 동영상')}</h3>
                    <SliderBox>
                        {vods.map((vod, index) => (
                            <VodClip key={index} vod={vod} paths={['my', 'hotissue', 'popular', 'vod']} />)
                        )}
                    </SliderBox>
                </div>}


                <ul className="hot-post">
                    {payload.data.slice(6).map((item, index) => <HotissuePopularItem key={index} item={item} onPosition={handlePosition} />)}
                </ul>
            </article>
        </>
    )
}
