import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';


export default function useCustomListModal(){
    //const [favLayer, setFavLayer] = useState("")
    //const [favTop, setFavTop] = useState(0);
    //const [favLeft, setFavLeft] = useState(0);
    const [favoriteId, setFavoriteId] = useState("");;

    const [customLayer, setCustomLayer] = useState("");
    const [customTop, setCustomTop] = useState(0);
    const [customLeft, setCustomLeft] = useState(0);
    const [titleNo, setTitleNo] = useState(0);

   
    const handleEventAction = useCallback((layer, data) => {
        // 즐찾 그룹핑 이외의 기능 추가 됨
        if (layer === "FAVORITELIST" || layer === "FAVORITEADD") {
            setFavoriteId(data); 
        } else if (layer === "LIST" || layer === "ADD") {
            setTitleNo(data);
        }
    }, []);

   /*const handleFavoriteLayer = useCallback((layer, favoriteId) =>{
        setCustomLayer(layer)
        setFavoriteId(favoriteId)
    },[setCustomLayer, setFavoriteId])

    const handleCallbackFavoriteLayer = useCallback((layer, favoriteId) =>(event) =>{
        // 즐찾 그룹핑 레이어 띄울 때 상위 event bubbling 제거
        event.stopPropagation();
        setCustomLayer(layer)
        setFavoriteId(favoriteId)
    },[setCustomLayer, setFavoriteId])*/

    const handleCustomLayer = useCallback((layer, data) => {
        setCustomLayer(layer);
        handleEventAction(layer, data);
    },[setCustomLayer, handleEventAction])


    const handleCustomCallbackLayer = useCallback((layer, data) =>(event) =>{
        event.stopPropagation();
        setCustomLayer(layer);
        handleEventAction(layer, data);
    },[setCustomLayer, handleEventAction]);

    const handleCustomPosition = useCallback((top,left)=>{
        setCustomTop(top);
        setCustomLeft(left);
    }, [])

    const handleFavoritePosition = useCallback((top,left)=>{
        setCustomTop(top-308);
        setCustomLeft(left < 0 ? 0 : left);
    }, [setCustomTop, setCustomLeft])

    return {
        favoriteId, customLayer, customTop, customLeft, titleNo,
        setFavoriteId, setCustomLayer, setCustomTop, setCustomLeft, setTitleNo,
        //handleFavoriteLayer, handleFavoritePosition, handleCallbackFavoriteLayer,
        handleCustomLayer, handleCustomPosition, handleCustomCallbackLayer, handleFavoritePosition
    }
}
