import { FAVORITE_CATCH } from "actions/favorite.catch.action";

const initialState = {
    loading: false,
    data: [],
    error: null
};

const favoriteCatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAVORITE_CATCH.LOADING_START:
            return { ...state, loading: true };

        case FAVORITE_CATCH.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_CATCH.FETCH_SUCCESS:
            // const data = action.payload.map(item => {
            //     item.read_cnt = parseInt(item.read_cnt.replace(/,/g, ''))
            //     return item;
            // })
            return { ...state, data: action.payload, error: null };

        case FAVORITE_CATCH.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default favoriteCatchReducer;
