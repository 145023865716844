import { RES_AFREECATV_URL } from "../constant/config";

export const AUTOHASHTAG = {
    LOADING_START : "LOADING_START",
    LOADING_DONE : "LOADING_DONE",
    SUCCESS_DATA : "SUCCESS_DATA",
    FAIL_DATA : "FAIL_DATA",
}

/**
 * GET event tag style 
 * @returns 
 */
export const fetchAutoHashTag = () => {
    return (dispatch) => {
        dispatch({type: AUTOHASHTAG.LOADING_START});
        fetch(`${RES_AFREECATV_URL}/event_auto_hashtags.php`, {
            cache: "no-cache" // Chrome Disable cache Control 문제가 있어 해당 옵션 추가, 안할시 CORS 나는 케이스가 존재
        })
            .then((response) => {
                dispatch({type: AUTOHASHTAG.LOADING_DONE});
                return response.json()
            }).then((response) => {
                dispatch({
                    type: AUTOHASHTAG.SUCCESS_DATA,
                    payload: response

                });
            }).catch((error) => {
                dispatch({
                    type: AUTOHASHTAG.FAIL_DATA,
                    payload: []
                });
            });
    }
}