import { ITEM_URL, RES_AFREECATV_URL } from "constant/config";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, SUBSCRIBE_ROUTE, HISTORY_RECENT_VOD_ROUTE, HISTORY_LATER_ROUTE, HISTORY_UP_VOD_ROUTE, HISTORY_PLAYLIST_ROUTE} from "constant/routeUrl";
import i18next from "i18next";
import React, { useCallback } from "react";
import { Trans } from "react-i18next";
import { useLocation } from "react-router";
import { sendLogApi } from "helpers";

export default function EmptySection() {
    const { pathname } = useLocation();

        /**
     * 영상 진입 로그 호출
     */
    const onClickVodLink = useCallback(
        () => {
            let sendData = {
                watch_type: "vod"
            };
            let codeType = "";
            switch (pathname) {
                case HISTORY_RECENT_VOD_ROUTE:
                    codeType = "my_history_recent";
                    sendData['button_type'] = "to_vod";
                    break;

                case HISTORY_LATER_ROUTE:
                    codeType = "my_history_afterward";
                    sendData['button_type'] = "to_vod";
                    break;

                case HISTORY_UP_VOD_ROUTE:
                    codeType = "my_history_up";
                    sendData['button_type'] = "to_vod";
                    break;

                default:
                    return false;
            }
            sendLogApi(codeType, sendData, "web");
        },
        [pathname]
    );

    switch (pathname) {
        case SUBSCRIBE_ROUTE:
            return i18next.language === "ko" ? (
                <>
                    <div className="banner" style={{ background: "#006cff" }}>
                        <a href={`${ITEM_URL}/subscription.php`} target="_blank">
                            <img src={`${RES_AFREECATV_URL}/images/afmain/banner_gd.jpg`} alt="" loading="lazy" />
                        </a>
                    </div>
                    <div className="nt_area">
                        <span className="ic_gudok"></span>
                        <p className="txt01">{i18next.t(`구독한 BJ가 없습니다.`)}</p>
                        <p className="txt02">
                            <Trans>
                                내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br />
                                구독팬만을 위한 아주 특별한 혜택을 만나보세요!
                            </Trans>
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div className="nt_area">
                        <span className="ic_gudok"></span>
                        <p className="txt01">{i18next.t(`구독한 BJ가 없습니다.`)}</p>
                        <p className="txt02">
                            <Trans>
                                내가 좋아하는 BJ를 구독하고 본방 입장, 구독 전용 닉네임, 시그니처 이모티콘 등<br />
                                구독팬만을 위한 아주 특별한 혜택을 만나보세요!
                            </Trans>
                        </p>
                    </div>
                </>
            );

        case FANCLUB_ROUTE:
            return (
                <div className="nt_area">
                    <span className="ic_fanclub"></span>
                    <p className="txt01">{i18next.t(`가입한 팬클럽이 없습니다.`)}</p>
                    <p className="txt02">
                        <Trans>
                            BJ에게 별풍선을 선물하면 선물한 BJ의 팬클럽에 가입됩니다!
                            <br />
                            팬클럽은 팬클럽 공개 게시판을 이용할 수 있고, 팬클럽만의 특별한 채팅 글자색으로 채팅이 돋보입니다.
                        </Trans>
                    </p>
                </div>
            );
        case HISTORY_RECENT_VOD_ROUTE:
            return ( 
                <div className="no_vod type2">
                    <p className="title">{i18next.t(`최근 본 VOD가 없습니다.`)}</p>
                    <p className="description">{i18next.t(`지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!`)}</p>
                    <a href="//vod.afreecatv.com" onClick={onClickVodLink} target="_blank" className="link">{i18next.t(`VOD 보러가기`)}</a>
                </div>
            );
        case HISTORY_LATER_ROUTE:
            return(
                <div className="no_vod type2">
                    <p className="title">{i18next.t(`나중에 보기 한 VOD가 없습니다.`)}</p>
                    <p className="description">{i18next.t(`지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!`)}</p>
                    <a href="//vod.afreecatv.com" onClick={onClickVodLink} target="_blank" className="link">{i18next.t(`VOD 보러가기`)}</a>
                </div>
            );
        case HISTORY_UP_VOD_ROUTE:
            return (
                <div className="no_vod type2">
                    <p className="title">{i18next.t(`UP 한 VOD가 없습니다.`)}</p>
                    <p className="description">{i18next.t(`지금 바로, 아프리카TV의 재미있고 다양한 VOD를 만나보세요!`)}</p>
                    <a href="//vod.afreecatv.com" onClick={onClickVodLink} target="_blank" className="link">{i18next.t(`VOD 보러가기`)}</a>
                </div>
            );
        case HISTORY_PLAYLIST_ROUTE:
            return (
                <div className="no_vod playList">
                    <p className="title">{i18next.t(`재생목록이 없습니다.`)}</p>
                    <p className="description">{i18next.t(`내 마음대로 만드는 플레이리스트!`)}<br />
                        {i18next.t(`테마/BJ별로 재생목록에 담아 편하게 시청하세요.`)}</p>
                </div>
            );
        case FAVORITE_ROUTE:
        default:
            return (
                <div className="nt_area">
                    <span className="ic_favrite"></span>
                    <p className="txt01">{i18next.t(`즐겨찾기한 BJ가 없습니다.`)}</p>
                    <p className="txt02">
                        <Trans>
                            좋아하는 BJ를 즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,
                            <br />
                            방송 시작 시 알림을 받을 수 있습니다.
                        </Trans>
                    </p>
                </div>
            );
    }
}
