import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Switch, Route, useLocation, Link, useHistory } from "react-router-dom";
import { HISTORY_RECENT_ROUTE, HISTORY_RECENT_VOD_ROUTE, HISTORY_LATER_ROUTE, HISTORY_UP_VOD_ROUTE, HISTORY_PLAYLIST_ROUTE } from "constant/routeUrl";
import HistoryRecentBroadSection  from "./HistoryRecentBroadSection";
import HistoryRecentVodSection  from "./HistoryRecentVodSection";
import HistoryLaterVodSection from "./HistroyLaterVodSection";
import HistoryUpVodSection from "./HistoryUpVodSection";
import HistoryPlayListSection from "./HistoryPlayListSection";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { VODS } from "actions/vods.action";
import MidBanner from "pages/banner/MidBanner";
import useAdBanner from "components/hook/useAdBanner";

export default function History() {
    const [step, setStep] = useState("recent_live");
    const { pathname } = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();
    const { renderBanner } = useAdBanner();
 
    const getStep = (pathname) => {
        let step = "";
        switch(pathname) {
            case '/history/recent/vod':
                step = 'recent_vod';
                break;
            case '/history/later':
                step = 'later';
                break;
            case '/history/up/vod':
                step = 'up_vod';
                break;
            case '/history/playlist':
                step = 'play_list';
                break;

            case '/history/recent':
            default:
                step = 'recent_live';
                break;
        }

        return step;
    }

    const handleStepChange = useCallback((changeStep, path) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        /**
         * 중복 클릭 방지 (event로 step은 변경되는데 pathname이 안바뀌는 현상이 있어 중복클릭 막기)
         */
        if(e.target.parentElement.className === 'on') {
            return false;
        }
        
        setStep(changeStep);

        /**
         * step 체인지 될 때마다 각 페이지 vod 데이터들 초기화 진행 
         */
        if(step !== changeStep) {
            dispatch({ type: VODS.VOD_INIT });
        }

    }, [setStep, dispatch]);

    useEffect(() => {
        let step = getStep(pathname);
        setStep(step);

    }, [pathname, setStep]);

    useEffect(() => {
        const listenBackEvent = () => {
            dispatch({ type: VODS.VOD_INIT });
        };

        /**
         * 뒤로가기 수정
         */
        const unListenHistoryEvent = history.listen((action) => {
            if(history.action === "POP") {
                listenBackEvent();
            }
        });

        return unListenHistoryEvent;
    }, [dispatch]);

    return (
        <div id="list-section" className="record">
            <div className="fixMenu_wrap">
                <div className="posMenu">
                    <ul className="menu-list">
                        <li 
                            className={`${(step === 'recent_live')  ? 'on' : '' }`} 
                            onClick={handleStepChange('recent_live', HISTORY_RECENT_ROUTE)}>

                            <Link to={{ pathname:"/history/recent"}}>{i18next.t(`최근 본 BJ의 LIVE`)}</Link>
                        </li> 
                        <li 
                            className={`${step === 'recent_vod' ? 'on' : '' }`} 
                            onClick={handleStepChange('recent_vod', HISTORY_RECENT_VOD_ROUTE)}>

                            <Link to={{ pathname:"/history/recent/vod"}}>{i18next.t(`최근 본 VOD`)}</Link>
                        </li>
                        <li 
                            className={`${step === 'later' ? 'on' : '' }`} 
                            onClick={handleStepChange('later', HISTORY_LATER_ROUTE)}>

                            <Link to={{ pathname:"/history/later"}}>{i18next.t(`나중에 보기`)}</Link>
                        </li>
                        <li 
                            className={`${step === 'up_vod'  ? 'on' : '' }`} 
                            onClick={handleStepChange('up_vod', HISTORY_UP_VOD_ROUTE)}>

                            <Link to={{ pathname:"/history/up/vod"}}>{i18next.t(`UP한 VOD`)}</Link>
                        </li>
                        <li 
                            className={`${step === 'play_list'  ? 'on' : '' }`} 
                            onClick={handleStepChange('play_list', HISTORY_PLAYLIST_ROUTE)}>

                            <Link to={{ pathname:"/history/playlist"}}>{i18next.t(`재생목록`)}</Link>
                        </li>
                    </ul>
                </div>
            </div>
             {/* 서브브랜딩 배너 추가 */}
             <MidBanner content={renderBanner}></MidBanner>
            <Switch>
                { step === 'recent_live' && <Route path={HISTORY_RECENT_ROUTE} component={HistoryRecentBroadSection} />}
                { step === 'recent_vod' && <Route path={HISTORY_RECENT_VOD_ROUTE} component={HistoryRecentVodSection} />}
                { step === 'later' && <Route path={HISTORY_LATER_ROUTE} component={HistoryLaterVodSection} />}
                { step === 'up_vod' && <Route path={HISTORY_UP_VOD_ROUTE} component={HistoryUpVodSection} />}
                { step === 'play_list' && <Route path={HISTORY_PLAYLIST_ROUTE} component={HistoryPlayListSection} />}

                {pathname === '/history' && <Redirect path="/history" to={HISTORY_RECENT_ROUTE} />}
            </Switch>
        </div>
    );
}