import { RECOMMANDS } from "actions/recommands.action";

const initialState = {
    loading: false,
    data: [],
    error: null
};

const recommandsReducer = (state = initialState, action) => {
    let data = null;
    switch (action.type) {
        case RECOMMANDS.LOADING_START:
            return { ...state, loading: true };

        case RECOMMANDS.LOADING_DONE:
            return { ...state, loading: false };

        case RECOMMANDS.FETCH_SUCCESS:
            return { ...state, data: action.payload.data, error: null };

        case RECOMMANDS.FETCH_REJECT_SUCCESS:
            const tmpAddInfo = action.payload.addInfo;
           
            data = state.data.map((item) => {
                if (item.user_id === action.payload.filterId) {  
                    if (tmpAddInfo !== null && tmpAddInfo.length > 0) {
                        return {...item, ...tmpAddInfo.shift()}
                    }
                }

                return item;
            });
            
            return {
                ...state,
                data: data,
            }

        case RECOMMANDS.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default recommandsReducer;
