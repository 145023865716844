import useSildeCount from "components/hook/useSildeCount";
import VodItem from "components/item/VodItem";
import TitleSection from "components/section/TitleSection";
import SwiperController from "components/swiper/SwiperController";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, SUBSCRIBE_ROUTE } from "constant/routeUrl";
import i18next from "i18next";
import React, { useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import SwiperCore, {Controller, Navigation} from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";



const VodSection = ({ handleLayer, handlePosition, handleCallbackLayer}) => {
    const ref = useRef(null);

    const count = useSildeCount();

    const { pathname } = useLocation();

    // useSelector
    const { data } = useSelector(state => (state.vodsReducer), shallowEqual);


    if (data.length > 0) {
        return (
            <>
                <TitleSection  sectionType={pathname === FAVORITE_ROUTE ? "VOD" : ""} >{VodsTitle(pathname)}</TitleSection>

                <div className="slide-vod">
                    {(data.length > 6) && <SwiperController swiperRef={ref} autoplay={false} />}

                    <Swiper
                        ref={ref}
                        autoplay={false}
                        wrapperTag="ul"
                        spaceBetween={20}
                        breakpoints= {{
                            // 1280px 보다 클 경우
                            2183: {
                              slidesPerView: 6,
                            },
                            1921 :{
                                slidesPerView:5,
                            },
                            640: {
                                slidesPerView:4
                            }
                          }
                        }
                        className ={'swiper-container'}
                        slidesPerView={count}
                        allowTouchMove={false}
                        loop={data.length > 6}
                    >
                        {data.map((vod, index) => {
                            return (
                                <SwiperSlide tag="li" key={index} data-type="cBox">
                                    <VodItem vod={vod} onChangeLayer={handleLayer} onPosition={handlePosition} onCallBackChangeLayer={handleCallbackLayer} />
                                </SwiperSlide>);
                        })}
                    </Swiper>
                </div>
            </>
        )
    } else {
        return null;
    }
}

export default VodSection;

const VodsTitle = (pathname) => {
    let title = "";

    switch (pathname) {
        case FAVORITE_ROUTE:
            title = `즐겨찾기한 BJ의 VOD`;
            break;

        case SUBSCRIBE_ROUTE:
            title = `구독한 BJ의 VOD`;
            break;

        case FANCLUB_ROUTE:
            title = `팬가입한 BJ의 VOD`;
            break;

        default:
    }

    return i18next.t(title);
};

