import React, { useCallback, useState } from "react";
import {LIVEIMG_URL} from "constant/config";

export default function LiveThumbnail({ href, onClick, broad }) {
    const { is_adult, is_password, broad_no } = broad;
    const [imgStatus, setImgStatus] = useState(true);

    let random = Math.random();
    const thumbNail = `${LIVEIMG_URL}/h/${broad_no}.webp?${random}`;

    const handleError = useCallback((e) => {
        let errorCheck = false;
        let image = new Image();
        let jpgThumb = `${LIVEIMG_URL}/h/${broad_no}.jpg?${random}`;

        image.src = jpgThumb;
        image.onerror = () => {
            errorCheck = true;
            setImgStatus(false);
        }
        
        if(!errorCheck) {
            e.target.src = jpgThumb;
        }
    }, []);

    const thumbCheck = () => {
        let classNames = "";
        let name = "";

        if (is_password && is_adult) {
            classNames = "thumb-lock_adult"
            name = "19금+비번방"
        } else if (is_password) {
            classNames = "thumb-lock";
            name = "비번방"
        } else if (is_adult) {
            classNames = "thumb-adult";
            name = "19금"
        } else if (imgStatus === false) {
            classNames = "thumb-default";
            name = "기본"
        }

        return {
            'classNames': classNames,
            'name': name
        }
    };

    const { classNames, name } = thumbCheck();

    return (
        <a href={href} onClick={onClick} target="_blank" className={classNames}>
            {classNames === '' && <img src={thumbNail} onError={handleError} alt="" loading="lazy" />}
            {name}
        </a>
    );
}
