import { fetchFeed, initFeed } from 'actions/feed.action';
import { FAVORITE_REMOVE } from 'actions/feed.favorite.action';
import SelectBox from 'components/form/SelectBox';
import { getFeedOptions, NOTICE, POST_PHOTO, VOD } from 'constant/feedType';
import { FEED_ROUTE } from 'constant/routeUrl';
import { documentScrollTop, getLocalStorageItem, setLocalStorageItem } from 'helpers';
import { useReudxSubsribe } from 'middlewares/ReduxSubscribeMiddleware';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import AutoSizer from "react-virtualized/dist/es/AutoSizer";
import CellMeasurer from "react-virtualized/dist/es/CellMeasurer";
import CellMeasurerCache from "react-virtualized/dist/es/CellMeasurer/CellMeasurerCache";
import List from "react-virtualized/dist/es/List";
import WindowScroller from "react-virtualized/dist/es/WindowScroller";
import FeedItem from './item/FeedItem';

const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 202,
    defaultHeight: 535
});

const FEED_TYPE = 'feed_type'
const IS_BJ_WRITE = 'is_bj_write'

export default function FeedContents({ match }) {
    let { user_id } = useParams();
    // dispatch
    const dispatch = useDispatch();
    let history = useHistory();
    const { loading, meta, data = [], error, index_reg_date, has_more } = useSelector(state => (state.feedReducer));
    const [playIndex, setPlayIndex] = useState(-1)
    const windowScrollerRef = useRef(null)
    const listRef = useRef(null)
    const [isBjWrite, setIsBjWrite] = useState(getLocalStorageItem(IS_BJ_WRITE) || false);
    const [feedType, setFeedType] = useState(getLocalStorageItem(FEED_TYPE));
    const isEmpty = useMemo(() => meta.total === 0 && has_more === false && data.length === 0, [data.length, has_more, meta.total])


    /**
     * 로컬스토리지 저장
     */
    useEffect(() => {
        setLocalStorageItem(FEED_TYPE, feedType);
    }, [feedType]);

    /**
     * 로컬스토리지 저장
     */
    useEffect(() => {
        setLocalStorageItem(IS_BJ_WRITE, isBjWrite);
    }, [isBjWrite]);

    /**
     * 최초로딩
     */
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
            //https://developer.mozilla.org/ko/docs/Web/API/History/scrollRestoration
        }
        _cache.clearAll();
        dispatch(initFeed());
        dispatch(fetchFeed({ user_id, isBjWrite, feedType }));
    }, [dispatch, feedType, isBjWrite, user_id])

    /**
     * 컨텐츠의 변경이 있으면
     */
    useEffect(() => {
        _cache.clearAll();
    }, [isEmpty])


    /**
     * 즐겨찾기가 삭제되었을 경우
     */
    useReudxSubsribe(FAVORITE_REMOVE, useCallback((action) => {

        _cache.clearAll();
        if (user_id === action.payload.favorite_id) {
            //전체로 동
            history.replace(FEED_ROUTE);
        } else {
            dispatch(initFeed());
            dispatch(fetchFeed({ user_id, isBjWrite, feedType }));
            setTimeout(() => {
                documentScrollTop();
                listRef.current.scrollToPosition(0)
            }, 10);
        }


    }, [dispatch, feedType, history, isBjWrite, user_id]));




    /**
     * 더보기
     * @param {*} param0 
     */
    const handleRowRendered = ({ overscanStartIndex, overscanStopIndex, startIndex, stopIndex }) => {
        if (loading === false
            && overscanStopIndex >= (data.length - 1)
            && has_more) {
            dispatch(fetchFeed({ user_id, index_reg_date, isBjWrite, feedType }));
        }

    }

    const _rowRenderer = useCallback((opt) => {
        const { index, key, style, parent, isVisible } = opt;
        const item = data[index];
        const feedItem = document.getElementById(`feed-item${index}`);
        //화면에 보이는 영역
        const isRendered = parent._renderedRowStartIndex <= index && parent._renderedRowStopIndex >= index ? true : false;
        if (isVisible && isRendered && feedItem) {
            const player = feedItem.getElementsByClassName("vod")[0];
            if (player) {
                const scrollY = window.scrollY || document.documentElement.scrollTop;
                const headerHeight = 67;
                const playerY = headerHeight + windowScrollerRef.current.offsetTop + style.top + player.offsetTop;
                //console.log(item.title_no, playerY, ">", scrollY, "&&", (playerY + player.clientHeight), "<", (scrollY + window.innerHeight), "", playIndex);
                if (playerY > scrollY    // 영상상단은 스크롤크기보다 커야하고
                    && (playerY + player.clientHeight) < (scrollY + window.innerHeight)  //영상하단은 화면위에 있어야함
                ) {

                    setPlayIndex(index)
                } else {
                    if (playIndex === index) {
                        setPlayIndex(-1)
                    }
                }
            }
        }
        return (
            <CellMeasurer cache={_cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
                {({ measure }) => <FeedItem
                    measure={measure}
                    index={index}
                    item={item}
                    style={style}
                    isRendered={isRendered}
                    playable={index === playIndex}
                />}
            </CellMeasurer>
        );
    }, [data, playIndex]);


    const noList = useCallback(() => {
        if (isBjWrite) {
            return <p className="no_list"><Trans>BJ가 등록한 게시물이 없습니다.</Trans></p>;
        } else {
            switch (feedType) {
                case NOTICE:
                    return <p className="no_list"><Trans>방송국에 등록된 공지글이 없습니다.</Trans></p>;
                case POST_PHOTO:
                    return <p className="no_list"><Trans>방송국에 등록된 게시글이 없습니다.</Trans></p>;
                case VOD:
                    return <p className="no_list"><Trans>방송국에 업로드된 VOD가 없습니다.</Trans></p>;
                default:
                    return <p className="no_list"><Trans>즐겨찾기한 BJ 방송국에 업로드된 게시물이 없습니다.</Trans></p>;
            }
        }
    }, [feedType, isBjWrite]);

    /**
     * 게시물이 없는 경우
     */
    const _emptyRender = useCallback((opt) => {
        const { index, key, parent, } = opt;
        return <CellMeasurer cache={_cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
            {({ measure }) => (
                <div className="list-item">
                    {noList()}
                </div>)
            }
        </CellMeasurer>
    }, [noList]);


    const handleFeedTypeChange = useCallback((type) => {
        setFeedType(type);
    }, []);
    return (
        <section className="feed-contents">
            <div className="feed-wrap">
                <div className="feed_heading">
                    <div className="lt">
                        <h2 className="title"><Trans>방송국 새 글</Trans></h2>
                    </div>
                    <div className="util">
                        <div className="chk-box">
                            <span><input type="checkbox" name="" id="chk1" defaultChecked={isBjWrite} onClick={() => setIsBjWrite(!isBjWrite)} /><i></i></span>
                            <label htmlFor="chk1"><Trans>BJ글만 보기</Trans></label>
                        </div>
                        <SelectBox options={getFeedOptions()} value={feedType} onChange={handleFeedTypeChange} />
                    </div>
                </div>


                <div ref={windowScrollerRef}>
                    <WindowScroller  >
                        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                            <AutoSizer disableHeight >
                                {({ width }) => {
                                    return (
                                        <div ref={registerChild}>
                                            <List
                                                height={height}
                                                isScrolling={isScrolling}
                                                onScroll={onChildScroll}
                                                scrollTop={scrollTop}
                                                ref={listRef}
                                                autoHeight
                                                width={width}
                                                estimatedRowSize={3}
                                                rowRenderer={isEmpty ? _emptyRender : _rowRenderer}
                                                overscanRowCount={3}
                                                rowCount={isEmpty ? 1 : data.length}
                                                rowHeight={_cache.rowHeight}
                                                onRowsRendered={handleRowRendered}
                                            />
                                        </div>
                                    );
                                }}
                            </AutoSizer>
                        )}
                    </WindowScroller>
                </div>

                {loading && <div className="af_loading more"></div>}
            </div>
        </section >


    )
}
