import { sendLogApi, handleFetchErrors } from "helpers";
import { ROOT_URL } from "constant/config";
import { API } from "./api.action";
import i18next from "i18next";
import { POST, DELETE } from "constant/method";
import querystring from "querystring";
import cookie from "react-cookies";
import { toast } from "afreecatv-react-toast";


/**
 * 즐찾 상수
 */
export const FAVORITE = {
	LOADING_START: "favorite/LOADING_START",
	LOADING_DONE: "favorite/LOADING_DONE",
	FETCH_SUCCESS: "favorite/FETCH_SUCCESS",
    FETCH_EXT_SUCCESS : "favorite/FETCH_EXT_SUCCESS",
	FETCH_ERROR: "favorite/FETCH_ERROR",
	FILTER_PIN: "favorite/FILTER_PIN",
	ADD_SUCCESS: "favorite/ADD_SUCCESS",
	REMOVE_SUCCESS: "favorite/REMOVE_SUCCESS",
	SET_MOBILE_PUSH_SUCCESS: "favorite/SET_MOBILE_PUSH_SUCCESS",
};

export const FAVORITE_EXT = {
    LOADING_START: "favorite_ext/LOADING_START",
	LOADING_DONE: "favorite_ext/LOADING_DONE",
    FETCH_SUCCESS: "favorite_ext/FETCH_SUCCESS",
    FETCH_ERROR: "favorite_ext/FETCH_ERROR",
    FETCH_EXT_SUCCESS : "favorite_ext/FETCH_EXT_SUCCESS",
    FILTER_PIN: "favorite/FILTER_PIN",
    SET_MOBILE_PUSH_SUCCESS: "favorite/SET_MOBILE_PUSH_SUCCESS",
}

/**
 * 즐찾 목록 가져오기
 */
export function fetchFavorite(group_idx) {
    let url = group_idx>0 ? `${ROOT_URL}/favorite/${group_idx}` :`${ROOT_URL}/favorite`;
    return (dispatch) => {
        if(group_idx ===0 || group_idx ===null)
            dispatch({ type: FAVORITE.LOADING_START });
        dispatch({type: FAVORITE_EXT.LOADING_START})
        fetch(url, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                if(group_idx ===0 || group_idx === null)
                {
                    dispatch({
                        type: FAVORITE.FETCH_SUCCESS,
                        payload: response,
                    });
                    dispatch({ type: FAVORITE.LOADING_DONE });
                }
                
                dispatch({
                    type: FAVORITE_EXT.FETCH_EXT_SUCCESS,
                    payload:response,
                })
                dispatch({ type: FAVORITE_EXT.LOADING_DONE });
               
                
            })
            .catch((error) => {
              
                if(group_idx ===0 || group_idx ===null){
                    dispatch({
                        type: FAVORITE.FETCH_ERROR,
                        payload: error,
                    });
                    dispatch({ type: FAVORITE.LOADING_DONE });
                }
                dispatch({
                    type: FAVORITE_EXT.FETCH_ERROR,
                    payload: error,
                })
                dispatch({ type: FAVORITE_EXT.LOADING_DONE });
                
                
            });
    };
}





/**
 * 핀 추가
 * @param  favoriteNo 즐찾 번호
 * @param  userId     유저 아이디
 * @return
 */
export const storeFavoritePin = (favoriteNo, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/pin`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                common_no: favoriteNo,
            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({ type: API.LOADING_DONE });
                dispatch({
                    type: FAVORITE.FILTER_PIN,
                    payload: { no: favoriteNo, flag: true }
                });
                let sendData = {
                    location: 'favorite',
                    action_type: 'add_fix',
                    bj_id: userId,
                }
                sendLogApi("main_00000008", sendData);
                toast.success(i18next.t(`목록 상단 고정 완료 되었습니다.`));
            })
            .catch((error) => {
                dispatch({ type: API.LOADING_DONE });
                toast.error(i18next.t(error.message));
            });
    }
}

/**
 * 핀 삭제
 * @param  favoriteNo 즐찾 번호
 * @param  userId     유저 아이디
 * @return
 */
export const destroyFavoritePin = (favoriteNo, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/favorite/pin/${favoriteNo}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
                common_no: favoriteNo,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({ type: API.LOADING_DONE });
                dispatch({
                    type: FAVORITE.FILTER_PIN,
                    payload: { no: favoriteNo, flag: false }
                });
                let sendData = {
                    location: 'favorite',
                    action_type: 'del_fix',
                    bj_id: userId,
                }
                sendLogApi("main_00000008", sendData);
                toast.success(i18next.t(`목록 상단 고정 해제 되었습니다.`));
            })
            .catch((error) => {
                dispatch({ type: API.LOADING_DONE });
                toast.error(i18next.t(error.message));
            });
    }
}
