import CatchItem from "components/item/CatchItem";
import TitleSection from "components/section/TitleSection";
import SwiperController from "components/swiper/SwiperController";
import i18next from "i18next";
import React, { useRef, useCallback, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

export default function UserCatchSection({handleLayer, handlePosition, handleCallbackLayer, location }) {

    const ref = useRef(null);

    // useSelector
    const { data } = useSelector(state => (state.favoriteCatchReducer), shallowEqual);
    const { dataCnt } = useSelector(state => (state.favoriteCatchStoryReducer), shallowEqual);

    // 캐치스토리 개수가 0개가 아니면 캐치섹션 하단에만 노출
    if(location == "top" && dataCnt != 0){
        return null;
    // 캐치스토리 개수가 0개면 캐치섹션 상단에만 노출
    } else if(location == "bottom" && dataCnt === 0){
        return null;
    }
    
    if (data.length > 0) {
        return (
            <>
                <TitleSection sectionType="CATCH" className="catch">{i18next.t(`Catch`)}</TitleSection>

                <div className="slide-catch">
                    {(data.length > 6) && <SwiperController swiperRef={ref} autoplay={false} />}
                    
                <div className="catch_hz_box">
                        <Swiper
                            ref={ref}
                            autoplay={false}
                            breakpoints= {{
                                // 2560px 보다 클 경우
                                2560: {
                                    slidesPerView: 14,
                                },
                                2370: {
                                    slidesPerView: 13,
                                },
                                2200: {
                                    slidesPerView: 12,
                                },
                                2090: {
                                    slidesPerView: 11,
                                },
                                1920: {
                                    slidesPerView: 10,
                                },
                                1800: {
                                    slidesPerView: 9,
                                },
                                1601 :{
                                    slidesPerView:8,
                                },
                                1450 :{
                                    slidesPerView:7,
                                },
                                1280: {
                                    slidesPerView:6
                                }
                              }
                            }
                            wrapperTag="ul"
                            spaceBetween={20}
                            className ={'catch-list'}
                            slidesPerView={6}
                            allowTouchMove={false}
                            loop={data.length > 6}
                        >
                            {data.map((vod, index) => {
                                return (
                                    <SwiperSlide tag="li" key={index} data-type="cBox">
                                        <CatchItem vod={vod} path3="catch" onChangeLayer={handleLayer} onPosition={handlePosition} onCallBackChangeLayer={handleCallbackLayer}/>
                                    </SwiperSlide>);
                            })}
                        </Swiper>
                    </div>    
                </div>
            </>
        );
    } else {
        return null;
    }
}