import { ROOT_URL } from "constant/config";
import { GET } from "constant/method";
import { handleFetchErrors } from "../helpers";

export const FAVORITE_CATCH_STORY = {
    LOADING_START: "favorite_catchstory/LOADING_START",
    LOADING_DONE: "favorite_catchstory/LOADING_DONE",
    FETCH_SUCCESS: "favorite_catchstory/FETCH_SUCCESS",
    FETCH_ERROR: "favorite_catchstory/FETCH_ERROR",
};

/**
 * MY 캐치 스토리 전체 리스트 가져오기
 */
export function fetchFavoriteCatchStorys() {
    return (dispatch) => {
        dispatch({ type: FAVORITE_CATCH_STORY.LOADING_START });

        fetch(`${ROOT_URL}/favorite/catchstory`, {
            method: GET,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FAVORITE_CATCH_STORY.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CATCH_STORY.FETCH_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({ type: FAVORITE_CATCH_STORY.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CATCH_STORY.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}

/**
 * MY 캐치 스토리 그룹핑 리스트 가져오기 
 * @param  group_idx, 즐찾BJ 그룹 아이디
 */
export function fetchFavoriteGroupingCatchStorys(group_idx) {
    return (dispatch) => {
        dispatch({ type: FAVORITE_CATCH_STORY.LOADING_START });

        fetch(`${ROOT_URL}/favorite/catchstory/${group_idx}`, {
            method: GET,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({ type: FAVORITE_CATCH_STORY.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CATCH_STORY.FETCH_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({ type: FAVORITE_CATCH_STORY.LOADING_DONE });
                dispatch({
                    type: FAVORITE_CATCH_STORY.FETCH_ERROR,
                    payload: error,
                });
            });
    };
}