import { fetchBroad } from "actions/broading.action";
import { fetchFanclub } from "actions/fanclub.action";
import { fetchVod } from "actions/vods.action";
import BroadingSection from "components/section/BroadingSection";
import TitleSection from "components/section/TitleSection";
import VodSection from "components/section/VodSection";
import useCustomListModal from "components/vods/useCustomListModal";
import ListLayer from "components/vods/ListLayer";
import AddLayer from "components/vods/AddLayer";


import { FANCLUB_ROUTE } from "constant/routeUrl";
import { goLogin } from "helpers";
import i18next from "i18next";
import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "react-navigationbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import FanclubListSection from "./FanclubListSection";
import useAdBanner from "components/hook/useAdBanner";
import MidBanner from "pages/banner/MidBanner";


export default function Fanclub() {
    const { pathname, key } = useLocation();
    const [isLoad, setIsLoad] = useState(false);

    const { isLogin } = useAuth();

    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const {loading, data } = useSelector((state) => state.fanclubReducer, shallowEqual);
    const { 
        customLayer, customTop, customLeft, titleNo,
        setCustomLayer, setCustomTop, setCustomLeft, setTitleNo,
        handleCustomLayer, handleCustomPosition, handleCustomCallbackLayer
    } = useCustomListModal();
    const { renderBanner } = useAdBanner();

    /**
     * 팬클럽 변경되면 방송중데이터 갱신
     */
    useEffect(() => {
        dispatch(fetchBroad(data, pathname));
    }, [data, dispatch]);

    useEffect(() => {
        initSetting();
        setIsLoad(true);
        
    }, [dispatch, isLogin, pathname]);

    //최초 진입 이후 LNB 동일 영역 클릭시 동작
    useEffect(()=>{
        if(isLoad && pathname === FANCLUB_ROUTE){
            window.location.reload();
        }
    }, [key])

    /*const handleLayer = useCallback((layer, titleNo) =>{
        setLayer(layer);
        setTitleNo(titleNo);
    },[setLayer])


    const handlePosition = useCallback((top,left)=>{
        setTop(top);
        setLeft(left);
    }, [])

    const handleCallbackLayer = useCallback((layer, titleNo) =>(event) =>{
        event.stopPropagation();
        setLayer(layer);
        setTitleNo(titleNo);
    },[setLayer])*/


    const initSetting = () =>{
        if (isLogin) {
            // 구독 정보 조회
            dispatch(fetchFanclub());
            // 구독한 BJ의 VOD 조회
            dispatch(fetchVod(pathname));
           
        } else {
            goLogin();
        }
    }

    return (
        <>
        <div id="list-section">
            {
                
            }
            <TitleSection big className="line_none">
                {i18next.t(`팬가입한 BJ`)}
            </TitleSection>
            {/* 서브브랜딩 배너 추가 */}
            <MidBanner content={renderBanner}></MidBanner>
            {!loading && data.length > 0 && (
                <>
                    <BroadingSection layer={customLayer} handlePosition={handleCustomPosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer} />
                    <VodSection layer={customLayer} handlePosition={handleCustomPosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer}  />   
                </>
            )}

            <FanclubListSection />
        </div>

        {customLayer === "LIST" && <ListLayer layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo}  pathname={pathname} top={customTop} left={customLeft} />}
        {customLayer === "ADD" && <AddLayer layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo} top={customTop} left={customLeft} />}
        </>
    );
}
