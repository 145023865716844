import FavoriteIcon from 'components/box/FavoriteIcon';
import LiveWatchButton from "components/button/LiveWatchButton";
import { makeProfileUrl, makeStationUrl } from 'helpers';
import i18next from 'i18next';
import React from 'react';
import PinButton from 'components/button/PinButton';
import ProfileImage from "components/image/ProfileImage";

export default function FanclubItem({ fanclub }) {


    return (
        <>
            <a href={makeStationUrl(fanclub.user_id)} className="wrap" target="_blank" >
                <ProfileImage className="thumb" tag="div" src={makeProfileUrl(fanclub.user_id)} user_id={fanclub.user_id}/>
                <div className="nick"><strong>{`${fanclub.user_nick}`}</strong>
                    {fanclub.is_subscribe && <span className="ico_subscribe">{i18next.t(`구독`)}</span>}
                    {fanclub.is_fanclub && <span className="ico_fan">{i18next.t(`팬`)}</span>}
                </div>
                <span className="id">{fanclub.user_id}</span>
                <span className="fan_join">{i18next.t(`팬클럽 가입일`)}<em>{fanclub.first_date}</em></span>
                {!fanclub.is_live && <span className="last_live">{i18next.t(`최근방송`)} <em>{fanclub.last_broad_start}</em></span>}
            </a>
            {fanclub.is_live && <LiveWatchButton broad={fanclub.broad_info[0]} />}
            <PinButton item={fanclub} />
            <FavoriteIcon item={fanclub} className="btn-fav" />
        </>
    )
}
