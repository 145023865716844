import { DELETE, POST } from "constant/method";
import i18next from "i18next";
import querystring from "querystring";
import { ROOT_URL } from "../constant/config";
import { handleFetchErrors, sendLogApi } from "../helpers";
import { API } from "./api.action";
import { toast } from "afreecatv-react-toast";


export const FANCLUB = {
    LOADING_START: "fanclub/LOADING_START",
    LOADING_DONE: "fanclub/LOADING_DONE",
    FETCH_SUCCESS: "fanclub/FETCH_SUCCESS",
    FETCH_ERROR: "fanclub/FETCH_ERROR",
    FILTER_PIN: "fanclub/FILTER_PIN",
};


/**
 * 팬클럽 목록 가져오기
 */
export const fetchFanclub = () => {
    return (dispatch) => {
        dispatch({ type: FANCLUB.LOADING_START });

        fetch(`${ROOT_URL}/fanclub`, {
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FANCLUB.FETCH_SUCCESS,
                    payload: response,
                });
                dispatch({ type: FANCLUB.LOADING_DONE });
            })
            .catch((error) => {
                dispatch({
                    type: FANCLUB.FETCH_ERROR,
                    payload: error,
                });
                dispatch({ type: FANCLUB.LOADING_DONE });
            });
    }
}

/**
 * 핀 추가
 * @param  fanclubNo 팬클럽 번호
 * @param  userId    유저 아이디
 * @return
 */
export const storeFanclubPin = (fanclubNo, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/fanclub/pin`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                common_no: fanclubNo,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({ type: API.LOADING_DONE });
                dispatch({
                    type: FANCLUB.FILTER_PIN,
                    payload: { fanclubNo: fanclubNo, flag: true }
                })
                let sendData = {
                    location: 'fanbj',
                    action_type: 'add_fix',
                    bj_id: userId,
                };
                sendLogApi("main_00000008", sendData);
                toast.success(i18next.t(`목록 상단 고정 완료 되었습니다.`));
            })
            .catch((error) => {
                dispatch({ type: API.LOADING_DONE });
                toast.error(i18next.t(error.message));
            });
    }
}

/**
 * 핀 삭제
 * @param  fanclubNo 팬클럽 번호
 * @param  userId    유저 아이디
 * @return
 */
export const destroyFanclubPin = (fanclubNo, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}/fanclub/pin/${fanclubNo}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
                common_no: fanclubNo,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({ type: API.LOADING_DONE });
                dispatch({
                    type: FANCLUB.FILTER_PIN,
                    payload: { fanclubNo: fanclubNo, flag: false }
                });
                let sendData = {
                    location: 'fanbj',
                    action_type: 'del_fix',
                    bj_id: userId,
                }
                sendLogApi("main_00000008", sendData);
                toast.success(i18next.t(`목록 상단 고정 해제 되었습니다.`));
            })
            .catch((error) => {
                dispatch({ type: API.LOADING_DONE });
                toast.error(i18next.t(error.message));
            });
    }
}
