import { FANCLUB } from "actions/fanclub.action";
import { FAVORITE_ADD, FAVORITE_REMOVE } from "actions/feed.favorite.action";
import produce from "immer";

const initialState = {
    loading: true,
    data: [],
    error: null
};

const fanclubReducer = (state = initialState, action) => {
    switch (action.type) {
        case FANCLUB.LOADING_START:
            return { ...state, loading: true };

        case FANCLUB.LOADING_DONE:
            return { ...state, loading: false };

        case FANCLUB.FETCH_SUCCESS:
            return { ...state, data: action.payload.data, error: null };

        case FANCLUB.FETCH_ERROR:
            return { ...state, error: action.payload };


        case FAVORITE_ADD:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(item => item.user_id === action.payload.user_id)
                if (index > -1) {
                    draftState.data[index].is_favorite = true;
                }
            });
        case FAVORITE_REMOVE:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(item => item.user_id === action.payload.favorite_id)
                if (index > - 1) {
                    draftState.data[index].is_favorite = false;
                }
            });


        case FANCLUB.FILTER_PIN:
            return produce(state, (draftState) => {
                const index = draftState.data.findIndex(item => item.fanclub_no === action.payload.no)
                if (index > - 1) {
                    draftState.data[index].is_pin = action.payload.flag;
                }
            });

        default:
            return state;
    }
};

export default fanclubReducer;
