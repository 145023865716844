import { HASHTAG } from "actions/feed.hashtag.action";
import produce from 'immer';

const initialState = {
    loading: false,
    meta: {},
    data: [],
    has_more: true,
    title_no: null,
    error: null,
    total: 0
};

const feedHashtagReducer = (state = initialState, action) => {
    switch (action.type) {
        case HASHTAG.INIT:
            return initialState;
        case HASHTAG.LOADING_START:
            return { ...state, loading: true };

        case HASHTAG.LOADING_DONE:
            return { ...state, loading: false };

        case HASHTAG.FETCH_SUCCESS:
            return produce(state, (draftState) => {
                draftState.has_more = action.payload.has_more
                draftState.meta = action.payload.meta
                draftState.data = state.data.concat(action.payload.data);
                if (draftState.data.length) {
                    draftState.index_reg_date = draftState.data[draftState.data.length - 1].index_reg_date
                }
                //전체갯수는 첫페이호출할때만
                if (action.index_reg_date === 0) {
                    draftState.total = action.payload.meta.total;
                }

            });

        case HASHTAG.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default feedHashtagReducer;