import { FAVORITE_GROUPITEM } from "actions/favorite.groupitem.action";

const initialState = {
    itemloading: false,
    groupitem: [],
    error: null
};

const favoriteGroupItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case FAVORITE_GROUPITEM.LOADING_START:
            return { ...state, loading: true };

        case FAVORITE_GROUPITEM.LOADING_DONE:
            return { ...state, loading: false };

        case FAVORITE_GROUPITEM.FETCH_SUCCESS:
            return { ...state, groupitem: action.payload.data.list, error: null };

        case FAVORITE_GROUPITEM.FETCH_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};

export default favoriteGroupItemReducer;
