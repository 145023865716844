import { useEffect, useState, useContext } from 'react'
import { WindowSizeContext } from 'components/context/WindowSizeProvider';

export default function useWideAreaClass() {
    const size = useContext(WindowSizeContext)
    const [sizeClass, setSizeClass] = useState("");

    useEffect(() => {
        if (size.width > 1737) {
            setSizeClass("listsix");
        } else if (size.width > 1493) {
            setSizeClass("listfive");
        } else {
            setSizeClass("");
        }
    }, [size.width]);

    return sizeClass;
}
