import React, { useEffect, useState, useCallback, useMemo, useRef, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LAYER } from "components/vods/PlayListLayer";
import useOutsideClick from "components/hook/useOutsideClick";
import {JAVASCRIPT_VOID} from "constant/config";
import i18next from 'i18next';
import { addFavoriteGroup,fetchFavoriteGroup, FAVORITE_GROUP } from 'actions/favorite.group.action';
import { sendLogApi } from "helpers";
import FavoriteGroupProvider, {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";

export default function  FavoriteListLayer ({ref, layers, onLayer, onFavoriteId, favoriteId, top, left}) {
    const dispatch = useDispatch();
    const currentRef = useRef();
    const [selectIdx, setSelectIdx] = useState([]);
    const [visible, setVisible] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);


    useOutsideClick(currentRef, () => {
        onLayer('');
        onFavoriteId('');
    }, [onLayer, onFavoriteId]);
    
    const handleClickLayer = useCallback(() => (event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }, []);

    useEffect(() => {
        dispatch(fetchFavoriteGroup(favoriteId));
        setVisible(layers==='FAVORITELIST' ? true : false);
    }, [dispatch]);
    
    const { loading, group_data = [] } = useSelector(state => (state.favoriteGroupReducer), shallowEqual);

    	// 즐찾 목록 담기 체크
	const handleCheckFavoriteLayer = (checked, idx) => {
        // dispatch(checkPlaylistLayer(idx));
        if(checked) {
            setSelectIdx([...selectIdx, idx]);
        } else {
            setSelectIdx(selectIdx.filter(listIdx=>listIdx!==idx));
        }
        if(!isChange)
            setIsChange(true)
    }
    
    const handleUpdateFavoriteGroupLayer = useCallback(() => {
        if(isChange) {
            let list = group_data.filter((item)=>!selectIdx.some(item2=>item2===item.idx)&& item.already);
            let remove_list = list.filter((item)=>{
                if(item.total_cnt <= 1)
                {   
                    if(item.idx === groupIdx)
                        setGroupIdx(0);
                    return item;
                }
            })
            let current_list = group_data.filter((item)=>!remove_list.some(item2=>item2.idx===item.idx));
            dispatch({type:FAVORITE_GROUP.FETCH_DATA,payload:current_list})
            dispatch(addFavoriteGroup(selectIdx.join(","),favoriteId ));
            
            
        }
        onLayer('');
        onFavoriteId('');
        setVisible(false);
    }, [selectIdx, dispatch, setVisible, onLayer, onFavoriteId]);

    useEffect(()=>{
        if(group_data.length > 0){
            let selIdx = []
            group_data.map((item)=>{
                if(item['already']) selIdx.push(item['idx']);
                return item;      
            });
            
            setSelectIdx(selIdx);
        }
        

    },[group_data])

    return (
        <>
            <div ref={currentRef} className="ui-pop PlayListAdd" style={{ display: visible ? "block" : "none", position: "absolute", top: top, left: left}} onClick={handleClickLayer}>
                <div className="pop-title">
                    <h3>{i18next.t(`즐찾BJ 그룹`)}</h3>
                </div>
                <div className="pop-body">
                    <ul className="PlayListBox">
                        {group_data.map((playlist) => (
                           <li key={playlist.idx}>
                                <input 
                                    type="checkbox" 
                                    id={playlist.idx} 
                                    onChange={(e) => handleCheckFavoriteLayer(e.target.checked, playlist.idx)}
                                    checked={selectIdx.indexOf(playlist.idx) >= 0 ? true : false}
                                    />
                                <label htmlFor={playlist.idx} >{playlist.title}</label>
                            </li>
                        ))}       
                    </ul>
                    {group_data.length <10 &&
                        <button type="button" className="btn_NewAddList" onClick={() => {
                            onLayer("FAVORITEADD");
                            onFavoriteId(favoriteId);
                            sendLogApi("fav_grouping", {
                                button_type:'create_method3',
                                location: 'favorite',
                            });
                        }}>+ {i18next.t(`새 그룹 만들기`)}</button>
                    }
                </div>
                <div className="pop-btn">
                    <button type="button" className="btn" onClick={handleUpdateFavoriteGroupLayer}>{i18next.t(`확인`)}</button>
                </div>
                <a href="#" className="pop-close" onClick={(e) => {
                        e.preventDefault();
                        onLayer("");
                        onFavoriteId('');
                    }}><span>{i18next.t(`닫기`)}</span></a>
            </div>
        </>
    );
}