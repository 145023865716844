import React, { useRef, useState, useCallback, useEffect } from 'react'
import Slider from "react-slick";
import classNames from "classnames";

export default function SliderBox({ children, setting }) {
    const sliderRef = useRef(null);
    const [autoplay, setAutoplay] = useState(true)

    const handlePlay = useCallback(() => {
        setAutoplay(!autoplay);
    }, [autoplay])

    /**
     * 자동플레이
     */
    useEffect(() => {
        autoplay ? sliderRef.current.slickPlay() : sliderRef.current.slickPause()
    }, [autoplay])

    return (
        <div className="broadcast-slider">
            <div className="controller">
                <button type="button" className={classNames({ "slider-stop": autoplay, "slider-play": !autoplay })} onClick={handlePlay} ></button>
                <button type="button" className="arrow-prev" onClick={() => sliderRef.current.slickPrev()}>이전</button>
                <button type="button" className="arrow-next" onClick={() => sliderRef.current.slickNext()}>다음</button>
            </div>
            <Slider
                className="slider-list"
                ref={sliderRef}
                dots={false}
                autoplay={children.length > 6 ? true : false}
                infinite={children.length > 6 ? true : false}
                draggable={false}
                arrows={false}
                variableWidth={true}
                {...setting} >
                {children}
            </Slider>
        </div>
    )
}
