import { usePopup } from "components/context/PopupProvider";
import React, { useCallback, useMemo, useState } from "react";

export default function ThreeDotButton({ trigger, children }) {
    const { isActivePopup, togglePopup } = usePopup();

    const [ top , setTop ] = useState(0);

    const handleClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();

            togglePopup();
        },
        [togglePopup],
    );

    /**
     * 버튼 이벤트 추가
     */
    const renderTrigger = useMemo(() => {
        const triggerProps = { key: "T", onClick: handleClick };
        if (typeof trigger === "function") {
            return React.cloneElement(trigger(), triggerProps);
        } else if (trigger.length > 1) {
            return React.Children.map(trigger, (child, index) => React.cloneElement(child, { ...triggerProps, key: index }));
        } else {
            return React.cloneElement(trigger, triggerProps);
        }
    }, [handleClick, trigger]);

    return (
        <>
            {/**  버튼 */}
            {renderTrigger}
            {isActivePopup && <div className="more-layer" >{children}</div>}
        </>
    );
}
