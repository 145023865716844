import React, { useCallback, useMemo, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import {fetchDeletePlaylist} from "actions/vods.action";
import { STBBS_URL } from "constant/config";
import { HISTORY_PLAYLIST_ROUTE } from "constant/routeUrl";
import PlaylistVodThumbnail from "components/thumbnail/PlaylistVodThumbnail";
import ThreeDotHistoryButton from "components/button/ThreeDotHistoryButton";
import ShareButton from "components/button/ShareButton";
import ContextMenu from "components/ict/ContextMenu";
import ItemCommon from "components/item/ItemCommon";
import { makePlaylistUrl, regDateConvert, getLanguageStr } from "helpers";
import i18next from "i18next";
import { vodInflowLog } from "libs/log";
import { toast } from "afreecatv-react-toast";

export default function PlayListItem({ vod, path3, onChangeLayer, onPosition }) {
    const [ layer, setLayer ] = useState("");
    const [titleNo, setTitleNo] = useState(0);
    const { pathname } = useLocation();
    const currentRef = useRef(null);
    const dispatch = useDispatch();

    const { no, list_idx, copyright_id, add_id, isOrigin, playlist } = vod;
    let { config = {}, count = {}, bbsTitle: post = {} } = playlist;
    post = post || {};
    const ucc = post.ucc || {};

    const { hash_tags = [] , category_tags = [] } = ucc; // 해시태그, 고정태그
    const lang = document.documentElement.lang;
    const lang_str = lang === "kr" ? "kr_KR" : getLanguageStr(lang).toLowerCase();
    const { hashtagUrl } = ItemCommon();

    /**
     * 영상 진입 로그 호출
     */
    const onClickVodLink = useCallback(
        (data) => (e) => {
            if(count.playlist_cnt < 1) {
                toast.success(i18next.t(`재생가능한 VOD가 없습니다.`));
                return false;
            }

            let pathType = "";
            let etcData = {
                //'tag': hash_tags.join("|"),
                //'category_tag': category_tags.join("|"),
            }
            switch (pathname) {
                case HISTORY_PLAYLIST_ROUTE:
                    pathType = "history";
                    break;
                default:
                    return false;
            }

            vodInflowLog(data.user_id, data.title_no, ["my", pathType, path3 || "vod"], etcData);
        },
        [category_tags, count.playlist_cnt, hash_tags, path3, pathname],
    );

    const handleGoPlayList = useCallback((listIdx) => (event) => {
        window.open(`${STBBS_URL}/vodplaylist/index.php?nPlaylistIdx=${listIdx}`);
    });

    const handleDeletePlayList = useCallback((listIdx, share) => (event) => {
        if(!window.confirm(i18next.t("재생목록을 삭제하시겠습니까?"))){
            return false;
        }

        dispatch(fetchDeletePlaylist(listIdx, share));
    }, [dispatch]);

    const handleClickLayer = useCallback((layer, titleNo) => (event) => {
        setLayer(layer);
        setTitleNo(titleNo);
    }, [setLayer, setTitleNo]);

    const handleChangeLayer = useCallback((layer, titleNo) => {
        onChangeLayer(layer, titleNo);
        setLayer(layer);
        setTitleNo(titleNo);
    }, [onChangeLayer, setLayer, setTitleNo]);

    /**
     * 재생목록 플레이어페이지 URL
     */
    const url = useMemo(() => makePlaylistUrl(playlist), [playlist]);
    return (
        <>
            <PlaylistVodThumbnail href={url} display={config} vod={ucc} onClick={onClickVodLink(ucc)} count={count} pathname={pathname} />

            <div className="cBox-info">
                <h3>
                    <ContextMenu user_id={playlist.reg_id} user_nick={playlist.reg_nick}>
                        <div className="nick">
                            <a href="#">{playlist.reg_nick}</a> 
                            {playlist.is_subscribe ? <span className="ico_subscribe">{i18next.t(`구독`)}</span> : ``}
                            {playlist.is_fanclub ? <span className="ico_fan">{i18next.t(`팬`)}</span> : ``}
                        </div>
                    </ContextMenu>
                    
                    <ThreeDotHistoryButton
                        onPosition={onPosition}
                        onChangeLayer={handleChangeLayer}
                        trigger={
                            <button type="button" className="more_dot">
                                <span>더보기 메뉴</span>
                            </button>
                        }>
                        {playlist.reg_id === add_id && <button type="button" onClick={handleGoPlayList(list_idx)} >
                            <span>
                                {i18next.t(`수정하기`)}
                            </span>
                        </button>}
                        <button type="button" onClick={handleDeletePlayList(list_idx, playlist.reg_id === add_id)} >
                            <span>
                                {i18next.t(`삭제하기`)}
                            </span>
                        </button>
                        <button type="button" onClick={handleClickLayer("SHARE",0)}>
                            <span>
                                {i18next.t(`공유하기`)}
                            </span>
                        </button>
                    </ThreeDotHistoryButton>
                </h3>
                { layer === "SHARE" && <ShareButton item={vod.playlist} type="PLAYLIST" layer={layer} setLayer={setLayer} onChangeLayer={handleChangeLayer} />}

                {/** 재생목록 제목 */}
                <a href={url} target="_blank" className="title" title="방송 풀 제목 노출">{playlist.title}</a>
                
                <span className="update">{i18next.t(`업데이트`)} : {regDateConvert(playlist.reg_date)}</span>
                <div className="allList"> 
                    <button type="button" onClick={handleGoPlayList(list_idx)}>{i18next.t(`전체보기`)}</button>
                    {config.display === 'N' && <span className="hidden_list">{i18next.t(`비공개`)}</span>}
                </div>
                
                {/** 고정 태그, 카테고리 태그 추가 */}
                <div className="tag_wrap">
                    {category_tags.length > 0 && category_tags.map((category, idx) => {
                        // [""] 으로 빈 값으로 들어오는 케이스가 있음
                        return category !== "" && <a href={hashtagUrl(category, "VOD")}>#{category}</a>
                    })}
                    {hash_tags.length > 0 && hash_tags.map((hash, idx) => {
                        return hash !== "" && <a href={hashtagUrl(hash, "VOD")}>#{hash}</a>
                    })}
                </div>

            </div>
        </>
    );
}
