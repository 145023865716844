import { fetchBroad } from "actions/broading.action";
import { fetchFavorite } from "actions/favorite.action";
import { fetchFavoriteClips, fetchFavoriteUserClips } from "actions/favorite.clip.action";
import {fetchFavoriteCatchs,  fetchFavoriteUserCatchs } from "actions/favorite.catch.action";
import {fetchFavoriteCatchStorys, fetchFavoriteGroupingCatchStorys } from "actions/favorite.catchstory.action";
import { FAVORITE_ADD } from "actions/feed.favorite.action";
import { fetchRecommand } from "actions/recommands.action";
import { fetchVod } from "actions/vods.action";
import BroadingSection from "components/section/BroadingSection";
import RecommandSection from "components/section/RecommandSection";
import TitleSection from "components/section/TitleSection";
import UserClipSection from "components/section/UserClipSection";
import UserCatchSection from "components/section/UserCatchSection";
import UserCatchStorySection from "components/section/UserCatchStorySection";
import VodSection from "components/section/VodSection";
import { ES_INDEX_STATION } from "constant/config";

import { goLogin } from "helpers";
import i18next from "i18next";
import { useReudxSubsribe } from "middlewares/ReduxSubscribeMiddleware";
import React, { useCallback, useEffect,useState,useContext } from "react";
import { useAuth } from "react-navigationbar";
import { shallowEqual, useDispatch, useSelector,  } from "react-redux";
import FavoriteGroupProvider, {FavoriteGroupContext} from "components/context/FavoriteGroupProvider";
import { useLocation } from "react-router";
import FavoriteGroupSection from "./FavoriteGroupSection";
import FavoriteFeedSection from "./FavoriteFeedSection";
import FavoriteListSection from "./FavoriteListSection";
import { sendLogApi } from "helpers";
import { FAVORITE_ROUTE} from "constant/routeUrl";

import { fetchFavoriteGroup } from "actions/favorite.group.action";


import FavoriteListLayer from "components/vods/FavoriteListLayer";
import FavoriteAddLayer from "components/vods/FavoriteAddLayer";
import useCustomListModal from "components/vods/useCustomListModal";
import ListLayer from "components/vods/ListLayer";
import AddLayer from "components/vods/AddLayer";

import useAdBanner from "components/hook/useAdBanner";
import MidBanner from "pages/banner/MidBanner";


export default function Favorite({ref}) {
    const { pathname, key } =  useLocation();
    const { isLogin } = useAuth();
    const {groupIdx, groupTitle, setGroupIdx, setGroupTitle} = useContext(FavoriteGroupContext);

    // dispatch
    const dispatch = useDispatch();

    // useSelector
    const { loading, extdata, poolCheck } = useSelector((state) => state.favoriteExtReducer, shallowEqual);

    const [isLoad, setIsLoad] = useState(false);
    const [isPool, setIsPool] = useState(0);
    const [groupChangeIndex, setGroupChangeIndex] = useState(0);
    const { 
        favoriteId, customLayer, customTop, customLeft, titleNo,
        setFavoriteId, setCustomLayer, setCustomTop, setCustomLeft, setTitleNo,
        //handleFavoriteLayer, handleFavoritePosition, handleCallbackFavoriteLayer,
        handleCustomLayer, handleCustomPosition, handleCustomCallbackLayer, handleFavoritePosition
    } = useCustomListModal();
    
    const { renderBanner } = useAdBanner();
  

    const lang = document.documentElement.lang;

    // 최초진입 즐찾 데이터 가져오기
    useEffect(() => {
        initSetting();
        setIsLoad(true);
    }, []);

    //최초 진입 이후 LNB 동일 영역 클릭시 동작
    useEffect(()=>{

        if(isLoad && pathname === FAVORITE_ROUTE){
           window.location.reload();
        }
    }, [key])



    /**
     * 숨김처리 값 셋팅
     */
    useEffect(() => {
        setIsPool(poolCheck);
    },[poolCheck]);

    /**
     * 최초진입 그룹 데이터 가져오기
     */
    useEffect(() => {
        if (window.oSimplePage) {
            dispatch(fetchFavoriteGroup());
        }
    }, [dispatch]);

    /**
     * 즐겨찾기데이터가 변경되면 방송중데이터 갱신
     */
    useEffect(() => {
        dispatch(fetchBroad(extdata, pathname));
    }, [extdata, dispatch]);

    useEffect(()=>{
    },[pathname])

    const initSetting = ()=>{
        fetchFavData(0);
        setGroupIdx(0);
    }

    /**
     * 즐찾 전체, 그룹핑 스위치 함수
     */
    const fetchFavData = useCallback((group_idx)=>{
        setGroupChangeIndex(group_idx); // 
        if (isLogin) {
            // 즐겨찾기 정보 조회
            dispatch(fetchFavorite(group_idx));
        } else {
            goLogin();
        }
    }, [dispatch, setGroupChangeIndex, groupChangeIndex]);

    /**
     * VOD 영역 + 즐찾 할만한 BJ 영역 useEffeect로 따로 뺴둠 (poolCheck - 영역 숨김)
     */
    useEffect(() => {
        // 즐겨찾기 유저클립 정보 조회
        // setIsPool(poolCheck);
        if (window.oSimplePage && window.oSimplePage.fav.fav_vod === 1 && (isPool > 0)) {
            // 즐겨찾기한 BJ의 유저클립 조회
            if(groupChangeIndex > 0){
                dispatch(fetchFavoriteUserClips(groupChangeIndex));
                dispatch(fetchFavoriteUserCatchs(groupChangeIndex));
                dispatch(fetchFavoriteGroupingCatchStorys(groupChangeIndex));
            }
            else{
                // 24.03.07 sch ES > 방송국 ES 변경 건, 즐찾 그룹핑이랑 동일하게 호출
                if (ES_INDEX_STATION) {
                    dispatch(fetchFavoriteUserClips(groupChangeIndex));
                    dispatch(fetchFavoriteUserCatchs(groupChangeIndex));
                } else {
                    dispatch(fetchFavoriteClips());
                    dispatch(fetchFavoriteCatchs("fav_catch"));
                }
                
                dispatch(fetchFavoriteCatchStorys());
            }
            // 즐겨찾기한 BJ의 VOD 조회
            dispatch(fetchVod(pathname, '',groupChangeIndex));
            // 즐겨찾기 할 만한 방송 조회
            if (window.oSimplePage.fav.recommend_live === 1) {
                dispatch(fetchRecommand());
            }
        }
    }, [dispatch, isPool, groupChangeIndex]);

    /**
     * 즐겨찾기가 추가됐을경우
     */
    useReudxSubsribe(
        FAVORITE_ADD,
        useCallback(
            (action) => {
                // 즐겨찾기 정보 조회
                dispatch(fetchFavorite());
            },
            [dispatch],
        ),
    );

    /**
     * 즐찾리스트로
     */
    const goFavoriteListSection = useCallback(() => {
        document.querySelector(".all_alarm").className = "all_alarm on";

        //UI 쪽 Header UI가 추가 되어scrollIntoView는 못씀(전체 UI 계속 바꿔야할듯..?)
        window.scrollTo({
           top : document.querySelector('#favorite_list').offsetTop+148,
           left : 0,
           behavior : 'smooth'
        });

        setTimeout(() => {
            document.querySelector(".all_alarm").className = "all_alarm";
        }, 3000);
    }, []);

        /**
     * 영상 진입 로그 호출
     */
    const onClickVodLink = useCallback(
        () => {
            let sendData = {
                watch_type: "vod",
                button_type: "to_vod"
            };
            let codeType = "";
            
            switch (pathname) {
                case FAVORITE_ROUTE:
                    codeType = "my_home_vod";
                    sendData['button_type'] = "to_vod";
                    break;
                default:
                    return false;
            }
            sendLogApi(codeType, sendData, 'web');
        },
        [pathname]
    );

    return (
        <>
        {extdata.length > 0 &&  
        <>
            <FavoriteGroupSection favorite={extdata} fetchFavData = {fetchFavData}  setGroupTitle={setGroupTitle} >
                <TitleSection
                    big
                    className="line_none"
                    right={
                        <>
                            <div className="favor_all_wrap">
                                <button type="button" className="favor_all" onClick={goFavoriteListSection}>
                                    {i18next.t(`전체`)}
                                </button>
                            </div>
                        <button type="button" className="favor_bj" onClick={() => (window.location.href = "/feed")}>
                                {i18next.t(`방송국 새 글`)}
                            </button>
                        </>
                    }
                >
                    {i18next.t(`즐겨찾기한 BJ`)}
                </TitleSection>
                
            </FavoriteGroupSection>
            {lang != "ko" && (
                // MY+ 서비스로 DOM구조 변경 - 국내만 적용
                <FavoriteFeedSection />
            )}
         </>
        }
    
        <div id="list-section">
            {
                !loading && extdata.length === 0 &&
                <>
                <TitleSection
                    big
                    className="line_none"
                    right={
                        <>
                            <div className="favor_all_wrap">
                                <button type="button" className="favor_all" onClick={goFavoriteListSection}>
                                    {i18next.t(`전체`)}
                                </button>
                            </div>
                        <button type="button" className="favor_bj" onClick={() => (window.location.href = "/feed")}>
                                {i18next.t(`방송국 새 글`)}
                            </button>
                        </>
                    }
                >
                    {i18next.t(`즐겨찾기한 BJ`)}
                </TitleSection>
                <FavoriteFeedSection/>
                </>
            }
            
            {extdata.length > 0 && (
                <>
                    {lang === "ko" && (
                        // MY+ 서비스로 DOM구조 변경 - 국내만 적용
                        <FavoriteFeedSection />
                    )}
                    {" "}

                    {/* 서브브랜딩 배너 추가 */}
                    <MidBanner content={renderBanner}></MidBanner>
 
                    {/* 방송 중 */}
                    <BroadingSection layer={customLayer} handlePosition={handleFavoritePosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer} />

                    {/**
                     * 즐겨찾기한 BJ 유저 클립,캐치,VOD
                     */}
                     {isPool > 0 ? (
                         <>
                        <UserCatchSection layer={customLayer} handlePosition={handleFavoritePosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer} location="top"/>
                        <UserCatchStorySection layer={customLayer} handlePosition={handleFavoritePosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer}/>
                        <UserClipSection layer={customLayer} handlePosition={handleFavoritePosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer} />
                        <VodSection layer={customLayer} handlePosition={handleFavoritePosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer}  />
                        <UserCatchSection layer={customLayer} handlePosition={handleFavoritePosition} handleLayer={handleCustomLayer} handleCallbackLayer = {handleCustomCallbackLayer} location="bottom"/>
                        </>
                     ) : ""}
                    {/**
                     * 즐겨찾기 할만한 BJ
                     */}
                    <RecommandSection layer={customLayer} onPosition={handleFavoritePosition} onChangeLayer={handleCustomLayer} onCallbackLayer = {handleCustomCallbackLayer} />

                </>
            )}

            <FavoriteListSection  onPosition={handleFavoritePosition} onChangeLayer={handleCustomLayer} onCallbackLayer = {handleCustomCallbackLayer} />
            {customLayer === "FAVORITELIST" && <FavoriteListLayer ref={ref} favoriteId={favoriteId} layers={customLayer} onLayer={setCustomLayer} onFavoriteId={setFavoriteId} top={customTop} left={customLeft}/>}
            {customLayer === "FAVORITEADD" && <FavoriteAddLayer ref={ref} favoriteId={favoriteId} layers={customLayer} onLayer={setCustomLayer} onFavoriteId={setFavoriteId} top={customTop} left={customLeft}/>}
            {customLayer === "LIST" && <ListLayer layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo}  pathname={pathname} top={customTop} left={customLeft} />}
            {customLayer === "ADD" && <AddLayer layers={customLayer} title_no={titleNo} onLayer={setCustomLayer} onTitle={setTitleNo} top={customTop} left={customLeft} />}
        </div>
        </>
    );
}
