import React, { useState, useCallback, useMemo } from "react";
import { secondToTime } from "helpers";
import LaterViewButton from "components/vods/LaterViewButton";
import Badge from "components/vods/Badge";
import VrBadge from "components/vods/VrBadge";
import {isAdultType} from "helpers";

export default function FeedHashVodThumbnail({ vod, titleNo, onLoad }) {
	const [imgStatus, setImgStatus] = useState(true);
	const onErrorThumb = useCallback(() => {
		setImgStatus(false);
	}, []);

	const thumbnail = useMemo(() => {
		const { thumb, grade, category } = vod;
		const isAdult = isAdultType(grade,category); //성인여부

		if (isAdult === true) {
			return <span className="thumb-adult">19금</span>;
		} else if (imgStatus === false) {
			return <span className="thumb-default">기본</span>;
		} else {
			return <img className="image" src={thumb} alt="" loading="lazy" onLoad={onLoad} onError={onErrorThumb} />;
		}
	}, [vod, imgStatus, onLoad, onErrorThumb]);

	return (
		<div className="item_thum vod">
			<div className="thum">
				{thumbnail}
				<Badge file_type={vod.file_type} />
				<LaterViewButton title_no={titleNo} />
				<VrBadge ucc_type={vod.ucc_type} />
				<span className="time">{secondToTime(vod.total_file_duration / 1000)}</span>
			</div>
		</div>
	);
}
