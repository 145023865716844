import { ROOT_URL } from "constant/config";
import { DELETE, POST } from "constant/method";
import { FANCLUB_ROUTE, FAVORITE_ROUTE, SUBSCRIBE_ROUTE } from "constant/routeUrl";
import { handleFetchErrors, sendLogApi } from "helpers";
import i18next from "i18next";
import querystring from "querystring";
import { FANCLUB } from "./fanclub.action";
import { FAVORITE,FAVORITE_EXT } from "./favorite.action";
import { SUBSCRIBE } from "./subscribe.action";
import { toast } from "afreecatv-react-toast";
/**
 * 핀 추가
 * @param  no  번호
 * @param  userId     유저 아이디
 * @return
 */
export const storePin = (pathname, no, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}${pathname}/pin`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                common_no: no,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({
                    type: getAactionType(pathname),
                    payload: { no: no, flag: true }
                });


                sendLogApi("main_00000008", {
                    location: getLocation(pathname),
                    action_type: 'add_fix',
                    bj_id: userId,
                });


                toast.success(i18next.t(`목록 상단 고정 완료 되었습니다.`));
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}

/**
 * 핀 삭제
 * @param  no  번호
 * @param  userId     유저 아이디
 * @return
 */
export const destroyPin = (pathname, no, userId) => {
    return (dispatch) => {
        fetch(`${ROOT_URL}${pathname}/pin/${no}`, {
            method: POST,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: querystring.stringify({
                _method: DELETE,
                common_no: no,

            }),
            credentials: "include",
        })
            .then(handleFetchErrors)
            .then((response) => {
                dispatch({
                    type: getAactionType(pathname),
                    payload: { no: no, flag: false }
                });
                //즐찾 그룹핑 작업 때문에 로직 추가
                if(getAactionType(pathname) ===FAVORITE_ROUTE){
                    dispatch({
                        type:FAVORITE_EXT.FILTER_PIN,
                        payload: { no: no, flag: false }
                    })
                }


                sendLogApi("main_00000008", {
                    location: getLocation(pathname),
                    action_type: 'del_fix',
                    bj_id: userId,
                });


                toast.success(i18next.t(`목록 상단 고정 해제 되었습니다.`));
            })
            .catch((error) => {
                toast.error(i18next.t(error.message));
            });
    }
}


const getLocation = (pathname) => {
    switch (pathname) {

        case SUBSCRIBE_ROUTE:
            return "subsbj";

        case FANCLUB_ROUTE:
            return "fanbj";

        default:
        case FAVORITE_ROUTE:
            return "favorite";
    }
}


const getAactionType = (pathname) => {
    switch (pathname) {
        case SUBSCRIBE_ROUTE:
            return SUBSCRIBE.FILTER_PIN;

        case FANCLUB_ROUTE:
            return FANCLUB.FILTER_PIN;
        default:
        case FAVORITE_ROUTE:
            return FAVORITE.FILTER_PIN;
    }
}